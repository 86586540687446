import React from "react";
import { Card } from "react-bootstrap";
import { Navigate, useOutletContext, useParams } from "react-router";
import { subMonths } from "date-fns";

import TableProvider from "components/tables/btable/provider";
import { EmployeeAbsenceAgencyTable, EmployeeAbsenceTable } from "components/tables";
import * as absenceAPI from "api2/absence";

function EmployeeAbsenceListPage({ initialMode, allowedModes, selectable = false, tableId = "absences" }) {
  const { mode } = useParams();
  const company = useOutletContext();
  const initialFilters = {
    mode: initialMode || mode || "",
    date: subMonths(new Date(), 1),
    ordering: "user__last_name",
  };

  if (!company.absence_report_enabled) {
    return <Navigate to="/" replace />;
  }

  if (!company.is_main_company_agency) {
    return (
      <Card>
        <Card.Body>
          <TableProvider
            tableId={tableId}
            dataSource={(filters, config) => absenceAPI.companyEmployeeAbsences(company.id, filters, config)}
            initialFilters={initialFilters}
          >
            <EmployeeAbsenceTable
              companyId={company.id}
              allowedModes={allowedModes}
              forStatus={initialFilters.mode}
              selectable={selectable}
            />
          </TableProvider>
        </Card.Body>
      </Card>
    );
  }
  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => absenceAPI.companyEmployeeAgencyAbsences(company.id, filters, config)}
          initialFilters={initialFilters}
        >
          <EmployeeAbsenceAgencyTable
            companyId={company.id}
            allowedModes={allowedModes}
            forStatus={initialFilters.mode}
            selectable={selectable}
          />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default EmployeeAbsenceListPage;
