import React from "react";
import cx from "classnames";
import { Checkbox } from "../../../../components/ui/checkboxes/Checkbox";

function TDCheckInput({ trans, toggleSelect }) {
  return (
    <td className={cx("selectable", { matched: trans.matched })} onClick={toggleSelect}>
      <Checkbox checked={!!trans.checked} handleChange={toggleSelect} />
    </td>
  );
}

function THCheckInput({ checked, label, toggleSelect, className, style }) {
  return (
    <th
      className={cx("sticky1 th-checkbox", className)}
      onClick={(e) => {
        e.stopPropagation();
        toggleSelect();
      }}
      style={style}
    >
      <Checkbox label={label} checked={checked} handleChange={toggleSelect} className={className} />
    </th>
  );
}

export { TDCheckInput, THCheckInput };
