import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Table } from "react-bootstrap";
import { DateRangeFilters, MultiSelectSimpleFilter } from "components/filters";
import { useTranslation } from "react-i18next";
import * as reportAPI from "api2/reports";
import { addDays, differenceInCalendarMonths, isWithinInterval, lastDayOfMonth } from "date-fns";
import { formatDate, isSameDayOrAfter } from "utils/date";
import { formatMoneyRounding } from "utils/money";
import TableLoader from "components/tables/btable/TableLoader";
import axios from "axios";
import cx from "classnames";
import { FileUrlModal } from "components/modals";
import useModal from "hooks/useModal";
import qs from "qs";
import { useNavigate, useOutletContext } from "react-router";
import { FiltersContext } from "state/providers/FiltersProviderRelatedToFY";
import RoundingSwitcher from "components/ui/switchers/RoundingSwitcher";
import { filterActiveCC, prepareProjectsForFilter, getDefaultRounding } from "utils/others";
import { useCompanyState } from "hooks/useCompany";
import { toast } from "react-toastify";
import ViewSwitcher from "../ViewSwitcher";
import CompareBudgetButton from "./CompareBudgetButton";
import BudgetMarginController from "./BudgetMarginController";

const initialReport = {
  categories: [],
  final_amounts: {
    accumulated: 0,
    period: 0,
    period_prev_year: 0,
    accumulated_prev_year: 0,
  },
};

function ProfitLossReportPage() {
  const { company, parentPath } = useOutletContext();
  const { t } = useTranslation("reports");
  const { filters, updateFilter } = useContext(FiltersContext);
  const [compareBudget, setCompareBudget] = useState(false);
  const [budgetMargin, setBudgetMargin] = useState(company.budget_margin);
  const plPDFModal = useModal();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(initialReport);
  const [rounding, setRounding] = useState(getDefaultRounding);

  useEffect(() => {
    const { start, end, fYear } = filters;
    const signal = axios.CancelToken.source();

    function getReport() {
      if (
        fYear &&
        start &&
        end &&
        isSameDayOrAfter(end, start) &&
        isWithinInterval(start, {
          start: fYear.date_start,
          end: fYear.date_end,
        }) &&
        isWithinInterval(filters.end, {
          start: fYear.date_start,
          end: fYear.date_end,
        })
      ) {
        setLoading(true);
        reportAPI
          .profitAndLoss(
            company.id,
            {
              date_from: formatDate(start),
              date_to: formatDate(end),
              financial_year: fYear.id,
              costcenter_ids: compareBudget ? [] : filters.costcenter_ids || [],
              project_ids: compareBudget ? [] : filters.project_ids || [],
              with_budget: compareBudget,
            },
            {
              cancelToken: signal.token,
              paramsSerializer: (params) => {
                return qs.stringify(params, { indices: false });
              },
            }
          )
          .then((response) => {
            setReport(response.data);
            setLoading(false);
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              setReport(initialReport);
              setLoading(false);
            }
          });
      } else {
        setReport(initialReport);
        setLoading(false);
        toast.error(t("msg:error.fixDatesFilteringToCurrentSelectedFY"));
      }
    }

    getReport();

    return () => {
      signal.cancel("aborted");
    };
  }, [company.id, compareBudget, filters, t]);

  const openProfitLossPDFModal = () => {
    const params = qs.stringify(
      {
        date_from: formatDate(filters.start),
        date_to: formatDate(filters.end),
        financial_year: filters.fYear.id,
        rounding,
        costcenter_ids: filters.costcenter_ids || [],
        project_ids: filters.project_ids || [],
        with_budget: compareBudget,
      },
      { indices: false }
    );
    let url = "";
    if (compareBudget) {
      url = `/reports/profit-loss-budget/pdf/?${params}`;
    } else {
      url = `/reports/profit-loss/pdf/?${params}`;
    }
    plPDFModal.open(url);
  };

  const onToggleCompareBudget = (value) => {
    if (!company.budget_enabled) {
      return;
    }
    setCompareBudget(value);
  };

  return (
    <>
      <Card>
        <Card.Body className="pb-1">
          <div className="d-flex justify-content-between">
            <div>
              <ViewSwitcher view="monthly" />
            </div>
            <div>
              <div className="form-group">
                <label className="text-end"> </label>
                <div>
                  <ButtonGroup>
                    <Button variant="secondary" onClick={openProfitLossPDFModal}>
                      <i className="fas fa-file-pdf me-1" /> {t("reportPDF")}
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          </div>
          <div className="table-filters-group d-flex justify-content-between">
            <Filters
              filters={filters}
              setFilters={updateFilter}
              compareBudget={compareBudget}
              budgetMargin={budgetMargin}
              setBudgetMargin={setBudgetMargin}
            />
            {company.budget_enabled && (
              <div className="form-group">
                <CompareBudgetButton onChange={onToggleCompareBudget} />
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
      <Card className="reports profit-loss">
        {loading && <TableLoader />}
        <Card.Body className="pt-2">
          <RoundingSwitcher onRoundChange={setRounding} />
          <ReportTable
            report={report}
            rounding={rounding}
            parentPath={parentPath}
            compareBudget={compareBudget}
            budgetMargin={budgetMargin}
            budgetNominal={company.budget_nominal}
          />
        </Card.Body>
      </Card>
      {plPDFModal.show && (
        <FileUrlModal fileUrl={plPDFModal.data} companyId={company.id} handleClose={plPDFModal.close} />
      )}
    </>
  );
}

function Filters({ filters, setFilters, compareBudget, budgetMargin, setBudgetMargin }) {
  const { t } = useTranslation("reports");
  const {
    company: { id: companyId },
    costCenters: { asOptions: costOptions },
    projects: { asOptions: projectOptions },
  } = useCompanyState();

  const onPeriodChange = (start, end) => {
    setFilters({ ...filters, start, end });
  };

  const onCCChange = (newData) => {
    setFilters({ ...filters, ...newData });
  };

  const onProjectChange = (newData) => {
    setFilters({ ...filters, ...newData });
  };

  const allProjects = prepareProjectsForFilter(projectOptions);
  const showProjects = allProjects.length !== 0;
  const showCostCenter = costOptions.filter(filterActiveCC).length !== 0 && !compareBudget;

  return (
    <div className="table-filters-group">
      <section className="table-filters-left">
        <DateRangeFilters
          label={t("common:dates.period")}
          dateStart={filters.start}
          dateEnd={filters.end}
          isClearable={false}
          onChange={({ start, end }) => onPeriodChange(start, end)}
        />
        {showCostCenter && (
          <MultiSelectSimpleFilter
            label={t("common:costCenter")}
            defaultValue={filters.costcenter_ids}
            options={costOptions}
            isClearable={false}
            name="costcenter_ids"
            onFilter={onCCChange}
          />
        )}
        {showProjects && (
          <MultiSelectSimpleFilter
            label={t("common:project")}
            defaultValue={filters.project_ids}
            options={allProjects}
            isClearable={false}
            name="project_ids"
            onFilter={onProjectChange}
          />
        )}
        {compareBudget && (
          <BudgetMarginController companyId={companyId} budgetMargin={budgetMargin} setBudgetMargin={setBudgetMargin} />
        )}
      </section>
    </div>
  );
}

function ReportTable({ report, parentPath, rounding = "3", compareBudget = false, budgetMargin, budgetNominal }) {
  const { t } = useTranslation("reports");
  const navigate = useNavigate();
  const {
    filters: { start, end },
  } = useContext(FiltersContext);
  const BUDGET_MARGIN = budgetMargin / 100;
  const diffMonths = differenceInCalendarMonths(addDays(lastDayOfMonth(end), 1), new Date(start).setDate(1));

  function goToLedger(accountNumber) {
    const ledgerPath = parentPath.match(/(consult|reports)$/) ? "/ledger/" : "ledger/";

    navigate(
      `${parentPath}${ledgerPath}${accountNumber}/?${qs.stringify({
        // fyear: filters.fYear ? filters.fYear.id : undefined,
        // start: filters.start ? formatDate(filters.start) : undefined,
        // end: filters.end ? formatDate(filters.end) : undefined,
        // costcenter_ids: filters["costcenter_ids"] || [],
        // project_ids: filters["project_ids"] || [],
      })}`
    );
  }

  function getBudgetClass(account) {
    if (!compareBudget) {
      return "";
    }

    if (Math.abs(account.period - account.budget) < (budgetNominal / 12) * diffMonths) {
      return "budget-ok";
    }
    if (account.number < 4000) {
      return (1 - BUDGET_MARGIN) * account.budget <= account.period ? "budget-ok" : "budget-fail";
    }
    return (1 + BUDGET_MARGIN) * account.budget <= account.period ? "budget-ok" : "budget-fail";
  }

  return (
    <Table bordered className={cx("sticky-header", compareBudget && "compare-budget")}>
      <thead>
        <tr>
          <th>Resultaträkning (SEK)</th>
          <th>{t("common:dates.period")}</th>
          {compareBudget ? <th style={{ width: "15%" }}>{t("budget")}</th> : <th>{t("cumulative")}</th>}
          <th style={{ width: "15%" }}>{t("periodPrevYear")}</th>
          {!compareBudget && <th style={{ width: "15%" }}>{t("cumulativePrevYear")}</th>}
        </tr>
      </thead>
      <tbody>
        {report.categories.map((category, ci) => (
          <React.Fragment key={`ci${ci}`}>
            {category.parent_category_name && (
              <tr className="parent-category--name">
                <th colSpan={5}>{category.parent_category_name}</th>
              </tr>
            )}
            {category.child_categories.map((child, cci) => (
              <React.Fragment key={`cci${cci}`}>
                {child.extra ? (
                  <tr className="extra-category">
                    <th>{child.name}</th>
                    <th>{formatMoneyRounding(child.sum_period, rounding)}</th>
                    {compareBudget ? (
                      <th>{formatMoneyRounding(child.sum_budget, rounding)}</th>
                    ) : (
                      <th>{formatMoneyRounding(child.sum_accumulated, rounding)}</th>
                    )}
                    <th>{formatMoneyRounding(child.sum_period_prev_year, rounding)}</th>
                    {!compareBudget && <th>{formatMoneyRounding(child.sum_accumulated_prev_year, rounding)}</th>}
                  </tr>
                ) : (
                  <>
                    <tr className="child-category--name">
                      <th colSpan={5}>{child.child_category_name}</th>
                    </tr>
                    {child.accounts &&
                      child.accounts.map((account, ai) => (
                        <tr key={`ai${ai}`} className="account">
                          <td>
                            <Button className="m-0 p-0" variant="link" onClick={() => goToLedger(account.number)}>
                              {account.number} - {account.name}
                            </Button>
                          </td>
                          <td className={getBudgetClass(account)}>{formatMoneyRounding(account.period, rounding)}</td>
                          {compareBudget ? (
                            <td>{formatMoneyRounding(account.budget, rounding)}</td>
                          ) : (
                            <td>{formatMoneyRounding(account.accumulated, rounding)}</td>
                          )}
                          <td>{formatMoneyRounding(account.period_prev_year, rounding)}</td>
                          {!compareBudget && <td>{formatMoneyRounding(account.accumulated_prev_year, rounding)}</td>}
                        </tr>
                      ))}
                    <tr className="child-category--sum">
                      <th>Summa {child.child_category_name}</th>
                      <th>{formatMoneyRounding(child.sum_period, rounding)}</th>
                      {compareBudget ? (
                        <th>{formatMoneyRounding(child.sum_budget, rounding)}</th>
                      ) : (
                        <th>{formatMoneyRounding(child.sum_accumulated, rounding)}</th>
                      )}
                      <th>{formatMoneyRounding(child.sum_period_prev_year, rounding)}</th>
                      {!compareBudget && <th>{formatMoneyRounding(child.sum_accumulated_prev_year, rounding)}</th>}
                    </tr>
                  </>
                )}
              </React.Fragment>
            ))}
            {category.parent_category_name && (
              <tr className="parent-category--sum">
                <th>SUMMA {category.parent_category_name}</th>
                <th>{formatMoneyRounding(category.sum_period, rounding)}</th>
                {compareBudget ? (
                  <th>{formatMoneyRounding(category.sum_budget, rounding)}</th>
                ) : (
                  <th>{formatMoneyRounding(category.sum_accumulated, rounding)}</th>
                )}
                <th>{formatMoneyRounding(category.sum_period_prev_year, rounding)}</th>
                {!compareBudget && <th>{formatMoneyRounding(category.sum_accumulated_prev_year, rounding)}</th>}
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th>{t("expectedResult")}</th>
          <th>{formatMoneyRounding(report.final_amounts.period, rounding)}</th>
          {compareBudget ? (
            <th>{formatMoneyRounding(report.final_amounts.budget, rounding)}</th>
          ) : (
            <th>{formatMoneyRounding(report.final_amounts.accumulated, rounding)}</th>
          )}
          <th>{formatMoneyRounding(report.final_amounts.period_prev_year, rounding)}</th>
          {!compareBudget && <th>{formatMoneyRounding(report.final_amounts.accumulated_prev_year, rounding)}</th>}
        </tr>
      </tfoot>
    </Table>
  );
}

export { ReportTable };
export default ProfitLossReportPage;
