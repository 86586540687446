import React from "react";
import { NavLink, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import LeftSidebar from "components/ui/sidebar";
import LayoutSwitcherMenuItem from "components/layouts/LayoutSwitcherMenuItem";

function Menu() {
  const { t } = useTranslation("navigation");
  const loc = useLocation().pathname;
  return (
    <LeftSidebar>
      <LayoutSwitcherMenuItem />
      <li>
        <NavLink
          to="/status-list/ongoing"
          className={({ isActive }) => {
            return isActive || loc.includes("/status-list/") ? "active" : undefined;
          }}
        >
          <i className="fe-check-square" />
          <span> {t("statusList")}</span>
        </NavLink>
      </li>
    </LeftSidebar>
  );
}

export default Menu;
