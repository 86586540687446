import React, { useContext, useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { useCompanyDispatch } from "hooks/useCompany";
import { useTranslation } from "react-i18next";
import OnboardingWizardLayout from "components/layouts/OnboardingWizardLayout";
import OnboardingProvider, { OnboardingStateContext } from "./provider/OnboardingProvider";
import WelcomeAnimation from "./WelcomeAnimation";
import {
  CompanyInformationStep,
  CompanySettingsStep,
  ConsultSettingsStep,
  EmployeesStep,
  FinishedStep,
  InvoicingDetailsStep,
  ImportAccountingStep,
  ImportFortnoxStep,
  PreviousSystemStep,
  SaveLedgersStep,
  FortnoxDataStep,
} from "./steps";

import StepsProgress from "./steps/StepsProgress/StepsProgress";
import AccountMappingStep from "./steps/AccountMappingStep/AccountMappingStep";

function OnboardingWizardPage() {
  const { activeStep, companyId, companyName, agencyId } = useContext(OnboardingStateContext);
  const { selectCompany: selectCompanyAction } = useCompanyDispatch();
  const navigate = useNavigate();

  const renderStep = useMemo(() => {
    switch (activeStep && activeStep.id) {
      case "company_info":
        return <CompanyInformationStep companyId={companyId} agencyId={agencyId} />;
      case "consultant_settings":
        return <ConsultSettingsStep companyId={companyId} agencyId={agencyId} />;
      case "previous_system":
        return <PreviousSystemStep companyId={companyId} agencyId={agencyId} />;
      case "invoicing_details":
        return <InvoicingDetailsStep companyId={companyId} agencyId={agencyId} />;
      case "company_settings":
        return <CompanySettingsStep companyId={companyId} agencyId={agencyId} />;
      case "employees":
        return <EmployeesStep companyId={companyId} agencyId={agencyId} />;
      case "import_accounting":
        return <ImportAccountingStep companyId={companyId} agencyId={agencyId} />;
      case "import_fortnox":
        return <ImportFortnoxStep companyId={companyId} agencyId={agencyId} />;
      case "save_accounting":
        return <AccountMappingStep companyId={companyId} agencyId={agencyId} />;
      case "save_ledgers":
        return <SaveLedgersStep companyId={companyId} agencyId={agencyId} />;
      case "fortnox_data":
        return <FortnoxDataStep companyId={companyId} agencyId={agencyId} />;
      case "finished":
        return <FinishedStep companyId={companyId} agencyId={agencyId} />;
      default:
        return <div>NOT IMPLEMENTED</div>;
    }
  }, [activeStep, companyId, agencyId]);

  if (activeStep?.welcome) {
    setTimeout(() => selectCompanyAction(companyId).then(() => navigate("/")), 4000);
    return (
      <OnboardingWizardLayout companyId={companyId} companyName={companyName}>
        <Card>
          <Card.Body className="p-0 pt-3">
            <WelcomeAnimation />
          </Card.Body>
        </Card>
      </OnboardingWizardLayout>
    );
  }

  return (
    <OnboardingWizardLayout companyId={companyId} companyName={companyName}>
      {activeStep && (
        <>
          <Card>
            <Card.Body className="p-0 pt-3">
              <StepsProgress />
            </Card.Body>
          </Card>
          {renderStep}
        </>
      )}
    </OnboardingWizardLayout>
  );
}

function OnboardingWizardPageWrapper() {
  const { companyId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation("company");
  const fortnoxError = searchParams.get("fortnox_error");
  const initialStep = searchParams.get("step");

  useEffect(() => {
    if (fortnoxError) {
      toast.error(t(`onboarding.${fortnoxError}`), { autoClose: 5000 });
      setSearchParams({});
    }
  }, [fortnoxError, t, setSearchParams]);

  return (
    <OnboardingProvider companyId={companyId} initialStep={initialStep}>
      <OnboardingWizardPage />
    </OnboardingProvider>
  );
}

export default OnboardingWizardPageWrapper;
