import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Table } from "react-bootstrap";
import { DateRangeFilters } from "components/filters";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router";
import { isWithinInterval } from "date-fns";
import axios from "axios";
import qs from "qs";
import cx from "classnames";
import { toast } from "react-toastify";

import * as reportAPI from "api2/reports";

import { formatDate, isAfter, isSameDayOrAfter } from "utils/date";
import { formatMoneyRounding } from "utils/money";
import TableLoader from "components/tables/btable/TableLoader";
import { FileUrlModal } from "components/modals";
import useModal from "hooks/useModal";
import { confirmExecute } from "components/modals/ConfirmModal";
import { FiltersContext } from "state/providers/FiltersProviderRelatedToFY";
import { getDefaultRounding } from "utils/others";
import { RoundingSwitcher } from "components/ui/switchers";
import { PermCodeRequired } from "components/perms";
import { codesForReports } from "components/perms/PermCodes";
import FetchAllSpecificationButton from "./FetchAllSpecificationButton";

const initialReport = {
  categories: [],
  final_amounts: {
    final_closing_balance: 0,
    final_initial_balance: 0,
    final_opening_balance: 0,
    final_period_balance: 0,
  },
};

function BalanceReportPage() {
  const { company, parentPath } = useOutletContext();
  const { t } = useTranslation("reports");
  const { filters, updateFilter } = useContext(FiltersContext);
  const balancePDFModal = useModal();
  const reconPDFModal = useModal();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(initialReport);
  const [rounding, setRounding] = useState(getDefaultRounding);
  useEffect(() => {
    const { start, end, fYear } = filters;
    const signal = axios.CancelToken.source();

    function getReport() {
      if (
        fYear &&
        start &&
        end &&
        isSameDayOrAfter(end, start) &&
        isWithinInterval(start, {
          start: fYear.date_start,
          end: fYear.date_end,
        }) &&
        isWithinInterval(filters.end, {
          start: fYear.date_start,
          end: fYear.date_end,
        })
      ) {
        setLoading(true);
        reportAPI
          .balanceReport(
            company.id,
            {
              date_from: formatDate(start),
              date_to: formatDate(end),
              financial_year: fYear.id,
            },
            { cancelToken: signal.token }
          )
          .then((response) => {
            setReport(response.data);
            setLoading(false);
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              setReport(initialReport);
              setLoading(false);
            }
          });
      } else {
        setReport(initialReport);
        setLoading(false);
        toast.error(t("msg:error.fixDatesFilteringToCurrentSelectedFY"));
      }
    }

    getReport();

    return () => {
      signal.cancel("aborted");
    };
  }, [company.id, filters, t]);

  const openBalancePDFModal = () => {
    const params = qs.stringify(
      {
        date_from: formatDate(filters.start),
        date_to: formatDate(filters.end),
        financial_year: filters.fYear.id,
        rounding,
      },
      { indices: false }
    );
    balancePDFModal.open(`/reports/balance/pdf/?${params}`);
    return null;
  };

  const openReconciliationPDFModal = async () => {
    const answer = await confirmExecute(t("confirm.reconciliationPDFCompanyArchive"), undefined, undefined, true);
    if (answer) {
      const params = qs.stringify({
        financial_year: filters.fYear.id,
        date_from: formatDate(filters.start),
        date_to: formatDate(filters.end),
      });
      return reconPDFModal.open(`/reconciliations/pdf/?${params}`);
    }
    return null;
  };

  return (
    <>
      <Card>
        <Card.Body className="pb-2">
          <div className="d-flex justify-content-between">
            <section className="">
              <Filters filters={filters} setFilters={updateFilter} t={t} />
            </section>
            <section className="table-filters-group">
              <div>
                <label className="text-end">{t("common:actions.actions")}</label>
                <div>
                  <ButtonGroup>
                    {filters.fYear && filters.end && (
                      <PermCodeRequired code={codesForReports.reconciliation}>
                        <FetchAllSpecificationButton
                          companyId={company.id}
                          financialYear={filters.fYear}
                          reconciliationDate={formatDate(filters.end)}
                        />
                      </PermCodeRequired>
                    )}
                    <Button variant="secondary" onClick={openBalancePDFModal}>
                      <i className="fas fa-file-pdf" /> {t("reportPDF")}
                    </Button>
                    <PermCodeRequired code={codesForReports.reconciliation}>
                      <Button variant="secondary" onClick={openReconciliationPDFModal}>
                        <i className="fas fa-file-pdf" /> {t("reconciliationPDF")}
                      </Button>
                    </PermCodeRequired>
                  </ButtonGroup>
                </div>
              </div>
            </section>
          </div>
        </Card.Body>
      </Card>
      <Card className="reports profit-loss">
        {loading && <TableLoader />}
        <Card.Body className="pt-2">
          <RoundingSwitcher onRoundChange={setRounding} />
          <ReportTable report={report} rounding={rounding} filters={filters} parentPath={parentPath} />
        </Card.Body>
      </Card>
      {balancePDFModal.show && (
        <FileUrlModal show fileUrl={balancePDFModal.data} companyId={company.id} handleClose={balancePDFModal.close} />
      )}
      {reconPDFModal.show && reconPDFModal.data && (
        <FileUrlModal fileUrl={reconPDFModal.data} companyId={company.id} handleClose={reconPDFModal.close} />
      )}
    </>
  );
}

function Filters({ filters, setFilters, t }) {
  function onPeriodChange(start, end) {
    setFilters({ ...filters, start, end });
  }

  return (
    <div className="table-filters-group">
      <section className="table-filters-left">
        <DateRangeFilters
          label={t("common:dates.period")}
          dateStart={filters.start}
          dateEnd={filters.end}
          isClearable={false}
          onChange={({ start, end }) => onPeriodChange(start, end)}
        />
      </section>
    </div>
  );
}

function ReportTable({ report, filters, parentPath, rounding = "3" }) {
  const { t } = useTranslation("reports");
  const navigate = useNavigate();

  function goToLedger(accountNumber) {
    const ledgerPath = parentPath.match(/(consult|reports)$/) ? "/ledger/" : "ledger/";

    navigate(
      `${parentPath}${ledgerPath}${accountNumber}/?${qs.stringify({
        fyear: filters.fYear ? filters.fYear.id : undefined,
        start: filters.start ? formatDate(filters.start) : undefined,
        end: filters.end ? formatDate(filters.end) : undefined,
      })}`
    );
  }

  function goToReconciliation(account) {
    if (!filters.end || isAfter(filters.end, new Date())) {
      toast.error(t("canNotReconcileFuture"));
    } else if (!filters.fYear) {
      toast.error(t("missingFinancialYear"));
    } else {
      navigate(`${parentPath}/balance/reconciliation/${account.number}/${formatDate(filters.end)}`);
    }
  }

  return (
    <Table bordered className="sticky-header">
      <thead>
        <tr>
          <th>Balansräkning (SEK)</th>
          <th>{t("openingBalance")}</th>
          <th>{t("initialBalance")}</th>
          <th>{t("common:dates.period")}</th>
          <th>{t("closingBalance")}</th>
        </tr>
      </thead>
      <tbody>
        {report.categories.map((category, ci) => (
          <React.Fragment key={`ci${ci}`}>
            <tr className="parent-category--name">
              <th colSpan={5}>{category.parent_category_name}</th>
            </tr>
            {category.child_categories.map((child, cci) => (
              <React.Fragment key={`cci${cci}`}>
                {child.extra ? (
                  <tr className="extra-category">
                    <th>{child.name}</th>
                    <th>{formatMoneyRounding(child.sum_opening_balance, rounding)}</th>
                    <th>{formatMoneyRounding(child.sum_initial_balance, rounding)}</th>
                    <th>{formatMoneyRounding(child.sum_period_balance, rounding)}</th>
                    <th>{formatMoneyRounding(child.sum_closing_balance, rounding)}</th>
                  </tr>
                ) : (
                  <>
                    <tr className="child-category--name">
                      <th colSpan={5}>{child.child_category_name}</th>
                    </tr>
                    {child.accounts &&
                      child.accounts.map((account, ai) => (
                        <tr key={`ai${ai}`} className="account">
                          <td>
                            <PermCodeRequired code={codesForReports.reconciliation}>
                              <div
                                className="reconciliation-info-button"
                                title={t("reconciliation")}
                                onClick={() => goToReconciliation(account)}
                              >
                                <i className={cx(account.is_reconciled ? "far fa-check-square" : "far fa-square")} />
                              </div>
                            </PermCodeRequired>
                            <PermCodeRequired code={codesForReports.ledger}>
                              <Button className="m-0 p-0" variant="link" onClick={() => goToLedger(account.number)}>
                                {account.number} - {account.name}
                              </Button>
                            </PermCodeRequired>
                          </td>
                          <th>{formatMoneyRounding(account.opening_balance, rounding)}</th>
                          <th>{formatMoneyRounding(account.initial_balance, rounding)}</th>
                          <th>{formatMoneyRounding(account.period_balance, rounding)}</th>
                          <th>{formatMoneyRounding(account.closing_balance, rounding)}</th>
                        </tr>
                      ))}
                    <tr className={cx("child-category--sum", { last: cci === category.child_categories.length - 1 })}>
                      <th>Summa {child.child_category_name}</th>
                      <th>{formatMoneyRounding(child.sum_opening_balance, rounding)}</th>
                      <th>{formatMoneyRounding(child.sum_initial_balance, rounding)}</th>
                      <th>{formatMoneyRounding(child.sum_period_balance, rounding)}</th>
                      <th>{formatMoneyRounding(child.sum_closing_balance, rounding)}</th>
                    </tr>
                  </>
                )}
              </React.Fragment>
            ))}
            <tr className="parent-category--sum">
              <th>SUMMA {category.parent_category_name}</th>
              <th>{formatMoneyRounding(category.sum_opening_balance, rounding)}</th>
              <th>{formatMoneyRounding(category.sum_initial_balance, rounding)}</th>
              <th>{formatMoneyRounding(category.sum_period_balance, rounding)}</th>
              <th>{formatMoneyRounding(category.sum_closing_balance, rounding)}</th>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th>{t("expectedResult")}</th>
          <th>{formatMoneyRounding(report.final_amounts.final_opening_balance, rounding)}</th>
          <th>{formatMoneyRounding(report.final_amounts.final_initial_balance, rounding)}</th>
          <th>{formatMoneyRounding(report.final_amounts.final_period_balance, rounding)}</th>
          <th>{formatMoneyRounding(report.final_amounts.final_closing_balance, rounding)}</th>
        </tr>
      </tfoot>
    </Table>
  );
}

export default BalanceReportPage;
