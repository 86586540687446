import React from "react";
import { truncateText } from "utils/text";
import { NavLink } from "react-router";
import { GetMainPageUrl } from "utils/layout";
import NavBarButton from "./NavBarButton";

function CompanySwitcher({ company }) {
  if (!company) {
    return null;
  }
  return (
    <NavBarButton
      as={NavLink}
      end
      to={GetMainPageUrl()}
      icon="fe-briefcase"
      rightIcon="fe-chevron-right"
      className="company-switcher"
      text={truncateText(company.name, 15)}
    />
  );
}

export default CompanySwitcher;
