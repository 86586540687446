import React from "react";
import { Outlet } from "react-router";
import NavBar from "components/ui/navbar";
import { StaffPermRequired } from "components/perms";
import AdminMenu from "./AdminMenu";

function AdminLayout() {
  return (
    <StaffPermRequired>
      <NavBar company={null} forDashboard={false} />
      <AdminMenu />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid faded-fixed-bottom">
            <Outlet />
          </div>
        </div>
      </div>
    </StaffPermRequired>
  );
}

export default AdminLayout;
