import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Button } from "react-bootstrap";

import * as companyAPI from "api/company";
import * as options from "api/options";
import { formatDate, parseDate } from "utils/date";
import { formatMoney } from "utils/money";
import BTable from "components/tables/btable";
import { BMDDatePicker } from "components/forms/bmd";
import { DocumentsModal } from "components/modals";
import useModal from "hooks/useModal";
import Filters from "./DayToDayFilters";

function mapVATPeriod(vatPeriod) {
  if (vatPeriod.includes("yearly")) {
    return "12";
  }
  if (vatPeriod.includes("month")) {
    return "1";
  }
  if (vatPeriod.includes("quarterly")) {
    return "3";
  }
  return "0";
}

function ReconFileBtn({ values, fileModal }) {
  if (!values.latest_recon_id) {
    return "-";
  }
  return (
    <Button variant="link" onClick={() => fileModal.open([values.id, values.latest_recon_id])}>
      {formatDate(values.last_reconciliation_date)}
    </Button>
  );
}

function DayToDayTable({ selectCompany }) {
  const { t } = useTranslation("company");
  const fileModal = useModal();

  async function updateInfo(companyId, field, value) {
    await companyAPI.saveCompanyManagement(companyId, {
      [field]: value ? formatDate(value) : null,
    });
  }

  const headers = useMemo(() => {
    return [
      {
        field: "name",
        label: t("common:companyName"),
        className: "text-left",
        width: 200,
        render: (row) => (
          <Button variant="link" onClick={() => selectCompany(row.id)}>
            {row.name}
          </Button>
        ),
      },
      {
        field: "org_no",
        label: t("common:orgNo"),
        canSort: false,
        width: 120,
      },
      {
        field: "consult_name",
        label: t("common:consult"),
        sortField: "consult__first_name",
        width: 120,
      },
      {
        field: "bank_name",
        label: t("manage.bank"),
        render: (row) =>
          row.bank_name === "other"
            ? row.other_bank_name || "-"
            : options.bankChoices.getOption(row.bank_name)?.label || "-",
      },
      {
        field: "vat_report_period",
        label: t("manage.vatPeriod"),
        render: (values) => mapVATPeriod(values.vat_report_period),
      },
      {
        field: "latest_vat_report",
        label: t("manage.lastVatReport"),
      },
      {
        field: "consult_info__share_own_capital",
        label: t("manage.shareCapital"),
        className: "text-end",
        render: (values) =>
          values.consult_info ? (
            <span
              className={cx({
                "text-danger": values.consult_info.share_own_capital < 0.5,
              })}
            >
              {formatMoney(values.consult_info.share_own_capital * 100, 0, 0)}%
            </span>
          ) : (
            "-"
          ),
      },
      {
        field: "consult_info__balance_1930",
        label: t("manage.1930BG"),
        className: "text-end",
        render: (values) => values.consult_info && formatMoney(values.consult_info.balance_1930, 0, 0),
      },
      {
        field: "consult_info__balance_1630",
        label: t("manage.1630Tax"),
        className: "text-end",
        render: (values) => values.consult_info && formatMoney(values.consult_info.balance_1630, 0, 0),
      },
      {
        field: "last_recon_file",
        label: t("manage.lastRecon"),
        sortField: "last_reconciliation_date",
        width: 110,
        render: (values) => <ReconFileBtn values={values} fileModal={fileModal} />,
      },
      {
        field: "consult_info__date_report_send",
        label: t("manage.sentReportToClient"),
        render: (values) => (
          <BMDDatePicker
            defaultValue={values.consult_info ? parseDate(values.consult_info.date_report_send) : null}
            onSelect={(selected) => updateInfo(values.id, "date_report_send", selected)}
          />
        ),
      },
      {
        field: "consult_info__date_last_silverback",
        label: t("manage.lastSilverback"),
        render: (values) => (
          <BMDDatePicker
            defaultValue={values.consult_info ? parseDate(values.consult_info.date_last_silverback) : null}
            onSelect={(selected) => updateInfo(values.id, "date_last_silverback", selected)}
          />
        ),
      },
    ];
  }, [selectCompany, t, fileModal]);

  return (
    <>
      <BTable
        headers={headers}
        tableClassName="sticky-header day-to-day"
        tableResponsive={false}
        selectable={false}
        FiltersComponent={<Filters />}
      />
      {fileModal.show && (
        <DocumentsModal
          partUrl="company-documents"
          companyId={fileModal.data[0]}
          documentIds={[fileModal.data[1]]}
          handleClose={fileModal.close}
        />
      )}
    </>
  );
}

export default DayToDayTable;
