import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { bankMatching as matchingAPI } from "api2/integrations";
import { formatMoney } from "utils/money";
import { useVerificationDispatch } from "hooks/useVerification";
import { TDCheckInput, THCheckInput } from "./CheckInputs";

function BflowTransTable({ companyId, data, set, reloadTable }) {
  const { t } = useTranslation("common");
  const { open: openVerificationModal } = useVerificationDispatch();
  const [checkedAll, setCheckedAll] = useState({ debit: false, credit: false });

  function openVerModal(trans) {
    openVerificationModal(companyId, { id: trans.verification_id });
  }

  async function toggleSelect(item, index) {
    const newData = { ...data };
    const trans = newData.bflow[item.booking_date].trans[index];
    newData.bflow[item.booking_date].trans[index].checked = !trans.checked;
    set(newData);

    if (trans.matched) {
      await matchingAPI
        .bflowTransactionResetMatch(companyId, trans.id)
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
      reloadTable();
    }
  }

  function toggleSectionSelect(section, forDebit) {
    const newData = { ...data };
    const key = forDebit ? "debitChecked" : "creditChecked";
    newData.bflow[section.date][key] = !newData.bflow[section.date][key];
    newData.bflow[section.date].trans.forEach((tr) => {
      if (!tr.matched) {
        if (forDebit && tr.debit) {
          tr.checked = newData.bflow[section.date][key];
        } else if (!forDebit && tr.credit) {
          tr.checked = newData.bflow[section.date][key];
        }
      }
    });
    set(newData);
  }

  function selectAll(forDebit) {
    setCheckedAll({
      debit: forDebit ? !checkedAll.debit : checkedAll.debit,
      credit: forDebit ? checkedAll.credit : !checkedAll.credit,
    });
    const newData = { ...data };
    Object.values(newData.bflow).forEach((section) => {
      section.trans.forEach((tr) => {
        if (!tr.matched) {
          if (forDebit && tr.debit) {
            tr.checked = !checkedAll.debit;
          } else if (!forDebit && tr.credit) {
            tr.checked = !checkedAll.credit;
          }
        }
      });
    });
    set(newData);
  }

  return (
    <>
      <h6>{t("common:accountingTransactions")}</h6>
      <div className="scroller">
        <Table bordered size="sm" className="sticky-header">
          <thead>
            <tr>
              <th style={{ width: 40 }} />
              <th>{t("common:verShort")}</th>
              <th>{t("common:description")}</th>
              <THCheckInput
                checked={checkedAll.debit}
                toggleSelect={() => selectAll(true)}
                label={t("common:money.debit")}
              />
              <THCheckInput
                checked={checkedAll.credit}
                className="text-end"
                toggleSelect={() => selectAll(false)}
                label={t("common:money.credit")}
              />
            </tr>
          </thead>
          <tbody>
            {!Object.values(data.bflow).length && (
              <tr>
                <td colSpan={5}>{t("common:noResultsFound")}</td>
              </tr>
            )}
            {Object.values(data.bflow).map((section) => (
              <React.Fragment key={section.date}>
                <tr className="sticky1 date-row">
                  <th colSpan={3}>{section.date}</th>
                  <THCheckInput
                    checked={section.debitChecked}
                    toggleSelect={() => toggleSectionSelect(section, true)}
                    label={formatMoney(section.debit)}
                  />
                  <THCheckInput
                    checked={section.creditChecked}
                    toggleSelect={() => toggleSectionSelect(section, false)}
                    label={formatMoney(section.credit)}
                  />
                </tr>
                {section.trans.map((trans, index) => (
                  <tr key={trans.id}>
                    <TDCheckInput trans={trans} toggleSelect={() => toggleSelect(trans, index)} />
                    <td>
                      <Button variant="link" className="p-0 m-0" onClick={() => openVerModal(trans)}>
                        {trans.verification_number}
                      </Button>
                    </td>
                    <td>{trans.description || "-"}</td>
                    <td className="text-end">{trans.debit ? formatMoney(trans.debit) : ""}</td>
                    <td className="text-end">{trans.credit ? formatMoney(trans.credit) : ""}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default BflowTransTable;
