import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, useFormikContext } from "formik";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FormGroup } from "components/formik";
import * as options from "api/options";
import * as supportAPI from "api2/office-support";
import { toast } from "react-toastify";
import * as yup from "yup";
import cx from "classnames";
import { allowedFileExtensionsFor } from "utils/file";
import UploadButton from "./UploadButton";
import { SubmitButton } from "../../../components/ui/buttons";
import { NewAttachmentBadge } from "./Buttons";

function OfficeSupportCreateNoteForm({ companyId, onCreate, disabled = false }) {
  const { t } = useTranslation("common");
  const categoriesOptions = options.notesCategory.asList();
  const [newFile, setNewFile] = useState(null);
  const initialValues = {
    text: "",
    category: null,
  };
  const formikProps = {
    initialValues,
    validationSchema: yup.object().shape({
      text: yup.string().required(),
      category: yup.object().nullable().notRequired(),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await supportAPI.notes
        .save(
          companyId,
          {
            text: values.text,
            category: values.category ? values.category.value : "",
          },
          newFile
        )
        .then((response) => {
          resetForm(initialValues);
          setNewFile(null);
          onCreate(response.data);
          toast.success(t("created"), { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            setErrors({ ...error.data });
          }
        });
    },
  };

  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      // changeEditedForm({ id: "client-details", dirty });
    }, [dirty]);
    return null;
  }
  const onCancel = (resetForm) => {
    resetForm();
    setNewFile(null);
  };
  return (
    <Formik {...formikProps}>
      {({ values, touched, isSubmitting, setFieldValue, errors, resetForm, dirty }) => {
        return (
          <Form key="note-form-create">
            <FormUpdateChecker />
            <Card className={cx({ disabled })}>
              <Row>
                <Col>
                  <Row>
                    <Col xl={3}>
                      <label className="label-create">
                        <b>{t("common:actions.newNote")}</b>
                      </label>
                    </Col>
                  </Row>
                  <div className="text-and-upload-field-container">
                    <Row>
                      <Col>
                        <FormGroup.AutoHeightEditableArea name="text" />
                      </Col>
                      <Col className="file_upload_col">
                        <UploadButton
                          fileTypes={allowedFileExtensionsFor.notes}
                          setFile={setNewFile}
                          fileAttached={newFile}
                        />
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col className="new-attachment-row text-end">
                      {newFile && <NewAttachmentBadge fileName={newFile.name} onDelete={() => setNewFile(null)} />}
                    </Col>
                  </Row>
                </Col>
                <Col className="category">
                  <FormGroup.SimpleSelect
                    name="category"
                    label={t("common:category")}
                    options={categoriesOptions}
                    required={false}
                    menuPlacement="top"
                    isClearable
                  />
                </Col>
                <Col className="submit-col">
                  <SubmitButton isSubmitting={isSubmitting} disabled={!dirty} icon="" />
                  {(dirty || newFile) && (
                    <Button variant="link" size="lg" onClick={() => onCancel(resetForm)} className="cancel-btn">
                      {t("common:actions.cancel")}
                    </Button>
                  )}
                </Col>
              </Row>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default OfficeSupportCreateNoteForm;
