import React, { useContext } from "react";
import { NavLink } from "react-router";

import LeftSidebar from "components/ui/sidebar";
import { useTranslation } from "react-i18next";
import { TimeTrackerStateContext } from "state/providers/TimeTrackerProvider";
import TimeTrackerMenuItem from "../TimeTrackerMenuItem";
import LayoutSwitcherMenuItem from "../LayoutSwitcherMenuItem";

function TimeTrackerMenu() {
  const { t } = useTranslation("navigation");
  const { selected: selectedAgency } = useContext(TimeTrackerStateContext);

  return (
    <LeftSidebar>
      <LayoutSwitcherMenuItem />
      <TimeTrackerMenuItem agencyId={selectedAgency.agency} />
      <li>
        <NavLink to="/tt/calendar">
          <i className="fe-calendar" />
          <span> {t("TTcalendar")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/tt/by-company">
          <i className="fe-briefcase" />
          <span> {t("TTclient")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/tt/logs">
          <i className="fe-watch" />
          <span> {t("TTlogs")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/tt/time-report">
          <i className="fe-clipboard" />
          <span> {t("TTTimeReport")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/tt/consult-report">
          <i className="fe-message-square" />
          <span> {t("TTconsultLogs")}</span>
        </NavLink>
      </li>
    </LeftSidebar>
  );
}

export default TimeTrackerMenu;
