import _ from "lodash";
import { isWithinInterval } from "date-fns";
import { parseDate } from "utils/date";

export function getFinancialYear(financialYears, selectedDateStr) {
  const selectedDate = parseDate(selectedDateStr);
  const year = _.find(financialYears, (fy) =>
    isWithinInterval(selectedDate, {
      start: fy.date_start,
      end: fy.date_end,
    })
  );
  if (year) {
    const startYear = year.date_start.getFullYear();
    const endYear = year.date_end.getFullYear();
    if (startYear !== endYear) {
      return `${startYear}/${endYear}`;
    }
    return `${startYear}`;
  }
  return selectedDate.getFullYear().toString();
}
