import { NavLink, useResolvedPath } from "react-router";
import React from "react";
import LeftSidebar from "components/ui/sidebar";
import { useTranslation } from "react-i18next";

function Menu() {
  const path = useResolvedPath("").pathname;
  const { t } = useTranslation("navigation");

  return (
    <LeftSidebar>
      <li className="menu-title">{t("admin")}</li>
      <li>
        <NavLink to={`${path}/users`} replace>
          <i className="fe-users" />
          <span> {t("users")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={`${path}/events`} replace>
          <i className="fe-calendar" />
          <span> {t("events")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={`${path}/suppliers`}>
          <i className="fe-box" />
          <span> {t("globalSuppliers")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={`${path}/search`}>
          <i className="fe-search" />
          <span> {t("globalSearch")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={`${path}/stats`} replace>
          <i className="fe-bar-chart" />
          <span> {t("statistics")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={`${path}/import-companies`} replace>
          <i className="fe-file-text" />
          <span> {t("importCompanies")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={`${path}/usage-report`} replace>
          <i className="fe-dollar-sign" />
          <span> {t("usageReport")}</span>
        </NavLink>
      </li>
    </LeftSidebar>
  );
}

export default Menu;
