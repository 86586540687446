import React, { useState } from "react";
import { Field, FieldArray, Form, Formik } from "formik";
import { Alert, Card, Col, InputGroup, Row } from "react-bootstrap";
import * as options from "api/options";
import * as yup from "yup";
import * as ciAPI from "api2/customer-invoices";
import { toast } from "react-toastify";
import { addMonths, parseISO } from "date-fns";
import { parseDate } from "utils/date";
import { useTranslation } from "react-i18next";
import _, { debounce } from "lodash";
import { CurrencyPicker, CustomerPicker, FormGroup } from "components/formik";

import useModal from "hooks/useModal";
import { CCProjModal, DiscountModal } from "components/modals/invoices";
import { SubmitButton } from "components/ui/buttons";
import { useCompanyState } from "hooks/useCompany";

import {
  isReversedVATAllowed,
  isVatDisabled,
  onCustomerChange,
  onCustomerReset,
  onReversedVatChange,
  RecordsForm,
  RecordsTool,
} from "components/forms/CustomerInvoiceForm/helpers";
import { DeliveryMethodSwitcher } from "components/forms/CustomerInvoiceForm/CustomerInvoiceForm";
import { filterActiveProjects } from "utils/others";
import { formatData } from "./helpers";
import { fromBillectaAmount } from "../../../utils/calc/ci";

const getContractTemplates = debounce((companyId, params, callback) => {
  params.ordering = "name";
  params.page_size = 9999;
  ciAPI.contractTemplates
    .list(companyId, params)
    .then((response) => {
      return callback(response.data.map((item) => ({ ...item, value: item.id, label: item.name })));
    })
    .catch(() => callback([]));
}, 500);

function ContractInvoiceForm({ company, invoice, onSave, invoiceConnectedProducts = {} }) {
  const {
    currencies: { byId: currencies },
    costCenters: { asOptions: centerOptions },
    projects: { asOptions: projectOptions },
  } = useCompanyState();
  const today = parseISO(new Date().toJSON().slice(0, 10));
  const ccProjModal = useModal();
  const paymentTerms = options.paymentTermsOptionsWithoutDefault();
  const createTerms = options.contractCreateTerms.asList();
  const priceGroups = options.priceGroups.asList();
  const vatOptions = options.vatOptions.asList();
  const { t, i18n } = useTranslation("ci");
  const discountModal = useModal();
  const [originalPrices, setOriginalPrices] = useState({});
  const [connectedProducts, setConnectedProducts] = useState(invoiceConnectedProducts);
  const initial_currency_code = {
    value: currencies[invoice.currency_code].value,
    label:
      i18n.language === "sv" ? currencies[invoice.currency_code].label_sv : currencies[invoice.currency_code].label_en,
  };
  const formikProps = {
    initialValues: {
      ...invoice,
      currency_code: initial_currency_code,
      customer: invoice.customer
        ? {
            ...invoice.customer,
            value: invoice.customer.id,
            label: invoice.customer.name,
          }
        : null,
      contract_date: invoice.contract_date ? parseDate(invoice.contract_date) : null,
      period_start: invoice.period_start ? parseDate(invoice.period_start) : null,
      period_end: invoice.period_end ? parseDate(invoice.period_end) : null,
      records: invoice.records.map((record) => {
        record.key = _.uniqueId("ln");
        if (record.record_type === "product") {
          return {
            ...record,
            product: {
              ...record.product,
              vat_percent: options.vatOptions.getOption(record.product.vat_percent) || vatOptions[0],
              unit_price: fromBillectaAmount(record.product.unit_price, initial_currency_code),
              account: record.product.account || 3001,
            },
            rot_rut: record.rot_rut
              ? {
                  ...record.rot_rut,
                  amount: fromBillectaAmount(record.rot_rut.amount, initial_currency_code),
                }
              : null,
          };
        }
        return record;
      }),
      payment_terms: options.paymentTerms.getOption(invoice.payment_terms) || paymentTerms[1],
      price_group: options.priceGroups.getOption(invoice.price_group) || priceGroups[0],
      create_terms: options.contractCreateTerms.getOption(invoice.create_terms) || createTerms[1],
    },
    validationSchema: yup.object().shape({
      customer: yup.object().nullable().required(),
      contract_date: yup.date().nullable().required(),
      period_start: yup.date().nullable().required(),
      invoice_interval: yup.number().required(),
      period_end: yup
        .date()
        .nullable()
        .when("ongoing", ([ongoing], schema) => {
          return ongoing ? schema.notRequired() : schema.required();
        }),
    }),
    onSubmit: async (values, { setErrors, setFieldError }) => {
      await ciAPI.contractInvoices
        .save(company.id, formatData(values))
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (onSave) {
            onSave(response.data);
          }
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          setErrors(error.data);
        });
    },
  };

  async function onTemplateSelected(templateId, values, setValues) {
    if (templateId) {
      const template = await ciAPI.contractTemplates.details(company.id, templateId).then((response) => response.data);
      if (values.customer && template.delivery_method !== values.customer.default_delivery_method) {
        toast.warning(t("warning.contractDeliveryMethodMismatch"), { autoClose: 10000 });
      }
      setConnectedProducts(
        Object.assign(
          {},
          ...template.records
            .filter((record) => record.record_type === "product")
            .map((r) => ({ [r.product.product_id]: r }))
        )
      );
      setValues({
        ...values,
        template: { value: template.id, label: template.name, ...template },
        delivery_method: template.delivery_method,
        template_delivery_method: template.delivery_method,
        invoice_interval: template.invoice_interval,
        ongoing: template.ongoing,
        period_end: template.ongoing ? null : addMonths(values.period_start, template.contract_length),
        payment_terms: options.paymentTerms.getOption(template.payment_terms),
        records: template.records.map((record) => ({
          ...record,
          key: _.uniqueId("ln"),
          product: record.product
            ? {
                ...record.product,
                unit_price: record.product.unit_price / 100,
                vat_percent: isVatDisabled(company, values)
                  ? vatOptions[0]
                  : options.vatOptions.getOption(record.product.vat_percent),
              }
            : null,
        })),
      });
    }
  }

  return (
    <Card>
      <Formik {...formikProps}>
        {({ values, errors, setFieldValue, setValues, isSubmitting }) => {
          const reversedVATAllowed = isReversedVATAllowed(values.customer);
          const activeProjects = filterActiveProjects(projectOptions, values.booking_date);
          return (
            <>
              <Form id="ciForm">
                <Card.Body>
                  <Row xl={2} lg={1}>
                    <Col xl={6} lg={12}>
                      <CustomerPicker
                        name="customer"
                        label={t("common:customer")}
                        companyId={company.id}
                        customer={values.customer}
                        onChange={(customer) =>
                          onCustomerChange(
                            company,
                            customer,
                            values,
                            setValues,
                            setFieldValue,
                            t,
                            connectedProducts,
                            {},
                            {},
                            true
                          )
                        }
                        onReset={() => onCustomerReset(setFieldValue, values, setValues, connectedProducts, company)}
                        required
                      />
                    </Col>
                  </Row>
                  {!invoice.id && (
                    <Row>
                      <Col xl={4}>
                        <FormGroup.AsyncSelect
                          name="template"
                          isClearable
                          label={t("common:contractTemplate")}
                          onChange={(template) => onTemplateSelected(template?.id, values, setValues)}
                          loadOptions={(params, callback) => getContractTemplates(company.id, params, callback)}
                          minSearchLength={0}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col lg={3}>
                      <FormGroup.Input name="your_reference" label={t("common:yourReference")} />
                    </Col>
                    <Col lg={3}>
                      <FormGroup.Input name="our_reference" label={t("common:ourReference")} />
                    </Col>
                    <Col lg={3}>
                      <CurrencyPicker
                        name="currency_code"
                        currentCode={values.currency_code.value}
                        currentDate={today}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                  </Row>
                  <Row xl={4} sm={3}>
                    <Col>
                      <FormGroup.DatePicker name="contract_date" label={t("contractDate")} required />
                    </Col>
                    <Col>
                      <FormGroup.Input
                        type="number"
                        label={t("invoiceIntervalMonths")}
                        name="invoice_interval"
                        required
                      />
                    </Col>
                    <Col>
                      <FormGroup.DatePicker name="period_start" label={t("periodStart")} required />
                    </Col>
                    {!values.ongoing && (
                      <Col>
                        <FormGroup.DatePicker name="period_end" label={t("periodEnd")} required />
                      </Col>
                    )}
                  </Row>
                  <Row xl={4} sm={3}>
                    <Col>
                      <FormGroup.SimpleSelect
                        name="payment_terms"
                        label={t("common:paymentTerms")}
                        options={paymentTerms}
                      />
                    </Col>
                    <Col>
                      <FormGroup.SimpleSelect name="create_terms" label={t("createTerms")} options={createTerms} />
                    </Col>
                    <Col>
                      <div className="form-group">
                        <label className="form-label">{t("reversedVat")}</label>
                        <InputGroup className="mb-3">
                          <InputGroup.Text style={{ width: 40 }}>
                            <Field
                              type="checkbox"
                              name="reversed_vat"
                              disabled={!reversedVATAllowed}
                              onChange={(e) =>
                                onReversedVatChange(
                                  e.currentTarget.checked,
                                  values.customer,
                                  values,
                                  setValues,
                                  connectedProducts,
                                  company.vat_disabled
                                )
                              }
                            />
                          </InputGroup.Text>
                          <Field
                            type="text"
                            name="reversed_vat_receiver_no"
                            placeholder={t("common:money.vatNo")}
                            disabled={values.reversed_vat === false}
                            className="form-control"
                          />
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>{t("common:options")}</label>
                      <FormGroup.Checkbox wrapperClass="mb-1" label={t("common:statuses.active")} name="is_active" />
                      <FormGroup.Checkbox wrapperClass="mb-3" label={t("common:statuses.ongoing")} name="ongoing" />
                    </Col>
                  </Row>
                  <FieldArray
                    name="records"
                    render={(arrayHelper) => (
                      <>
                        <RecordsForm
                          values={values}
                          currency={values.currency_code.value}
                          arrayHelper={arrayHelper}
                          t={t}
                        />
                        <RecordsTool
                          companyId={company.id}
                          arrayHelper={arrayHelper}
                          currency={values.currency_code.value}
                          vatDisabled={isVatDisabled(company, values)}
                          rotRutEnabled={false}
                          discountModal={discountModal}
                          rotRutModal={null}
                          ccProjModal={ccProjModal}
                          centerOptions={centerOptions}
                          projectOptions={activeProjects}
                          prices={[]}
                          priceGroup={values.price_group}
                          connectedProducts={connectedProducts}
                          setConnectedProducts={setConnectedProducts}
                          originalPrices={originalPrices}
                          setOriginalPrices={setOriginalPrices}
                          forOffer={false}
                          t={t}
                          bookingDate={values.booking_date}
                        />
                      </>
                    )}
                  />
                  <Row>
                    <Col sm={12}>
                      <div className="form-group">
                        <label className="form-label">{t("sendInvoiceBy")}</label>
                        <DeliveryMethodSwitcher
                          t={t}
                          deliveryMethod={values.delivery_method}
                          changeDeliveryMethod={(newMethod) => setFieldValue("delivery_method", newMethod)}
                        />
                      </div>
                    </Col>
                  </Row>
                  {errors && errors.__all__ && <Alert variant="danger">{errors.__all__}</Alert>}
                </Card.Body>
                <Card.Footer>
                  <SubmitButton className="ms-2" isSubmitting={isSubmitting} />
                </Card.Footer>
              </Form>
              {discountModal.show && (
                <DiscountModal
                  show
                  companyId={company.id}
                  currency={values.currency_code.value}
                  records={values.records}
                  onSave={(records) => {
                    setFieldValue("records", records);
                    discountModal.close();
                  }}
                  onCancel={discountModal.close}
                />
              )}
              {ccProjModal.show && (
                <CCProjModal
                  companyId={company.id}
                  records={values.records}
                  onCancel={ccProjModal.close}
                  onSave={(records) => {
                    setFieldValue("records", records, false);
                    ccProjModal.close();
                  }}
                  bookingDate={values.booking_date}
                />
              )}
            </>
          );
        }}
      </Formik>
    </Card>
  );
}

export default ContractInvoiceForm;
