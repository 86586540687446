import React, { useCallback } from "react";
import _ from "lodash";
import cx from "classnames";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import useAsync from "hooks/useAsync";
import { ApprovalRequirementBadge } from "components/ui/badges/ApprovalRequirementBadge";
import { approvalFlows } from "api2/companies";
import { Loader } from "components/ui/loaders";

function ShortApproverItem({
  approverId,
  employeesByUserId,
  deniedByIds,
  approvedByIds,
  isCurrentStep = false,
  materialStatus = null,
}) {
  const getApproverInitials = () => {
    if (employeesByUserId[approverId]) {
      return `${employeesByUserId[approverId].first_name.substring(0, 1)}${employeesByUserId[approverId].last_name.substring(0, 1)}`;
    }
    return `..`;
  };
  const isApproved = approvedByIds.includes(approverId);
  const isDenied = deniedByIds.includes(approverId);
  const materialInDraft = ["", 0, "draft"].includes(materialStatus);
  const materialNotInPrelim = materialStatus !== "prelim";
  const notReadyForApproval = !isCurrentStep || materialInDraft || materialNotInPrelim;
  const forApproval = !notReadyForApproval && !approvedByIds.includes(approverId);
  return (
    <div className="employee-initials-badge-wrapper">
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            {employeesByUserId[approverId].first_name} {employeesByUserId[approverId].last_name}
          </Tooltip>
        }
      >
        <span
          className={cx("initials-badge", {
            approved: isApproved,
            denied: isDenied,
            next: materialStatus !== null && !(isApproved || isDenied) && notReadyForApproval,
            "for-approval": forApproval,
            // "for-approvalx": isCurrentStep && !isFinishedStep && !["", 0, "draft", "prelim", 1].includes(materialStatus) && !approvedByIds.includes(approverId),
          })}
        >
          {getApproverInitials(approverId)}
        </span>
      </OverlayTrigger>
    </div>
  );
}

function ApproverItem({
  approverId,
  employeesByUserId,
  deniedByIds,
  approvedByIds,
  isCurrentStep = false,
  materialStatus = null,
}) {
  const isApproved = approvedByIds.includes(approverId);
  const isDenied = deniedByIds.includes(approverId);
  const materialInDraft = ["", 0, "draft"].includes(materialStatus);
  const materialNotInPrelim = materialStatus !== "prelim";
  const notReadyForApproval = !isCurrentStep || materialInDraft || materialNotInPrelim;
  const forApproval = !notReadyForApproval && !approvedByIds.includes(approverId);
  return (
    <span
      className={cx("approvers-full-name-badges badge badge-pill", {
        approved: isApproved,
        denied: isDenied,
        next: materialStatus !== null && !(isApproved || isDenied) && notReadyForApproval,
        "for-approval": forApproval,
      })}
    >
      {employeesByUserId[approverId].first_name} {employeesByUserId[approverId].last_name}
    </span>
  );
}

function ApprovalFlowPreview({
  steps,
  employeesByUserId,
  deniedByIds = [],
  approvedByIds = [],
  cutPassedStepsMode = true,
  fullNameMode = false,
  currentActiveStepId = null,
  materialStatus = null,
}) {
  const ApproverItemDisplay = fullNameMode ? ApproverItem : ShortApproverItem;
  const extendedSteps = steps.map((step) => ({
    ...step,
    isFinished:
      step.approvers_ids.filter((stepApproverId) => approvedByIds.includes(stepApproverId)).length >=
      step.min_approvers_counter_condition,
    isNext: currentActiveStepId && currentActiveStepId < step.id,
    isCurrent: currentActiveStepId && currentActiveStepId === step.id,
  }));

  const shouldCutStep = (extendedStep) => {
    return cutPassedStepsMode && extendedStep.isFinished;
  };
  const shouldIncludeApprover = (extendedStep, approverId) => {
    if (!shouldCutStep(extendedStep)) {
      return true;
    }
    return approvedByIds.includes(approverId) || deniedByIds.includes(approverId);
  };
  return (
    <div>
      {extendedSteps.map((step, index) => (
        <>
          {index > 0 && <i className="fe-chevron-right" />}
          {!shouldCutStep(step) && (
            <ApprovalRequirementBadge
              minValue={step.min_approvers_counter_condition}
              stepApproverLen={step.approvers_ids.length}
            />
          )}
          {step.approvers_ids.map(
            (approverId) =>
              shouldIncludeApprover(step, approverId) && (
                <ApproverItemDisplay
                  approverId={approverId}
                  employeesByUserId={employeesByUserId}
                  approvedByIds={approvedByIds}
                  deniedByIds={deniedByIds}
                  isFinishedStep={step.isFinished}
                  isInNextStep={step.isNext}
                  isCurrentStep={step.isCurrent}
                  materialStatus={materialStatus}
                />
              )
          )}
        </>
      ))}
    </div>
  );
}

function ApprovalFlowFetchPreview({
  companyId,
  approvalFlowId,
  deniedByIds = [],
  approvedByIds = [],
  fullNameMode = false,
  currentActiveStepId = null,
  materialStatus = null,
}) {
  const dataSource = useCallback(
    (cancelToken) => approvalFlows.details(companyId, approvalFlowId, { cancelToken }),
    [companyId, approvalFlowId]
  );
  const [{ data, loading }] = useAsync(dataSource, []);
  if (loading) {
    return <Loader />;
  }
  const approversById = _.keyBy(data.approvers, (user) => user.id);
  return (
    <ApprovalFlowPreview
      companyId={companyId}
      title={data.title}
      steps={data.steps}
      employeesByUserId={approversById}
      deniedByIds={deniedByIds}
      approvedByIds={approvedByIds}
      cutPassedStepsMode
      fullNameMode={fullNameMode}
      currentActiveStepId={currentActiveStepId}
      materialStatus={materialStatus}
    />
  );
}

export default ApprovalFlowPreview;
export { ApprovalFlowFetchPreview };
