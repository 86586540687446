import React, { useEffect } from "react";

import NavBar from "components/ui/navbar";
import FinancialYearProvider from "state/providers/FinancialYearProvider";
import VerificationProvider from "state/providers/VerificationProvider";
import MenuNumberProvider from "state/providers/MenuNumberProvider";
import { VerificationModal } from "components/modals";
import HotKeysComponent from "components/HotKeysComponent";
import { Navigate, Outlet } from "react-router";
import { useCompanyDispatch, useCompanyState } from "hooks/useCompany";
import { RocketLoader } from "components/ui/loaders";
import { SetDocTitle } from "components/ui/titles/PageTitle";
import DashboardMenu from "./DashboardMenu";
import DashboardTTOnlyMenu from "./DashboardTTOnlyMenu";
import CompanyNotificationBar from "./CompanyNotificationBar";
import SystemNotificationBar from "./SystemNotificationBar";

function DashboardLayout({ company }) {
  return (
    <>
      <SetDocTitle title={company.name} />
      <FinancialYearProvider>
        <VerificationProvider>
          <MenuNumberProvider>
            <NavBar company={company} />
            <HotKeysComponent />
            {company.ttOnly ? <DashboardTTOnlyMenu /> : <DashboardMenu />}
            <div className="content-page">
              <div className="content">
                <div className="container-fluid">
                  <SystemNotificationBar companyId={company.id} />
                  <CompanyNotificationBar companyId={company.id} />
                  <Outlet context={company} />
                </div>
              </div>
            </div>
            <VerificationModal />
          </MenuNumberProvider>
        </VerificationProvider>
      </FinancialYearProvider>
    </>
  );
}

function DashboardLayoutWrapper() {
  const { company, loading } = useCompanyState();
  const { loadCompany } = useCompanyDispatch();
  const companyId = window.localStorage.getItem("lastCompanyId");
  useEffect(() => {
    loadCompany(companyId);
  }, [loadCompany, companyId]);

  if (loading && companyId) {
    return <RocketLoader />;
  }

  if (!company) {
    return <Navigate to="/companies" />;
  }
  return <DashboardLayout company={company} />;
}

export default DashboardLayoutWrapper;
