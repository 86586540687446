import React, { useCallback } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Loader } from "components/ui/loaders";

function CompanyConsultsPermsForm({ data, setData, loading }) {
  const { t } = useTranslation(["common", "msg", "company"]);
  const updatedItem = (key, item) => {
    if (key === "has_access" && !item.has_access) {
      return {
        ...item,
        has_access: true,
        has_office_support_access: true,
      };
    }
    if (key === "has_salary_access" && !item.has_salary_access) {
      return {
        ...item,
        has_salary_access: true,
        has_access: true,
        has_office_support_access: true,
      };
    }
    return {
      ...item,
      [key]: !item[key],
    };
  };
  const togglePerm = useCallback(
    (key, index) => {
      setData(
        data.map((item, index2) => {
          return index !== index2 ? item : updatedItem(key, item);
        })
      );
    },
    [data, setData]
  );

  const CompanyPerm = useCallback(
    ({ perm, index }) => {
      return (
        <tr className="text-center">
          <th className="text-start">{perm.user_name}</th>
          <td onClick={() => togglePerm("has_office_support_access", index)}>
            <input defaultChecked={perm.has_office_support_access} type="checkbox" />
          </td>
          <td onClick={() => togglePerm("has_access", index)}>
            <input defaultChecked={perm.has_access} type="checkbox" />
          </td>
          <td onClick={() => togglePerm("has_salary_access", index)}>
            <input type="checkbox" defaultChecked={perm.has_salary_access} />
          </td>
        </tr>
      );
    },
    [togglePerm]
  );

  const items = data.map((perm, index) => <CompanyPerm key={perm.user_id} perm={perm} index={index} />);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Row>
        <Col>
          <Table size="sm" className="table-bordered agency-consult-perm">
            <thead>
              <tr className="text-center">
                <th className="text-start">{t("consult")}</th>
                <th style={{ width: 150 }}>{t("officeSupport")}</th>
                <th style={{ width: 150 }}>{t("standardAccess")}</th>
                <th style={{ width: 150 }}>{t("salaryAccess")}</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}

export default CompanyConsultsPermsForm;
