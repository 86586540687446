import * as React from "react";
import cx from "classnames";

export function Checkbox({
  checked,
  disabled,
  handleChange,
  label,
  className,
  indicatorClassName,
  labelClassName,
  extraInfo,
  indeterminate,
  style,
  containerClassName,
}) {
  return (
    <div id="checkbox" className={`d-flex flex-row align-items-center ${containerClassName}`} style={style}>
      <button
        type="button"
        className={cx(`custom-checkbox ${className}`, { checked, indeterminate })}
        disabled={disabled}
        onClick={(event) => {
          event.stopPropagation();
          handleChange(!checked);
        }}
      >
        <div className={`indicator ${indicatorClassName}`} />
      </button>
      {label ? (
        <div className={`ms-2 ${labelClassName}`}>
          <label>{label}</label>
          {extraInfo}
        </div>
      ) : null}
    </div>
  );
}
