import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { BExpandedTable } from "components/tables/btable";
import { formatDatetime } from "utils/date";
import Actions from "./IsoBasketActions";
import IsoPaymentPreviewList from "./IsoPaymentPreviewList";
import "./IsoBasketTable.scss";

function IsoBasketTable({ companyId }) {
  const { t } = useTranslation("common");

  const headers = useMemo(
    () => [
      {
        field: "bank_file_name",
        label: t("common:filename"),
      },
      {
        field: "created_by",
        label: t("common:createdBy"),
        render: (values) => values.created_by_name,
        sortField: "created_by_name",
      },
      {
        field: "created",
        label: t("common:created"),
        className: "text-end",
        render: (values) => <div className="text-end">{formatDatetime(values.created)}</div>,
      },
    ],
    [t]
  );
  const getRowProps = (row) => {
    return {
      className: cx(row.is_restored && "text-muted"),
    };
  };
  return (
    <div id="iso-basket-table">
      <BExpandedTable
        id="iso-basket-table"
        headers={headers}
        renderExpandedRow={(row) => <IsoPaymentPreviewList basket={row} />}
        getRowProps={getRowProps}
        renderActions={(row) => <Actions companyId={companyId} row={row} />}
      />
    </div>
  );
}

export default IsoBasketTable;
