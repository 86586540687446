import React from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "react-bootstrap";
import { useAuthState } from "../../hooks/useAuth";

function CustomerInvoiceNotEnabledErrorPage() {
  const { t } = useTranslation("msg");
  const { user } = useAuthState();
  const isConsult = user.is_superuser || user.is_consult;
  return (
    <div className="page-alert pt-2">
      <Alert variant="warning">
        <span>
          <i className="fas fa-exclamation me-2" />
          {isConsult ? t("consultBillectaNotConfigured") : t("clientBillectaNotConfigured")}
        </span>
      </Alert>
    </div>
  );
}
export default CustomerInvoiceNotEnabledErrorPage;
