import * as React from "react";
import cx from "classnames";

function Switch({ checked, disabled, handleChange, label, className, indicatorClassName, extraInfo }) {
  return (
    <div className="d-flex flex-row">
      <button
        type="button"
        className={cx(`custom-switch ${className}`, { checked })}
        disabled={disabled}
        onClick={() => handleChange(!checked)}
      >
        <div className={`indicator ${indicatorClassName}`} />
      </button>
      {label ? (
        <div className="ms-2">
          <label>{label}</label>
          {extraInfo}
        </div>
      ) : null}
    </div>
  );
}

export default Switch;
