import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";

import { parseDate } from "utils/date";
import SilverbackForm from "components/forms/SilverbackForm";
import useInitialAsync from "hooks/useInitialAsync";
import * as companyAPI from "api2/companies";
import { RocketLoader } from "components/ui/loaders";

function SilverbackEditPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const { id: silverId } = useParams();
  const { loading, item } = useInitialAsync(({ cancelToken }) => {
    return companyAPI.silverback.detail(company.id, silverId, { cancelToken });
  }, {});

  const onSave = () => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }

  return (
    <div>
      <SilverbackForm
        data={{ ...item, meeting_date: parseDate(item.meeting_date) }}
        companyId={company.id}
        onSave={onSave}
      />
    </div>
  );
}

export default SilverbackEditPage;
