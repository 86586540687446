import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { formatMoneyRounding } from "utils/money";
import { NumericFormat } from "react-number-format";
import cx from "classnames";
import { useOutletContext } from "react-router";
import { truncateText } from "utils/text";
import "./ProfitLossYearTable.scss";
import {
  calculateCategoryBudget,
  calculateCategoryBudgetMonthly,
  calculateExtraCatMonthly,
  calculateExtraCatYearly,
} from "./utils";

function BudgetInput({ defaultValue, updateAccountBudget, ...props }) {
  const { className } = props;
  const [value, setValue] = useState(defaultValue || 0);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  const onBlur = () => {
    updateAccountBudget(value);
  };
  return (
    <NumericFormat
      thousandSeparator={" "}
      decimalSeparator=","
      autoComplete="off"
      decimalScale={0}
      value={value}
      onBlur={onBlur}
      onValueChange={(values) => {
        setValue(values.floatValue || 0);
      }}
      className={cx(["money-input form-control", className])}
      fixedDecimalScale
    />
  );
}

const ROUNDING = 2; // SEK without ore

function ReportTable({ report }) {
  const { budget, setBudget, setEditMode } = useOutletContext();
  const { t } = useTranslation("reports");

  const updateAccountBudget = (accountNumber, value, monthIndex) => {
    setEditMode(true);
    setBudget((_budget) => {
      const months = [...(_budget.current[accountNumber]?.months || Array(report.months.length).fill(0))];
      const newAccountSum = (_budget.current[accountNumber]?.sum || 0) - months[monthIndex] + value;
      months[monthIndex] = value;
      return {
        previous: _budget.previous,
        current: { ..._budget.current, [accountNumber]: { sum: newAccountSum, months } },
      };
    });
  };

  return (
    <Table bordered className="sticky-header budget-ps-12">
      <thead>
        <tr>
          <th>Resultaträkning 12 månader</th>
          {report.months.map((month) => (
            <th key={month}>{month}</th>
          ))}
          <th>ACK</th>
        </tr>
      </thead>
      <tbody>
        {report.categories.map((category, ci) => (
          <React.Fragment key={`ci${ci}`}>
            {category.name && (
              <tr className="parent-category--name">
                <th colSpan={report.months.length + 2}>{category.name}</th>
              </tr>
            )}
            {category.child_categories.map((child, cci) => (
              <React.Fragment key={`cci${cci}`}>
                {child.extra ? (
                  <tr className="extra-category">
                    <th>{child.extra_name}</th>
                    {Object.values(child.sums).map((sum, i) => (
                      <th key={`sum${i}`} className="disabled">
                        {formatMoneyRounding(calculateExtraCatMonthly(budget.current, child, i), ROUNDING)}
                      </th>
                    ))}
                    <th className="disabled">
                      {formatMoneyRounding(calculateExtraCatYearly(budget.current, child), ROUNDING)}
                    </th>
                  </tr>
                ) : (
                  <>
                    <tr className="child-category--name">
                      <th colSpan={report.months.length + 2}>{child.name}</th>
                    </tr>
                    {child.accounts &&
                      child.accounts.map((account, ai) => (
                        <tr key={`ai${ai}`} className="account">
                          <td>{truncateText(`${account.number} - ${account.name}`, 36)}</td>
                          {Object.values(account.sums).map((sum, i) => (
                            <th key={`sum${i}`} className="td-input">
                              <BudgetInput
                                defaultValue={budget.current[account.number]?.months[i] || 0}
                                updateAccountBudget={(value) => updateAccountBudget(account.number, value, i)}
                              />
                            </th>
                          ))}
                          <th className="disabled">
                            {formatMoneyRounding(budget.current[account.number]?.sum, ROUNDING)}
                          </th>
                        </tr>
                      ))}

                    <tr className="child-category--sum">
                      <th>Summa {child.name}</th>
                      {Object.values(child.sums).map((sum, i) => (
                        <th key={`sum${i}`} className="disabled">
                          {formatMoneyRounding(calculateCategoryBudgetMonthly(budget.current, child, i), ROUNDING)}
                        </th>
                      ))}
                      <th className="disabled">
                        {formatMoneyRounding(calculateCategoryBudget(budget.current, child), ROUNDING)}
                      </th>
                    </tr>
                  </>
                )}
              </React.Fragment>
            ))}
            {category.name && (
              <tr className="parent-category--sum">
                <th>SUMMA {category.name}</th>
                {Object.values(category.sums).map((sum, i) => (
                  <th key={`sum${i}`} className="disabled">
                    {formatMoneyRounding(calculateCategoryBudgetMonthly(budget.current, category, i), ROUNDING)}
                  </th>
                ))}
                <th className="disabled">
                  {formatMoneyRounding(calculateCategoryBudget(budget.current, category), ROUNDING)}
                </th>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th>{t("expectedResult")}</th>
          {Object.values(report.budgets).map((sum, i) => (
            <th key={`sum${i}`} className="disabled">
              {formatMoneyRounding(sum, ROUNDING)}
            </th>
          ))}
          <th className="disabled">{formatMoneyRounding(report.budgets_ack, ROUNDING)}</th>
        </tr>
      </tfoot>
    </Table>
  );
}

export default ReportTable;
