import React from "react";
import { Dropdown } from "react-bootstrap";
import { truncateText } from "utils/text";
import { useTranslation } from "react-i18next";

function AgencySwitcher({ agencies, selected, onSelect }) {
  const { t } = useTranslation("common");

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        as="a"
        className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light"
      >
        {truncateText(selected ? selected.agency_name : "", 23)} <i className="fas fa-chevron-down" />
      </Dropdown.Toggle>
      <Dropdown.Menu id="dropdown-basic-button" className="dropdown-menu-right profile-dropdown">
        <Dropdown.Header>{t("common:actions.select")}:</Dropdown.Header>
        {agencies.map((agency) => (
          <Dropdown.Item key={agency.agency} onClick={() => onSelect(agency)}>
            <span>{agency.agency_name}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default AgencySwitcher;
