import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";

import useInitialAsync from "hooks/useInitialAsync";
import * as ciAPI from "api2/customer-invoices";
import { RocketLoader } from "components/ui/loaders";
import ContractTemplateForm from "components/forms/ContractTemplateForm";

function ContractTemplateDuplicatePage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const { id: contractId } = useParams();
  const { loading, item } = useInitialAsync(
    ({ cancelToken }) => {
      return ciAPI.contractTemplates.details(company.id, contractId, { cancelToken });
    },
    { records: [] },
    [company.id, contractId]
  );

  const onCreate = (invoice) => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }

  return (
    <ContractTemplateForm
      company={company}
      invoice={{
        ...item,
        name: `${item.name} -`,
        id: null,
      }}
      onSave={onCreate}
    />
  );
}

export default ContractTemplateDuplicatePage;
