import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FinancialYearStateContext } from "state/providers/FinancialYearProvider";

import BTable from "components/tables/btable";
import { formatMoney } from "utils/money";
import Filters from "./VATReportFilters";
import Actions from "./VATReportActions";
import useTable from "../btable/useTable";

function VATReportTable({ companyId, forStatus, selectable = false, initialFilters = {} }) {
  const { t } = useTranslation("tax");
  const { cleanFilters } = useTable();

  const { financialYear } = useContext(FinancialYearStateContext);
  React.useEffect(() => {
    // skip initial render
    return () => {
      cleanFilters(initialFilters);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialYear.id, initialFilters]);
  const headers = useMemo(
    () => [
      {
        field: "description",
        label: t("common:name"),
      },
      {
        field: "date_start",
        label: t("common:dates.period"),
        render: (values) => `${values.date_start} - ${values.date_end}`,
      },
      {
        field: "due_date",
        label: t("common:dates.dueDate"),
      },
      {
        field: "amount",
        label: t("common:money.amount"),
        className: "text-end",
        render: (values) => formatMoney(values.amount),
      },
    ],
    [t]
  );

  const getRowProps = (row) => {
    return { className: row.vat_report ? "paid" : "unpaid" };
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters companyId={companyId} forStatus={forStatus} initialFilters={initialFilters} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      getRowProps={getRowProps}
    />
  );
}

export default VATReportTable;
