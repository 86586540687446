import React from "react";
import { useOutletContext, useParams } from "react-router";
import * as tableAPI from "api/table";

import TableProvider from "components/tables/btable/provider";
import { RepeatedTaskExtendedTable } from "components/tables";
import CompanySettingProvider from "state/providers/CompanySettingProvider";
import { RepeatedTaskAddWidget } from "components/forms/company-settings-forms/RepeatedTaskForm";

function TimeTrackerSettingsPage({ tableId = "repeatedTasks" }) {
  const context = useOutletContext();
  const { companyId: paramsCompanyId } = useParams();
  const companyId = context?.company?.id ?? paramsCompanyId;
  const initialFilters = {};
  return (
    <CompanySettingProvider companyId={companyId}>
      <TableProvider
        tableId={tableId}
        dataSource={(filters, config) => tableAPI.companyRepeatedTasks(companyId, filters, config)}
        initialFilters={initialFilters}
      >
        <RepeatedTaskExtendedTable companyId={companyId} />
        <RepeatedTaskAddWidget companyId={companyId} />
      </TableProvider>
    </CompanySettingProvider>
  );
}

export default TimeTrackerSettingsPage;
