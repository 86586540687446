import React from "react";
import { useNavigate, useOutletContext } from "react-router";

import CompanyArchive from "components/forms/CompanyArchiveForm";

function CompanyArchiveAddPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const archive = {
    category: "other",
    title: "",
    description: "",
    financial_year: null,
    for_auditor: false,
    auditor_category: "",
  };
  const onCreate = (newArchive) => {
    navigate(-1);
  };

  return <CompanyArchive companyId={company.id} archive={archive} onSave={onCreate} />;
}

export default CompanyArchiveAddPage;
