import { useLocation } from "react-router";

function GetLayout() {
  const location = useLocation();
  if (location.pathname.includes("/tt/")) {
    return "tt-layout";
  }
  if (location.pathname.includes("/status-list/")) {
    return "st-layout";
  }
  if (location.pathname.includes("/office-support")) {
    return "office-support-layout";
  }
  return "default-layout";
}

function isActive(layout) {
  return layout === GetLayout();
}

function getActiveClass(layout) {
  return isActive(layout) ? "active" : "";
}

function isTargetBlank(layout) {
  const currentLayout = GetLayout();
  if (currentLayout === "st-layout") {
    return layout !== "st-layout";
  }
  return layout === "st-layout";
}

function GetMainPageUrl() {
  const location = useLocation();
  if (location.pathname.includes("/status-list/") || location.pathname.includes("/office-support")) {
    return "/office-support-companies/";
  }
  return "/companies";
}

export { GetLayout, isActive, getActiveClass, isTargetBlank, GetMainPageUrl };
