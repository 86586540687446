import React from "react";
import NavBar from "components/ui/navbar";
import { Outlet, useParams } from "react-router";
import { SetDocTitle } from "components/ui/titles/PageTitle";
import StatusListProvider from "state/providers/StatusListProvider";
import StatusListMenu from "./StatusListMenu";
import StatusListNavTabs from "./StatusListNavTabs";

function StatusListLayout() {
  const { section } = useParams();
  return (
    <>
      <SetDocTitle title="Bflow" />
      <div className="status-list">
        <NavBar company={null} forDashboard={false} />
        <StatusListMenu />
        <div className="content-page">
          <div className="content">
            <div className="container-fluid">
              <div className="tab-card">
                <StatusListProvider section={section}>
                  <StatusListNavTabs />
                  <Outlet />
                </StatusListProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StatusListLayout;
