import qs from "qs";
import { differenceInCalendarDays, parseISO } from "date-fns";
import _ from "lodash";
import { filesToBase64 } from "utils/file";
import client from "./client";
import { fromBillectaAmount } from "../utils/calc/ci";
import * as options from "../api/options";

export function history(companyId, invoiceId, config) {
  return client.get(`/customer-invoices/${invoiceId}/history/`, {
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export const invoice = {
  list: (companyId, filters, config) => {
    return client.get(`/customer-invoices/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  save: async (companyId, invoiceData, newFiles, config) => {
    invoiceData.attachments = await filesToBase64(newFiles);

    if (invoiceData.id) {
      return client.put(`/customer-invoices/${invoiceData.id}/update/`, invoiceData, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    return client.post(`/customer-invoices/create/`, invoiceData, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  previewPdf: async (companyId, invoiceData, newFiles, config) => {
    invoiceData.attachments = await filesToBase64(newFiles);
    return client.post(`/customer-invoices/preview/pdf/`, invoiceData, {
      responseType: "blob",
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  attest: (companyId, invoiceId, config) => {
    return client.put(`/customer-invoices/${invoiceId}/attest/`, null, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  remove: (companyId, invoiceId, config) => {
    return client.delete(`/customer-invoices/${invoiceId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  details: (companyId, invoiceId, config) => {
    return client
      .get(`/customer-invoices/${invoiceId}/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      })
      .then((response) => {
        const vatOptions = options.vatOptions.asList();
        const booking_date = response.data.booking_date ? parseISO(response.data.booking_date) : null;
        const due_date = response.data.due_date ? parseISO(response.data.due_date) : null;
        const payment_terms = differenceInCalendarDays(due_date, booking_date);
        const currency = response.data.amount_currency;
        return {
          ...response.data,
          connected_products: _.keyBy(response.data.connected_products, (p) => p.id),
          payment_terms,
          booking_date,
          due_date,
          customer: !_.isEmpty(response.data.customer_snapshot) && {
            value: response.data.customer,
            label: response.data.customer_snapshot.name,
            ...response.data.customer_snapshot,
          },
          rot_rut: response.data.rot_rut
            ? {
                customers: response.data.rot_rut.customers.map((customer) => ({
                  ...customer,
                  amount: fromBillectaAmount(customer.amount, currency),
                })),
                property_designation: response.data.rot_rut.property_designation || "",
                residence_org_no: response.data.rot_rut.residence_org_no || "",
                work_start: response.data.rot_rut.work_start,
                work_end: response.data.rot_rut.work_end,
              }
            : null,
          extra_receivers: response.data.extra_receivers
            ? response.data.extra_receivers.filter((item) => item.length > 0)
            : [],
          records: response.data.records.map((record) => {
            record.key = _.uniqueId("ln");
            if (record.record_type === "product") {
              return {
                ...record,
                product: {
                  ...record.product,
                  vat_percent: options.vatOptions.getOption(record.product.vat_percent) || vatOptions[0],
                  unit_price: fromBillectaAmount(record.product.unit_price, currency),
                  account: record.product.account || 3001,
                },
                rot_rut: record.rot_rut
                  ? {
                      ...record.rot_rut,
                      material_cost: fromBillectaAmount(record.rot_rut.material_cost, currency),
                      amount: fromBillectaAmount(record.rot_rut.amount, currency),
                    }
                  : null,
              };
            }
            return record;
          }),
          newFiles: [{ key: _.uniqueId("nd.") }],
        };
      });
  },
  send: (companyId, invoiceId, payload, config) => {
    return client.post(`/customer-invoices/${invoiceId}/send/`, payload, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  sendReminder: (companyId, invoiceId, config) => {
    return client.post(`/customer-invoices/${invoiceId}/send-reminder/`, null, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  manualPay: (companyId, invoiceId, data, config) => {
    return client.post(`/customer-invoices/${invoiceId}/payments/pay-manual/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const credits = {
  list: (companyId, debitInvoiceId, config) => {
    return client.get(`/customer-invoices/${debitInvoiceId}/credits/`, {
      params: { page_size: 25 },
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  create: (companyId, debitInvoiceId, creditInvoiceId) => {
    return client.post(`/customer-invoices/${debitInvoiceId}/credits/${creditInvoiceId}/create/`, null, {
      headers: {
        "x-company": companyId,
      },
    });
  },
};

export const periodisation = {
  details: (companyId, invoiceId, config) => {
    return client.get(`/customer-invoices/${invoiceId}/periodisation/detail/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  configure: (companyId, invoiceId, data, config) => {
    return client.post(`/customer-invoices/${invoiceId}/periodisation/configure/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};

export const rotRut = {
  list: (companyId, filters, config) => {
    return client.get(`/customer-invoices/rot-rut/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  xml: (ids, companyId, filters, config) => {
    return client.get(`/customer-invoices/rot-rut/xml/`, {
      params: { filters, ids },
      ...config,
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
      headers: {
        "x-company": companyId,
      },
      responseType: "blob",
    });
  },
  moveBackFromApplied: (ciId, companyId, config) => {
    return client.put(
      `/customer-invoices/${ciId}/rot-rut/move-back-from-applied/`,
      {},
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
  uploadSkvFile: (companyId, files) => {
    const formData = new FormData();
    files.forEach((file) => {
      if (file) {
        formData.append("file", file.file);
      }
    });
    return client.post(`/customer-invoices/rot-rut/upload-skv-file/`, formData, {
      headers: {
        "x-company": companyId,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  bookPayments: (companyId, data, config) => {
    return client.post(`/customer-invoices/rot-rut/book-payments/`, data, {
      headers: {
        "x-company": companyId,
        ...config,
      },
    });
  },
  materialCostAndHoursUpdate: (ciId, companyId, data, config) => {
    return client.patch(`/customer-invoices/${ciId}/rot-rut/update-material-cost-and-hours/`, data, {
      headers: {
        "x-company": companyId,
        ...config,
      },
    });
  },
};

export const products = {
  list: (companyId, filters, config) => {
    return client.get(`/products/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { indices: false });
      },
      ...config,
    });
  },
  save: (companyId, productData, config) => {
    if (productData.id) {
      return client.put(`/products/${productData.id}/update/`, productData, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    return client.post(`/products/create/`, productData, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  toggleActivate: (companyId, productId, config) => {
    return client.patch(`/products/${productId}/toggle-active/`, null, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  csvImport: (companyId, csvFile, config) => {
    const formData = new FormData();
    formData.append("file", csvFile, csvFile.name);
    return client.post("/products/csv-import/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-company": companyId,
      },
    });
  },
};
export const customers = {
  list: (companyId, filters, config) => {
    return client.get(`/customers/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  save: (companyId, customerData, config) => {
    if (customerData.id) {
      return client.put(`/customers/${customerData.id}/update/`, customerData, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    return client.post(`/customers/create/`, customerData, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  remove: (companyId, customerId, config) => {
    return client.delete(`/customers/${customerId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  toggleActivate: (companyId, customerId, config) => {
    return client.patch(`/customers/${customerId}/toggle-active/`, null, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  prelimCustomerNumber: (companyId, config) => {
    return client.get(`/customers/prelim-customer-number/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  updatePrivateInfo: (companyId, customerId, propertyDesignation, residenceOrgNo, config) => {
    return client.patch(
      `/customers/${customerId}/update-private/`,
      {
        property_designation: propertyDesignation,
        residence_org_no: residenceOrgNo,
      },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    );
  },
  csvImport: (companyId, csvFile, config) => {
    const formData = new FormData();
    formData.append("file", csvFile, csvFile.name);
    return client.post("/customers/csv-import/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-company": companyId,
      },
    });
  },
  shouldWarnOldMailDelivery: (companyId, customerId, config) => {
    return client
      .get(`/customers/${customerId}/temporary-delivery-mail-check/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      })
      .then((response) => {
        return response.status === 200;
      });
  },
};

export const contractTemplates = {
  list: (companyId, filters, config) => {
    return client.get(`/contract-templates/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  details: (companyId, templateId, config) => {
    return client.get(`/contract-templates/${templateId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  save: (companyId, templateData, config) => {
    if (templateData.id) {
      return client.put(`/contract-templates/${templateData.id}/update/`, templateData, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    return client.post(`/contract-templates/create/`, templateData, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  remove: (companyId, templateId, config) => {
    return client.delete(`/contract-templates/${templateId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};
export const contractInvoices = {
  list: (companyId, filters, config) => {
    return client.get(`/contract-invoices/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  details: (companyId, contractId, config) => {
    return client.get(`/contract-invoices/${contractId}/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  save: (companyId, contractData, config) => {
    if (contractData.id) {
      return client.put(`/contract-invoices/${contractData.id}/update/`, contractData, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    }
    return client.post(`/contract-invoices/create/`, contractData, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  remove: (companyId, contractId, config) => {
    return client.delete(`/contract-invoices/${contractId}/delete/`, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  createDraftInvoices: (companyId, data, config) => {
    return client.post(`/contract-invoices/create-draft-invoices/`, data, {
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
  draftInvoices: (companyId, filters, config) => {
    return client.get(`/contract-invoices/draft-invoices/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    });
  },
};
