import React from "react";

import GlobalDraftSILayout from "components/layouts/GlobalDraftSILayout";
import { GlobalDraftSITable } from "components/tables";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router";

import TableProvider from "components/tables/btable/provider";
import * as siAPI from "api2/supplier-invoices";
import { useCompanyDispatch } from "hooks/useCompany";

function GlobalDraftSupplierInvoiceListPage() {
  const initialFilters = {
    favourites: true,
  };
  const { selectCompany: selectCompanyAction } = useCompanyDispatch();
  const navigate = useNavigate();

  const selectCompany = async (companyId, nextRoute = "") => {
    await selectCompanyAction(companyId).then((response) => {
      navigate(nextRoute, { replace: true });
    });
  };

  return (
    <GlobalDraftSILayout>
      <Card>
        <Card.Body>
          <TableProvider
            tableId="global-draft-si"
            dataSource={(filters, config) => siAPI.globalSupplierInvoices(filters, config)}
            initialFilters={initialFilters}
          >
            <GlobalDraftSITable selectCompany={selectCompany} />
          </TableProvider>
        </Card.Body>
      </Card>
    </GlobalDraftSILayout>
  );
}

export default GlobalDraftSupplierInvoiceListPage;
