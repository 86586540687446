import qs from "qs";
import client from "./client";

export function balanceReport(companyId, params, config) {
  return client.get(`/reports/balance/`, {
    params,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function accountBalance(companyId, params, config) {
  return client.get(`/reports/account-balance/`, {
    params,
    paramsSerializer: (args) => {
      return qs.stringify(args, { indices: false });
    },
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function accountGlobalBalance(companyId, params, config) {
  return client.get(`/reports/account-global-balance/`, {
    params,
    paramsSerializer: (args) => {
      return qs.stringify(args, { indices: false });
    },
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function ledgerReport(companyId, params, config) {
  return client.get(`/reports/ledger/`, {
    params,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function profitAndLoss(companyId, params, config) {
  return client.get(`/reports/profit-loss/`, {
    params,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function profitAndLoss12(companyId, params, config) {
  return client.get(`/reports/profit-loss-year/`, {
    params,
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export function employeeSettlementAccounts(companyId, financialYearId, config) {
  return client.get(`/reports/settlement-accounts/`, {
    params: { financial_year: financialYearId },
    headers: {
      "x-company": companyId,
    },
    ...config,
  });
}

export const timeReport = {
  list: (companyId, filters, config) =>
    client.get(`/time-logs/`, {
      params: filters,
      headers: {
        "x-company": companyId,
      },
      ...config,
    }),
  approveMany: (companyId, logIds, config) =>
    client.patch(
      `/time-logs/approve-many/`,
      { ids: logIds },
      {
        headers: {
          "x-company": companyId,
        },
        ...config,
      }
    ),
  comment: {
    list(companyId, logId, config) {
      return client.get(`/time-logs/${logId}/comments/`, {
        params: { page_size: 100 },
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
    create(companyId, logId, text, config) {
      return client.post(
        `/time-logs/${logId}/comments/create/`,
        {
          text,
        },
        {
          headers: {
            "x-company": companyId,
          },
          ...config,
        }
      );
    },
    remove(companyId, logId, commentId, config) {
      return client.delete(`/time-logs/${logId}/comments/${commentId}/delete/`, {
        headers: {
          "x-company": companyId,
        },
        ...config,
      });
    },
  },
};
