import React from "react";
import { useNavigate, useOutletContext, useParams, useResolvedPath } from "react-router";

import useInitialAsync from "hooks/useInitialAsync";
import * as outlayAPI from "api2/outlays";
import { RocketLoader } from "components/ui/loaders";
import ReceiptForm from "components/forms/ReceiptForm";

function ReceiptEditPage() {
  const company = useOutletContext();
  const currentPath = useResolvedPath("").pathname;
  const navigate = useNavigate();
  const { id: receiptId } = useParams();
  const { loading, item } = useInitialAsync(() => {
    return outlayAPI.receiptDetails(company.id, receiptId);
  });

  const onUpdate = (outlay) => {
    if (outlay.next && item.sub_status !== "investigate") {
      const nextLoc = currentPath
        .replace(item.id, outlay.next.id)
        .replace("receipt", outlay.next.is_receipt ? "receipt" : "mileage");
      navigate("/temp", { replace: true });
      navigate(nextLoc, { replace: true });
    } else {
      navigate(-1);
    }
  };

  if (loading) {
    return <RocketLoader />;
  }

  return <ReceiptForm company={company} outlay={item} onSave={onUpdate} />;
}

export default ReceiptEditPage;
