import React from "react";
import { useNavigate, useOutletContext } from "react-router";

import ConsultArchiveForm from "components/forms/ConsultArchiveForm/ConsultArchiveForm";

function ConsultArchiveAddPage() {
  const { company, reloadIndicators } = useOutletContext();
  const navigate = useNavigate();
  const archive = {
    title: "",
    description: "",
    category: null,
    month: null,
  };

  const onCreate = (newArchive) => {
    reloadIndicators();
    navigate(-1);
  };

  return <ConsultArchiveForm companyId={company.id} archive={archive} onSave={onCreate} />;
}

export default ConsultArchiveAddPage;
