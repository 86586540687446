import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { toast } from "react-toastify";

import { codesForOutlays, codesForVerifications } from "components/perms/PermCodes";
import { PermCodeRequired } from "components/perms";
import {
  HistoryButton,
  PreviewButton,
  TooltipActionButton,
  TooltipActionLink,
  VerificationBookingButton,
  VerificationCommentsButton,
  VerificationDocumentsButton,
} from "components/ui/buttons";
import { confirmExecute, confirmRemove } from "components/modals/ConfirmModal";
import useTable from "components/tables/btable/useTable";
import * as outlayAPI from "api2/outlays";
import { SelectionContext } from "state/providers/SelectionProvider";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { useResolvedPath } from "react-router";
import useModal from "hooks/useModal";
import { OutlayBookPaymentModal } from "components/modals";
import { useCompanyState } from "hooks/useCompany";
import { useAuthState } from "hooks/useAuth";
import { handleActionErrors } from "api/errors";

function OutlayActions({ row, companyId }) {
  const { t } = useTranslation("outlay");
  const { user } = useAuthState();
  const {
    tableId,
    dataActions: { reload },
  } = useTable();
  const {
    company: { payment_provider: paymentProvider },
  } = useCompanyState();
  const { removeSelection } = useContext(SelectionContext);
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const url = useResolvedPath("").pathname;
  const bookPayModal = useModal();
  const show = {
    bookBtn: _.includes(["unpaid"], row.sub_status) || (paymentProvider === "pis" && row.sub_status === "sent_bank"),
    bookingBtn: row.status === 1,
    editBtn: _.includes(["", "disputed", "investigate"], row.sub_status),
    deleteBtn: _.includes(["", "prelim", "disputed"], row.sub_status),
    cancelPayBtn: row.sub_status === "sent_bank",
    moveDraftBtn: _.includes(["prelim", "unpaid", "investigate"], row.sub_status),
  };
  const canDeleteOutlay = user.id === row.created_by_id;

  const onDelete = async () => {
    const answer = await confirmRemove(t("confirm.remove", { employee: row.created_by }));
    if (answer) {
      outlayAPI
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          if (removeSelection) {
            removeSelection(tableId, row);
          }
          reload();
          reloadMenuNumbers();
        })
        .catch((error) => {
          if (error.data && error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };

  const onBookPayModalClose = (saved) => {
    bookPayModal.close();
    if (saved) {
      if (removeSelection) {
        removeSelection(tableId, row);
      }
      reload();
      reloadMenuNumbers();
    }
  };

  const onCancelPayment = async () => {
    const answer = await confirmExecute(t("confirm.cancelPayment", { employee: row.created_by }));
    if (answer) {
      outlayAPI
        .cancelPayment(companyId, row.id)
        .then(() => {
          toast.success(t("msg:updated"));
          reload();
          reloadMenuNumbers();
        })
        .catch((error) => {
          if (error.data && error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };

  const onMoveDraft = async () => {
    const answer = await confirmExecute(t("confirm.moveDraft", { employee: row.created_by }));
    if (answer) {
      outlayAPI
        .moveDraft(companyId, row.id)
        .then(() => {
          if (removeSelection) {
            removeSelection(tableId, row);
          }
          reload();
          reloadMenuNumbers();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    }
  };

  return (
    <>
      <HistoryButton apiResource={outlayAPI.history} apiParams={[companyId, row.id]} />
      <VerificationDocumentsButton companyId={companyId} documentsIds={row.documents} verificationId={row.id} />
      <VerificationCommentsButton companyId={companyId} verification={row} />
      <PreviewButton link={`${url}/${row.type}/${row.id}/preview`} />
      <PermCodeRequired code={codesForVerifications.enabled}>
        {show.bookingBtn && <VerificationBookingButton companyId={companyId} verification={row} />}
      </PermCodeRequired>
      <PermCodeRequired code={codesForOutlays.manage}>
        {show.editBtn && (
          <TooltipActionLink
            text={t("common:actions.change")}
            link={`${url}/${row.type}/${row.id}/change`}
            icon="fas fa-pen"
          />
        )}
      </PermCodeRequired>
      <PermCodeRequired code={codesForOutlays.bookPayment}>
        {show.bookBtn && (
          <TooltipActionButton
            text={t("common:actions.bookPayment")}
            onClick={bookPayModal.open}
            icon="fas fa-dollar-sign"
          />
        )}
      </PermCodeRequired>
      <PermCodeRequired code={codesForOutlays.cancelPayment}>
        {show.cancelPayBtn && (
          <TooltipActionButton
            variant="action-red"
            text={t("common:actions.cancelPayment")}
            onClick={onCancelPayment}
            icon="fas fa-ban"
          />
        )}
      </PermCodeRequired>
      <PermCodeRequired code={codesForOutlays.manage}>
        {show.moveDraftBtn && (
          <TooltipActionButton
            variant="action"
            text={t("common:actions.moveDraft")}
            onClick={onMoveDraft}
            icon="fas fa-reply"
          />
        )}
      </PermCodeRequired>
      {show.deleteBtn && canDeleteOutlay && (
        <TooltipActionButton text={t("common:actions.remove")} onClick={onDelete} icon="fas fa-trash" />
      )}

      {bookPayModal.show && (
        <OutlayBookPaymentModal companyId={companyId} invoiceData={row} handleClose={onBookPayModalClose} />
      )}
    </>
  );
}

export default OutlayActions;
