import React, { useState } from "react";
import { NavLink, useLocation, useResolvedPath } from "react-router";

import LeftSidebar from "components/ui/sidebar";
import { useTranslation } from "react-i18next";
import { useCompanyState } from "hooks/useCompany";
import { useMenuNumbersState } from "hooks/useMenuNumbers";
import { HasPermCode, PermCodeRequired } from "components/perms";
import cx from "classnames";
import { codesFor } from "components/perms/PermCodes";
import TimeTrackerMenuItem from "../TimeTrackerMenuItem";
import MenuSwitcher from "./MenuSwitcher";
import LayoutSwitcherMenuItem from "../LayoutSwitcherMenuItem";

function DashboardMenu() {
  const { t } = useTranslation("navigation");
  const { company } = useCompanyState();
  const { pathname } = useLocation();
  const hasConsultManage = HasPermCode(codesFor.consultManage.enabled);
  const [menuRegular, setMenuRegular] = useState(
    !hasConsultManage || (!pathname.includes("consult") && pathname !== "/")
  );

  const menuNumbers = useMenuNumbersState();
  const url = useResolvedPath("").pathname;
  const loc = useLocation().pathname;
  const fileArchiveEnabled = HasPermCode(codesFor.fileArchive.enabled);
  const companyArchiveEnabled = HasPermCode(codesFor.companyArchive.enabled);
  const salaryArchiveEnabled = HasPermCode(codesFor.salaries.archive_all) || HasPermCode(codesFor.salaries.archive_own);
  const archiveEnabled = fileArchiveEnabled || companyArchiveEnabled || salaryArchiveEnabled;
  const canSeeSI = HasPermCode(codesFor.supplierInvoices.enabled);
  const canSeeOutlays = HasPermCode(codesFor.outlays.enabled);
  const canSeeSalaries = HasPermCode(codesFor.salaries.enabled);
  const canSeeDirectPayments = HasPermCode(codesFor.directPayments.enabled);
  const canSeeAbsences = HasPermCode(codesFor.absences.enabled);
  const canSeeTaxes = HasPermCode(codesFor.taxes.enabled);
  const canSeeApproveSI = HasPermCode(codesFor.supplierInvoices.approve) || (canSeeSI && hasConsultManage);
  const canSeeApproveOutlays = HasPermCode(codesFor.outlays.approve) || (canSeeOutlays && hasConsultManage);
  const canSeeApproveSalaries = HasPermCode(codesFor.salaries.approve) || (canSeeSalaries && hasConsultManage);
  const canSeeApproveAbsences = HasPermCode(codesFor.absences.approve) || (canSeeAbsences && hasConsultManage);
  const canSeeApprove = canSeeApproveSI || canSeeApproveOutlays || canSeeApproveSalaries || canSeeApproveAbsences;
  const canSeePaySI = HasPermCode(codesFor.supplierInvoices.pay) || (canSeeSI && hasConsultManage);
  const canSeePayOutlays = HasPermCode(codesFor.outlays.pay) || (canSeeOutlays && hasConsultManage);
  const canSeePaySalaries = HasPermCode(codesFor.salaries.pay) || (canSeeSalaries && hasConsultManage);
  const canSeePayDirectPayments =
    HasPermCode(codesFor.directPayments.pay) || (canSeeDirectPayments && hasConsultManage);
  const canSeePayTaxes = HasPermCode(codesFor.taxes.pay) || (canSeeTaxes && hasConsultManage);
  const canSeePay = canSeePaySI || canSeePayOutlays || canSeePaySalaries || canSeePayDirectPayments || canSeePayTaxes;

  const canSeePayments = canSeeSI || canSeeOutlays || canSeeSalaries || canSeeDirectPayments || canSeeTaxes;

  const approveUrlPathToTab = () => {
    if (canSeeApproveSI) {
      return "supplier-invoices";
    }
    if (canSeeApproveOutlays) {
      return "outlays";
    }
    if (canSeeApproveSalaries) {
      return "salaries";
    }
    if (canSeeApproveAbsences) {
      return "employee-absences";
    }
    return "";
  };
  const payUrlPathToTab = () => {
    if (canSeePaySI) {
      return "supplier-invoices";
    }
    if (canSeePayOutlays) {
      return "outlays";
    }
    if (canSeePaySalaries) {
      return "salaries";
    }
    if (canSeePayTaxes) {
      return "taxes";
    }
    if (canSeePayDirectPayments) {
      return "direct-payments";
    }
    return "";
  };
  const paymentsUrlPathToTab = () => {
    if (canSeeSI) {
      return "supplier-invoices";
    }
    if (canSeeOutlays) {
      return "outlays";
    }
    if (canSeeSalaries) {
      return "salaries";
    }
    if (canSeeTaxes) {
      return "taxes";
    }
    if (canSeeDirectPayments) {
      return "direct-payments";
    }
    return "";
  };
  return (
    <LeftSidebar extraDeps={menuRegular}>
      <LayoutSwitcherMenuItem />
      <PermCodeRequired code={codesFor.tt.enabled}>
        <TimeTrackerMenuItem agencyId={company.agency_id} />
      </PermCodeRequired>
      <li>
        <NavLink to="/">
          <i className="fe-airplay" />
          <span> {t("dashboard")}</span>
        </NavLink>
        <PermCodeRequired code={codesFor.companySettings.enabled}>
          <NavLink
            to={{
              pathname: `/companies/${company.id}/settings`,
              state: { prevPath: url, companyId: company.id },
            }}
          >
            <i className="fe-settings" />
            <span> {t("settings")}</span>
          </NavLink>
        </PermCodeRequired>
        <PermCodeRequired code={codesFor.upload.enabled}>
          <NavLink to="/upload/supplier-invoices">
            <i className="fe-upload-cloud" />
            <span> {t("common:actions.upload")}</span>
          </NavLink>
        </PermCodeRequired>
      </li>
      {menuRegular && (
        <>
          <li className="menu-title">{t("iWantTo")}...</li>
          <PermCodeRequired code={codesFor.customerInvoices.enabled}>
            <li>
              <NavLink to="/invoicing/customer-invoices">
                <i className="fe-mail" />
                <span> {t("invoice")}</span>
              </NavLink>
            </li>
          </PermCodeRequired>
          <li>
            {canSeeApprove && (
              <NavLink
                to={`/approve/${approveUrlPathToTab()}`}
                className={({ isActive }) => {
                  return isActive || loc.includes("/approve") ? "active" : undefined;
                }}
              >
                <i className="fe-check" />
                <span className="badge badge-prelim badge-pill float-end">{menuNumbers.prelim.count}</span>
                <span> {t("approve")}</span>
              </NavLink>
            )}
          </li>
          {canSeePay && (
            <li>
              <NavLink
                to={`/pay/${payUrlPathToTab()}`}
                className={({ isActive }) => {
                  return isActive || loc.includes("/pay/") ? "active" : undefined;
                }}
              >
                <i className="fe-dollar-sign" />
                <span className="badge badge-unpaid badge-pill float-end">{menuNumbers.unpaid.count}</span>
                <span> {t("pay")}</span>
              </NavLink>
            </li>
          )}
          {canSeePayments && (
            <li>
              <NavLink
                to={`/payments/${paymentsUrlPathToTab()}`}
                className={({ isActive }) => {
                  return isActive || loc.includes("/payments") ? "active" : undefined;
                }}
              >
                <i className="fe-check-circle" />
                <span className="badge badge-sentBank badge-pill float-end">{menuNumbers.sent_bank.count}</span>
                <span> {t("seePayments")}</span>
              </NavLink>
            </li>
          )}
          <li className={url.includes("/reports") ? "mm-active" : ""}>
            <NavLink to="/reports">
              <i className="fe-bar-chart-2" />
              <span> {t("seeReports")}</span>
              <span className="menu-arrow" />
            </NavLink>
            <ul className="nav-second-level">
              <PermCodeRequired code={codesFor.reports.enabled}>
                {company.cashflow_enabled && (
                  <li>
                    <NavLink end to="/reports/cashflow">
                      {t("cashflow")}
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink to="/reports/balance">{t("balanceSheet")}</NavLink>
                </li>
                <li>
                  <NavLink to="/reports/profit-and-loss">{t("plAccount")}</NavLink>
                </li>
              </PermCodeRequired>
              <PermCodeRequired code={codesFor.reports.ledger}>
                <li>
                  <NavLink to="/reports/ledger">{t("ledger")}</NavLink>
                </li>
              </PermCodeRequired>
              <PermCodeRequired code={codesFor.verifications.enabled}>
                <li>
                  <NavLink to="/reports/verifications">{t("verifications")}</NavLink>
                </li>
              </PermCodeRequired>
              <PermCodeRequired code={codesFor.customerInvoices.enabled}>
                <li>
                  <NavLink to="/reports/invoicing/customer-invoices">{t("customerInvoices")}</NavLink>
                </li>
              </PermCodeRequired>
              <PermCodeRequired code={codesFor.supplierInvoices.enabled}>
                <li>
                  <NavLink to="/reports/supplier-invoices">{t("supplierInvoices")}</NavLink>
                </li>
              </PermCodeRequired>
              <PermCodeRequired code={codesFor.outlays.enabled}>
                <li>
                  <NavLink to="/reports/outlays">{t("outlays")}</NavLink>
                </li>
              </PermCodeRequired>
              <PermCodeRequired code={codesFor.salaries.enabled}>
                <li>
                  <NavLink to="/reports/salaries">{t("salaries")}</NavLink>
                </li>
              </PermCodeRequired>
              <PermCodeRequired code={codesFor.timeReports.enabled}>
                {company.time_reporting_enabled && (
                  <li>
                    <NavLink to="/reports/time-report">{t("timeReport")}</NavLink>
                  </li>
                )}
              </PermCodeRequired>
              <PermCodeRequired code={codesFor.absences.enabled}>
                {company.absence_report_enabled && (
                  <li>
                    <NavLink to="/reports/employee-absence">{t("employeeAbsence")}</NavLink>
                  </li>
                )}
              </PermCodeRequired>
            </ul>
          </li>
          {archiveEnabled && (
            <li className={url.includes("/archive") ? "mm-active" : ""}>
              <NavLink to="/archive">
                <i className="fe-archive" />
                <span> {t("archive")}</span>
                <span className="menu-arrow" />
              </NavLink>
              <ul className="nav-second-level">
                {fileArchiveEnabled && (
                  <li>
                    <NavLink to="/archive/file-archive">{t("fileArchive")}</NavLink>
                  </li>
                )}
                {companyArchiveEnabled && (
                  <li>
                    <NavLink to="/archive/company-archive">{t("companyArchive")}</NavLink>
                  </li>
                )}
                {salaryArchiveEnabled && (
                  <li>
                    <NavLink to="/archive/salary-archive">
                      <span> {t("salaryArchive")}</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </li>
          )}
          <PermCodeRequired code={codesFor.inventoryRegister.enabled}>
            <li>
              <NavLink to="/inventory-register">
                <i className="fa fa-paste" />
                <span> {t("inventoryRegister")}</span>
              </NavLink>
            </li>
          </PermCodeRequired>
        </>
      )}
      {/* Consult Menu New */}
      {!menuRegular && (
        <PermCodeRequired code={codesFor.consultManage.enabled}>
          <li className="menu-title">{t("consultMenu")}</li>
          <li>
            <NavLink to="/consult/bank-matching">
              <i className="fa fa-university" />
              <span
                className={cx("badge badge-dark badge-pill float-end", {
                  "badge-primary": menuNumbers.consult.bank_matching > 0,
                })}
              >
                {menuNumbers.consult.bank_matching}
              </span>
              <span> {t("bankMatching")}</span>
            </NavLink>
          </li>
          {company.cashflow_enabled && (
            <li>
              <NavLink end to="/consult/cashflow">
                <i className="fe-activity" />
                <span> {t("cashflow")}</span>
              </NavLink>
            </li>
          )}
          <li>
            <NavLink to="/consult/balance">
              <i className="fe-book-open" />
              <span> {t("balanceSheet")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/consult/profit-and-loss">
              <i className="fe-file-text" />
              <span> {t("plAccount")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/consult/invoicing/customer-invoices">
              <i className="fe-upload-cloud" />
              <span> {t("customerInvoices")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/consult/supplier-invoices">
              <i className="fe-download-cloud" />
              <span
                className={cx("badge badge-dark badge-pill float-end", {
                  "badge-primary": menuNumbers.consult.draft_si > 0,
                })}
              >
                {menuNumbers.consult.draft_si}
              </span>
              <span> {t("supplierInvoices")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/consult/outlays">
              <i className="fa fa-receipt" />
              <span> {t("outlays")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/consult/vat">
              <i className="fe-flag" />
              <span> {t("vatReports")}</span>
            </NavLink>
          </li>
          {archiveEnabled && <li className="menu-title" />}
          {fileArchiveEnabled && (
            <li>
              <NavLink to="/consult/file-archive">
                <i className="fe-archive" />
                <span
                  className={cx("badge badge-dark-light badge-pill float-end", {
                    "badge-primary": menuNumbers.consult.file_archive > 0,
                  })}
                >
                  {menuNumbers.consult.file_archive}
                </span>
                <span> {t("fileArchive")}</span>
              </NavLink>
            </li>
          )}
          {companyArchiveEnabled && (
            <li>
              <NavLink to="/consult/company-archive">
                <i className="fe-archive" />
                <span> {t("companyArchive")}</span>
              </NavLink>
            </li>
          )}
          {salaryArchiveEnabled && (
            <li>
              <NavLink to="/consult/salary-archive">
                <i className="fe-archive" />
                <span> {t("salaryArchive")}</span>
              </NavLink>
            </li>
          )}
          <li className="menu-title" />
          <PermCodeRequired code={codesFor.reports.ledger}>
            <li>
              <NavLink to="/consult/ledger">
                <i className="fa fa-eye" />
                <span> {t("ledger")}</span>
              </NavLink>
            </li>
          </PermCodeRequired>
          <li>
            <NavLink to="/consult/verifications">
              <i className="fa fa-bars" />
              <span> {t("verifications")}</span>
            </NavLink>
          </li>
          <PermCodeRequired code={codesFor.inventoryRegister.enabled}>
            <li>
              <NavLink to="/consult/inventory-register">
                <i className="fa fa-paste" />
                <span> {t("inventoryRegister")}</span>
              </NavLink>
            </li>
          </PermCodeRequired>
          <li className="menu-title" />
          <li>
            <NavLink to="/consult/taxes">
              <i className="fa fa-search-dollar" />
              <span> {t("taxAuthorities")}</span>
            </NavLink>
          </li>
          <PermCodeRequired code={codesFor.absences.enabled}>
            {company.absence_report_enabled && (
              <li>
                <NavLink to="/consult/employee-absence">
                  <i className="fe-user-minus" />
                  <span> {t("employeeAbsence")}</span>
                </NavLink>
              </li>
            )}
          </PermCodeRequired>
          <PermCodeRequired code={codesFor.salaries.enabled}>
            <li>
              <NavLink to="/consult/salaries">
                <i className="fa fa-hand-holding-usd" />
                <span> {t("salaries")}</span>
              </NavLink>
            </li>
          </PermCodeRequired>
          <li className="menu-title" />
          <li>
            <NavLink to="/consult/silverback">
              <i className="fe-award" />
              <span> Silverback</span>
            </NavLink>
          </li>
        </PermCodeRequired>
      )}
      <MenuSwitcher menuRegular={menuRegular} onChange={setMenuRegular} />
    </LeftSidebar>
  );
}

export default DashboardMenu;
