import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Form, Formik, useFormikContext } from "formik";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import * as supportAPI from "api2/office-support";

function OfficeSupportBusinessProfileEssentialForm({ companyId, initialData, changeEditedForm, refreshProfileData }) {
  const { t } = useTranslation("company");
  const [showMore, setShowMore] = useState(true);
  const formikProps = {
    initialValues: initialData,
    onSubmit: async (values, { setErrors, resetForm }) => {
      await supportAPI.businessProfile
        .update(companyId, {
          description: values?.description || "",
        })
        .then((response) => {
          resetForm({ values });
          toast.success(t("msg:saved"), { autoClose: 2000 });
          refreshProfileData();
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          if (error.data.__all__) {
            setErrors({ __all__: error.data.__all__ });
          } else {
            setErrors({ ...error.data });
          }
        });
    },
  };
  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      changeEditedForm({ id: "business-profile-essential-form", dirty });
    }, [dirty]);
    return null;
  }

  return (
    <Formik {...formikProps}>
      {({ values, touched, isSubmitting, setFieldValue, dirty, resetForm }) => {
        return (
          <Form>
            <FormUpdateChecker />
            <Card>
              <Card.Header onClick={() => setShowMore((prevValue) => !prevValue)}>
                <Card.Title style={{ color: "#ADADAD", fontWeight: 400, fontSize: "12px" }}>
                  {t("header.businessEssential")}
                  <i
                    style={{ textAlign: "right", float: "right" }}
                    className={showMore ? "fas fa-chevron-up fa-1x" : "fas fa-chevron-down fa-1x"}
                  />
                </Card.Title>
              </Card.Header>
              {showMore && (
                <Card.Body className="pt-0">
                  <Row>
                    <Col>
                      <FormGroup.TextAreaInput
                        label={t("title.businessDescription")}
                        name="description"
                        as="textarea"
                        helpText={t("helpText.businessDescription")}
                        placeholder={t("placeholder.businessDescription")}
                        rows={30}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-end">
                      {dirty && (
                        <Button variant="link" size="lg" onClick={() => resetForm()} className="cancel-btn">
                          {t("common:actions.cancel")}
                        </Button>
                      )}
                      <SubmitButton isSubmitting={isSubmitting} disabled={!dirty} />
                    </Col>
                  </Row>
                </Card.Body>
              )}
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default OfficeSupportBusinessProfileEssentialForm;
