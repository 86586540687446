import React from "react";
import { Outlet, useOutletContext } from "react-router";
import { SelectionProvider } from "state/providers/SelectionProvider";
import { codesFor, HasPermCode } from "components/perms";
import ApproveNavTabs from "./ApproveNavTabs";
import ApproveBox from "./ApproveBox";

function ApproveLayout() {
  const company = useOutletContext();
  const canApproveSI = HasPermCode(codesFor.supplierInvoices.approve);
  const canApproveOutlays = HasPermCode(codesFor.outlays.approve);
  const canApproveSalaries = HasPermCode(codesFor.salaries.approve);
  const canApproveAbsences = HasPermCode(codesFor.absences.approve);
  const canApprove = canApproveSI || canApproveOutlays || canApproveSalaries || canApproveAbsences;

  return (
    <div className="tab-card">
      <ApproveNavTabs />
      <SelectionProvider>
        <Outlet context={company} />
        {canApprove && <ApproveBox />}
      </SelectionProvider>
    </div>
  );
}

export default ApproveLayout;
