import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { formatMoneyRounding } from "utils/money";
import { NumericFormat } from "react-number-format";
import cx from "classnames";
import { useOutletContext } from "react-router";

import "./ProfitLossTable.scss";
import { calculateExpectedBudget, calculateExtraCatYearly } from "./utils";

function BudgetInput({ defaultValue, updateAccountBudget, ...props }) {
  const { className } = props;
  const [value, setValue] = useState(defaultValue || 0);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  const onBlur = () => {
    updateAccountBudget(value);
  };
  return (
    <NumericFormat
      thousandSeparator={" "}
      decimalSeparator=","
      autoComplete="off"
      decimalScale={0}
      value={value}
      onBlur={onBlur}
      onValueChange={(values) => {
        setValue(values.floatValue || 0);
      }}
      className={cx(["money-input form-control", className])}
      fixedDecimalScale
    />
  );
}

const calculateCategoryBudget = (budget, item) => {
  if (!item) {
    return 0;
  }
  let accounts;
  if (item.accounts) {
    accounts = item.accounts;
  } else if (item.child_categories) {
    accounts = item.child_categories.reduce((all, child) => [...all, ...(child.accounts || [])], []) || [];
  } else {
    return 0;
  }
  if (!accounts) {
    return 0;
  }
  return accounts.reduce((total, a) => (budget[a.number]?.sum || 0) + total, 0);
};

function ReportTable({ report, rounding = "3" }) {
  const { budget, setBudget, setEditMode } = useOutletContext();
  const { t } = useTranslation("reports");

  const updateAccountBudget = (accountNumber, value) => {
    setEditMode(true);
    setBudget((_budget) => ({
      previous: budget.previous,
      current: { ...budget.current, [accountNumber]: { sum: value, months: [value] } },
    }));
  };

  const expectedBudgetCurrent = calculateExpectedBudget(budget.current);
  const expectedBudgetPrevious = calculateExpectedBudget(budget.previous);

  return (
    <Table bordered className="sticky-header budget-pl">
      <thead>
        <tr>
          <th>Resultaträkning (SEK)</th>
          <th>{t("common:dates.period")}</th>
          <th>{t("budget")}</th>
          <th>{t("periodPrevYear")}</th>
          <th>{t("budgetPrevYear")}</th>
        </tr>
      </thead>
      <tbody>
        {report.categories.map((category, ci) => (
          <React.Fragment key={`ci${ci}`}>
            {category.parent_category_name && (
              <tr className="parent-category--name">
                <th colSpan={5}>{category.parent_category_name}</th>
              </tr>
            )}
            {category.child_categories.map((child, cci) => (
              <React.Fragment key={`cci${cci}`}>
                {child.extra ? (
                  <tr className="extra-category">
                    <th>{child.name}</th>
                    <th className="disabled">{formatMoneyRounding(child.sum_period, rounding)}</th>
                    <th className="disabled">
                      {formatMoneyRounding(calculateExtraCatYearly(budget.current, child), rounding)}
                    </th>
                    <th className="disabled">{formatMoneyRounding(child.sum_period_prev_year, rounding)}</th>
                    <th className="disabled">
                      {formatMoneyRounding(calculateExtraCatYearly(budget.previous, child), rounding)}
                    </th>
                  </tr>
                ) : (
                  <>
                    <tr className="child-category--name">
                      <th colSpan={5}>{child.child_category_name}</th>
                    </tr>
                    {child.accounts &&
                      child.accounts.map((account, ai) => (
                        <tr key={`ai${ai}`} className="account">
                          <td>
                            {account.number} - {account.name}
                          </td>
                          <td className="disabled">{formatMoneyRounding(account.period, rounding)}</td>
                          <td className="td-input">
                            <BudgetInput
                              defaultValue={budget.current[account.number]?.sum || 0}
                              updateAccountBudget={(value) => updateAccountBudget(account.number, value)}
                            />
                          </td>
                          <td className="disabled">{formatMoneyRounding(account.period_prev_year, rounding)}</td>
                          <td className="disabled">
                            {formatMoneyRounding(budget.previous[account.number]?.sum, rounding)}
                          </td>
                        </tr>
                      ))}
                    <tr className="child-category--sum">
                      <th>Summa {child.child_category_name}</th>
                      <th className="disabled">{formatMoneyRounding(child.sum_period, rounding)}</th>
                      <th className="disabled">
                        {formatMoneyRounding(calculateCategoryBudget(budget.current, child), rounding)}
                      </th>
                      <th className="disabled">{formatMoneyRounding(child.sum_period_prev_year, rounding)}</th>
                      <th className="disabled">
                        {formatMoneyRounding(calculateCategoryBudget(budget.previous, child), rounding)}
                      </th>
                    </tr>
                  </>
                )}
              </React.Fragment>
            ))}
            {category.parent_category_name && (
              <tr className="parent-category--sum">
                <th>SUMMA {category.parent_category_name}</th>
                <th className="disabled">{formatMoneyRounding(category.sum_period, rounding)}</th>
                <th className="disabled">
                  {formatMoneyRounding(calculateCategoryBudget(budget.current, category), rounding)}
                </th>
                <th className="disabled">{formatMoneyRounding(category.sum_period_prev_year, rounding)}</th>
                <th className="disabled">
                  {formatMoneyRounding(calculateCategoryBudget(budget.previous, category), rounding)}
                </th>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th>{t("expectedResult")}</th>
          <th>{formatMoneyRounding(report.final_amounts.period, rounding)}</th>
          <th>{formatMoneyRounding(expectedBudgetCurrent, rounding)}</th>
          <th>{formatMoneyRounding(report.final_amounts.period_prev_year, rounding)}</th>
          <th>{formatMoneyRounding(expectedBudgetPrevious, rounding)}</th>
        </tr>
      </tfoot>
    </Table>
  );
}

export default ReportTable;
