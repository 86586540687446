import React from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router";

import TableProvider from "components/tables/btable/provider";
import { HistoryTable } from "components/tables";
import * as companyAPI from "api2/companies";

function HistoryListPage({ tableId = "history" }) {
  const { companyId } = useParams();

  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => companyAPI.history.list(companyId, { ...filters }, config)}
        >
          <HistoryTable companyId={companyId} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default HistoryListPage;
