import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { useLocation, useOutletContext } from "react-router";

import TableProvider from "components/tables/btable/provider";
import { CompanyArchiveTable } from "components/tables";
import * as documentsAPI from "api2/documents";
import { FiltersContext } from "state/providers/FiltersProviderRelatedToFY";

function CompanyArchiveListPage({ selectable = false, tableId = "companyArchive" }) {
  const company = useOutletContext();
  const { state } = useLocation();
  const {
    filters: { projection },
  } = useContext(FiltersContext);
  const initialFilters = {
    term: state?.term ?? "",
    category: state?.category ?? "",
    financial_year: state?.financial_year ?? null,
    projection,
    is_archived: state?.is_archived ?? false,
  };

  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) =>
            documentsAPI.companyDocuments.list(
              company.id,
              {
                ...filters,
                projection,
              },
              config
            )
          }
          initialFilters={initialFilters}
        >
          <CompanyArchiveTable companyId={company.id} selectable={selectable} projection={projection} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default CompanyArchiveListPage;
