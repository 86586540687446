import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";

import SalaryArchive from "components/forms/SalaryArchiveForm";
import useInitialAsync from "hooks/useInitialAsync";
import * as documentsAPI from "api2/documents";
import { RocketLoader } from "components/ui/loaders";

function SalaryArchiveEditPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const { id: documentId } = useParams();
  const { loading, item } = useInitialAsync(({ cancelToken }) => {
    return documentsAPI.salaryDocuments.detail(company.id, documentId, { cancelToken });
  }, {});

  const onUpdate = () => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }

  return <SalaryArchive companyId={company.id} archive={item} onSave={onUpdate} />;
}

export default SalaryArchiveEditPage;
