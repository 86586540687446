/* eslint-disable prettier/prettier */
import React from "react";
import { Button, ButtonGroup, Modal, Table } from "react-bootstrap";
import * as yup from "yup";
import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import { SubmitButton } from "components/ui/buttons";
import { formatMoney } from "utils/money";
import { TableGroup } from "components/formik";
import { calculateRecordTotal } from "utils/calc/ci";
import * as options from "api/options";
import { useIsOverflow } from "hooks/useIsOverflow";
import { DraggableModalDialog } from "../DraggableModalDialog";

const discountTypes = options.discountTypes.asList();

function DiscountModal({ currency, records, onSave, onCancel, forCreditInvoice }) {
  const { t } = useTranslation("ci");

  const ref = React.useRef();

  const { scrollBarWidth } = useIsOverflow(ref);

  const formikProps = {
    initialValues: {
      records: records.map((record) => ({
        ...record,
        discount: record.discount
          ? {
              value: record.discount.value,
              type: options.discountTypes.getOption(record.discount.type),
            }
          : {
              value: 0,
              type: discountTypes[0],
            },
      })),
      commonDiscount: {
        value: 0,
        type: discountTypes[0],
      },
    },
    validationSchema: yup.object().shape({
      records: yup.array().of(
        yup
          .object()
          .shape({
            discount: yup.object().shape({
              value: yup.number(),
              type: yup.object(),
            }),
          })
          .test("validDiscount", function (record) {
            if (record.discount.value !== 0 && record.discount.type) {
              if (
                record.discount.type.value === "percent" &&
                (record.discount.value < 0 || record.discount.value > 100)
              ) {
                return this.createError({
                  path: this.path,
                  message: t("errors.wrongDiscountPercentage"),
                });
              }
              if (record.discount.type.value === "amount") {
                const amount = record.product.unit_price * record.product.quantity;
                if ((amount > 0 && record.discount.value > amount) || (amount < 0 && record.discount.value < amount)) {
                  return this.createError({
                    path: this.path,
                    message: t("errors.wrongDiscountAmount"),
                  });
                }
              }
            }
            return true;
          })
      ),
    }),
    onSubmit: async (values) => {
      onSave(
        values.records.map((record) => {
          return {
            ...record,
            discount: record.discount.value ? { ...record.discount, type: record.discount.type.value } : null,
          };
        })
      );
    },
  };

  return (
    <Modal scrollable show onHide={onCancel} size="xl" dialogAs={DraggableModalDialog}>
      <Formik {...formikProps}>
        {({ values, setFieldValue, errors, isValid }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title className="m-0">{t("common:money.discount")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
              <div style={{ maxHeight: "60vh", overflowY: "auto" }} ref={ref}>
                <Table bordered className="mb-0">
                  <thead>
                    <tr>
                      <th>{t("common:description")}</th>
                      <th style={{ width: 200 }} className="text-end">
                        {t("discountExclVAT")}
                      </th>
                      <th style={{ width: 150 }} className="text-center">
                        {t("discountType")}
                      </th>
                      <th style={{ width: 230 }} className="text-end">
                        {t("discountTotal")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name="records">
                      <>
                        {values.records.map(
                          (record, index) =>
                            record.record_type === "product" && (
                              <React.Fragment key={record.key}>
                                <tr>
                                  <td className="disabled">
                                    <span>{record.product.name}</span>
                                  </td>
                                  <TableGroup.MoneyInput
                                    name={`records[${index}].discount.value`}
                                    disabled={forCreditInvoice}
                                  />
                                  <td className="text-center">
                                    <ButtonGroup size="sm">
                                      {discountTypes.map((discountType, i) => (
                                        <Button
                                          key={`${index}-${i}`}
                                          variant="switch"
                                          disabled={forCreditInvoice}
                                          active={record.discount.type.value === discountType.value}
                                          onClick={() => setFieldValue(`records[${index}].discount.type`, discountType)}
                                        >
                                          {discountType.value === "amount" ? currency : "%"}
                                        </Button>
                                      ))}
                                    </ButtonGroup>
                                  </td>
                                  <td className="text-end disabled">
                                    <span>
                                      {formatMoney(calculateRecordTotal(record))} {currency}
                                    </span>
                                  </td>
                                </tr>
                                <TableGroup.RowErrors
                                  errors={errors.records && errors.records[index] && [errors.records[index]]}
                                />
                              </React.Fragment>
                            )
                        )}
                        {!values.records.filter((item) => item.record_type === "product").length && (
                          <tr>
                            <td colSpan={8} className="p-2">
                              <span>{t("common:noResultsFound")}</span>
                            </td>
                          </tr>
                        )}
                      </>
                    </FieldArray>
                  </tbody>
                </Table>
              </div>

              <div style={{ paddingRight: scrollBarWidth }}>
                <Table>
                  <tbody>
                    <FieldArray name="commonDiscount">
                      <tr>
                        <td>
                          <span className="text-dark">{t("common:addDiscountForAllRows")}</span>
                        </td>
                        <TableGroup.MoneyInput name="commonDiscount.value" tdProps={{ style: { width: 200 } }} />
                        <td className="text-center" style={{ width: 150 }}>
                          <ButtonGroup size="sm">
                            {discountTypes.map((discountType, i) => (
                              <Button
                                key={`${discountType.value}-${i}`}
                                variant="switch"
                                active={values.commonDiscount.type.value === discountType.value}
                                onClick={() => setFieldValue(`commonDiscount.type`, discountType)}
                              >
                                {discountType.value === discountTypes[0].value ? currency : "%"}
                              </Button>
                            ))}
                          </ButtonGroup>
                        </td>
                        <td className="text-end disabled" style={{ width: 230 }}>
                          <Button
                            variant="secondary"
                            disabled={forCreditInvoice}
                            size="sm"
                            onClick={() => {
                              values.records.forEach((record, index) => {
                                if (record.record_type === "product") {
                                  setFieldValue(`records[${index}].discount.value`, values.commonDiscount.value);
                                  setFieldValue(`records[${index}].discount.type`, values.commonDiscount.type);
                                }
                              });

                              setFieldValue(`commonDiscount.value`, 0);
                            }}
                          >
                            {t("common:actions.applyAllRows")}
                          </Button>
                        </td>
                      </tr>
                    </FieldArray>
                  </tbody>
                </Table>
              </div>
            </Modal.Body>
            <Modal.Footer className="space">
              <Button type="button" variant="secondary" onClick={onCancel}>
                {t("common:actions.cancel")}
              </Button>
              <SubmitButton disabled={!isValid || forCreditInvoice} />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default DiscountModal;
