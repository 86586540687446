import React from "react";
import { Alert, Card } from "react-bootstrap";
import * as outlayAPI from "api2/outlays";
import { Trans } from "react-i18next";
import { Link, useOutletContext } from "react-router";
import { allowedFileExtensionsFor } from "utils/file";
import BaseUpload from "./BaseUpload";

function UploadOutlayPage() {
  const company = useOutletContext();
  const onFileUpload = (files) => {
    return outlayAPI.receiptCreateFromFiles(company.id, files);
  };

  return (
    <Card className="upload-page">
      <Card.Body>
        <Alert variant="info">
          <Trans i18nKey="others:uploadOutlayInfo">
            <span>For each uploaded file there will be a Outlay/Receipt in draft.</span>
            <Link to="/reports/outlays">Link</Link>
          </Trans>
        </Alert>
        <BaseUpload onFileUpload={onFileUpload} fileTypes={allowedFileExtensionsFor.receipt} />
      </Card.Body>
    </Card>
  );
}

export default UploadOutlayPage;
