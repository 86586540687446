import { NavLink, useResolvedPath } from "react-router";
import React, { useContext } from "react";
import LeftSidebar from "components/ui/sidebar";
import { useTranslation } from "react-i18next";
import { CodeRequiredInList } from "components/perms";
import { codesForAgency } from "components/perms/PermCodes";
import { AgencySettingStateContext } from "state/providers/AgencySettingProvider";

function Menu() {
  const url = useResolvedPath("").pathname;
  const { t } = useTranslation("navigation");
  const { info } = useContext(AgencySettingStateContext);
  return (
    <LeftSidebar>
      <li className="menu-title">{t("agencyConfiguration")}</li>
      <li>
        <NavLink to={url} end replace>
          <i className="fe-box" />
          <span> {t("general")}</span>
        </NavLink>
        <CodeRequiredInList code={codesForAgency.userManage} codeList={info.perm_codes}>
          <NavLink to={`${url}/users`} replace>
            <i className="fe-users" />
            <span> {t("users")}</span>
          </NavLink>
        </CodeRequiredInList>
        <CodeRequiredInList code={codesForAgency.editConsultPerms} codeList={info.perm_codes}>
          <NavLink to={`${url}/consults`} replace>
            <i className="fe-user-check" />
            <span> {t("consults")}</span>
          </NavLink>
        </CodeRequiredInList>
        <CodeRequiredInList code={codesForAgency.scheduleTemplateManage} codeList={info.perm_codes}>
          <NavLink to={`${url}/schedule-templates`} replace>
            <i className="fe-calendar" />
            <span> {t("scheduleTemplates")}</span>
          </NavLink>
        </CodeRequiredInList>
        <CodeRequiredInList code={codesForAgency.officeSupervisior} codeList={info.perm_codes}>
          <NavLink to={`${url}/offices`} replace>
            <i className="far fa-building" />
            <span> {t("offices")}</span>
          </NavLink>
          <NavLink to={`${url}/admin`} replace>
            <i className="fe-lock" />
            <span> {t("admin")}</span>
          </NavLink>
        </CodeRequiredInList>
        <NavLink to={`${url}/statistics`}>
          <i className="fe-bar-chart" />
          <span> {t("statistics")}</span>
        </NavLink>
      </li>
    </LeftSidebar>
  );
}

export default Menu;
