import React from "react";
import { Alert, Card } from "react-bootstrap";
import { Trans } from "react-i18next";
import { Link, useOutletContext } from "react-router";
import * as documentsAPI from "api2/documents";
import { allowedFileExtensionsFor } from "utils/file";
import BaseUpload from "./BaseUpload";

function UploadFileArchivePage() {
  const company = useOutletContext();
  const onFileUpload = (files) => {
    return documentsAPI.archiveDocuments.createFromFiles(company.id, files);
  };

  return (
    <Card className="upload-page">
      <Card.Body>
        <Alert variant="info">
          <Trans i18nKey="others:uploadArchiveInfo">
            <span>The documents will be uploaded to your file archive.</span>
            <Link to="/archive/file-archive">Link</Link>
          </Trans>
        </Alert>
        <BaseUpload onFileUpload={onFileUpload} fileTypes={allowedFileExtensionsFor.file_archive} />
      </Card.Body>
    </Card>
  );
}

export default UploadFileArchivePage;
