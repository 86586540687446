import React from "react";
import { Card } from "react-bootstrap";
import { useOutletContext, useSearchParams } from "react-router";

import { SelectionProvider } from "state/providers/SelectionProvider";
import TableProvider from "components/tables/btable/provider";
import { RotRutTable } from "components/tables";
import * as customerInvoiceApi from "api2/customer-invoices";

function RotRutListPage() {
  const [searchParams] = useSearchParams();
  const company = useOutletContext();
  const initialFilters = {
    is_rot_rut_applied: searchParams.get("is_rot_rut_applied") || "",
    ordering: "-invoice_no",
    advanced_search: false,
  };

  return (
    <Card>
      <Card.Body>
        <SelectionProvider>
          <TableProvider
            tableId="customerInvoicesRotRut"
            dataSource={(filters, config) => customerInvoiceApi.rotRut.list(company.id, filters, config)}
            initialFilters={initialFilters}
          >
            <RotRutTable selectable companyId={company.id} />
          </TableProvider>
        </SelectionProvider>
      </Card.Body>
    </Card>
  );
}

export default RotRutListPage;
