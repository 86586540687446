import React, { useCallback } from "react";
import * as userAPI from "api2/users";
import { Loader } from "components/ui/loaders";
import useTable from "components/tables/btable/useTable";
import { useParams } from "react-router";
import useAsync from "hooks/useAsync";
import UserGlobalForm from "./UserGlobalForm";
import UserForm from "./UserForm";

function UserEditWidget({ id, agencyName }) {
  const { agencyId } = useParams();
  const dataSource = useCallback(
    (cancelToken) => {
      if (agencyId) {
        return userAPI.agencies.userDetails(agencyId, id, { cancelToken });
      }
      return userAPI.details(id, { cancelToken });
    },
    [agencyId, id]
  );
  const [{ data: item, loading }] = useAsync(dataSource, []);
  const {
    dataActions: { reload, updateRow },
  } = useTable();
  if (loading) {
    return <Loader />;
  }

  const successCallback = (newData) => {
    updateRow(newData);
  };

  const deleteCallback = () => {
    reload();
  };

  const FormComponent = agencyId ? UserForm : UserGlobalForm;

  return (
    <FormComponent
      agencyId={agencyId}
      formName="edit-form"
      user={{ ...item, name: `${item.first_name} ${item.last_name}` }}
      successCallback={successCallback}
      deleteCallback={deleteCallback}
      agencyName={agencyName}
    />
  );
}

export default UserEditWidget;
