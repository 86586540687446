import React, { useEffect, useMemo } from "react";
import { Link, useResolvedPath } from "react-router";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import _ from "lodash";

import BTable from "components/tables/btable";
import { formatMoney } from "utils/money";
import Filters from "./OutlayFilters";
import Actions from "./OutlayActions";
import useTable from "../btable/useTable";

function OutlayTable({ companyId, forStatus, allowedModes, selectable = false }) {
  const { t } = useTranslation("outlay");
  const url = useResolvedPath("").pathname;

  const {
    dataActions: { reload },
  } = useTable();
  useEffect(() => {
    // in case SI deleted from Verification modal
    document.body.addEventListener("verification/deleted", reload);
    return () => document.body.removeEventListener("verification/deleted", reload);
  }, [reload]);

  const headers = useMemo(() => {
    const _headers = [
      {
        field: "type",
        label: t("common:type"),
        canSort: false,
        render: (values) => {
          if (values.status !== 0 || values.sub_status === "investigate") {
            return (
              <Link to={`${url}/${values.type}/${values.id}/preview`} className="table-link">
                {t(`common:${values.type}`)} <i className="fa fa-search" />
              </Link>
            );
          }
          return (
            <Link to={`${url}/${values.type}/${values.id}/change`} className="table-link">
              {t(`common:${values.type}`)} <i className="fas fa-pen" />
            </Link>
          );
        },
      },
      {
        field: "created_by",
        sortField: "created_by__last_name",
        label: t("common:employee"),
        render: (values) => values.created_by,
      },
      {
        field: "paid_by",
        label: t("paidWith"),
        render: (values) => t(`paidWith${values.paid_by}`),
      },
      {
        field: "account",
        label: t("common:category"),
      },
      {
        field: "amount_sek",
        label: t("common:money.amount"),
        className: "text-end",
        render: (values) => formatMoney(values.amount_sek),
      },
      {
        field: "balance",
        label: t("common:money.toPay"),
        className: "text-end",
        render: (values) => formatMoney(values.balance),
      },
      {
        field: "receipt_date",
        label: t("common:dates.outlayDate"),
      },
    ];
    if (forStatus === "paid" || forStatus === "draft") {
      _headers.splice(4, 1); // no balance
    }
    if (_.includes(["paid", "sent_bank"], forStatus)) {
      _headers.push({
        field: "payment_date",
        label: t("common:dates.paymentDate"),
      });
    }
    return _headers;
  }, [forStatus, url, t]);

  const getRowProps = (row) => {
    return {
      className: cx(row.sub_status || "draft", {
        "has-message": !!row.comments.length,
      }),
    };
  };

  const renderTotals = (responseHeaders) => (
    <>
      {forStatus !== "unpaid" && (
        <div>
          <strong>{t("common:money.amount")}:</strong>
          {formatMoney(responseHeaders["x-pagination-amount"])}
        </div>
      )}
      {forStatus !== "paid" && forStatus !== "draft" && (
        <div>
          <strong>{t("common:money.toPay")}:</strong>
          {formatMoney(responseHeaders["x-pagination-balance"])}
        </div>
      )}
    </>
  );

  const renderSelect = (row) => {
    if (row.sub_status === "" || row.sub_status === "disputed" || row.sub_status === "investigate") {
      return "";
    }
    if (row.sub_status === "unpaid") {
      if (row.in_signing) {
        return "SIGN";
      }
    }
    return undefined;
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      renderSelect={renderSelect}
      FiltersComponent={<Filters companyId={companyId} forStatus={forStatus} allowedModes={allowedModes} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      renderTotals={renderTotals}
      getRowProps={getRowProps}
    />
  );
}

export default OutlayTable;
