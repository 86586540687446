import { useTranslation } from "react-i18next";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import React, { useState } from "react";
import { NavLink } from "react-router";
import { toast } from "react-toastify";
import { Scrollbars } from "react-custom-scrollbars";
import cx from "classnames";
import _ from "lodash";

import * as companyAPI from "api2/companies";
import useAsync from "hooks/useAsync";
import { SubmitButton } from "components/ui/buttons";
import { RocketLoader } from "components/ui/loaders";
import { DraggableModalDialog } from "../DraggableModalDialog";

function SelectCompanyToOnboardModal({ handleClose, handleSubmit }) {
  const { t } = useTranslation("company");
  const [checkedCompany, setCheckedCompany] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const [searchText, setSearchText] = useState("");
  const dataSource = companyAPI.onboardings.selectCompanyList;
  const [{ data: companies, loading: loadingData }] = useAsync(dataSource, []);

  const isCompanyChecked = (companyId) => {
    return companyId === checkedCompany;
  };

  function filteredCompanies() {
    return companies.filter((company) => company.name.toLowerCase().includes(searchText.toLowerCase()));
  }

  const onSubmit = () => {
    if (!checkedCompany) {
      toast.error(t("msg:selectCompany"));
      return;
    }
    setSubmitting(true);
    companyAPI.onboardings
      .create(checkedCompany)
      .then((response) => {
        toast.success(t("common:created"));
        setSubmitting(false);
        handleSubmit({ name: response.data.name, id: response.data.id });
      })
      .catch((error) => {
        toast.error(error.data.__all__);
        setSubmitting(false);
      });
  };

  const search = _.debounce((event) => {
    setSearchText(event.target.value.trim());
    setCheckedCompany(null);
  }, 500);

  return (
    <Modal
      show
      size="lg"
      className="select-company-modal"
      onHide={handleClose}
      animation={false}
      dialogAs={DraggableModalDialog}
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title as="h4">{t("selectCompanyFromOfficeSupport")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loadingData ? (
          <RocketLoader />
        ) : (
          <div>
            <div className="search-input-wrapper">
              <input
                placeholder={t("common:search")}
                name="search"
                type="text"
                className="form-control"
                autoComplete="off"
                onChange={search}
                autoFocus
              />
            </div>
            {filteredCompanies().length > 0 ? (
              <Scrollbars className="body" style={{ height: "450px" }}>
                <Table>
                  <thead>
                    <tr>
                      <th>{t("common:name")}</th>
                      <th className="org-number">{t("common:orgNo")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredCompanies().map((company) => (
                      <tr
                        key={company.id}
                        className={cx({ checked: isCompanyChecked(company.id) })}
                        onClick={() => {
                          setCheckedCompany(company.id);
                        }}
                      >
                        <td className="company-name">
                          {isCompanyChecked(company.id) && <i className="icon-checked fas fa-check" />}
                          <span className={cx("title ", { checked: isCompanyChecked(company.id) })}>
                            {company.name}
                          </span>
                        </td>
                        <td className="org-number">{company.org_no}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Scrollbars>
            ) : (
              <Row style={{ height: "450px" }}>
                <Col style={{ marginTop: "100px" }}>
                  <p>{t("company:onboarding.missingCompanyText1")}</p>
                  <p>{t("company:onboarding.missingCompanyText2")}</p>
                  <p>
                    <NavLink to="/office-support-companies" className="office-support-link" target="_blank">
                      <span> {t("navigation:officeSupport")}</span>
                      <i className="fe-external-link" />
                    </NavLink>
                    &nbsp;
                    {t("company:onboarding.missingCompanyText3")}
                  </p>
                </Col>
              </Row>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="link" onClick={handleClose}>
          {t("common:actions.cancel")}
        </Button>
        <SubmitButton
          title="others:actions.startOnboarding"
          icon=""
          onClick={onSubmit}
          isSubmitting={isSubmitting}
          disabled={!checkedCompany}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default SelectCompanyToOnboardModal;
