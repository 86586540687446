import React, { useCallback, useState } from "react";
import _ from "lodash";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { endOfMonth, startOfMonth } from "date-fns";
import { handleActionErrors } from "api/errors";
import { bankMatching as matchingAPI } from "api2/integrations";
import useInitialAsync from "hooks/useInitialAsync";
import { formatDate, formatMonth } from "utils/date";
import TableLoader from "components/tables/btable/TableLoader";
import { PreviewInput } from "components/formik/PreviewInput";
import { SubmitButton } from "components/ui/buttons";
import { formatMoney } from "utils/money";
import { confirmExecute } from "components/modals/ConfirmModal";
import { StaffPermRequired } from "components/perms";
import { useCompanyState } from "hooks/useCompany";
import { useOutletContext, useParams } from "react-router";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import BflowTransTable from "./BflowTransTable";
import BankTransTable from "./BankTransTable";
import Filters from "./BankMatchingFilters";

function _sumSelected(data) {
  const sum = {
    bflow: 0,
    bank: 0,
    diff: 0,
  };
  if (!Object.keys(data).length) {
    return sum;
  }
  Object.values(data.bflow).forEach((section) => {
    section.trans.forEach((trans) => {
      sum.bflow += trans.checked && !trans.matched ? trans.amount : 0;
    });
  });
  data.bank.forEach((trans) => {
    sum.bank += trans.checked && !trans.matched ? trans.amount : 0;
  });
  sum.diff = Math.abs(sum.bflow - sum.bank);
  return sum;
}

function ResetBalanceButton({ companyId, account, onFinish }) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);

  const resetBalance = async () => {
    const confirmed = await confirmExecute(t("others:confirm.resetBalances", { account }));
    if (confirmed) {
      setLoading(true);
      matchingAPI
        .resetBalances(companyId, { account })
        .then((response) => {
          toast.success("Done");
        })
        .catch((err) => {
          toast.error(err.data);
        })
        .finally(() => {
          setLoading(false);
          onFinish();
        });
    }
  };

  return (
    <Button variant="warning" onClick={resetBalance} disabled={loading}>
      {loading ? (
        <>
          <i className="fas fa-spin fa-spinner" /> {t("common:loading")}
        </>
      ) : (
        <>
          <i className="fas fa-recycle" /> {t("others:actions.resetBalance")}
        </>
      )}
    </Button>
  );
}

function BankMatchingPage() {
  const company = useOutletContext();
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const { t } = useTranslation(["others", "msg", "common"]);
  const { account } = useParams();
  const [counter, setCounter] = useState(0);
  const [autoMatchLoading, setAutoMatchLoading] = useState(false);
  const {
    accounts: { asOptions: accountOptions },
  } = useCompanyState();
  const matchingAccounts = accountOptions.filter((acc) =>
    [company.is_sole_type ? "" : 1630, 1930, 1931, 1932, 1933, 1934, 1935, 1936, 1937, 1938, 1939, 1940, 1950].includes(
      acc.number
    )
  );
  const [filters, setFilters] = useState({
    account: account ? parseInt(account, 10) : company.booking_bank_account || 1930,
    month: new Date(),
  });
  const {
    loading,
    set,
    item: data,
  } = useInitialAsync(
    () =>
      matchingAPI
        .detail(company.id, {
          account: filters.account,
          date_month: filters.month ? formatDate(filters.month) : formatDate(new Date()),
          unmatched_only: filters.mode === "unmatched",
        })
        .then((res) => {
          // group by date with date summary
          const groupedByDate = _.groupBy(res.data.bflow, "booking_date");
          const groupedWithSummary = {};
          Object.keys(groupedByDate).forEach((key) => {
            const dayOutput = {
              date: key,
              trans: groupedByDate[key],
              debit: 0,
              credit: 0,
            };
            for (let i = 0; i < groupedByDate[key].length; i++) {
              dayOutput.debit += groupedByDate[key][i].debit;
              dayOutput.credit += groupedByDate[key][i].credit;
            }
            groupedWithSummary[key] = dayOutput;
          });
          res.data.bflow = groupedWithSummary;
          return res;
        }),
    { bflow: {}, bank: [], account_balance: 0, bank_balance: 0, highlighted_months: [], unmatched_count: {} },
    [filters, counter]
  );

  const selectedSum = _sumSelected(data);
  const manualMatching = async () => {
    let answer = true;
    if (selectedSum.diff !== 0) {
      answer = await confirmExecute(
        t("others:confirm.manualMatching", {
          diff: formatMoney(selectedSum.diff),
        })
      );
    }
    if (answer) {
      const bflow = [];
      const bank = [];
      Object.values(data.bflow).forEach((section) => {
        section.trans.forEach((trans) => {
          if (trans.checked && !trans.matched) {
            bflow.push(trans.id);
          }
        });
      });
      data.bank.forEach((trans) => {
        if (trans.checked && !trans.matched) {
          bank.push(trans.id);
        }
      });
      if (!bflow.length || !bank.length) {
        toast.error(t("others:matchAtLeastOneTrans"));
        return;
      }
      matchingAPI
        .manualMatch(company.id, {
          bflow,
          bank,
        })
        .then(() => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          setCounter(counter + 1);
          reloadMenuNumbers();
        });
    }
  };

  const matchOnVerCreate = async (bflowIds, bankId) => {
    return matchingAPI
      .manualMatch(company.id, {
        bflow: bflowIds,
        bank: [bankId],
      })
      .then(() => {
        setCounter(counter + 1);
      });
  };

  const automaticMatching = async () => {
    setAutoMatchLoading(true);
    await matchingAPI
      .autoMatch(company.id, {
        ...filters,
        date_month: formatDate(filters.month),
      })
      .then(() => {
        toast.success(t("msg:saved"), { autoClose: 2000 });
        setCounter(counter + 1);
        reloadMenuNumbers();
      })
      .catch((error) => {
        toast.error("Sorry... matching error");
      })
      .finally(() => {
        setAutoMatchLoading(false);
      });
  };

  const deleteMonthMatching = async () => {
    const answer = await confirmExecute(
      t("others:confirm.removeMatching", {
        month: formatMonth(filters.month),
      })
    );
    if (answer) {
      matchingAPI
        .resetMatching(company.id, {
          account: filters.account,
          date_month: formatDate(filters.month),
        })
        .then(() => {
          toast.success(t("msg:deleted"), { autoClose: 2000 });
          setCounter(counter + 1);
          reloadMenuNumbers();
        });
    }
  };

  const deleteMonthTransactions = async () => {
    const answer = await confirmExecute();
    if (answer) {
      const dateFrom = startOfMonth(filters.month);
      const dateTo = endOfMonth(filters.month);
      matchingAPI
        .bankTransactionDeleteMany(company.id, filters.account, formatDate(dateFrom), formatDate(dateTo))
        .then(() => {
          toast.success(t("msg:deleted"), { autoClose: 2000 });
          setCounter(counter + 1);
          reloadMenuNumbers();
        });
    }
  };

  const fetchFromBank = useCallback(
    (accountNumber, dateFrom, dateTo) => {
      return matchingAPI
        .fetchBank(company.id, accountNumber, dateFrom, dateTo)
        .then((response) => {
          const msg = response.data.map((acc) => `${acc.account} (${acc.count})`);
          toast.success(`${t("msg:generated")}:\n${msg.join(", ")}\n`, {
            autoClose: 5000,
          });
          setCounter((c) => c + 1);
          reloadMenuNumbers();
        })
        .catch((response) => {
          if (response.data.__all__) {
            toast.error(response.data.__all__);
          }
          return response;
        });
    },
    [company.id, reloadMenuNumbers, t]
  );

  const fetchFromSkv = useCallback(
    (dateFrom, dateTo) => {
      return matchingAPI
        .fetchTax(company.id, dateFrom, dateTo)
        .then((response) => {
          if (response.data.error) {
            toast.error(response.data.error);
          } else {
            toast.success(`${t("msg:generated")} - ${response.data.imported > 0 ? response.data.imported : 0}`, {
              autoClose: 4000,
            });
            setCounter((c) => c + 1);
            reloadMenuNumbers();
          }
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    },
    [company.id, reloadMenuNumbers, t]
  );

  return (
    <Card id="bank-match">
      <Card.Body>
        <Filters
          accountOptions={matchingAccounts}
          accountsUnmatchedCount={data.unmatched_count}
          fetchFromBank={fetchFromBank}
          fetchFromSkv={fetchFromSkv}
          filters={filters}
          setFilters={setFilters}
          deleteMonthMatching={deleteMonthMatching}
          automaticMatching={automaticMatching}
          highlightedMonths={data.highlighted_months}
        />
      </Card.Body>
      <Card.Body>
        {(loading || autoMatchLoading) && <TableLoader />}
        {!loading && !autoMatchLoading && (
          <Row>
            <Col>
              <BflowTransTable
                companyId={company.id}
                set={set}
                data={data}
                reloadTable={() => setCounter((_counter) => _counter + 1)}
              />
            </Col>
            <Col>
              <BankTransTable
                set={set}
                data={data}
                companyId={company.id}
                reloadTable={() => setCounter((_counter) => _counter + 1)}
                matchOnVerCreate={matchOnVerCreate}
                deleteMonthTransactions={deleteMonthTransactions}
              />
            </Col>
          </Row>
        )}
      </Card.Body>
      <Card.Footer className="matching-summary">
        <Row noGutters>
          <Col xl={10}>
            <Row>
              <Col xl={2}>
                <PreviewInput label={t("others:bflowSelected")} value={formatMoney(selectedSum.bflow)} />
              </Col>
              <Col xl={2}>
                <PreviewInput label={t("common:money.deviation")} value={formatMoney(selectedSum.diff)} />
              </Col>
              <Col xl={2}>
                <PreviewInput label={t("others:balanceAccBflow")} value={formatMoney(data.account_balance, 0, 0)} />
              </Col>
              <Col xl={2}>
                <PreviewInput
                  label={t("common:money.deviation")}
                  value={formatMoney(data.account_balance - data.bank_balance)}
                />
              </Col>
              <Col xl={2}>
                <PreviewInput label={t("others:lastScanDate")} value={0} />
              </Col>
            </Row>
            <Row>
              <Col xl={4}>
                <PreviewInput label={t("others:bankSelected")} value={formatMoney(selectedSum.bank)} />
              </Col>
              <Col xl={4}>
                <PreviewInput label={t("others:balanceAccBank")} value={formatMoney(data.bank_balance, 0, 0)} />
              </Col>
            </Row>
          </Col>
          <Col xl={2} className="manual-match">
            <SubmitButton onClick={manualMatching} title="others:actions.manualMatch" className="btn-block" />
          </Col>
        </Row>
      </Card.Footer>
      <StaffPermRequired>
        <Card.Footer>
          <ResetBalanceButton
            companyId={company.id}
            account={filters.account}
            onFinish={() => setCounter((c) => c + 1)}
          />
        </Card.Footer>
      </StaffPermRequired>
    </Card>
  );
}

export default BankMatchingPage;
