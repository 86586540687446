import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Row, Col, Button, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Formik, Form, FieldArray } from "formik";
import * as yup from "yup";
import { FormGroup } from "components/formik";
import cx from "classnames";
import { ApprovalRequirementBadge } from "components/ui/badges/ApprovalRequirementBadge";
import CompanyEmployeePicker from "components/formik/CompanyEmployeePicker";
import { SubmitButton } from "components/ui/buttons";
import EmployeeInitialsBadge from "components/ui/badges/EmployeeInitialsBadge";
import RemoveButton from "components/ui/buttons/RemoveButton";
import { MinApproversInput } from "components/formik/FormGroup";
import "./ApprovalFlowForm.scss";
import { toast } from "react-toastify";

function ApprovalFlowForm({
  initialData,
  titleForm,
  employeesByUserId,
  onSave,
  onCancel,
  onDelete,
  companyId,
  approvalFlowsDataLen,
}) {
  const { t } = useTranslation("company");
  const emptyStep = {
    approvers: [],
    approvers_ids: [],
    min_approvers_counter_condition: 1,
    approval_option: "all",
  };

  const initialSteps = initialData?.steps && initialData.steps.length > 0 ? initialData.steps : [emptyStep];

  const [activeStep, setActiveStep] = useState(initialSteps.length - 1);

  const transformStep = (step) => {
    const onlyUserIds = step.approvers?.map((user) => user.user_id) || [];
    switch (step.approval_option) {
      case "other":
        return {
          approvers_ids: onlyUserIds,
          approval_option: step.approval_option,
          min_approvers_counter_condition: step.min_approvers_counter_condition,
        };
      case "all":
        return {
          approvers_ids: onlyUserIds,
          approval_option: step.approval_option,
          min_approvers_counter_condition: onlyUserIds.length,
        };
      case "any":
        return {
          approvers_ids: onlyUserIds,
          approval_option: step.approval_option,
          min_approvers_counter_condition: 1,
        };
      default:
        return {
          approvers_ids: onlyUserIds,
          approval_option: step.approval_option,
          min_approvers_counter_condition: 1,
        };
    }
  };

  const formikProps = {
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      ...initialData,
      steps: initialSteps,
    },

    validationSchema: yup.object().shape({
      title: yup.string().required(),
      steps: yup
        .array()
        .of(
          yup.object().shape({
            approvers: yup
              .array()
              .min(1, t("approvalFlows.errors_minApprovers"))
              .max(10, t("approvalFlows.errors_maxApprovers"))
              .required(t("approvalFlows.errors_minApprovers")),
            approvers_ids: yup
              .array()
              .of(yup.number())
              .min(1, t("approvalFlows.errors_minApprovers"))
              .max(10, t("approvalFlows.errors_maxApprovers")),
            approval_option: yup.string().oneOf(["all", "any", "other"]).required(),
            min_approvers_counter_condition: yup.number().when("approval_option", {
              is: "other",
              then: (schema) =>
                schema
                  .min(1, t("approvalFlows.errors_minApproversMustBeAtLeastOne"))
                  .max(10, t("approvalFlows.errors_minApproversMax10"))
                  .test("max-min-approvers", t("approvalFlows.errors_minApproversExceed"), function (value) {
                    const approversCount = (this.parent.approvers_ids || []).length;
                    return value <= approversCount;
                  })
                  .required(),
              otherwise: (schema) => schema.notRequired().nullable(true),
            }),
          })
        )
        .min(1, t("approvalFlows.errors_minSteps"))
        .max(3, t("approvalFlows.errors_maxSteps"))
        .required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      const transformedSteps = values.steps.map(transformStep);
      const payload = {
        id: values?.id,
        title: values.title || "",
        is_default: values.is_default || false,
        steps: transformedSteps,
        for_object_type: "si",
      };

      try {
        await onSave(payload, setErrors);
      } catch (error) {
        if (error.data && error.data.__all__) {
          toast.error(error.data.__all__);
        }
      }
    },
  };

  const getExcludedUserIds = (steps) => {
    return steps.flatMap((step) => step.approvers_ids || []);
  };

  return (
    <div className="approval-flow-wrapper">
      <div className="approval-flow-title">{titleForm}</div>
      {initialData.id && (
        <Alert variant="warning">
          <i className="fe-info" /> {t("approvalFlows.errors_editingWarning")}
        </Alert>
      )}
      <Formik {...formikProps}>
        {({ values, setFieldValue, setErrors, isSubmitting, errors, validateForm, setTouched }) => {
          const excludedUserIds = getExcludedUserIds(values.steps, activeStep);

          return (
            <Form>
              <Card className="approval-flow-card">
                <Card.Body className="approval-flow-card-body">
                  <FieldArray name="steps">
                    {({ push, remove }) => (
                      <>
                        <Row>
                          {values.steps.map((step, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Col className="approval-flow-step-col">
                                  <div
                                    className={cx("approval-flow-step-wrapper", {
                                      "active-step": activeStep === index,
                                      "completed-step": index < activeStep,
                                    })}
                                    onClick={() => setActiveStep(index)}
                                    role="button"
                                    tabIndex={0}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="approval-flow-step-header">
                                      <div className="approval-flow-step-title">
                                        {t("approvalFlows.labels_step")} {index + 1}
                                        {values.steps.length !== 1 && (
                                          <Button
                                            variant="link"
                                            className="p-0 text-danger float-end"
                                            disabled={values.steps.length === 1}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              remove(index);
                                              setActiveStep((prev) => Math.max(0, prev - 1));
                                            }}
                                          >
                                            <i className="fe-trash-2" />
                                          </Button>
                                        )}
                                      </div>
                                      <div
                                        className={cx("approval-flow-step-bar", {
                                          "active-step": activeStep === index,
                                          "completed-step": index < activeStep,
                                        })}
                                      >
                                        {activeStep === index && <div className="approval-flow-step-marker" />}
                                      </div>
                                    </div>
                                    <div
                                      className={cx("approval-flow-step-body", {
                                        "active-step-body": activeStep === index,
                                      })}
                                    >
                                      <div className="approval-flow-approvers-container">
                                        <ApprovalRequirementBadge
                                          minValue={step.min_approvers_counter_condition}
                                          stepApproverLen={step.approvers_ids.length}
                                          mode="form"
                                        />
                                        {step.approvers_ids.length === 0 ? (
                                          <div className="no-users-assigned ms-1">
                                            {t("approvalFlows.errors_noAssignees")}
                                          </div>
                                        ) : (
                                          step.approvers_ids.map((userId, userIndex) => (
                                            <EmployeeInitialsBadge
                                              mode="form"
                                              key={userIndex}
                                              approverId={userId}
                                              employeesByUserId={employeesByUserId}
                                              onRemove={() => {
                                                const updatedApproversIds = values.steps[index].approvers_ids.filter(
                                                  (id) => id !== userId
                                                );
                                                setFieldValue(`steps.${index}.approvers_ids`, updatedApproversIds);

                                                const updatedApprovers = values.steps[index].approvers.filter(
                                                  (u) => u.user_id !== userId
                                                );
                                                setFieldValue(`steps.${index}.approvers`, updatedApprovers);

                                                const newMinApprovers = Math.min(
                                                  values.steps[index].min_approvers_counter_condition,
                                                  updatedApproversIds.length
                                                );
                                                setFieldValue(
                                                  `steps.${index}.min_approvers_counter_condition`,
                                                  newMinApprovers
                                                );
                                              }}
                                              className="ms-1"
                                            />
                                          ))
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                                {index < values.steps.length - 1 && (
                                  <div className="approval-flow-step-arrow">
                                    <span className="arrow">></span>
                                  </div>
                                )}
                              </React.Fragment>
                            );
                          })}
                          {values.steps.length < 3 && (
                            <div className="approval-flow-add-step-container">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>{t("approvalFlows.helpText_addStep")}</Tooltip>}
                              >
                                <span
                                  className={cx("approval-flow-add-step", {
                                    disabled: !!Object.keys(errors?.steps || {}).length,
                                  })}
                                  onClick={async () => {
                                    const formErrors = await validateForm();
                                    setTouched(
                                      {
                                        steps: values.steps.map((step, idx) => {
                                          if (idx === activeStep) {
                                            return {
                                              approvers: true,
                                              approvers_ids: true,
                                              approval_option: true,
                                              min_approvers_counter_condition: true,
                                            };
                                          }
                                          return {};
                                        }),
                                      },
                                      true
                                    );

                                    if (!formErrors?.steps?.[activeStep]) {
                                      push({ ...emptyStep });
                                      setActiveStep(values.steps.length);
                                    }
                                  }}
                                >
                                  <i className="fe-plus" />
                                </span>
                              </OverlayTrigger>
                            </div>
                          )}
                          {Array.from({ length: 4 - values.steps.length }).map((__, index) => (
                            <Col key={`empty-col-${index}`} />
                          ))}
                        </Row>

                        {values.steps[activeStep] && (
                          <>
                            <Row className="mb-3">
                              <Col>
                                <CompanyEmployeePicker
                                  name={`steps.${activeStep}.approvers`}
                                  label={t("approvalFlows.labels_assignUsers")}
                                  placeholder={t("common:actions.select")}
                                  required
                                  companyId={companyId}
                                  isMulti
                                  onChange={(employees) => {
                                    const updatedApproversIds = employees.map((emp) => emp.user_id);
                                    setFieldValue(`steps.${activeStep}.approvers_ids`, updatedApproversIds);

                                    const approvalOption = values.steps[activeStep].approval_option;
                                    let newMinApprovers = values.steps[activeStep].min_approvers_counter_condition;

                                    if (approvalOption === "all") {
                                      newMinApprovers = updatedApproversIds.length;
                                    } else if (approvalOption === "any") {
                                      newMinApprovers = 1;
                                    } else if (approvalOption === "other") {
                                      newMinApprovers = Math.min(newMinApprovers, updatedApproversIds.length);
                                    }

                                    setFieldValue(
                                      `steps.${activeStep}.min_approvers_counter_condition`,
                                      newMinApprovers
                                    );
                                  }}
                                  excludedUserIds={excludedUserIds}
                                  helpText={t("approvalFlows.helpText_assignUsers")}
                                  options={Object.values(employeesByUserId)}
                                />
                              </Col>
                              <Col />
                            </Row>

                            <Row className="mb-3">
                              <Col lg={3}>
                                <FormGroup.InlineOptionsSelect
                                  label={t("approvalFlows.labels_mandatoryApprovalFrom")}
                                  name={`steps.${activeStep}.approval_option`}
                                  options={[
                                    {
                                      value: "all",
                                      label: t("approvalFlows.options_all"),
                                      tooltip: t("approvalFlows.helpText_all"),
                                    },
                                    {
                                      value: "any",
                                      label: t("approvalFlows.options_any"),
                                      tooltip: t("approvalFlows.helpText_any"),
                                    },
                                    {
                                      value: "other",
                                      label: t("approvalFlows.options_other"),
                                      tooltip: t("approvalFlows.helpText_other"),
                                    },
                                  ]}
                                  onChange={(newValue) => {
                                    const approversCount = values.steps[activeStep].approvers_ids?.length || 0;

                                    if (newValue === "all") {
                                      setFieldValue(
                                        `steps.${activeStep}.min_approvers_counter_condition`,
                                        approversCount
                                      );
                                    } else if (newValue === "any") {
                                      setFieldValue(`steps.${activeStep}.min_approvers_counter_condition`, 1);
                                    } else if (newValue === "other") {
                                      setFieldValue(
                                        `steps.${activeStep}.min_approvers_counter_condition`,
                                        approversCount > 0
                                          ? Math.min(
                                              values.steps[activeStep].min_approvers_counter_condition,
                                              approversCount
                                            )
                                          : 1
                                      );
                                    }
                                  }}
                                  required
                                />
                              </Col>

                              <Col lg={3}>
                                {values.steps[activeStep].approval_option === "other" && (
                                  <MinApproversInput
                                    name={`steps.${activeStep}.min_approvers_counter_condition`}
                                    label={t("approvalFlows.labels_minApprovers")}
                                    assignedUsersCount={values.steps[activeStep].approvers_ids.length}
                                  />
                                )}
                              </Col>
                            </Row>
                          </>
                        )}
                      </>
                    )}
                  </FieldArray>
                  <Row className="mt-4">
                    <Col>
                      <FormGroup.Input name="title" label={t("approvalFlows.labels_name")} required />
                      {approvalFlowsDataLen > 0 && (
                        <FormGroup.LabeledCheckbox
                          name="is_default"
                          label={t("approvalFlows.labels_isDefault")}
                          disabled={initialData?.is_default}
                        />
                      )}
                    </Col>
                    <Col />
                    <Col />
                    <Col />
                  </Row>
                </Card.Body>
                <Card.Footer>
                  {initialData?.id && !initialData.is_default && (
                    <RemoveButton
                      icon=""
                      label={t("approvalFlows.buttons_deleteFlow")}
                      className="btn-outline-red text-start"
                      onClick={() => onDelete(initialData?.id, setErrors)}
                      confirmMessage={t("approvalFlows.buttons_deleteFlowConfirm", { title: values.title })}
                    />
                  )}
                  <SubmitButton className="float-end" isSubmitting={isSubmitting} />
                  <Button className="float-end" variant="link" onClick={onCancel}>
                    {t("common:actions.cancel")}
                  </Button>
                </Card.Footer>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ApprovalFlowForm;
