import React from "react";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { addYears } from "date-fns";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { formatMoney } from "utils/money";
import { config as chartConfig } from "components/charts/helpers";
import { parseDate } from "utils/date";
import { _arrayFromRange, yM } from "./helpers";

function YearsCompareChart({ financialYear, income, expenses, prevIncome, prevExpenses }) {
  const { t } = useTranslation("reports");
  const chartData = _arrayFromRange(financialYear.date_start, new Date(), {
    income: 0,
    prevIncome: 0,
    result: 0,
    prevResult: 0,
  });

  income.forEach((item) => {
    try {
      chartData[yM(item.month)].income = Math.round(item.sum);
      chartData[yM(item.month)].result += Math.round(item.sum);
    } catch (error) {
      // pass
    }
  });
  expenses.forEach((item) => {
    try {
      chartData[yM(item.month)].result -= Math.round(item.sum);
    } catch (error) {
      // pass
    }
  });
  let month;
  prevIncome.forEach((item) => {
    month = addYears(parseDate(item.month), 1); // chart uses current year, need  to replace years
    try {
      chartData[yM(month)].prevIncome = Math.round(item.sum);
      chartData[yM(month)].prevResult += Math.round(item.sum);
    } catch (error) {
      // pass
    }
  });
  prevExpenses.forEach((item) => {
    month = addYears(parseDate(item.month), 1); // chart uses current year, need  to replace years
    try {
      chartData[yM(month)].prevResult -= Math.round(item.sum);
    } catch (error) {
      // pass
    }
  });
  return (
    <Card>
      <Card.Body className="m-0 p-0 pe-1">
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart
            data={Object.values(chartData).slice(0, 12)}
            margin={{
              top: 20,
              right: 0,
              bottom: 20,
              left: 0,
            }}
          >
            <CartesianGrid stroke={chartConfig.gridColor} strokeDasharray="3 3" />
            <XAxis tick={{ fontSize: chartConfig.axisFontSize }} dataKey="name" scale="band" />
            <YAxis tick={{ fontSize: chartConfig.axisFontSize }} tickFormatter={(tick) => formatMoney(tick, 0, 0)} />
            <Tooltip formatter={(value) => `${formatMoney(value, 0, 0)} SEK`} />
            <Legend />
            <Bar dataKey="income" name={t("stats.revenue")} barSize={10} fill={chartConfig.blue} />
            <Bar dataKey="prevIncome" name={t("dashboard.prevRevenue")} barSize={10} fill={chartConfig.darkBlue} />
            <Line dataKey="result" name={t("dashboard.result")} type="monotone" stroke={chartConfig.blue} />
            <Line dataKey="prevResult" name={t("dashboard.prevResult")} type="monotone" stroke={chartConfig.darkBlue} />
          </ComposedChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card>
  );
}

export default YearsCompareChart;
