import React from "react";
import { Outlet } from "react-router";

import StatsNavTabs from "./StatsNavTabs";

function StatsLayout({ children }) {
  return (
    <div className="tab-card">
      <StatsNavTabs />
      <Outlet />
    </div>
  );
}

export default StatsLayout;
