import React from "react";
import { Button } from "react-bootstrap";

import useTable from "components/tables/btable/useTable";
import {
  MultiAgencyFilter,
  MultiCompanyConsultFilter,
  MultiOfficeFilter,
  MultiSelectSimpleFilter,
  SearchFilter,
} from "components/filters";
import { updateOsCompaniesFilters } from "components/tables/btable/provider";
import { useTranslation } from "react-i18next";
import * as options from "../../../api/options";
import { StaffPermRequired } from "../../perms";

function CompanyFilters({ forStaff, showOfficesFilter }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("company");
  const setCompaniesSharedFilters = (selected) => {
    updateOsCompaniesFilters(selected);
  };
  return (
    <section className="table-filters-left">
      <SearchFilter
        onFilter={(selected) => {
          setFilters(selected);
          setCompaniesSharedFilters(selected);
        }}
        defaultValue={filters.term}
        width={300}
        autoFocus
      />
      <StaffPermRequired agencyConsult>
        <MultiCompanyConsultFilter
          name="consults"
          isClearable
          onFilter={(selected) => {
            setFilters(selected);
            setCompaniesSharedFilters(selected);
          }}
          defaultValue={filters.consults}
          placeholder={t("common:customerManager")}
        />
      </StaffPermRequired>
      <MultiSelectSimpleFilter
        isClearable
        placeholder={t("common:statuses.status")}
        name="statuses"
        onFilter={setFilters}
        defaultValue={filters.statuses}
        options={options.companyStatuses.asList()}
      />
      {forStaff && (
        <>
          <MultiAgencyFilter
            name="agencies"
            onFilter={(selected) => {
              setFilters({ ...selected, offices: [] });
              setCompaniesSharedFilters({ ...selected, offices: [] });
            }}
            placeholder={t("common:agency")}
            defaultValue={filters.agencies}
          />
          {showOfficesFilter && (
            <MultiOfficeFilter
              name="offices"
              onFilter={(selected) => {
                setFilters(selected);
                setCompaniesSharedFilters(selected);
              }}
              placeholder={t("common:office")}
              defaultValue={filters.offices}
              queryParams={{ belongsMyAgencies: true, agencies: filters.agencies }}
            />
          )}
          <Button
            variant="toggle"
            onClick={() => {
              setFilters({ favourites: filters.favourites ? null : true });
              setCompaniesSharedFilters({ favourites: filters.favourites ? null : true });
            }}
            active={filters.favourites}
          >
            <i className="fe-heart me-1" /> {t("favouritesOnly")}
          </Button>
        </>
      )}
    </section>
  );
}

export default CompanyFilters;
