import React, { useCallback } from "react";
import { Link } from "react-router";

import "./CompanyNotificationBar.scss";
import * as companyAPI from "api2/companies";
import useAsync from "hooks/useAsync";
import { Trans, useTranslation } from "react-i18next";
import useModal from "hooks/useModal";
import ConnectKYCModal from "components/modals/ConnectKYCModal";
import { Button } from "react-bootstrap";

function CompanyNotificationBar({ companyId }) {
  const dataSource = useCallback(
    (cancelToken) => companyAPI.notifications.list(companyId, { cancelToken }),
    [companyId]
  );
  const [{ data: notifications, loading }] = useAsync(dataSource, []);

  const renderNotifications = () => {
    return notifications.map((notification) => {
      switch (notification.code) {
        case "bank-expiration":
          return (
            <BankConnection
              key={notification.code}
              companyId={companyId}
              i18nKey="msg:notification.bank-expiration"
              icon="fe-alert-triangle"
              classNames=""
            />
          );
        case "bank-unmapped":
          return (
            <BankConnection
              key={notification.code}
              companyId={companyId}
              i18nKey="msg:notification.bank-unmapped"
              icon="fe-bell"
              classNames="bg-warning"
            />
          );
        case "kyc-required":
          return <KycRequired key={notification.code} companyId={companyId} classNames="bg-info" />;
        default:
          return null;
      }
    });
  };

  if (loading || !notifications) {
    return null;
  }

  return renderNotifications();
}

function BankConnection({ companyId, i18nKey, icon, classNames }) {
  const { t } = useTranslation();

  return (
    <div className={`company-notification-bar ${classNames}`}>
      <i className={icon} />
      <Trans i18nKey={i18nKey}>
        {t(i18nKey)}
        <Link to={`/companies/${companyId}/settings/bank-connections`} />
      </Trans>
    </div>
  );
}

function KycRequired({ companyId, classNames }) {
  const modal = useModal();
  return (
    <div className={`company-notification-bar ${classNames}`}>
      <i className="fas fa-shield-alt font-12" />
      <Trans i18nKey="msg:notification.kyc-required">
        <strong>Mandatory identity check needed</strong>
        To ensure seamless payments, please complete your identity verification.
        <Button size="sm" className="ms-2" onClick={modal.open}>
          Complete KYC
        </Button>
      </Trans>
      {modal.show && <ConnectKYCModal companyId={companyId} handleClose={modal.close} />}
    </div>
  );
}

export default CompanyNotificationBar;
