import React from "react";
import { useParams } from "react-router";

import * as agencyAPI from "api2/agencies";
import { AgencyForm } from "components/forms/agency-settings-forms";
import { withInitialAsync } from "hooks/useAsync";

function AgencyFormPage({ data: item }) {
  return <AgencyForm agency={item} />;
}

const EnhancedAgencyFormPage = withInitialAsync(
  AgencyFormPage,
  () => {
    const { agencyId } = useParams();
    return React.useCallback((cancelToken) => agencyAPI.general.details(agencyId, { cancelToken }), [agencyId]);
  },
  {},
  true
);

export default EnhancedAgencyFormPage;
