import React from "react";
import NavBar from "components/ui/navbar";
import { Outlet } from "react-router";
import CompanyListMenu from "components/layouts/CompanyListMenu";
import ManagementDashboardNavTabs from "./ManagementDashboardNavTabs";

function ManagementDashboardLayout() {
  return (
    <div className="bmd-list">
      <NavBar companyMenu={false} />
      <CompanyListMenu />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="tab-card">
              <ManagementDashboardNavTabs />
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagementDashboardLayout;
