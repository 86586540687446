import React, { useEffect } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";

import useInitialAsync from "hooks/useInitialAsync";
import * as ciAPI from "api2/customer-invoices";
import { RocketLoader } from "components/ui/loaders";
import CustomerInvoiceForm from "components/forms/CustomerInvoiceForm";
import _ from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function CustomerInvoiceCreditPage() {
  const { t } = useTranslation("ci");
  const company = useOutletContext();
  const navigate = useNavigate();
  const { id: verificationId } = useParams();
  const { loading, item } = useInitialAsync(
    ({ cancelToken }) => {
      return ciAPI.invoice.details(company.id, verificationId, { cancelToken });
    },
    {},
    [company.id, verificationId]
  );
  useEffect(() => {
    if (item.periodisation_status && !["undone", "stopped_before_execution"].includes(item.periodisation_status)) {
      toast.warning(t("warning.creditedWithPeriodisation"), { autoClose: 5000 });
    }
  }, [item.periodisation_status, t]);
  const onCreate = (invoice) => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }

  const draftCreditInvoice = {
    ...item,
    id: null,
    booking_date: new Date(),
    credit_for: item.id,
    invoice_no: "",
    status: 0,
    sub_status: "",
    delivery_method: item.delivery_method || "manual",
    periodisation_config: null,
    // periodisation_status: null,
    periodisation_enabled: false,
    rot_rut: null,
    records: [
      {
        key: _.uniqueId("ln"),
        record_type: "message",
        product: null,
        message: { text: `Kredit avser faktura ${item.invoice_no}` },
      },
      ...item.records.map((record) => {
        if (record.record_type === "product") {
          record.product.unit_price *= -1;
          record.maxQtyForCredit = record.product.quantity;
          if (record.discount) {
            if (record.discount.type === "amount") {
              record.discount.value *= -1;
            } else {
              record.discount.value = Math.abs(record.discount.value);
            }
          }
          record.rot_rut = null;
        }
        return record;
      }),
    ],
  };
  const connectedProducts = item.connected_products || {};
  delete item.connected_products;

  return (
    <CustomerInvoiceForm
      forCredit
      invoice={draftCreditInvoice}
      company={company}
      forCreditInvoice
      invoiceConnectedProducts={connectedProducts}
      onSave={onCreate}
    />
  );
}

export default CustomerInvoiceCreditPage;
