import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { useTranslation } from "react-i18next";

import SalaryForm from "components/forms/SalaryForm";
import useInitialAsync from "hooks/useInitialAsync";
import * as salaryAPI from "api2/salaries";
import { RocketLoader } from "components/ui/loaders";
import { parseDate } from "utils/date";
import { useCompanyState } from "hooks/useCompany";

function SalaryAddPage() {
  const company = useOutletContext();
  const {
    costCenters: { byId: centerById },
    projects: { byId: projectById },
  } = useCompanyState();
  const { t } = useTranslation("common");
  const { id: salaryId } = useParams();
  const navigate = useNavigate();
  const { loading, item } = useInitialAsync(({ cancelToken }) => {
    return salaryAPI.details(company.id, salaryId, { cancelToken });
  }, {});

  if (loading) {
    return <RocketLoader />;
  }

  const onCreate = (newItem) => {
    navigate(-1);
  };

  const salaries = {
    booking_date: item.booking_date,
    payment_date: parseDate(item.payment_date),
    salaries: [
      {
        id: item.id,
        employee: item.receiver_name,
        receiver: item.receiver,
        account: item.account,
        paid_to:
          item.paid_to === "P"
            ? { value: "P", label: t("common:accountP") }
            : { value: "F", label: t("common:accountF") },
        salary_gross: item.salary_gross,
        fringe_benefits: item.fringe_benefits,
        soc_sec: item.soc_sec,
        personal_tax: item.personal_tax,
        tax_free: item.tax_free,
        holiday_allowance: item.holiday_allowance,
        holiday_allowance_soc_sec: item.holiday_allowance_soc_sec,
        selected: true,
        specification: item.documents.length ? item.documents[0] : null,
        specificationHolder: item.documents.length ? item.documents[0] : null,
        amount: item.amount,
        project: item.project ? projectById[item.project] : null,
        cost_center: item.cost_center ? centerById[item.cost_center] : null,
      },
    ],
  };
  return <SalaryForm companyId={company.id} salaries={salaries} onSave={onCreate} />;
}

export default SalaryAddPage;
