import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router";

import TableProvider from "components/tables/btable/provider";
import { AuditorTable } from "components/tables";
import * as companyAPI from "api2/companies";
import { AuditorAddWidget } from "components/forms/company-settings-forms/AuditorForm";
import { codesForAuditors } from "components/perms/PermCodes";
import { CodeRequiredInList } from "components/perms";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";

function AuditorListPage({ tableId = "auditors" }) {
  const { companyId } = useParams();
  const { userPermCodes } = useContext(CompanySettingStateContext);
  const initialFilters = {};
  return (
    <TableProvider
      tableId={tableId}
      dataSource={(filters, config) => companyAPI.auditors.list(companyId, filters, config)}
      initialFilters={initialFilters}
    >
      {" "}
      <Card>
        <Card.Body>
          <AuditorTable companyId={companyId} userPermCodes={userPermCodes} />
        </Card.Body>
      </Card>
      <CodeRequiredInList code={codesForAuditors.manage} codeList={userPermCodes}>
        <AuditorAddWidget companyId={companyId} />
      </CodeRequiredInList>
    </TableProvider>
  );
}

export default AuditorListPage;
