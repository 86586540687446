import { NavLink } from "react-router";
import React from "react";
import { useTranslation } from "react-i18next";

function BMDNavTabs() {
  const { t } = useTranslation("navigation");
  return (
    <ul className="nav nav-tabs nav-bordered nav-card pt-3" role="tablist">
      <li className="nav-item">
        <NavLink to="/manage/day-to-day" className="nav-link">
          D1 - {t("dayToDay")}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/manage/year-end-closure" className="nav-link">
          D2 - {t("yearEndClosure")}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/manage/profitability" className="nav-link">
          D3 - {t("profitability")}
        </NavLink>
      </li>
    </ul>
  );
}

export default BMDNavTabs;
