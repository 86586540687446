import React, { useContext, useMemo, useState } from "react";
import { Link, useResolvedPath } from "react-router";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import cx from "classnames";

import BTable from "components/tables/btable";
import useTable from "components/tables/btable/useTable";
import { codesFor, HasPermCode } from "components/perms";
import { SelectionContext } from "state/providers/SelectionProvider";
import { truncateText } from "utils/text";
import { formatMoney } from "utils/money";
import { saveBlobToComputer } from "utils/file";
import * as ciApi from "api2/customer-invoices";
import Filters from "./RotRutFilters";
import Actions from "./RotRutActions";
import RotRutModal from "./RotRutModal";
import "./RotRutTable.scss";

function RotRutTable({ companyId }) {
  const { t } = useTranslation("ci");
  const url = useResolvedPath("").pathname.replace("/rot-rut", "/customer-invoices");
  const { getFlatten, clearSelection } = useContext(SelectionContext);
  const canEdit = HasPermCode(codesFor.customerInvoices.manage);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {
    dataActions: { reload },
  } = useTable();
  const selected = getFlatten();

  const headers = useMemo(
    () => [
      {
        field: "invoice_no",
        sortField: ["invoice_no"],
        label: t("common:invoiceNo"),
        render: (values) => {
          if (values.status !== 0 || !canEdit) {
            return (
              <Link to={`${url}/${values.id}/preview`} className="table-link">
                {values.invoice_no || t("common:notSet")} <i className="fa fa-search" />
              </Link>
            );
          }
          return (
            <Link to={`${url}/${values.id}/change`} className="table-link">
              {values.invoice_no || t("common:notSet")} <i className="fas fa-pen" />
            </Link>
          );
        },
      },
      {
        field: "customer_snapshot",
        label: t("common:customer"),
        sortField: "customer_name",
        render: (values) =>
          values.customer_snapshot ? (
            <span title={values.customer_snapshot.name}>{truncateText(values.customer_snapshot.name, 20)}</span>
          ) : (
            "-"
          ),
      },
      {
        field: "customer_amount_to_pay",
        label: t("rotRutToPayCustomer"),
        className: "text-end",
        render: (value) => `${formatMoney(value.customer_amount_to_pay)} ${value.currency}`,
      },
      {
        field: "tax_amount_to_pay",
        label: t("rotRutTaxDeduction"),
        className: "text-end",
        render: (value) => `${formatMoney(value.tax_amount_to_pay)} ${value.currency}`,
      },
      {
        field: "booking_date",
        label: t("common:dates.invoiceDate"),
      },
      {
        field: "payment_date",
        label: t("common:dates.paymentDate"),
      },
    ],
    [canEdit, t, url]
  );

  const getRowProps = (row) => {
    const baseClassName =
      row.is_rot_rut_applied && ["unpaid", "expired"].includes(row.sub_status) ? "sent_bank" : row.sub_status;

    return {
      className: cx(baseClassName, {
        "has-message": !!row.comments.length,
      }),
    };
  };

  const renderTotals = (responseHeaders) => {
    return (
      <>
        <div>
          <strong>{t("rotRutToPayCustomer")}:</strong>
          {formatMoney(responseHeaders["x-pagination-amount"])}
        </div>
        <div>
          <strong>{t("rotRutTaxDeduction")}:</strong>
          {formatMoney(responseHeaders["x-pagination-balance"])}
        </div>
      </>
    );
  };

  const renderSelect = (row) => {
    if (Math.abs(row.customer_amount_to_pay) <= 1) {
      return undefined;
    }
    return "";
  };

  const handleUploadClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    reload();
  };

  const onDownload = async () => {
    setLoading(true);

    const rotIds = selected
      .filter((item) => Math.abs(item.customer_amount_to_pay) <= 1 && item.rot_rut_type === "ROT")
      .map((item) => item.id);

    const rutIds = selected
      .filter((item) => Math.abs(item.customer_amount_to_pay) <= 1 && item.rot_rut_type === "RUT")
      .map((item) => item.id);

    const promises = [];

    if (rotIds.length > 0) {
      promises.push(
        ciApi.rotRut
          .xml(rotIds, companyId)
          .then((response) => {
            saveBlobToComputer(response);
          })
          .catch((error) => {
            if (error.data && error.data.__all__) {
              toast.error(error.data.__all__);
            }
          })
      );
    }

    if (rutIds.length > 0) {
      promises.push(
        ciApi.rotRut
          .xml(rutIds, companyId)
          .then((response) => {
            saveBlobToComputer(response);
          })
          .catch((error) => {
            if (error.data && error.data.__all__) {
              toast.error(error.data.__all__);
            }
          })
      );
    }
    Promise.all(promises)
      .then(() => {
        clearSelection();
        reload();
      })
      .finally(() => {
        setLoading(false);
      });

    return true;
  };

  return (
    <>
      <div id="customer-invoice-table">
        <BTable
          headers={headers}
          selectable
          renderSelect={renderSelect}
          renderTotals={renderTotals}
          getRowProps={getRowProps}
          FiltersComponent={<Filters companyId={companyId} />}
          renderActions={(row) => <Actions companyId={companyId} row={row} />}
        />
      </div>
      <div className="table-footer">
        <div className="table-footer-buttons">
          <Row>
            <Col>
              <ButtonGroup>
                <Button
                  variant="secondary"
                  disabled={selected.length === 0 || loading}
                  className="table-footer-button"
                  onClick={onDownload}
                >
                  <i className="fe-download" /> {t("actions.downloadRotRutXml")}
                </Button>
                <Button variant="secondary" onClick={handleUploadClick} className="table-footer-button">
                  <i className="fe-upload" /> {t("actions.uploadFromSKV")}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </div>
      </div>
      <RotRutModal companyId={companyId} show={showModal} onHide={handleCloseModal} />
    </>
  );
}

export default RotRutTable;
