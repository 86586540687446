import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";

import useInitialAsync from "hooks/useInitialAsync";
import * as ciAPI from "api2/customer-invoices";
import { RocketLoader } from "components/ui/loaders";
import ContractInvoiceForm from "components/forms/ContractInvoiceForm";

function ContractInvoiceEditPage() {
  const company = useOutletContext();
  const { id: contractId } = useParams();
  const navigate = useNavigate();
  const { loading, item } = useInitialAsync(
    ({ cancelToken }) => {
      return ciAPI.contractInvoices.details(company.id, contractId, { cancelToken });
    },
    {},
    [company.id, contractId]
  );

  const onUpdate = (invoice) => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }
  return <ContractInvoiceForm invoice={item} company={company} onSave={onUpdate} />;
}

export default ContractInvoiceEditPage;
