import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { Link } from "react-router";

import useTable from "components/tables/btable/useTable";
import { SelectSimpleFilter } from "components/filters";
import { useTranslation } from "react-i18next";
import * as options from "api/options";

function EventFilters() {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const eventTypes = options.eventTypes.asList();

  return (
    <>
      <section className="table-filters-left">
        <SelectSimpleFilter
          placeholder={t("common:eventType")}
          defaultValue={filters.event_type}
          options={eventTypes}
          isClearable
          name="event_type"
          onFilter={setFilters}
        />
      </section>
      <section className="table-filters-right">
        <ButtonGroup>
          <Link to="/admin/events/form" className="btn btn-blue">
            <i className="fas fa-plus" /> {t("common:actions.create")}
          </Link>
        </ButtonGroup>
      </section>
    </>
  );
}

export default EventFilters;
