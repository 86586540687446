import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import BTable from "components/tables/btable";
import { formatMoney } from "utils/money";
import Filters from "./GlobalSearchFilters";
import Actions from "./GlobalSearchActions";

function statusToClass(status) {
  switch (status) {
    case 0:
      return "draft";
    case 1:
      return "paid";
    case 2:
      return "unpaid";
    default:
      return "draft";
  }
}

function GlobalSearchTable({ forStatus }) {
  const { t } = useTranslation("common");

  const headers = useMemo(
    () => [
      {
        field: "id",
        label: "#",
      },
      {
        field: "company_name",
        label: t("common:company"),
        canSort: false,
      },
      {
        field: "object_type",
        label: t("common:objectTypes.name"),
        render: (values) => t(`common:objectTypes.${values.object_type}`),
      },
      {
        field: "verification_number",
        label: t("common:verShort"),
      },
      {
        field: "amount",
        label: t("common:money.amount"),
        className: "text-end",
        render: (values) => `${formatMoney(values.amount)} ${values.amount_currency}`,
      },
    ],
    [t]
  );

  const getRowProps = (row) => {
    return { className: statusToClass(row.status) };
  };

  const renderTotals = (responseHeaders) => (
    <div>
      <strong>{t("common:money.total")}:</strong>
      {responseHeaders["x-pagination-count"]}
    </div>
  );

  return (
    <BTable
      headers={headers}
      selectable={false}
      FiltersComponent={<Filters forStatus={forStatus} />}
      renderActions={(row) => <Actions row={row} />}
      renderTotals={renderTotals}
      getRowProps={getRowProps}
    />
  );
}

export default GlobalSearchTable;
