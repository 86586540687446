import React from "react";
import { useNavigate } from "react-router";
import { Card } from "react-bootstrap";
import * as companyAPI from "api2/companies";

import OfficeSupportCompanyListLayout from "components/layouts/OfficeSupportCompanyListLayout";
import TableProvider from "components/tables/btable/provider";
import { OfficeSupportCompanyTable } from "components/tables";
import { useAuthState } from "hooks/useAuth";
import { useCompanyDispatch } from "../../../hooks/useCompany";

function OfficeSupportCompanyListPage() {
  const { user } = useAuthState();
  const navigate = useNavigate();
  const { selectCompany: selectCompanyAction } = useCompanyDispatch();
  const forStaff = user.is_superuser || user.is_consult;
  const getCompanies = companyAPI.officeSupportCompanies;
  const {
    user: { has_favourites_companies },
  } = useAuthState();
  const initialFilters = {
    statuses: ["full", "basic"],
    favourites: forStaff && has_favourites_companies,
  };
  const openCompanyArchive = async (companyId, nextRoute = "/") => {
    await localStorage.setItem("lastCompanyId", companyId);
    window.open(`/consult/company-archive`);
  };
  const selectCompany = async (companyId, nextRoute = "/") => {
    await selectCompanyAction(companyId, true);
    navigate(nextRoute);
  };
  return (
    <OfficeSupportCompanyListLayout>
      <Card>
        <Card.Body>
          <TableProvider
            tableId="office-support-companies" // this id is used for filtering logic
            dataSource={getCompanies}
            initialFilters={initialFilters}
            keepTerm
          >
            <OfficeSupportCompanyTable
              selectCompany={selectCompany}
              openCompanyArchive={openCompanyArchive}
              forStaff={forStaff}
            />
          </TableProvider>
        </Card.Body>
      </Card>
    </OfficeSupportCompanyListLayout>
  );
}

export default OfficeSupportCompanyListPage;
