import React from "react";
import { Outlet, useOutletContext, useSearchParams } from "react-router";
import { SelectionProvider } from "state/providers/SelectionProvider";
import PaymentAccountProvider from "state/providers/PaymentAccountProvider";

import { BGCNotConnectedErrorPage } from "pages/errors";
import PayNavTabs from "./PayNavTabs";
import PayBox from "./PayBox";

function PayLayout() {
  const [searchParams] = useSearchParams();
  const basketId = searchParams.get("bid");
  const company = useOutletContext();
  if (company.payment_provider === "bgc" && !company.connected_to_bgc) {
    return <BGCNotConnectedErrorPage />;
  }
  return (
    <div className="tab-card">
      <PayNavTabs />
      <PaymentAccountProvider>
        <SelectionProvider>
          <Outlet context={company} />
          <PayBox basketId={basketId} />
        </SelectionProvider>
      </PaymentAccountProvider>
    </div>
  );
}

export default PayLayout;
