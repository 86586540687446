import React, { useCallback, useRef, useState } from "react";
import { Button, Col, Dropdown, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-i18next";
import { useAuthDispatch, useAuthState } from "hooks/useAuth";
import { truncateText } from "utils/text";

import seFlag from "assets/images/se.svg";
import enFlag from "assets/images/us.svg";
import * as authAPI from "api2/auth";
import { startPages } from "api/options";
import _ from "lodash";
import { useNavigate } from "react-router";

function LandingPageSelector({ userStartPageCode, setUserStartPageCode, t, menuToggleRef }) {
  // if user is consult then allow to pick start page
  const [showOptions, setShowOptions] = useState(!userStartPageCode);
  const [showTooltip, setShowTooltip] = useState(!userStartPageCode);
  const { user } = useAuthState();
  const { updateUserProps } = useAuthDispatch();

  const onChangeStartPage = (e, start_page, triggeredByTooltipOk = false) => {
    e.stopPropagation();
    e.preventDefault();
    authAPI.changeDefaultAfterLoginPage(start_page).then(() => {
      setUserStartPageCode(start_page);
      updateUserProps({ ...user, start_page });
      if (triggeredByTooltipOk) {
        menuToggleRef.current.click();
      }
      if (showTooltip) {
        setShowTooltip(false);
      }
    });
  };
  const onShowOptions = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowOptions(!showOptions);
    // document.getElementById("dropdown-autoclose-false").click();
  };

  function pageButton(icon, pageCode, text) {
    const _userStartPageCode = userStartPageCode || startPages.code_for.officeSupport;
    const isSelected = _userStartPageCode === pageCode;
    return (
      <OverlayTrigger
        trigger={["hover", "click"]}
        placement="bottom"
        overlay={<Tooltip id={String(_.uniqueId("tooltip_"))}>{text}</Tooltip>}
      >
        <Button
          type="button"
          className={isSelected ? "selected" : "not-selected"}
          variant="toggle"
          onClick={(e) => onChangeStartPage(e, pageCode)}
        >
          {_userStartPageCode === pageCode && <i className="fe-check" />}
          <i className={icon} />
        </Button>
      </OverlayTrigger>
    );
  }

  return (
    <>
      <Dropdown.Item className="p-0">
        {!userStartPageCode && showTooltip && (
          <div className="forced-tooltip">
            <Row>
              <Col>
                <p>{t("msg:tooltip.startPageFirstInfo")}</p>
              </Col>
              <Col>
                <Button
                  type="button"
                  variant="primary"
                  onClick={(e) => onChangeStartPage(e, startPages.code_for.officeSupport, true)}
                >
                  Ok{/* by clicking also set Office Support as default - workaround for hide/show tooltip */}
                </Button>
              </Col>
            </Row>
          </div>
        )}
        <div onClick={(e) => onShowOptions(e)} className="toogleShowMoreOptions">
          <i className="fe-home" />
          <span>{t("others:defaultStartPage")}</span>
          <i className={showOptions ? "fe-chevron-up ps-2" : "fe-chevron-down ps-2"} />
        </div>
      </Dropdown.Item>
      {showOptions && (
        <Dropdown.Item className="p-0 dropdown-item-start-page-options">
          <div
            className="start-page-options"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            {pageButton("fe-flag", startPages.code_for.sl, t("navigation:statusList"))}
            {pageButton("fe-book-open", startPages.code_for.accounting, t("company:accounting"))}
            {pageButton("os-icon", startPages.code_for.officeSupport, t("common:officeSupport"))}
          </div>
        </Dropdown.Item>
      )}
      <Dropdown.Divider />
    </>
  );
}

function BlockAndDimAllPrevElements() {
  // block elements placed before it (visual disabled also), user can click elements placed only after it.
  return <span className="block-and-dim-all-prev-elements" />;
}

function UserMenu({ t }) {
  const { user } = useAuthState();
  const { logout } = useAuthDispatch();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const setLanguage = async (lng) => {
    await i18n.changeLanguage(lng);
  };
  const menuToggleRef = useRef();
  const [userStartPageCode, setUserStartPageCode] = useState(user.start_page);
  const isConsult = user.is_consult || user.is_superuser;
  const forceShowDropdownProps = !isConsult || userStartPageCode ? {} : { show: true }; // force to show dropdown all the time till statement is valid

  const onLogout = useCallback(() => {
    logout();
    navigate("/auth/logout", { replace: true });
  }, [logout, navigate]);

  return (
    <>
      {isConsult && !userStartPageCode && <BlockAndDimAllPrevElements />}
      <Dropdown {...forceShowDropdownProps}>
        <Dropdown.Toggle
          variant="success"
          id="dropdown-autoclose-false"
          as="a"
          className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light"
          ref={menuToggleRef}
        >
          <span className="user-icon">
            <i className="fe-user" />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu id="dropdown-basic-button" className="dropdown-menu-right profile-dropdown">
          <Dropdown.Header>{truncateText(user.name, 23)}</Dropdown.Header>
          <Dropdown.Divider />
          {isConsult && (
            <LandingPageSelector
              userStartPageCode={userStartPageCode}
              setUserStartPageCode={setUserStartPageCode}
              t={t}
              menuToggleRef={menuToggleRef}
            />
          )}
          {i18n.language === "en" ? (
            <Dropdown.Item onClick={() => setLanguage("sv")}>
              <img src={seFlag} alt={t("sv")} height={14} className="me-2" />
              <span>{t("sv")}</span>
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onClick={() => setLanguage("en")}>
              <img src={enFlag} alt={t("en")} height={14} className="me-2" />
              <span>{t("en")}</span>
            </Dropdown.Item>
          )}
          <Dropdown.Divider />
          <Dropdown.Item onClick={onLogout}>
            <i className="fe-log-out" />
            <span>{t("actions.logout")}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default withTranslation("common")(UserMenu);
