import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import BTable from "components/tables/btable";
import { truncateText } from "utils/text";
import { formatMoney } from "utils/money";
import { formatDate } from "utils/date";
import Filters from "./OfferFilters";
import Actions from "./OfferActions";

function OfferTable({ companyId }) {
  const { t } = useTranslation("common");
  const getRowProps = (row) => {
    let className;
    switch (row.status) {
      case "active":
        if (formatDate(new Date(row.valid_date)) >= formatDate(new Date())) {
          className = "empty";
        } else {
          className = "unpaid";
        }
        break;
      case "denied":
        className = "denied";
        break;
      case "accepted":
        className = "paid";
        break;
      default:
        className = "";
    }
    return {
      className,
    };
  };
  const headers = useMemo(
    () => [
      {
        field: "number",
        label: t("ci:offerNumber"),
      },
      {
        field: "customer_name",
        label: t("common:customer"),
        render: (values) =>
          values.customer_name ? (
            <span title={values.customer_name}>{truncateText(values.customer_name, 20)}</span>
          ) : (
            "-"
          ),
      },
      {
        field: "amount",
        label: t("common:money.amount"),
        className: "text-end",
        render: (value) => `${formatMoney(value.amount)} ${value.amount_currency}`,
      },
      {
        field: "booking_date",
        label: t("ci:offerDate"),
      },
      {
        field: "valid_date",
        label: t("common:dates.validUntil"),
      },
      {
        field: "last_email_sent_date",
        label: t("common:dates.sentDate"),
      },
    ],
    [t]
  );

  return (
    <BTable
      headers={headers}
      selectable={false}
      FiltersComponent={<Filters companyId={companyId} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      getRowProps={getRowProps}
    />
  );
}

export default OfferTable;
