import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "./BankAccountList.scss";
import { psd2 as psd2Api } from "api2/integrations";
import useModal from "hooks/useModal";
import { formatMoney } from "utils/money";
import { StaffPermRequired } from "components/perms";
import { confirmExecute } from "components/modals/ConfirmModal";
import { PaymentAccountContext } from "state/providers/PaymentAccountProvider";
import AccountMappingModal from "./AccountMappingModal";
import { Switch } from "../../../components/ui/switchers";

function BankAccountSwitch({
  initialChecked = false,
  accountId,
  currency,
  accountNumber,
  balance,
  disabled,
  onChange,
}) {
  const { t } = useTranslation("company");
  const { isPaymentAccount, setIsPaymentAccount } = useContext(PaymentAccountContext);

  const [checked, setChecked] = useState(initialChecked);
  useEffect(() => {
    const handle = (event) => {
      setChecked(event.detail.id === accountId);
    };
    document.body.addEventListener("psd/accountSelected", handle);
    return () => {
      document.body.removeEventListener("psd/accountSelected", handle);
    };
  }, [accountId]);

  const handleChange = async () => {
    if (checked) {
      toast.warning(t("company:psd.onePaymentAccount"));
    } else {
      if (isPaymentAccount) {
        const answer = await confirmExecute(t("confirm.changePaymentAccount"));
        if (!answer) return;
      }

      try {
        setChecked(true);
        await onChange();

        if (!isPaymentAccount) {
          setIsPaymentAccount(true);
        }
      } catch (error) {
        setChecked(false);
      }
    }
  };

  return (
    <div>
      <Switch
        checked={checked}
        handleChange={handleChange}
        disabled={disabled}
        label={
          <>
            <strong>{currency} |</strong> {accountNumber} {checked && <span>({t("common:paymentAccount")})</span>}
          </>
        }
        extraInfo={
          <div className="balance">
            {t("common:money.balance")}: {balance ? formatMoney(balance) : t("common:notSet")}
          </div>
        }
      />
    </div>
  );
}

function BankAccountList({ companyId, consentId, accounts, switchDisabled = false, showBflowAccounts }) {
  const accountModal = useModal();
  const onSwitch = (account) => {
    return psd2Api.bankAccounts
      .paymentAccountToggle(companyId, consentId, account.id)
      .then(() => {
        document.body.dispatchEvent(new CustomEvent("psd/accountSelected", { detail: { id: account.id } }));
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__);
        }
        throw error;
      });
  };

  return (
    <>
      <ul className="connection__accounts">
        {accounts.map((account) => (
          <li key={account.id}>
            <BankAccountSwitch
              accountId={account.id}
              currency={account.currency}
              accountNumber={account.iban}
              balance={account.psd_balance}
              onChange={() => onSwitch(account)}
              initialChecked={account.is_payment_account}
              disabled={switchDisabled}
            />
            {showBflowAccounts && (
              <StaffPermRequired agencyConsult>
                <div className="bflow-account" onClick={accountModal.open}>
                  {account.bflow_account || "-"} <i className="fe-edit" />
                </div>
              </StaffPermRequired>
            )}
          </li>
        ))}
      </ul>
      {accountModal.show && (
        <AccountMappingModal handleClose={accountModal.close} consentId={consentId} companyId={companyId} />
      )}
    </>
  );
}

export default BankAccountList;
