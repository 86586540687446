import React, { useEffect, useRef, useState } from "react";
import { useField } from "formik";
import cx from "classnames";
import { Checkbox } from "../../../components/ui/checkboxes/Checkbox";

export function IndeterminateCheckbox({
  name,
  label,
  onChange,
  parentShowMore,
  toggleSection,
  disabled = false,
  indeterminate = false,
  header = false,
  tdProps,
  ...props
}) {
  const [field, , helpers] = useField({ name, ...props });
  const checkboxRef = useRef(null);
  const [showMore, setShowMore] = useState(parentShowMore);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  useEffect(() => {
    setShowMore(parentShowMore);
  }, [parentShowMore]);

  const handleCheckboxChange = (isChecked) => {
    helpers.setValue(isChecked);
    if (onChange) {
      onChange(isChecked);
    }
  };

  const renderIcon = () => {
    if (header) {
      return (
        <i
          onClick={toggleSection}
          className={showMore ? "fas fa-chevron-up fa-1x" : "fas fa-chevron-down fa-1x"}
          style={{ paddingRight: 10, color: "rgba(173, 173, 173, 1)" }}
        />
      );
    }
    return null;
  };

  return (
    <td {...tdProps} className={cx(tdProps?.className)}>
      <div className="d-flex flex-row align-items-center">
        {renderIcon()}
        <Checkbox
          indeterminate={indeterminate}
          checked={field.value || false}
          handleChange={handleCheckboxChange}
          disabled={disabled}
          label={label}
        />
      </div>
    </td>
  );
}
