import React, { useCallback } from "react";
import { Outlet, useOutletContext } from "react-router";
import { SelectionProvider } from "state/providers/SelectionProvider";
import * as supportAPI from "api2/office-support";
import useAsync from "hooks/useAsync";
import CompanyCardNavTabs from "./CompanyCardNavTabs";

function CompanyCardLayout() {
  const company = useOutletContext();
  const [tabIndicators, setTabIndicators] = React.useState({});
  const getCompanyDetailsIndicators = useCallback(
    (cancelToken) =>
      supportAPI.statusIndicators.details(company.id, { cancelToken }).then((response) => {
        setTabIndicators(response.data);
      }),
    [company]
  );
  // eslint-disable-next-line no-empty-pattern
  const [{}, { reload: reloadIndicators }] = useAsync(getCompanyDetailsIndicators, []);

  return (
    <div className="tab-card">
      <CompanyCardNavTabs tabIndicators={tabIndicators} />
      <SelectionProvider>
        <Outlet context={{ company, tabIndicators, reloadIndicators }} />
      </SelectionProvider>
    </div>
  );
}

export default CompanyCardLayout;
