import React from "react";
import { NavLink } from "react-router";
import { useTranslation } from "react-i18next";
import { AgencyPermCodeRequired } from "components/perms";
import LeftSidebar from "components/ui/sidebar";
import LayoutSwitcherMenuItem from "components/layouts/LayoutSwitcherMenuItem";
import { codesFor } from "components/perms/PermCodes";

function Menu() {
  const { t } = useTranslation("navigation");
  return (
    <LeftSidebar>
      <LayoutSwitcherMenuItem />
      <li>
        <NavLink to="/office-support-export" end replace>
          <i className="fe-download" />
          <span> {t("export")}</span>
        </NavLink>
      </li>
      <AgencyPermCodeRequired code={codesFor.agency.officeSupervisior}>
        <li>
          <NavLink to="/office-support-kyc" end replace>
            <i className="fe-download" />
            <span> {t("KYC")}</span>
          </NavLink>
        </li>
      </AgencyPermCodeRequired>
    </LeftSidebar>
  );
}

export default Menu;
