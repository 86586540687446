import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { useIdleTimer } from "react-idle-timer";
import { Outlet } from "react-router";
import PrivateRoute from "./PrivateRoute";
import { useAuthDispatch } from "../../hooks/useAuth";
import FreshchatWrapper from "./FreshchatWrapper";

function ProtectedRoute() {
  const { logout } = useAuthDispatch();
  const handleOnIdle = useCallback(
    (event) => {
      toast.warning("Your session expired.");
      logout();
    },
    [logout]
  );

  useIdleTimer({
    timeout: 1000 * 60 * 60, // 60m  # BFLAKT-1924
    syncTimers: 1000 * 60 * 60,
    onIdle: handleOnIdle,
    onActive: undefined,
    onAction: undefined,
    debounce: 2000,
    crossTab: true,
    name: "bflow-idle",
  });

  return (
    <PrivateRoute>
      <FreshchatWrapper />
      <Outlet />
    </PrivateRoute>
  );
}

export default ProtectedRoute;
