import React from "react";
import NavBar from "components/ui/navbar";
import { Outlet } from "react-router";
import BMDNavTabs from "./BMDNavTabs";
import CompanyListMenu from "../CompanyListMenu";

function BMDLayout({ children }) {
  return (
    <div className="bmd-list">
      <NavBar companyMenu={false} />
      <CompanyListMenu />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="tab-card">
              <BMDNavTabs />
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BMDLayout;
