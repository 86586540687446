import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { SaveButton } from "pages/companies/OnboardingWizard/utils";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import "./InvoicingDetailsButtons.scss";

function InvoicingDetailsButtons({ isSubmitting, handleSubmit, handleSkip, isCustomerInvoiceEnabled = false }) {
  const { t } = useTranslation("common");

  const renderTooltip = (props) => (
    <Tooltip id="skip-tooltip" {...props} className="skip-tooltip">
      {t("company:onboarding.tooltips.skipInvoicingDetails")}
    </Tooltip>
  );

  return (
    <>
      {!isCustomerInvoiceEnabled && (
        <OverlayTrigger placement="bottom" overlay={renderTooltip}>
          <Button
            type="button"
            onClick={handleSkip}
            size="lg"
            variant="outline"
            className="btn-outline-orange me-1"
            disabled={isSubmitting}
          >
            {t("company:onboarding.buttons.skipInvoicingDetails")}
          </Button>
        </OverlayTrigger>
      )}
      <SaveButton disabled={isSubmitting} onSave={handleSubmit} />
    </>
  );
}

export default memo(InvoicingDetailsButtons);
