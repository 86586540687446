import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatDatetime } from "utils/date";
import { ActionButton } from "./TooltipButton";

function _renderPopover({ logs, t, ...props }) {
  return (
    <Popover id="popover-logs" className="popover-logs" {...props}>
      <Popover.Header as="h3">{t("logs.title")}</Popover.Header>
      <Popover.Body>
        <ul>
          {logs.map((log) => (
            <li key={`log.${log.id}`}>
              {formatDatetime(log.created)}{" "}
              <strong>
                {t(`logs.${log.action}`).toLowerCase()}
                {log.bold_extra_info && ` ${log.bold_extra_info}`}
              </strong>
              {log.extra_info ? ` ${log.extra_info}` : ` (${log.user_name})`}
            </li>
          ))}
        </ul>
      </Popover.Body>
    </Popover>
  );
}

function HistoryButton({ apiResource, apiParams, ...btnProps }) {
  const { t } = useTranslation("common");

  const [logs, setLogs] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const getLogs = React.useCallback(() => {
    apiResource(...apiParams).then((res) => setLogs(res.data));
  }, [apiResource, apiParams]);
  return (
    <OverlayTrigger
      show={show}
      placement="left"
      onEnter={getLogs}
      overlay={(props) => _renderPopover({ logs, t, ...props })}
    >
      <ActionButton
        icon="fas fa-history"
        className="history-btn"
        onClick={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        {...btnProps}
      />
    </OverlayTrigger>
  );
}

function HistoryButtonCreated({ createdAt, createdBy, id }) {
  const { t } = useTranslation("common");
  const [logs] = React.useState([
    {
      id,
      created: createdAt,
      user_name: createdBy,
      action: "created",
    },
  ]);

  const [show, setShow] = React.useState(false);

  return (
    <OverlayTrigger show={show} placement="left" overlay={(props) => _renderPopover({ logs, t, ...props })}>
      <ActionButton icon="fas fa-history" onClick={() => setShow(true)} onMouseLeave={() => setShow(false)} />
    </OverlayTrigger>
  );
}

export { HistoryButton, HistoryButtonCreated };
