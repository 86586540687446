import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { ContractTemplateTable } from "components/tables";
import * as ciAPI from "api2/customer-invoices";
import { useOutletContext } from "react-router";

function ContractTemplateListPage({ tableId = "contractInvoicesTemplates" }) {
  const company = useOutletContext();
  const initialFilters = {
    mode: "",
  };
  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => ciAPI.contractTemplates.list(company.id, filters, config)}
          initialFilters={initialFilters}
        >
          <ContractTemplateTable companyId={company.id} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default ContractTemplateListPage;
