import { NavLink, useResolvedPath } from "react-router";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "react-bootstrap";
import { PermCodeRequired } from "components/perms";
import { codesForConsultManage } from "components/perms/PermCodes";
import { FiltersContext } from "state/providers/FiltersProviderRelatedToFY";
import "./CompanyArchiveNavTabs.scss";

function ProjectionBar() {
  const { t } = useTranslation("common");
  const [projection, setProjection] = useState("");
  const { updateFilter } = useContext(FiltersContext);

  const updateProjection = (newValue) => {
    setProjection(newValue);
    updateFilter({ projection: newValue });
  };
  return (
    <ul className="nav pt-3 projection-bar" role="tablist">
      <li className="nav-item">
        <ButtonGroup>
          <Button variant="switch" active={!projection} onClick={() => updateProjection("")}>
            {t("consult")}
          </Button>
          <Button variant="switch" active={projection === "cus"} onClick={() => updateProjection("cus")}>
            {t("customer")}
          </Button>
          <Button variant="switch" active={projection === "aud"} onClick={() => updateProjection("aud")}>
            {t("company:auditor")}
          </Button>
        </ButtonGroup>
      </li>
    </ul>
  );
}

function CompanyArchiveNavTabs() {
  const { t } = useTranslation("navigation");
  const path = useResolvedPath("").pathname;

  return (
    <div className="d-flex justify-content-between">
      <ul className="nav nav-tabs nav-bordered nav-card pt-3" role="tablist">
        <li className="nav-item">
          <NavLink to={`${path}`} end className="nav-link">
            {t("common:statuses.all")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={`${path}/basic`} end className="nav-link">
            {t("basicDocuments")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={`${path}/yearly`} end className="nav-link">
            {t("yearlyDocuments")}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={`${path}/auditor`} className="nav-link">
            {t("forAuditor")}
          </NavLink>
        </li>
      </ul>
      <PermCodeRequired code={codesForConsultManage.enabled}>
        <ProjectionBar />
      </PermCodeRequired>
    </div>
  );
}

export default CompanyArchiveNavTabs;
