import { NavLink, useOutletContext } from "react-router";
import React from "react";
import { useTranslation } from "react-i18next";

import Pill from "components/ui/pills/Pill";
import { useMenuNumbersState } from "hooks/useMenuNumbers";
import { codesFor } from "components/perms/PermCodes";
import { HasPermCode } from "components/perms";

function ApproveNavTabs() {
  const { t } = useTranslation("navigation");
  const { prelim } = useMenuNumbersState();
  const company = useOutletContext();
  const badgeClass = "badge-prelim";
  const hasConsultManage = HasPermCode(codesFor.consultManage.enabled);
  const canSeeSI = HasPermCode(codesFor.supplierInvoices.enabled);
  const canSeeOutlays = HasPermCode(codesFor.outlays.enabled);
  const canSeeSalaries = HasPermCode(codesFor.salaries.enabled);
  const canSeeAbsences = HasPermCode(codesFor.absences.enabled);
  const canSeeApproveSI = HasPermCode(codesFor.supplierInvoices.approve) || (canSeeSI && hasConsultManage);
  const canSeeApproveOutlays = HasPermCode(codesFor.outlays.approve) || (canSeeOutlays && hasConsultManage);
  const canSeeApproveSalaries = HasPermCode(codesFor.salaries.approve) || (canSeeSalaries && hasConsultManage);
  const canSeeApproveAbsences = HasPermCode(codesFor.absences.approve) || (canSeeAbsences && hasConsultManage);
  return (
    <ul className="nav nav-tabs nav-bordered nav-card pt-3" role="tablist">
      {canSeeApproveSI && (
        <li className="nav-item">
          <NavLink to="/approve/supplier-invoices" className="nav-link">
            {t("supplierInvoices")} <Pill number={prelim.supplier_invoices} badgeClass={badgeClass} />
          </NavLink>
        </li>
      )}
      {canSeeApproveOutlays && (
        <li className="nav-item">
          <NavLink to="/approve/outlays" className="nav-link">
            {t("outlays")} <Pill number={prelim.outlays} badgeClass={badgeClass} />
          </NavLink>
        </li>
      )}
      {canSeeApproveSalaries && (
        <li className="nav-item">
          <NavLink to="/approve/salaries" className="nav-link">
            {t("salaries")} <Pill number={prelim.salaries} badgeClass={badgeClass} />
          </NavLink>
        </li>
      )}
      {company.absence_report_enabled && canSeeApproveAbsences && (
        <li className="nav-item">
          <NavLink to="/approve/employee-absences" className="nav-link">
            {t("employeeAbsence")} <Pill number={prelim.employee_absences} badgeClass={badgeClass} />
          </NavLink>
        </li>
      )}
    </ul>
  );
}

export default ApproveNavTabs;
