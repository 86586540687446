import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import cx from "classnames";
import { GetLayout } from "utils/layout";
import NavBarRightSide from "./NavBarRightSide";
import NavBarLeftSide from "./NavBarLeftSide";

function NavBar({ company, forDashboard = true }) {
  const [isBreadCrumbs, setIsBreadcrumbs] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const breadCrumbs = document.querySelector(".breadcrumbs");
    const fyearSelect = document.querySelector(".fyear-react-select");

    if (breadCrumbs) {
      setIsBreadcrumbs(!!breadCrumbs.childElementCount);
    } else {
      setIsBreadcrumbs(false);
    }

    setIsSelect(!!fyearSelect);
  }, [pathname]);

  return (
    <div
      className={cx("navbar-custom", GetLayout(), {
        "with-breadcrumbs": isBreadCrumbs,
        "with-fyear-select": isSelect,
      })}
    >
      <NavBarLeftSide company={company} forDashboard={forDashboard} />
      <NavBarRightSide company={company} forDashboard={forDashboard} />
    </div>
  );
}

export default NavBar;
