import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import customConfirm from "components/modals/ConfirmModal";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import "./OnboardingRollbackConfirmModal.scss";

function OnboardingRollbackConfirmModal({ giveAnswer }) {
  const { t } = useTranslation("company");
  const [confirmed, setConfirmed] = useState(false);

  const handleConfirm = () => {
    if (confirmed) {
      giveAnswer(true);
    }
  };

  const handleCancel = () => {
    giveAnswer(false);
  };

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={handleCancel}
      dialogClassName="onboarding-confirmation-modal"
      dialogAs={DraggableModalDialog}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("onboarding.rollbackModalTitle")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>
            <Trans i18nKey={t("onboarding.rollbackModalBodyParagraph1")} />
          </p>
          <ul>
            <li>{t("onboarding.rollbackModalBodyItem1")}</li>
            <li>
              {t("onboarding.rollbackModalBodyItem2")}
              <ul>
                <li>{t("onboarding.rollbackModalBodyItem2SubItem1")}</li>
                <li>{t("onboarding.rollbackModalBodyItem2SubItem2")}</li>
                <li>{t("onboarding.rollbackModalBodyItem2SubItem3")}</li>
                <li>{t("onboarding.rollbackModalBodyItem2SubItem4")}</li>
                <li>{t("onboarding.rollbackModalBodyItem2SubItem5")}</li>
                <li>{t("onboarding.rollbackModalBodyItem2SubItem6")}</li>
                <li>{t("onboarding.rollbackModalBodyItem2SubItem7")}</li>
                <li>{t("onboarding.rollbackModalBodyItem2SubItem8")}</li>
              </ul>
            </li>
          </ul>
          <p>{t("onboarding.rollbackModalBodyParagraph2")}</p>
        </div>
        <Form.Check
          type="checkbox"
          label={t("onboarding.rollbackModalBodyCheckboxLabel")}
          checked={confirmed}
          onChange={(e) => setConfirmed(e.target.checked)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={handleCancel}>
          {t("common:actions:cancel")}
        </Button>
        <Button variant="red" onClick={handleConfirm} disabled={!confirmed}>
          {t("onboarding.rollbackModalFooterYes")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const confirmOnboardingRollback = () =>
  customConfirm(({ giveAnswer }) => <OnboardingRollbackConfirmModal giveAnswer={giveAnswer} />);

export { OnboardingRollbackConfirmModal, confirmOnboardingRollback };
