import React, { useCallback } from "react";
import cx from "classnames";
import "./OnboardingProgressTable.scss";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

function ProgressItem({ onClickItem, name, status }) {
  const { t } = useTranslation("company");
  const title = `onboarding.steps.${name}`;
  return <div title={t(title)} className={cx("progress", status)} onClick={onClickItem} />;
}
function OnboardingProgress({ companyId, progress }) {
  const navigate = useNavigate();
  const onClickItem = useCallback(
    (stepId) => {
      navigate(`/onboarding/${companyId}?step=${stepId}`);
    },
    [companyId, navigate]
  );

  return (
    <div className="onboarding-progress">
      {progress.map((step) => (
        <ProgressItem key={step.id} name={step.id} status={step.status} onClickItem={() => onClickItem(step.id)} />
      ))}
    </div>
  );
}

export default OnboardingProgress;
