import React, { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router";
import { Card } from "react-bootstrap";
import * as supportApi from "api2/office-support";
import OfficeSupportUpdateNoteContainer from "./OfficeSupportUpdateNoteContainer";
import OfficeSupportCreateNoteForm from "./OfficeSupportCreateNoteForm";
import "./OfficeSupportCompanyNotesPage.scss";
import Filters from "./Filters";

function OfficeSupportCompanyNotesPage() {
  const { company } = useOutletContext();
  const [items, setItems] = useState([]);
  const [showMore, setShowMore] = useState(true);

  const [filters, setFilters] = useState({});
  const pageSize = 10;
  const listInnerRef = useRef();
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [lastList, setLastList] = useState(false);
  const [noteIdInEdit, setNoteIdInEdit] = useState(null);
  const [noteIdInChildCreation, setNoteIdInChildCreation] = useState(null);
  const paginationCount = useRef(null);
  const [refresher, setRefresher] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      const response = await supportApi.notes.list(company.id, { ...filters, page: currPage, page_size: pageSize });
      if (!response.data.length) {
        setLastList(true);
        return;
      }
      if (!paginationCount.current) {
        paginationCount.current = response.headers["x-pagination-count"];
      }
      setPrevPage(currPage);

      setItems((prevItems) => {
        const mergedItems = [...prevItems, ...response.data];
        const uniqueMergedItems = mergedItems.filter(
          (value, index, self) => index === self.findIndex((i) => i.id === value.id)
        );
        setShowMore(uniqueMergedItems.length < response.headers["x-pagination-count"]);
        return uniqueMergedItems;
      });
    };
    if (!lastList && prevPage !== currPage) {
      fetchData();
    }
  }, [company.id, currPage, lastList, prevPage, filters]);
  const onScroll = () => {
    if (listInnerRef.current && showMore) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight - 50 <= scrollHeight) {
        setCurrPage(currPage + 1);
        setShowMore(false);
      }
    }
  };
  useEffect(() => {
    setItems([]);
    setCurrPage(1);
    setPrevPage(0);
    setLastList(false);
    setShowMore(false);
  }, [filters]);
  const onCreate = async (note) => {
    const response = await supportApi.notes.list(company.id, {
      ...filters,
      page: 1,
      page_size: items.length + 1,
      id: note.id,
    });
    if (response.data.length === 0) {
      return;
    }
    const copiedItems = [...items];
    copiedItems.unshift(response.data[0]);
    setItems(copiedItems);
    setRefresher(refresher + 1);
  };
  const onUpdate = async (note, props) => {
    const response = await supportApi.notes.list(company.id, { ...filters, page: 1, page_size: 1, id: note.id });
    const copiedItems = [...items];
    if (response.data.length === 0) {
      const _items = copiedItems.filter((item) => item.id !== note.id);
      setItems(_items);
      return;
    }
    const _items = copiedItems.map((item) => (item.id !== note.id ? item : response.data[0]));
    setItems(_items);
    if (props && !props.prevent_refresh) {
      setRefresher(refresher + 1);
    }
  };
  const onDelete = async (note) => {
    const _items = items.filter((item) => item.id !== note.id);
    setItems(_items);
  };
  const disableFilters = noteIdInEdit !== null || noteIdInChildCreation !== null;
  return (
    <Card className="notes">
      <Card.Body>
        <div>
          <Filters filters={filters} setFilters={setFilters} disabled={disableFilters} />
          <hr className="mt-0 mb-1" />
          <div className="notes-scroller" onScroll={onScroll} ref={listInnerRef}>
            <React.Fragment key={`refresher_${refresher}`}>
              {items.map((note, index) => {
                return (
                  <OfficeSupportUpdateNoteContainer
                    key={`parent_${note.id}`}
                    companyId={company.id}
                    note={note}
                    noteIdInEdit={noteIdInEdit}
                    setNoteIdInEdit={setNoteIdInEdit}
                    noteIdInChildCreation={noteIdInChildCreation}
                    setNoteIdInChildCreation={setNoteIdInChildCreation}
                    onUpdate={(props) => onUpdate(note, props)}
                    onDelete={() => onDelete(note)}
                    filters={filters}
                  />
                );
              })}
            </React.Fragment>
          </div>
        </div>
        <div className="parent-note-container-create">
          <OfficeSupportCreateNoteForm companyId={company.id} onCreate={onCreate} disabled={disableFilters} />
        </div>
      </Card.Body>
    </Card>
  );
}

export default OfficeSupportCompanyNotesPage;
