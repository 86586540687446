import React from "react";
import { useNavigate, useOutletContext } from "react-router";

import ContractTemplateForm from "components/forms/ContractTemplateForm";

function ContractTemplateAddPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const invoice = {
    name: "",
    ongoing: false,
    contract_length: 12,
    invoice_interval: 1,
    payment_terms: company.default_invoice_payment_terms,
    currency_code: "SEK",
    our_reference: "",
    records: [],
    delivery_method: "email",
  };

  const onCreate = (newInvoice) => {
    navigate(-1);
  };

  return <ContractTemplateForm invoice={invoice} company={company} onSave={onCreate} />;
}

export default ContractTemplateAddPage;
