import React, { useEffect } from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router";

import { formatMoney } from "utils/money";
import { collectionSum } from "utils/list";
import { toast } from "react-toastify";

function SummaryBox({ title, children }) {
  return (
    <Card className="text-center">
      <Card.Header className="pb-0">{title}</Card.Header>
      <Card.Body className="p-1">{children}</Card.Body>
    </Card>
  );
}

function ReconciliationSummary({ ledger, baseReconciled, currentReconciled, setReconciled, goToNextAccount }) {
  const { t } = useTranslation("reports");
  const navigate = useNavigate();
  const {
    values: { specifications },
    submitForm,
  } = useFormikContext();

  const specsSum = collectionSum(
    specifications.filter((spec) => !spec.is_deleted),
    "amount"
  );
  const accountBalance = ledger.ending_balance.sum;
  const diff = specsSum - accountBalance;

  useEffect(() => {
    if ((diff >= 1 || diff <= -1) && currentReconciled) {
      setReconciled(false);
    }
  }, [diff, currentReconciled, setReconciled]);

  function tryReconcile() {
    if (diff >= 1 || diff <= -1) {
      toast.error(t("canNotReconcileDiff"));
    } else {
      setReconciled(!currentReconciled);
      if (currentReconciled === false) {
        // switch to reconciled and go to next not reconciled
        submitForm().then(async (res) => {
          if (res) {
            // if not then server error handled before
            const redirectUrl = await goToNextAccount(!currentReconciled);
            navigate(redirectUrl, { replace: true });
          }
        });
      }
    }
  }

  const displayWarn = baseReconciled !== currentReconciled && (diff >= 1 || diff <= -1);

  return (
    <div>
      <Row>
        <Col lg={3}>
          <SummaryBox title={t("sumOfSpec")}>
            <h3 className="m-0 p-0">{formatMoney(specsSum)} SEK</h3>
          </SummaryBox>
        </Col>
        <Col lg={3}>
          <SummaryBox title={`${t("saldoForAccount")} ${ledger.number}`}>
            <h3 className="m-0 p-0">{formatMoney(accountBalance)} SEK</h3>
          </SummaryBox>
        </Col>
        <Col lg={3}>
          <SummaryBox title={t("common:money.deviation")}>
            <h3 className="m-0 p-0">{formatMoney(diff)} SEK</h3>
          </SummaryBox>
        </Col>
        <Col lg={3}>
          <SummaryBox title={t("accountIsReconciled")}>
            <h3 className="m-0 p-0 cursor-pointer" onClick={tryReconcile}>
              <i className={currentReconciled ? "far fa-check-square" : "far fa-square"} />
            </h3>
          </SummaryBox>
        </Col>
      </Row>
      {displayWarn && (
        <Row>
          <Col>
            <Alert variant="danger">
              Account is marked as reconciled but after reconciliation - balance had been changed... Fix me.
            </Alert>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default ReconciliationSummary;
