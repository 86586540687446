import React from "react";
import { Outlet } from "react-router";
import { addDays } from "date-fns";
import TTNavBar from "components/ui/navbar/TTNavBar";
import TimeTrackerProvider from "state/providers/TimeTrackerProvider";
import { SetDocTitle } from "components/ui/titles/PageTitle";
import TimeTrackerMenu from "./TimeTrackerMenu";
import { useAuthState } from "../../../hooks/useAuth";
import { FiltersProvider } from "../../../state/providers/FiltersProvider";

function TimeTrackerLayout() {
  return (
    <>
      <SetDocTitle title="TT" />
      <TimeTrackerProvider>
        <div id="time-tracker">
          <TTNavBar />
          <TimeTrackerMenu />
          <div className="content-page">
            <div className="content">
              <div className="container-fluid">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </TimeTrackerProvider>
    </>
  );
}

export function TimeTrackerCommonFilters() {
  const {
    user: { id: userId },
  } = useAuthState();

  const initialFilters = {
    user: userId,
    with_tickets_only: true,
    deadline_date__end: addDays(new Date(), 5),
  };

  return (
    <FiltersProvider initial={initialFilters}>
      <Outlet />
    </FiltersProvider>
  );
}

export default TimeTrackerLayout;
