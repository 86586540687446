import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import * as selectAPI from "api2/selects";
import * as supportApi from "api2/office-support";
import { useAuthState } from "hooks/useAuth";
import useModal from "hooks/useModal";
import OfficeSupportCompanyListLayout from "components/layouts/OfficeSupportCompanyListLayout";
import { SelectSimpleFilter } from "components/filters";
import { KYCModal } from "components/modals/OfficeSupportModal";
import { codeInList, codesFor } from "components/perms";
import { Loader } from "components/ui/loaders";
import { allowedFileExtensionsFor } from "utils/file";
import "./OfficeSupportKYC.scss";

function OfficeSupportKYCPage() {
  const { user } = useAuthState();
  const { t } = useTranslation("common");
  const fileRef = useRef(null);
  const [kycData, setKycData] = useState(undefined);
  const [loadingAgency, setLoadingAgency] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [showToBeRemoved, setShowToBeRemoved] = useState(true);
  const [showToBeAdded, setShowToBeAdded] = useState(true);
  const [showMissing, setShowMissing] = useState(true);
  const [agencies, setAgencies] = useState([]);
  const [agency, setAgency] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [withReportDate, setWithReportDate] = useState(true);
  const modal = useModal();

  const handleModalClose = () => {
    modal.close();
  };

  useEffect(() => {
    if (user.is_superuser) {
      setLoadingAgency(true);
      selectAPI.agencies().then((agenciesOptions) => {
        setAgencies(agenciesOptions);
        setLoadingAgency(false);
      });
    } else {
      const agenciesData = user.agencies.filter((item) =>
        codeInList(codesFor.agency.officeSupervisior, item.perm_codes)
      );
      if (agenciesData.length === 1) {
        setAgency(agenciesData[0].agency);
      }
      setAgencies(agenciesData.map((item) => ({ value: item.agency, label: item.agency_name })));
      setLoadingAgency(false);
    }
  }, [user, user.agencies]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setKycData(undefined);
        setLoadingData(true);
        const response = await supportApi.kyc.getData(agency);
        setKycData(_.isEmpty(response.data) ? undefined : response.data);
        setLoadingData(false);
      } catch (error) {
        setKycData(undefined);
        setLoadingData(false);
      }
    };
    if (agency) {
      fetchData();
    } else {
      setKycData(undefined);
    }
  }, [agency]);

  function onChangeAgency(event) {
    setAgency(event.agency);
  }

  function onFileSelected(event) {
    event.preventDefault();
    const selectedFile = (event.target.files || event.dataTransfer.files)[0];
    if (selectedFile) {
      const extension = `.${selectedFile.name.split(".").pop().toLowerCase()}`;
      if (!allowedFileExtensionsFor.kyc.includes(extension)) {
        // allowed extension...
        toast.error(t("common:file.notSupported", { formats: allowedFileExtensionsFor.kyc }), {
          autoClose: 4000,
        });
        fileRef.current.value = null;
      } else if (!agency) {
        toast.error(t("common:selectAgency"), {
          autoClose: 4000,
        });
        fileRef.current.value = null;
      } else {
        setKycData(undefined);
        setLoadingData(true);
        supportApi.kyc
          .uploadFile(agency, selectedFile)
          .then((response) => {
            toast.success(t("msg:uploaded"), { autoClose: 2000 });
            setKycData(response.data);
            setLoadingData(false);
          })
          .catch((error) => {
            if (_.isObject(error.data)) {
              _.forEach(error.data, function (value, key) {
                toast.error(value, { autoClose: 4000 });
              });
            }
            setLoadingData(false);
          });
      }
    }
  }

  return (
    <OfficeSupportCompanyListLayout>
      <Card>
        <Card.Body>
          {loadingAgency ? (
            <Loader />
          ) : (
            <Row>
              {agencies.length > 0 ? (
                <>
                  <Col lg={4}>
                    {agencies.length > 1 && (
                      <AgencyFilter
                        name="agency"
                        options={agencies}
                        onFilter={(event) => onChangeAgency(event)}
                        placeholder={t("common:agency")}
                        isClearable={false}
                      />
                    )}
                    {agencies.length === 1 && (
                      <h3>
                        <b>{agencies[0].label}</b>
                      </h3>
                    )}
                  </Col>
                  <Col lg={8}>
                    <div className="upload-btn float-end">
                      <Button
                        variant="primary"
                        disabled={!agency || loadingData}
                        onClick={() => fileRef.current.click()}
                      >
                        {t("officeSupport:uploadNewFileFromVerified")}
                      </Button>
                      <input ref={fileRef} type="file" onChange={onFileSelected} />
                      {kycData && (
                        <p>
                          {t("lastUpdated")} {kycData.timestamp} {t("by")} {kycData.user}
                        </p>
                      )}
                    </div>
                  </Col>
                </>
              ) : (
                <Col>
                  <p>
                    <b>{t("common:missingAccess")}</b>
                  </p>
                </Col>
              )}
            </Row>
          )}
          {loadingData && <Loader />}
          {kycData && (
            <Row>
              <Col lg={7}>
                <div>
                  <b>{t("officeSupport:step1DifferencesInCustomerRegister")}</b>
                  <p>{t("officeSupport:pleaseCorrectAndReUploadFromVerified")}</p>
                </div>
                <Table bordered responsive size="sm" name="kyc-table" className="office-support-export-table">
                  <tbody>
                    <tr className="main-section">
                      <td>
                        <div className="form-check">
                          <i
                            onClick={() => {
                              setShowToBeRemoved(!showToBeRemoved);
                            }}
                            className={
                              showToBeRemoved ? "chevron fas fa-chevron-up fa-1x" : "chevron fas fa-chevron-down fa-1x"
                            }
                          />
                          {t("officeSupport:clientsToBeRemovedInVerified")}
                        </div>
                      </td>
                    </tr>
                    {showToBeRemoved && (
                      <tr>
                        <td>
                          <CustomersTable tableId="to-be-removed" customers={kycData.customers_to_be_removed} />
                        </td>
                      </tr>
                    )}
                    <tr className="main-section">
                      <td>
                        <div className="form-check">
                          <i
                            onClick={() => {
                              setShowToBeAdded(!showToBeAdded);
                            }}
                            className={
                              showToBeAdded ? "chevron fas fa-chevron-up fa-1x" : "chevron fas fa-chevron-down fa-1x"
                            }
                          />
                          {t("officeSupport:clientsToBeAddedInVerified")}
                        </div>
                      </td>
                    </tr>
                    {showToBeAdded && (
                      <tr>
                        <td>
                          <CustomersTable tableId="to-be-added" customers={kycData.customers_to_be_added} />
                        </td>
                      </tr>
                    )}
                    <tr className="main-section">
                      <td>
                        <div className="form-check">
                          <i
                            onClick={() => {
                              setShowMissing(!showMissing);
                            }}
                            className={
                              showMissing ? "chevron fas fa-chevron-up fa-1x" : "chevron fas fa-chevron-down fa-1x"
                            }
                          />
                          {t("officeSupport:clientsMissingInBflow")}
                        </div>
                      </td>
                    </tr>
                    {showMissing && (
                      <tr>
                        <td>
                          <CustomersTable tableId="missing-customers" customers={kycData.customers_missing} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
              <Col lg={5}>
                <div>
                  <b>{t("officeSupport:step2Statistics")}</b>
                  <p className="expired-report">{t("officeSupport:noteAboutNumbers")}</p>
                </div>
                <Table bordered responsive size="sm" name="statuses-table" className="office-support-export-table">
                  <tbody>
                    <tr className="main-section">
                      <td colSpan={3}>{t("officeSupport:statusInVerified")}</td>
                    </tr>
                    {Object.entries(kycData.kyc_statuses).map(([status, customers], i) => (
                      <tr key={`kyc-${i}`}>
                        <td>{status}</td>
                        <td className="wp-100">{customers.length}</td>
                        <td className="wp-50">
                          <i
                            className="expand fas fa-external-link-alt"
                            onClick={() => {
                              setModalTitle(status);
                              setModalData(customers);
                              setWithReportDate(false);
                              modal.open();
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="text-end">
                        <b>{t("total")}</b>
                      </td>
                      <td className="wp-100">
                        <b>{kycData.kyc_total}</b>
                      </td>
                      <td className="wp-50" />
                    </tr>
                  </tbody>
                </Table>
                <Table bordered responsive size="sm" name="expired-table" className="office-support-export-table">
                  <tbody>
                    <tr className="main-section">
                      <td colSpan={3}>{t("officeSupport:clientsNeededUpdatedSoon")}</td>
                    </tr>
                    <tr>
                      <td>{t("expired")}</td>
                      <td className="wp-100 expired-report">{kycData.date_reports.expired.length}</td>
                      <td className="wp-50">
                        <i
                          className="expand fas fa-external-link-alt"
                          onClick={() => {
                            setModalTitle(t("expired"));
                            setModalData(kycData.date_reports.expired);
                            setWithReportDate(true);
                            modal.open();
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{t("withinOneMonth")}</td>
                      <td className="wp-100">{kycData.date_reports.one_month_to_expired.length}</td>
                      <td className="wp-50">
                        <i
                          className="expand fas fa-external-link-alt"
                          onClick={() => {
                            setModalTitle(t("withinOneMonth"));
                            setModalData(kycData.date_reports.one_month_to_expired);
                            setWithReportDate(true);
                            modal.open();
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">
                        <b>{t("total")}</b>
                      </td>
                      <td className="wp-100">
                        <b>{kycData.date_total}</b>
                      </td>
                      <td className="wp-50" />
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
      {modal.show && (
        <KYCModal
          title={modalTitle}
          modalData={modalData}
          handleClose={handleModalClose}
          withReportDate={withReportDate}
        />
      )}
    </OfficeSupportCompanyListLayout>
  );
}

function CustomersTable({ tableId, customers }) {
  const { t } = useTranslation("common");

  const initialHeaders = useMemo(
    () => [
      {
        field: "number",
        label: t("officeSupport:customerIdentity"),
        canSort: true,
        width: "150px",
        render: (values) => values.number,
      },
      {
        field: "name",
        label: t("company:customerName"),
        canSort: true,
        width: "auto",
        render: (values) => values.name,
      },
      {
        field: "manager",
        label: t("common:customerManager"),
        canSort: true,
        width: "auto",
        render: (values) => values.manager,
      },
      {
        field: "status",
        label: t("common:statusBflow"),
        canSort: true,
        width: "120px",
        render: (values) => values.status,
      },
      {
        field: "is_in_verified",
        label: t("officeSupport:isInVerified"),
        canSort: true,
        width: "120px",
        render: (values) => values.is_in_verified,
      },
    ],
    [t]
  );

  const [headers, setHeaders] = useState(
    initialHeaders.map((header) => ({
      isSorted: false,
      isSortedDesc: false,
      ...header,
    }))
  );

  const [data, setData] = useState(customers.map((x) => x));

  function onSortPress(field, index) {
    let isSorted = false;
    let isSortedDesc = false;
    setHeaders(
      headers.map((header, hi) => {
        if (index === hi) {
          isSorted = !header.isSorted || header.isSortedDesc;
          isSortedDesc = isSorted && !header.isSortedDesc;
          return {
            ...header,
            isSorted,
            isSortedDesc,
          };
        }
        return { ...header, isSorted: false, isSortedDesc: undefined };
      })
    );
    const originalData = customers.map((x) => x);
    const sortDirection = isSortedDesc ? -1 : 1;
    setData(
      isSorted ? originalData.sort((a, b) => (a[field] > b[field] ? sortDirection : -sortDirection)) : originalData
    );
  }

  const SortIcon = useCallback(({ header }) => {
    if (header.isSorted) {
      if (header.isSortedDesc) {
        return <i className="fas fa-sort-down" />;
      }
      return <i className="fas fa-sort-up" />;
    }
    return <i className="fas fa-sort" />;
  }, []);

  return (
    <Table bordered>
      <thead>
        <tr>
          {headers.map((header, i) => (
            <th style={{ width: header.width }} key={`${tableId}-header-${i}`}>
              <div className="sortable" onClick={() => onSortPress(header.field, i)}>
                {header.label}
                <SortIcon header={header} />
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((customer, i) => (
          <tr key={`${tableId}-row-${i}`}>
            <td>{customer.number}</td>
            <td>{customer.name}</td>
            <td>{customer.manager}</td>
            <td>{customer.status}</td>
            <td>{customer.is_in_verified}</td>
          </tr>
        ))}
        {!data.length && (
          <tr>
            <td colSpan={headers.length}>{t("common:noResultsFound")}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

function AgencyFilter({ name, options, label, onFilter, placeholder, isClearable = true, defaultValue }) {
  return (
    <SelectSimpleFilter
      name={name}
      label={label}
      placeholder={placeholder}
      options={options}
      defaultValue={defaultValue}
      isClearable={isClearable}
      onFilter={onFilter}
    />
  );
}

export default OfficeSupportKYCPage;
