import React from "react";
import { Card } from "react-bootstrap";

import TableProvider from "components/tables/btable/provider";
import { ContractInvoiceTable } from "components/tables";
import * as ciAPI from "api2/customer-invoices";
import { useOutletContext } from "react-router";

function ContractInvoiceListPage({ tableId = "contractInvoices" }) {
  const company = useOutletContext();
  const initialFilters = {
    mode: "",
  };
  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => ciAPI.contractInvoices.list(company.id, filters, config)}
          initialFilters={initialFilters}
        >
          <ContractInvoiceTable companyId={company.id} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default ContractInvoiceListPage;
