import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Table } from "react-bootstrap";
import { FieldArray, Form, Formik } from "formik";
import { TableGroup } from "components/formik";

import { formatDate, parseDate } from "utils/date";
import * as vatAPI from "api2/vat";
import * as options from "api/options";
import useAsync from "hooks/useAsync";
import { Loader } from "components/ui/loaders";
import { confirmRemove } from "../../../modals/ConfirmModal";

function CompanyVATPeriodWrapper({ companyId }) {
  const { t } = useTranslation(["common", "company"]);
  const getCompanyVATPeriods = useCallback(
    (cancelToken) => vatAPI.vatPeriods.list(companyId, null, cancelToken),
    [companyId]
  );
  const [{ data, loading }] = useAsync(getCompanyVATPeriods, []);
  const vatReportOptions = options.vatReportOptions.asList();

  if (loading) {
    return <Loader />;
  }

  async function onRemove(companyVATPeriod, index, remove) {
    if (companyVATPeriod.id) {
      const answer = await confirmRemove(t("company:confirm.removeVatPeriod"));
      if (answer) {
        vatAPI.vatPeriods
          .remove(companyId, companyVATPeriod.id)
          .then(() => {
            remove(index);
            toast.success(t("msg:deleted"), { autoClose: 2000 });
          })
          .catch((error) => {
            if (error.data.__all__) {
              toast.error(error.data.__all__, { autoClose: 5000 });
            }
          });
      }
    } else {
      remove(index);
    }
  }

  function onSave(companyVATPeriod, index, values, setFieldValue, setFieldError) {
    if (!companyVATPeriod.date_start || !companyVATPeriod.period_type) {
      setFieldError(`companyVATPeriods[${index}].__all__`, t("company:errors.vatPeriods.startDateOrTypeIsMissing"));
    } else {
      vatAPI.vatPeriods
        .create(companyId, {
          period_type: companyVATPeriod.period_type.value,
          date_start: formatDate(companyVATPeriod.date_start),
        })
        .then((response) => {
          setFieldValue(`companyVATPeriods[${index}].id`, response.data.id);
          setFieldValue(`companyVATPeriods[${index}].isModified`, false);
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          }
        });
    }
  }

  const formikProps = {
    initialValues: {
      companyVATPeriods: data.map((companyVATPeriod) => ({
        ...companyVATPeriod,
        date_start: parseDate(companyVATPeriod.date_start),
        period_type: options.vatReportOptions.getOption(companyVATPeriod.period_type),
        isModified: false,
      })),
    },
  };
  return (
    <Formik {...formikProps}>
      {({ values, errors, setFieldValue, setFieldError, dirty }) => {
        const earliestDate = values.companyVATPeriods.reduce((earliest, current) => {
          if (!current.date_start || !current.id) {
            return earliest;
          }
          const currentDate = new Date(current.date_start);
          return earliest === null || currentDate < earliest ? currentDate : earliest;
        }, null);
        return (
          <Form>
            <FieldArray
              name="companyVATPeriods"
              render={(arrayHelpers) => (
                <Table id="companyVATPeriodTable">
                  <thead>
                    <tr>
                      <th style={{ minWidth: 180 }}>{t("common:dates.startDate")}</th>
                      <th style={{ minWidth: 300 }}>{t("company:table.header.vatPeriodType")}</th>
                      <th style={{ minWidth: 105 }} className="th-actions text-end">
                        <Button
                          variant="info"
                          className="mb-1"
                          size="sm"
                          onClick={() =>
                            arrayHelpers.unshift({ date_start: undefined, period_type: null, isModified: true })
                          }
                        >
                          <i className="fas fa-plus" />
                        </Button>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.companyVATPeriods.map((companyVATPeriod, idx) => {
                      const dateIsValid = companyVATPeriod.date_start;
                      const isEarliestDate =
                        dateIsValid && new Date(companyVATPeriod.date_start).getTime() === earliestDate?.getTime();
                      const isDisabled = !!companyVATPeriod.id;
                      return (
                        <React.Fragment key={idx}>
                          <tr>
                            <TableGroup.DatePicker
                              name={`companyVATPeriods[${idx}].date_start`}
                              popperClassName="popper-in-modal"
                              required
                              onChange={() => {
                                setFieldValue(`companyVATPeriods[${idx}].isModified`, true);
                              }}
                              tdProps={{ style: { width: 180, maxWidth: 180 } }}
                              disabled={isDisabled}
                            />
                            <TableGroup.SimpleSelect
                              name={`companyVATPeriods[${idx}].period_type`}
                              popperClassName="popper-in-modal"
                              options={vatReportOptions}
                              required
                              onChange={() => {
                                setFieldValue(`companyVATPeriods[${idx}].isModified`, true);
                              }}
                              isDisabled={isDisabled}
                              classNamePrefix=""
                            />
                            <td className="td-actions text-end">
                              {values.companyVATPeriods[idx].isModified ? (
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() =>
                                    onSave(companyVATPeriod, idx, values, setFieldValue, setFieldError, companyId)
                                  }
                                >
                                  <i className="fas fa-save" />
                                </Button>
                              ) : null}
                              {!isEarliestDate ? (
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => onRemove(companyVATPeriod, idx, arrayHelpers.remove, companyId)}
                                >
                                  <i className="fa fa-trash" />
                                </Button>
                              ) : (
                                <Button variant="danger" size="sm" disabled>
                                  <i className="fa fa-trash" />
                                </Button>
                              )}
                            </td>
                          </tr>
                          <TableGroup.RowErrors errors={errors.companyVATPeriods && errors.companyVATPeriods[idx]} />
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            />
          </Form>
        );
      }}
    </Formik>
  );
}

export default CompanyVATPeriodWrapper;
