import React from "react";
import { Button, ButtonGroup, Card, Table } from "react-bootstrap";
import { Form, Formik } from "formik";
import cx from "classnames";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { FormGroup, TableGroup } from "components/formik";
import * as companyAPI from "api2/companies";
import { formatDate } from "utils/date";

const initial = {
  id: null,
  meeting_date: new Date(),
  checklist: [
    {
      label: "Revenue / profit improvement",
      main: true,
      c1: false,
      c2: false,
      c3: false,
      conclusion: "",
      price: "",
      child: [
        {
          label: "Product / service portfolio analysis",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Pricing strategy",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Sales coaching",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Benchmarking",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Marketing analysis",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Expense analysis analysis",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Debt restructuring",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Controller services",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Budget",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Forecast",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Budget / forecast follow ups",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
      ],
    },
    {
      label: "Cash-flow management",
      main: true,
      c1: false,
      c2: false,
      c3: false,
      conclusion: "",
      price: "",
      child: [
        {
          label: "Accounts receivable management",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Accounts payable management",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Factoring",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Debt restructuring",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Cash flow planning",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Approval routines",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
      ],
    },
    {
      label: "Wealth management",
      main: true,
      c1: false,
      c2: false,
      c3: false,
      conclusion: "",
      price: "",
      child: [
        {
          label: "Tax Minimation",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Pension funds analysis",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Succession / Retirement",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Dividend policy",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Salary planning",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Interests",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Rental agreements",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "K10",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
      ],
    },
    {
      label: "Human Resources",
      main: true,
      c1: false,
      c2: false,
      c3: false,
      conclusion: "",
      price: "",
      child: [
        {
          label: "Employment contracts",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Legal issues",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Personal / group coaching",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Option programs",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Salary comparisons",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Employee Handbooks",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Change management",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
      ],
    },
    {
      label: "Personal development",
      main: true,
      c1: false,
      c2: false,
      c3: false,
      conclusion: "",
      price: "",
      child: [
        {
          label: "Personal coaching",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Financial / Business training",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
      ],
    },

    {
      label: "Digitalisation",
      main: true,
      c1: false,
      c2: false,
      c3: false,
      conclusion: "",
      price: "",
      child: [
        {
          label: "Web-shop development",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Digitalisation of existing processes",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
      ],
    },
    {
      label: "Crisis management",
      main: true,
      c1: false,
      c2: false,
      c3: false,
      conclusion: "",
      price: "",
      child: [
        {
          label: "Control Balance Sheet",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Tax audit follow up",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Debt renegotiations",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
      ],
    },

    {
      label: "Future Business",
      main: true,
      c1: false,
      c2: false,
      c3: false,
      conclusion: "",
      price: "",
      child: [
        {
          label: "Business planning",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Venture Capital",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Due Diligence",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Management board",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
      ],
    },
    {
      label: "Corporate secreterial",
      main: true,
      c1: false,
      c2: false,
      c3: false,
      conclusion: "",
      price: "",
      child: [
        {
          label: "Share holders register",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Admin control",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Protocols",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
      ],
    },
    {
      label: "Administrative services",
      main: true,
      c1: false,
      c2: false,
      c3: false,
      conclusion: "",
      price: "",
      child: [
        {
          label: "Updating client base",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Digitalisation help",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Marketing assistance",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
      ],
    },
    {
      label: "Asset protection",
      main: true,
      c1: false,
      c2: false,
      c3: false,
      conclusion: "",
      price: "",
      child: [
        {
          label: "IP- rights",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Patents",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
        {
          label: "Trademarks",
          c1: false,
          c2: false,
          c3: false,
          conclusion: "",
          price: "",
        },
      ],
    },
  ],
};

function SilverbackForm({ companyId, data, onSave }) {
  const { t } = useTranslation("common");
  const formikProps = {
    initialValues: data || initial,
    onSubmit: async (values) => {
      return companyAPI.silverback
        .save(companyId, {
          ...values,
          checklist: [
            ...values.checklist.map((check) => {
              return {
                ...check,
                child: check.child.filter((subCheck) => !!subCheck.label.trim()),
              };
            }),
          ],
          meeting_date: formatDate(values.meeting_date),
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (onSave) {
            onSave();
          }
        });
    },
  };

  function addCustom(check, i, setFieldValue) {
    check.child.push({
      label: "",
      c1: false,
      c2: false,
      c3: false,
      conclusion: "",
      price: "",
      custom: true,
    });
    setFieldValue(`checklist[${i}]`, check);
  }

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, setFieldValue, handleSubmit, errors }) => {
        return (
          <Form id="silverback-form">
            <Card>
              <Card.Body>
                <div className="toolbar">
                  <div className="">
                    <FormGroup.DatePicker name="meeting_date" required label="Meeting date" />
                  </div>
                  <div>
                    <label className="form-label mb-0">Actions</label>
                    <ButtonGroup className="d-block">
                      <Button type="submit" variant="toggle-green">
                        Save
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>

                <Table className="sticky-header">
                  <thead>
                    <tr>
                      <th />
                      <th className="text-center">N/A</th>
                      <th className="text-center">Disc</th>
                      <th className="text-center">Deal</th>
                      <th>Agreed conclusion</th>
                      <th>Agreed price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.checklist.map((check, i) => (
                      <React.Fragment key={i}>
                        <tr>
                          <th className={cx({ main: check.main })}>
                            {check.label}
                            <Button
                              size="xs"
                              className="float-end"
                              variant="secondary"
                              onClick={() => addCustom(check, i, setFieldValue)}
                            >
                              +
                            </Button>
                          </th>
                          <TableGroup.Checkbox name={`checklist[${i}].c1`} label="" />
                          <TableGroup.Checkbox name={`checklist[${i}].c2`} label="" />
                          <TableGroup.Checkbox name={`checklist[${i}].c3`} label="" />
                          <TableGroup.Input name={`checklist[${i}].conclusion`} />
                          <TableGroup.Input name={`checklist[${i}].price`} />
                        </tr>
                        {check.child.map((subCheck, k) => (
                          <tr key={`s.${k}`}>
                            {subCheck.custom ? (
                              <TableGroup.Input name={`checklist[${i}].child[${k}].label`} />
                            ) : (
                              <th>{subCheck.label}</th>
                            )}
                            <TableGroup.Checkbox name={`checklist[${i}].child[${k}].c1`} label="" />
                            <TableGroup.Checkbox name={`checklist[${i}].child[${k}].c2`} label="" />
                            <TableGroup.Checkbox name={`checklist[${i}].child[${k}].c3`} label="" />
                            <TableGroup.Input name={`checklist[${i}].child[${k}].conclusion`} />
                            <TableGroup.Input name={`checklist[${i}].child[${k}].price`} />
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SilverbackForm;
