import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router";

import TableProvider from "components/tables/btable/provider";
import { FinancialYearExtendedTable } from "components/tables";
import * as companyAPI from "api2/companies";
import { FinancialYearAddWidget } from "components/forms/company-settings-forms/FinancialYearForm";
import { CodeRequiredInList } from "components/perms";
import { codesForFinancialYears } from "components/perms/PermCodes";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";

function FinancialYearListPage({ tableId = "financialYears" }) {
  const { companyId } = useParams();
  const { userPermCodes } = useContext(CompanySettingStateContext);
  const initialFilters = {};

  return (
    <TableProvider
      tableId={tableId}
      dataSource={(filters, config) => companyAPI.financialYears.list(companyId, filters, config)}
      initialFilters={initialFilters}
    >
      <Card>
        <Card.Body>
          <FinancialYearExtendedTable companyId={companyId} userPermCodes={userPermCodes} />
        </Card.Body>
      </Card>
      <CodeRequiredInList code={codesForFinancialYears.manage} codeList={userPermCodes}>
        <FinancialYearAddWidget companyId={companyId} userPermCodes={userPermCodes} />
      </CodeRequiredInList>
    </TableProvider>
  );
}

export default FinancialYearListPage;
