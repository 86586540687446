import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router";

import { ManagementOverviewTable } from "components/tables";
import TableProvider from "components/tables/btable/provider";
import * as tableAPI from "api/table";
import { useCompanyDispatch } from "hooks/useCompany";

function OverviewPage({ t }) {
  const { selectCompany: selectCompanyAction } = useCompanyDispatch();
  const navigate = useNavigate();

  const selectCompany = async (companyId, nextRoute = "/") => {
    await selectCompanyAction(companyId);
    navigate(nextRoute, { replace: true });
  };

  return (
    <Card id="company-manage-table">
      <Card.Body>
        <TableProvider tableId="ongoing" initialFilters={{ favourites: true }} dataSource={tableAPI.manageOngoing}>
          <ManagementOverviewTable selectCompany={selectCompany} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default OverviewPage;
