import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import _ from "lodash";

function UpToDateToggleButton({ onClickEvent, checked = false, disabled = true, tooltipText = "" }) {
  const { t } = useTranslation("company");

  return (
    <div className="float-end up-to-date-container">
      {t("upToDate")}
      <OverlayTrigger
        trigger={disabled ? ["hover", "click"] : []}
        placement="bottom"
        overlay={<Tooltip id={String(_.uniqueId("tooltip_"))}>{tooltipText}</Tooltip>}
      >
        <Button
          className={cx("up-to-date-toogle", checked && "checked")}
          variant="secondary"
          size="sm"
          disabled={disabled}
          onClick={() => onClickEvent()}
        >
          <div className="circle">{checked ? <i className="fe-check" /> : <i className="fe-x" />}</div>
        </Button>
      </OverlayTrigger>
    </div>
  );
}

export { UpToDateToggleButton };
