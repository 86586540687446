import React, { useContext, useMemo, useState } from "react";
import { Button, ButtonGroup, Card, Table } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import cx from "classnames";
import _ from "lodash";
import { Loader } from "components/ui/loaders";
import { FilterButton } from "components/ui/buttons";
import {
  MonthDateFilter,
  MultiCompanyConsultFilter,
  MultiConsultFilter,
  MultiSelectSimpleFilter,
  SearchFilter,
} from "components/filters";
import useModal from "hooks/useModal";
import { StatusListStateContext } from "state/providers/StatusListProvider";
import { truncateText } from "utils/text";
import { statusListMainStatuses } from "api/options";
import EventSelect from "./EventSelect";
import "./StatusListPage.scss";
import { prepareData } from "./helpers";
import EventDetailModal from "./EventDetailModal";
import CompanyEventOverviewModal from "./CompanyEventOverviewModal/CompanyEventOverviewModal";
import { formatMonth } from "../../../utils/date";

function ViewSwitch() {
  const { filters, setFilters } = useContext(StatusListStateContext);
  const { t } = useTranslation("officeSupport");
  const isForAnnual = filters.section === "yearly";
  const { view } = filters;
  const update = (newValue) => {
    setFilters({ ...filters, view: newValue });
  };
  if (isForAnnual) {
    return (
      <ButtonGroup>
        <Button variant="switch" active={view === "rolling12"} onClick={() => update("rolling12")}>
          {t("view.rolling12")}
        </Button>
        <Button variant="switch" active={view === "year_end"} onClick={() => update("year_end")}>
          {t("view.year_end")}
        </Button>
        <Button variant="switch" active={view === "delivery"} onClick={() => update("delivery")}>
          {t("view.delivery")}
        </Button>
      </ButtonGroup>
    );
  }
  return (
    <ButtonGroup>
      <Button variant="switch" active={view === "delivery"} onClick={() => update("delivery")}>
        {t("view.delivery")}
      </Button>
      <Button variant="switch" active={view === "period"} onClick={() => update("period")}>
        {t("view.period")}
      </Button>
    </ButtonGroup>
  );
}

function CondensedSwitch() {
  const { options, setOptions } = useContext(StatusListStateContext);
  return (
    <Button
      variant="toggle"
      active={options.extended}
      onClick={() => {
        setOptions({ ...options, extended: !options.extended });
      }}
    >
      <i className="fe-menu" style={{ fontWeight: 500 }} />
    </Button>
  );
}

function Filters({ filters, setFilters }) {
  const { t } = useTranslation("common");
  const filtersCount =
    0 +
    (filters.assignees ? !!filters.assignees.length : 0) +
    (filters.customer_managers ? !!filters.customer_managers.length : 0);
  const [more, setMore] = useState(filtersCount);
  const statusesOptions = statusListMainStatuses.asList();
  return (
    <div className="table-filters-group">
      <section className="table-filters-left">
        <SearchFilter
          onFilter={({ term }) => setFilters({ ...filters, term })}
          defaultValue={filters.term}
          label="&nbsp;"
        />
        <FilterButton active={more} count={filtersCount} onClick={() => setMore(!more)} />

        <MonthDateFilter
          name="date_month"
          isClearable={false}
          label={t("dates.month")}
          defaultValue={filters.date_month}
          disabled={filters.view === "rolling12"}
          onChange={(selected) => setFilters({ ...filters, date_month: selected })}
        />
        <div className="form-group">
          <label className="form-label">{t("common:view")}</label>
          <ViewSwitch filters={filters} setFilters={setFilters} />
        </div>
        <div className="form-group">
          <label className="form-label">&nbsp;</label>
          <CondensedSwitch />
        </div>
      </section>
      {more && (
        <section className="more-filters">
          <MultiConsultFilter
            label={t("officeSupport:config.assignee")}
            name="assignees"
            defaultValue={filters.assignees}
            onFilter={({ assignees }) => {
              setFilters({ ...filters, assignees });
            }}
          />
          <MultiCompanyConsultFilter
            label={t("common:customerManager")}
            name="customer_managers"
            onFilter={({ customer_managers }) => {
              setFilters({ ...filters, customer_managers });
            }}
            defaultValue={filters.customer_managers}
          />
          <MultiSelectSimpleFilter
            label={t("common:statuses.status")}
            name="statuses"
            options={statusesOptions}
            defaultValue={filters.statuses}
            onFilter={({ statuses }) => {
              setFilters({ ...filters, statuses });
            }}
          />
        </section>
      )}
    </div>
  );
}

function InvolvedBadges({ involved }) {
  const visible = involved.slice(0, 4);
  const notVisible = involved
    .slice(4)
    .map(({ name }) => name)
    .join(",");
  return (
    <div className="initials">
      {visible.map(({ name, initials }) => (
        <div key={initials} className="initials__badge" title={name}>
          <div>{initials}</div>
        </div>
      ))}
      {involved.length > 4 && (
        <div className="initials__badge text-muted" title={notVisible}>
          <div>+{involved.length - 4}</div>
        </div>
      )}
    </div>
  );
}

function EventDetailBadges({ event }) {
  const detailModal = useModal();
  return (
    <>
      <div className="bottom" onClick={detailModal.open}>
        <div className="d-flex text-end">
          <div className={cx("bottom-badge comment-btn", { "has-comments": event.comments.length })}>
            <div className="dot-indicator" />
            <i className="fe-message-circle" />
          </div>
          {event.activity.note && (
            <div className="bottom-badge">
              <i className="fe-file-text" />
            </div>
          )}
          {event.activity.checklist.length > 0 && (
            <div className="bottom-badge">
              <i className="fe-check-square" />
            </div>
          )}
          <div className="initials__badge">
            <div>{event.assignee_initials}</div>
          </div>
          <div className="bottom-badge bottom-badge-open">
            <i className="fe-arrow-up-right" />
          </div>
        </div>
      </div>
      {detailModal.show && <EventDetailModal event={event} handleClose={detailModal.close} />}
    </>
  );
}

function EventTd({ event, extended }) {
  if (event.disabled) {
    return <td className="event-col disabled" />;
  }

  return (
    <td
      className={cx("event-col", {
        "event-done": event.statusIndicator.index === event.statusIndicator.max,
        "no-comments": !event.comments,
      })}
    >
      <div className="top">
        <EventSelect
          eventId={event.id}
          deadline={event.deadline}
          periodIndicator={event.periodIndicator}
          current={event.statusIndicator.current}
          options={event.statusIndicator.statuses}
          extended={extended}
        />
      </div>
      <EventDetailBadges event={event} />
    </td>
  );
}

function OverviewButton({ enabled, company }) {
  const overviewModal = useModal();
  return (
    <div title={company.name}>
      <Button disabled={!enabled} variant="link" className="company-btn" onClick={overviewModal.open}>
        {truncateText(company.name, 20)}
      </Button>
      {overviewModal.show && (
        <CompanyEventOverviewModal
          companyId={company.id}
          companyName={company.name}
          handleClose={overviewModal.close}
        />
      )}
    </div>
  );
}

function StatusListTable({ overviewEnabled, templates, companies, tableOptions, yearEndColumn }) {
  const { t, i18n } = useTranslation();
  const isEnglish = useMemo(() => i18n.language === "en", [i18n.language]);
  if (!companies.length) {
    return (
      <div className="no-results">
        <Trans i18nKey="officeSupport:statusListNoResults">
          <h3>There are no events matching your filter yet.</h3>
          <h4>
            Please update your filter selection or go to the <a href="/office-support-companies/">Office Support</a> to
            activate status list for each of your clients
          </h4>
        </Trans>
      </div>
    );
  }
  return (
    <div className="sticky-wrapper">
      <Table bordered>
        <thead>
          <tr>
            <th>{t("common:company")}</th>
            {yearEndColumn && <th style={{ width: 100 }}>{t("officeSupport:view.year_end")}</th>}
            {templates.map((tpl) => (
              <th key={tpl.id}>{isEnglish ? tpl.name_en : tpl.name_sv}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {companies.map((company) => (
            <tr key={company.id}>
              <th>
                <div className="d-flex">
                  <OverviewButton enabled={overviewEnabled} company={company} />{" "}
                  <InvolvedBadges involved={company.involved} />
                  <div>
                    <i className="fe-grid" />
                  </div>
                </div>
                <span>{company.org_no}</span>
              </th>
              {yearEndColumn && (
                <td>
                  {company.year_end ? _.startCase(formatMonth(company.year_end, "MMM", i18n.language).slice(0, 3)) : ""}
                </td>
              )}
              {templates.map((tpl) => {
                const event = company.events[tpl.id];
                return <EventTd key={event.id} event={event} extended={tableOptions.extended} />;
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function StatusListPage() {
  const { loading, templates, events, options, filters, setFilters } = useContext(StatusListStateContext);
  const data = useMemo(() => {
    if (loading) {
      return [];
    }
    return prepareData(templates, events, filters.section !== "yearly");
  }, [loading, templates, events, filters.section]);

  return (
    <Card id="status-list-table">
      <Card.Body>
        <Filters filters={filters} setFilters={setFilters} />
        {loading ? (
          <Loader />
        ) : (
          <StatusListTable
            overviewEnabled={filters.section === "ongoing"}
            yearEndColumn={filters.section === "yearly"}
            templates={data.templates}
            companies={data.companies}
            tableOptions={options}
          />
        )}
      </Card.Body>
    </Card>
  );
}

export default StatusListPage;
