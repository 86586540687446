import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";

import useInitialAsync from "hooks/useInitialAsync";
import { RocketLoader } from "components/ui/loaders";
import { ItemNotFoundErrorPage } from "pages/errors";
import * as salaryAPI from "api2/salaries";
import { SavedDocuments } from "components/ui/documents-refactored/documents";
import { NoDocumentBox } from "components/ui/documents/document-box";
import { PreviewInput } from "components/formik/PreviewInput";
import { formatDate } from "utils/date";
import { formatMoney } from "utils/money";

function SalaryPreviewPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const { t } = useTranslation("salary");
  const { id: salaryId } = useParams();
  const { loading, item } = useInitialAsync(
    ({ cancelToken }) => salaryAPI.details(company.id, salaryId, { cancelToken }),
    {}
  );

  if (loading) {
    return <RocketLoader />;
  }

  if (!loading && _.isEmpty(item)) {
    return <ItemNotFoundErrorPage />;
  }

  const salary = {
    ...item,
    booking_date: formatDate(item.booking_date),
    amount: `${formatMoney(item.amount)} ${item.amount_currency}`,
  };
  if (salary.account_id) {
    salary.receiver = `${salary.receiver} (${salary.account_id})`;
  }

  return (
    <Card>
      <Card.Body>
        <SalaryContent company={company} salary={salary} t={t} />
      </Card.Body>
      <Card.Footer>
        <ButtonGroup>
          <Button variant="toggle" onClick={() => navigate(-1)}>
            <i className="fas fa-arrow-left" /> {t("common:actions.goBack")}
          </Button>
        </ButtonGroup>
      </Card.Footer>
    </Card>
  );
}

function SalaryContent({ company, salary, t }) {
  return (
    <Row>
      <Col lg={4} md={12} className="verification-files">
        <SavedDocuments
          companyId={company.id}
          partUrl={`/verifications/${salary.id}/documents`}
          documents={salary.documents}
          removable={false}
        />
        {!salary.documents.length && <NoDocumentBox />}
      </Col>
      <Col lg={8}>
        <Row>
          <Col lg={4}>
            <PreviewInput label={t("common:employee")} value={salary.employee_name} />
          </Col>
          <Col lg={4}>
            <PreviewInput label={t("paidTo")} value={t(`common:account${salary.paid_to}`)} />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <PreviewInput label={t("grossSalary")} value={salary.salary_gross} />
          </Col>
          <Col lg={4}>
            <PreviewInput label={t("fringeBenefits")} value={salary.fringe_benefits} />
          </Col>
          <Col lg={4}>
            <PreviewInput label={t("socSecFees")} value={salary.soc_sec} />
          </Col>
          <Col lg={4}>
            <PreviewInput label={t("persTax")} value={salary.personal_tax} />
          </Col>
          <Col lg={4}>
            <PreviewInput label={t("taxFree")} value={salary.tax_free} />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <PreviewInput label={t("common:money.toBePaid")} value={salary.amount} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SalaryPreviewPage;
