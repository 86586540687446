import React from "react";
import { useNavigate, useOutletContext } from "react-router";

import HolidaySocForm from "components/forms/HolidaySocForm";

function HolidaySocAddPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const item = {
    booking_date: new Date(),
    holiday_allowance: 0,
    soc_sec: 0,
    project: null,
    cost_center: null,
  };

  const onCreate = (newItem) => {
    navigate(-1);
  };

  return <HolidaySocForm companyId={company.id} item={item} onSave={onCreate} />;
}

export default HolidaySocAddPage;
