import React from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";

function ReInvoicedAlert({ invoiceId }) {
  const { t } = useTranslation("ci");
  if (!invoiceId) {
    return null;
  }
  return (
    <Alert variant="primary" className="mb-0">
      <i className="fas fa-exclamation me-2" />
      <span className="me-1">{t("reInvoicedAlert")}</span>
      <Link to={`/invoicing/customer-invoices/${invoiceId}/change`}>{t("goToInvoice")}</Link>
    </Alert>
  );
}

export default ReInvoicedAlert;
