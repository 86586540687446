import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button, Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Form, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import { RemoveButton, SubmitButton } from "components/ui/buttons";
import { AllError, FormGroup } from "components/formik";
import { useCompanyState } from "hooks/useCompany";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import useModal from "hooks/useModal";
import { formatDate, isBefore, parseDate } from "utils/date";
import {
  disallowedAssetAccountsForDepreciationList,
  filterAssetAccountOptions,
  filterAssetCostAccountOptions,
  filterDepreciationAccountOptions,
} from "utils/assets";
import { HasPermCode } from "components/perms";
import { codesForVerifications } from "components/perms/PermCodes";
import { useVerificationDispatch } from "hooks/useVerification";
import * as assetAPI from "api2/assets";
import { parseISO } from "date-fns";
import FileUploadModal from "components/modals/FileUploadModal";
import * as documentAPI from "api/document";
import { handleActionErrors, handleFormErrors } from "api/errors";
import { confirmExecute } from "components/modals/ConfirmModal";
import { NewDocuments } from "components/ui/documents/verification-documents";
import cx from "classnames";
import UnsavedWarn from "components/ui/UnsavedWarn";
import { allowedFileExtensionsFor } from "utils/file";
import {
  AssetUpdatesVerificationsPreviewModal,
  BaseVerificationsPreviewContainer,
  AssetDepreciationsPreviewModal,
} from "components/modals/VerificationPreviewModals";
import {
  depreciationAmountPerMonth,
  depreciationAmountTotal,
  getCountOfAutoVerToCreate,
  onAssetAccountChange,
  onDepreciationMonthsChange,
  onDepreciationStartChange,
  onIsFullyDepreciatedClicked,
  outputBalance,
  yupForAssetForm,
} from "./AssetFormUtils";

const formatPayloadAssetValues = (values) => {
  return {
    ...values,
    is_initially_depreciated_fully: values.is_initially_depreciated_fully,
    project_for_depreciation: values.project_for_depreciation ? values.project_for_depreciation.value : null,
    cost_center_for_depreciation: values.cost_center_for_depreciation
      ? values.cost_center_for_depreciation.value
      : null,
    acquisition_value: values.acquisition_value,
    residual_value: values.residual_value,
    global_depreciation_months: values.global_depreciation_months,
    outside_depreciated_months: values.is_initially_depreciated_fully
      ? values.global_depreciation_months
      : values.outside_depreciated_months,
    outside_depreciated_amount: values.outside_depreciated_amount,
    cost_account: values.cost_account?.value || null,
    asset_account: values.asset_account.value,
    depreciation_account: values.depreciation_account?.value || null,
    global_depreciation_start: formatDate(values.global_depreciation_start),
    acquisition_date: formatDate(values.acquisition_date),
    change_date: values.change_date ? formatDate(values.change_date) : null,
  };
};

function PartialEditableWrapper({
  assetIsPartialEditable,
  partialUpdateFor,
  setPartialUpdateFor,
  forField,
  t,
  colXl = 3,
  isSubmitting,
  resetForm,
  setFieldValue,
  values,
  children,
}) {
  const verPreviewModal = useModal();

  const onCancel = () => {
    resetForm();
    setPartialUpdateFor(null);
  };
  const onAccountsVerPreviewClick = () => {
    verPreviewModal.open({ values });
  };
  const helperTexts = {
    description: "",
    project_and_cc: t("asset:affectOnlyFutureDepreciations"),
    accounts: t("asset:accountsUpdateHelper"),
    residual_value_finished: t("asset:oneDepBookedAfterResidualUpdate"),
    residual_value_ongoing: t("asset:futureDepreciationRecalculated"),
    acquisition_value: t("asset:futureDepreciationRecalculated"),
    global_depreciation_months: t("asset:futureDepreciationRecalculated"),
  };
  if (!assetIsPartialEditable) {
    return children;
  }
  return (
    <>
      <Col
        className={cx("partial-update-col", {
          active: partialUpdateFor === forField,
          "hover-enabled": !partialUpdateFor,
        })}
        xl={colXl}
      >
        {partialUpdateFor === forField &&
          (["accounts", "acquisition_value"].includes(forField) ||
            (values.status === "active_finished" && forField === "residual_value")) && (
            <Row>
              <Col xl={forField === "accounts" ? 4 : 12}>
                <FormGroup.DatePicker name="change_date" label={t("common:dates.date")} required />
              </Col>
            </Row>
          )}
        <Row>
          {children}
          {partialUpdateFor !== forField && (
            <i
              className={cx("fe-edit-2", {
                inactive: !!partialUpdateFor,
              })}
              onClick={() => setPartialUpdateFor(partialUpdateFor ? null : forField)}
            />
          )}
        </Row>
        {partialUpdateFor === forField && (
          <>
            <Row>
              <Col>
                {forField === "residual_value" && (
                  <p>
                    {
                      helperTexts[
                        values.status === "active_finished" ? "residual_value_finished" : "residual_value_ongoing"
                      ]
                    }
                  </p>
                )}
                {forField !== "residual_value" && <p>{helperTexts[forField]}</p>}
              </Col>
            </Row>
            <Row>
              {(partialUpdateFor === "accounts" ||
                (partialUpdateFor === "residual_value" && values.status === "active_finished")) && (
                <Col xl={5}>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>{!values.change_date ? t("ver:fillMandatoryForPreview") : t("ver:previewVer")}</Tooltip>
                    }
                  >
                    <Button disabled={!values.change_date} variant="toggle" onClick={() => onAccountsVerPreviewClick()}>
                      <i className="mdi mdi-file-search-outline" /> {t("ver:verShort")}
                    </Button>
                  </OverlayTrigger>
                </Col>
              )}
              <Col className="text-end">
                <Button variant="light me-1" onClick={() => onCancel()} className="cancel-btn">
                  {t("common:actions.cancel")}
                </Button>
                <SubmitButton isSubmitting={isSubmitting} />
              </Col>
            </Row>
          </>
        )}
      </Col>
      {verPreviewModal.show && (
        <AssetUpdatesVerificationsPreviewModal
          handleClose={verPreviewModal.close}
          data={formatPayloadAssetValues(verPreviewModal.data.values)}
          forField={forField}
        />
      )}
    </>
  );
}

function AssetForm({ companyId, asset, successCallback, deleteCallback, onSuccessUpload }) {
  const { t } = useTranslation("asset");
  const uploadModal = useModal();
  const decommissionAndSellModal = useModal();
  const extraDepreciationModal = useModal();
  const verPreviewModal = useModal();

  const [newFiles, setNewFiles] = useState(asset.newDocuments || [{ key: _.uniqueId("nd.") }]);
  const {
    description,
    cost_center_for_depreciation,
    project_for_depreciation,
    id,
    status,
    is_initially_depreciated_fully,
    output_balance,
    verification_id,
    depreciation_end,
    cancel_verification_id,
  } = asset;

  const {
    accounts: { byId: accountById, asOptions: accountOptions },
    costCenters: { byId: centerOptionsById, asOptions: centerOptions },
    projects: { byId: projectById, asOptions: projectOptions },
  } = useCompanyState();
  const [partialUpdateFor, setPartialUpdateFor] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const deprecationDisallowed = (values) =>
    values?.depreciation_disallowed ?? disallowedAssetAccountsForDepreciationList.includes(asset.asset_account);
  const { open: openVerificationModal } = useVerificationDispatch();
  const canOpenVerModal = HasPermCode(codesForVerifications.enabled);

  const editableStatusesForAsset = ["draft", "material_in_approval"];
  const assetIsEditable = editableStatusesForAsset.includes(status) || !id;
  const assetIsPartialEditable = asset.status === "active_ongoing";
  const possibleDecommissionOrSoldStatusesForAsset = ["active_ongoing", "active_finished"];
  const assetCanBeDecommissionedOrSold = possibleDecommissionOrSoldStatusesForAsset.includes(status);
  const [previewVerMode, setPreviewVerMode] = useState(false);

  const formikProps = {
    enableReinitialize: true,
    initialValues: {
      ...asset,
      is_initially_depreciated_fully: is_initially_depreciated_fully || false,
      description: description || "",
      project_for_depreciation: projectById[project_for_depreciation] || null,
      cost_center_for_depreciation: centerOptionsById[cost_center_for_depreciation] || null,
      depreciation_disallowed: deprecationDisallowed(true, false),
      global_depreciation_months: asset.global_depreciation_months || 0,
      acquisition_value: asset.acquisition_value || 0,
      residual_value: asset.residual_value || 0,
      cost_account: accountById[asset.cost_account] || null,
      asset_account: accountById[asset.asset_account] || null,
      depreciation_account: accountById[asset.depreciation_account] || null,
      acquisition_date: asset.acquisition_date ? parseDate(asset.acquisition_date) : null,
      latest_depreciation_date: asset.latest_depreciation_date ? parseDate(asset.latest_depreciation_date) : null,
      global_depreciation_start: asset.global_depreciation_start ? parseDate(asset.global_depreciation_start) : null,
      depreciation_end: depreciation_end ? parseDate(asset.depreciation_end) : null,
      outside_depreciated_months: asset.outside_depreciated_months || 0,
      outside_depreciated_amount: asset.outside_depreciated_amount || 0,
      change_date: null,
      depreciation_amount_total: 0, // needed for keep UpdateChecker works after creation
      depreciation_amount_per_months: 0, // needed for keep UpdateChecker works after creation
      output_balance: 0, // needed for keep UpdateChecker works after creation
    },
    validationSchema: yupForAssetForm(assetIsEditable, partialUpdateFor),
    onSubmit: async (values, { setErrors, resetForm }) => {
      const countOfAutoVerToCreate = getCountOfAutoVerToCreate(values);
      const verificationPreviewOnly = previewVerMode;
      setPreviewVerMode(false);

      if (!verificationPreviewOnly) {
        if (countOfAutoVerToCreate > 0 && !asset.id) {
          const confirmed = await confirmExecute(
            t("warning.versToBeCreatedAfterAssetCreation", { countOfAutoVerToCreate })
          );
          if (!confirmed) {
            return;
          }
        }
        await assetAPI
          .save(companyId, formatPayloadAssetValues(values), partialUpdateFor)
          .then(async (response) => {
            toast.success(t("msg:saved"), { autoClose: 2000 });
            resetForm();
            if (!asset.id) {
              const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
              if (_newFiles.length) {
                await assetAPI.uploadAsset(companyId, response.data.id, _newFiles).catch(() => {
                  // pass silently
                });
                setNewFiles([{ key: _.uniqueId("nd.") }]);
              }
            }
            if (successCallback) {
              successCallback();
            }
          })
          .catch((error) => {
            handleFormErrors(error, setErrors);
          });
      } else {
        await assetAPI
          .depreciationsPreview(companyId, formatPayloadAssetValues(values))
          .then(async (response) => {
            verPreviewModal.open(response.data);
          })
          .catch((error) => {
            setErrors(error.data);
            toast.error(t("msg:fixErrors"));
          });
      }
    },
  };
  const handleNewDocumentChange = (file, index) => {
    documentAPI.onNewFileChange({
      file,
      index,
      allFiles: newFiles,
      setFile: setNewFiles,
    });
  };

  const onUpload = async (files) => {
    await assetAPI
      .uploadAsset(companyId, asset.id, [files][0])
      .then((response) => {
        if (onSuccessUpload) {
          onSuccessUpload(response.data);
        }
      })
      .catch((error) => {
        handleActionErrors(error);
      });
  };

  const activeCenter = centerOptions.filter(filterActiveCC);
  const onDelete = async () => {
    assetAPI
      .remove(companyId, asset.id)
      .then(() => {
        toast.success(t("msg:deleted"));
        if (deleteCallback) {
          deleteCallback();
        }
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__, { autoClose: 4000 });
        } else {
          toast.error(t("msg:canNotExecuteAction"));
        }
      });
  };
  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      setEditMode(dirty || newFiles.length > 1);
    }, [dirty]);
    return null;
  }
  return (
    <Formik
      {...formikProps}
      key={id ? `formik_${id}` : "formik_creation"}
      id={id ? `formik_${asset.id}` : "formik_creation"}
    >
      {({ values, errors, isSubmitting, setFieldValue, resetForm }) => {
        const activeProjects = filterActiveProjects(
          projectOptions,
          values.global_depreciation_start,
          values.depreciation_end
        );
        return (
          <>
            <Form
              className="inventory-register asset-form"
              noValidate
              key={id ? `form_${id}` : "form_creation"}
              id={id ? `form_${asset.id}` : "form_creation"}
            >
              <FormUpdateChecker />
              <UnsavedWarn isUnsaved={editMode} />
              <Row>
                <PartialEditableWrapper
                  assetIsPartialEditable={["active_ongoing", "active_finished", "inactive"].includes(asset.status)}
                  partialUpdateFor={partialUpdateFor}
                  setPartialUpdateFor={setPartialUpdateFor}
                  forField="description"
                  t={t}
                  colXl={3}
                  isSubmitting={isSubmitting}
                  resetForm={resetForm}
                  setFieldValue={setFieldValue}
                  values={values}
                >
                  <Col>
                    <FormGroup.Input
                      label={t("common:description")}
                      name="description"
                      required
                      disabled={!assetIsEditable && partialUpdateFor !== "description"}
                    />
                  </Col>
                </PartialEditableWrapper>
                <PartialEditableWrapper
                  assetIsPartialEditable={["active_ongoing", "active_finished"].includes(asset.status)}
                  partialUpdateFor={partialUpdateFor}
                  setPartialUpdateFor={setPartialUpdateFor}
                  forField="accounts"
                  t={t}
                  colXl={9}
                  isSubmitting={isSubmitting}
                  resetForm={resetForm}
                  setFieldValue={setFieldValue}
                  values={values}
                >
                  <Col>
                    <FormGroup.SimpleSelect
                      options={accountOptions.filter(filterAssetAccountOptions)}
                      name="asset_account"
                      label={t("asset:assetAccount")}
                      disabled={!assetIsEditable && partialUpdateFor !== "accounts"}
                      onChange={(selected) => onAssetAccountChange(selected, setFieldValue, values, accountById, t)}
                      required
                      filterOptionStartsWith
                    />
                  </Col>
                  <Col>
                    <FormGroup.SimpleSelect
                      options={accountOptions.filter(filterDepreciationAccountOptions)}
                      name="depreciation_account"
                      label={t("depreciationAccount")}
                      disabled
                      required={!deprecationDisallowed(values)}
                      filterOptionStartsWith
                    />
                  </Col>
                  <Col>
                    <FormGroup.SimpleSelect
                      options={accountOptions.filter(filterAssetCostAccountOptions)}
                      name="cost_account"
                      label={t("common:costAccount")}
                      disabled={(!assetIsEditable && partialUpdateFor !== "accounts") || deprecationDisallowed(values)}
                      required={!(deprecationDisallowed(values) || values.is_initially_depreciated_fully)}
                      filterOptionStartsWith
                    />
                  </Col>
                </PartialEditableWrapper>
              </Row>
              <hr className="mt-1 mb-1" />
              <Row>
                <PartialEditableWrapper
                  assetIsPartialEditable={assetIsPartialEditable}
                  partialUpdateFor={partialUpdateFor}
                  setPartialUpdateFor={setPartialUpdateFor}
                  forField="acquisition_value"
                  t={t}
                  colXl={3}
                  isSubmitting={isSubmitting}
                  resetForm={resetForm}
                  setFieldValue={setFieldValue}
                  values={values}
                >
                  <Col>
                    <FormGroup.MoneyInput
                      name="acquisition_value"
                      label={t("acquisitionValue")}
                      required
                      disabled={!assetIsEditable && partialUpdateFor !== "acquisition_value"}
                    />
                  </Col>
                </PartialEditableWrapper>
                <Col xl={3}>
                  <FormGroup.DatePicker
                    name="acquisition_date"
                    label={t("acquisitionDate")}
                    required
                    disabled={!assetIsEditable}
                  />
                </Col>
                <PartialEditableWrapper
                  assetIsPartialEditable={
                    ["active_ongoing", "active_finished"].includes(asset.status) && !deprecationDisallowed(values)
                  }
                  partialUpdateFor={partialUpdateFor}
                  setPartialUpdateFor={setPartialUpdateFor}
                  forField="residual_value"
                  t={t}
                  colXl={3}
                  isSubmitting={isSubmitting}
                  resetForm={resetForm}
                  setFieldValue={setFieldValue}
                  values={values}
                >
                  <Col>
                    {!deprecationDisallowed(values) ? (
                      <FormGroup.MoneyInput
                        name="residual_value"
                        label={t("residualValue")}
                        required
                        disabled={!assetIsEditable && partialUpdateFor !== "residual_value"}
                      />
                    ) : (
                      <FormGroup.MoneyInput
                        name="residual_value"
                        label={t("residualValue")}
                        required={false}
                        disabled
                        value={values.acquisition_value}
                      />
                    )}
                  </Col>
                </PartialEditableWrapper>
                <Col xl={3}>
                  {assetIsEditable && (
                    <FormGroup.LabeledCheckbox
                      key={
                        id ? `is_initially_depreciated_fully_${asset.id}` : "is_initially_depreciated_fully_creation"
                      }
                      id={id ? `is_initially_depreciated_fully_${asset.id}` : "is_initially_depreciated_fully_creation"}
                      name="is_initially_depreciated_fully"
                      label={t("alreadyFullyDeprecated")}
                      required={false}
                      disabled={!assetIsEditable || deprecationDisallowed(values)}
                      onClick={() => onIsFullyDepreciatedClicked(setFieldValue, values)}
                      checked={values.is_initially_depreciated_fully}
                    />
                  )}
                </Col>
              </Row>
              <hr className="mt-1 mb-1" />
              <Row>
                <PartialEditableWrapper
                  assetIsPartialEditable={assetIsPartialEditable}
                  partialUpdateFor={partialUpdateFor}
                  setPartialUpdateFor={setPartialUpdateFor}
                  forField="global_depreciation_months"
                  t={t}
                  colXl={3}
                  isSubmitting={isSubmitting}
                  resetForm={resetForm}
                  setFieldValue={setFieldValue}
                  values={values}
                >
                  <Col>
                    {deprecationDisallowed(values) ? (
                      <FormGroup.Input
                        type="number"
                        label={t("depreciationPeriod")}
                        name="global_depreciation_months_fake"
                        disabled
                        required={false}
                        value={0}
                      />
                    ) : (
                      <FormGroup.Input
                        type="number"
                        label={t("depreciationPeriod")}
                        name="global_depreciation_months"
                        disabled={!assetIsEditable && partialUpdateFor !== "global_depreciation_months"}
                        onChange={(e) => onDepreciationMonthsChange(e.currentTarget.value, setFieldValue, values)}
                        required={!deprecationDisallowed(values)}
                      />
                    )}
                  </Col>
                </PartialEditableWrapper>
                <Col xl={3}>
                  {deprecationDisallowed(values) ? (
                    <FormGroup.DatePicker
                      name="global_depreciation_start_fake"
                      label={t("depreciationStart")}
                      disabled
                      required={false}
                      helpText={t("helpDateStart")}
                    />
                  ) : (
                    <FormGroup.DatePicker
                      name="global_depreciation_start"
                      label={t("depreciationStart")}
                      required
                      disabled={!assetIsEditable}
                      onChange={(selected) => onDepreciationStartChange(selected, setFieldValue, values)}
                      helpText={t("helpDateStart")}
                    />
                  )}
                </Col>
                <Col xl={3}>
                  {deprecationDisallowed(values) ? (
                    <FormGroup.DatePicker name="depreciation_end_fake" label={t("depreciationEnd")} disabled />
                  ) : (
                    <FormGroup.DatePicker name="depreciation_end" label={t("depreciationEnd")} disabled />
                  )}
                </Col>
                <Col xl={3}>
                  <FormGroup.MoneyInput
                    name="depreciation_amount_total"
                    label={t("depreciationAmountTotal")}
                    disabled
                    required={false}
                    value={depreciationAmountTotal(values)}
                  />
                </Col>
                <Col xl={3}>
                  <FormGroup.MoneyInput
                    name="depreciation_amount_per_months"
                    label={t("depreciationAmountPerMonth")}
                    disabled
                    required={false}
                    value={depreciationAmountPerMonth(values, values.is_initially_depreciated_fully)}
                  />
                </Col>
                <Col xl={3}>
                  {assetIsEditable && !values.is_initially_depreciated_fully && (
                    <FormGroup.Input
                      type="number"
                      label={t("executedMonths")}
                      name="outside_depreciated_months"
                      required={!deprecationDisallowed(values)}
                      disabled={deprecationDisallowed(values)}
                    />
                  )}
                  {assetIsEditable && !!values.is_initially_depreciated_fully && (
                    <FormGroup.Input
                      type="number"
                      label={t("executedMonths")}
                      name="outside_depreciated_months"
                      disabled
                      required={false}
                      value={values.global_depreciation_months}
                    />
                  )}
                  {!assetIsEditable && (
                    <FormGroup.Input
                      type="number"
                      label={t("executedMonths")}
                      name="depreciated_months"
                      disabled
                      required={false}
                    />
                  )}
                </Col>
                <Col xl={3}>
                  {assetIsEditable && !values.is_initially_depreciated_fully && (
                    <FormGroup.MoneyInput
                      name="outside_depreciated_amount"
                      label={t("accumulatedAmountOfDepreciation")}
                      required={!deprecationDisallowed(values)}
                      disabled={deprecationDisallowed(values)}
                    />
                  )}
                  {assetIsEditable && !!values.is_initially_depreciated_fully && (
                    <FormGroup.MoneyInput
                      name="outside_depreciated_amount"
                      label={t("accumulatedAmountOfDepreciation")}
                      disabled
                      required={false}
                      value={depreciationAmountTotal(values)}
                    />
                  )}
                  {!assetIsEditable && (
                    <FormGroup.MoneyInput
                      name="depreciated_amount"
                      label={t("accumulatedAmountOfDepreciation")}
                      required={false}
                      disabled
                    />
                  )}
                </Col>
              </Row>
              <hr className="mt-1 mb-1" />
              <Row>
                <PartialEditableWrapper
                  assetIsPartialEditable={["active_ongoing", "active_finished"].includes(asset.status)}
                  partialUpdateFor={partialUpdateFor}
                  setPartialUpdateFor={setPartialUpdateFor}
                  forField="project_and_cc"
                  t={t}
                  colXl={6}
                  isSubmitting={isSubmitting}
                  resetForm={resetForm}
                  setFieldValue={setFieldValue}
                  values={values}
                >
                  <Col>
                    <FormGroup.SimpleSelect
                      maxMenuHeight={130}
                      options={activeCenter}
                      disabled={
                        deprecationDisallowed(values) || !(assetIsEditable || partialUpdateFor === "project_and_cc")
                      }
                      isClearable
                      name="cost_center_for_depreciation"
                      label={t("label.costCenter")}
                    />
                  </Col>
                  <Col>
                    <FormGroup.SimpleSelect
                      maxMenuHeight={130}
                      options={activeProjects}
                      disabled={
                        deprecationDisallowed(values) || !(assetIsEditable || partialUpdateFor === "project_and_cc")
                      }
                      isClearable
                      name="project_for_depreciation"
                      label={t("label.project")}
                    />
                  </Col>
                </PartialEditableWrapper>
                <Col xl={3}>
                  <FormGroup.MoneyInput
                    name="output_balance"
                    label={t("outputBalance")}
                    required={false}
                    disabled
                    value={outputBalance(values, output_balance)}
                  />
                </Col>
                {verification_id && canOpenVerModal && (
                  <Col xl={3} className="text-end">
                    <Button
                      type="button"
                      className="mt-3 my-3"
                      onClick={() => openVerificationModal(companyId, { id: verification_id })}
                    >
                      {t("connectedVerificationBtn")}
                    </Button>
                  </Col>
                )}
              </Row>
              {cancel_verification_id && canOpenVerModal && (
                <Row>
                  <Col xl={12} className="text-center">
                    <Button
                      type="button"
                      className="mt-3 my-3"
                      variant="info"
                      onClick={() => openVerificationModal(companyId, { id: cancel_verification_id })}
                    >
                      {t("decommissionVerificationBtn")}
                    </Button>
                  </Col>
                </Row>
              )}
              <AllError errors={errors} />
              <hr className="mt-1 mb-1" />
              <Row>
                <Col>
                  {assetIsEditable && (
                    <>
                      <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("ver:previewVer")}</Tooltip>}>
                        <Button
                          type="submit"
                          className="me-2"
                          variant="outline-secondary"
                          isSubmitting={isSubmitting}
                          onClick={() => {
                            setPreviewVerMode(true);
                          }}
                        >
                          <i className="mdi mdi-file-search-outline" /> {t("ver:verShort")}
                        </Button>
                      </OverlayTrigger>
                      <SubmitButton isSubmitting={isSubmitting} />
                    </>
                  )}
                  {asset.id && (
                    <Button type="button" variant="toggle-grey" className="ms-2" onClick={uploadModal.open}>
                      <i className="fas fa-file-upload pe-1" />
                      {t("common:actions.connectDocument")}
                    </Button>
                  )}
                  {asset.status === "active_ongoing" && (
                    <Button type="button" variant="toggle-grey" className="ms-2" onClick={extraDepreciationModal.open}>
                      {t("actions.extraDepreciation")}
                    </Button>
                  )}
                  {assetCanBeDecommissionedOrSold && (
                    <>
                      <Button
                        type="button"
                        variant="toggle-red"
                        className="float-end ms-2"
                        onClick={() => decommissionAndSellModal.open({ forSell: true })}
                      >
                        <i className="fas fa-dollar-sign pe-1" />
                        {t("actions.sell")}
                      </Button>
                      <Button
                        type="button"
                        variant="toggle-red"
                        className="float-end ms-2"
                        onClick={() => decommissionAndSellModal.open({ forSell: false })}
                      >
                        <i className="fas fa-trash-alt pe-1" />
                        {t("actions.decommission")}
                      </Button>
                    </>
                  )}
                  {asset.id && assetIsEditable && (
                    <RemoveButton
                      variant="red"
                      disabled={isSubmitting}
                      className="float-end ms-2"
                      confirmMessage={t("confirm.remove", {
                        assetDescription: asset.description,
                      })}
                      onClick={onDelete}
                    />
                  )}
                </Col>
                {!asset.id && (
                  <Col>
                    <span className="form-label">{t("common:actions.connectDocument")}</span>
                    <NewDocuments
                      documents={newFiles}
                      fileTypes={allowedFileExtensionsFor.asset}
                      multiple
                      onChange={({ file, index }) => handleNewDocumentChange(file, index)}
                    />
                  </Col>
                )}
              </Row>
            </Form>
            {uploadModal.show && (
              <FileUploadModal
                uploadHandler={onUpload}
                handleClose={uploadModal.close}
                fileTypes={allowedFileExtensionsFor.asset}
                max={3}
              />
            )}
            {decommissionAndSellModal.show && (
              <DecommissionAndSellModal
                companyId={companyId}
                assetId={asset.id}
                successCallback={successCallback}
                handleClose={decommissionAndSellModal.close}
                forSell={decommissionAndSellModal.data.forSell}
                assetData={asset}
              />
            )}
            {extraDepreciationModal.show && (
              <ExtraDepreciationModal
                companyId={companyId}
                assetId={asset.id}
                successCallback={successCallback}
                handleClose={extraDepreciationModal.close}
                assetData={asset}
              />
            )}
            {verPreviewModal.show && (
              <AssetDepreciationsPreviewModal
                handleClose={verPreviewModal.close}
                fys_verifications={verPreviewModal.data}
                isLoading={false}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
}

function DecommissionAndSellModal({ companyId, assetId, successCallback, handleClose, forSell, assetData }) {
  const { t } = useTranslation(["asset"]);
  return (
    <Modal show onHide={handleClose} scrollable size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{forSell ? t("actions.sell") : t("actions.decommission")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {forSell ? (
          <SellForm
            companyId={companyId}
            assetId={assetId}
            successCallback={successCallback}
            handleClose={handleClose}
            assetData={assetData}
          />
        ) : (
          <DecommissionForm
            companyId={companyId}
            assetId={assetId}
            successCallback={successCallback}
            handleClose={handleClose}
            assetData={assetData}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

function ExtraDepreciationModal({ companyId, assetId, successCallback, handleClose, assetData }) {
  const { t } = useTranslation(["asset"]);
  return (
    <Modal show onHide={handleClose} scrollable size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("actions.extraDepreciation")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ExtraDepreciationForm
          companyId={companyId}
          assetId={assetId}
          successCallback={successCallback}
          handleClose={handleClose}
          assetData={assetData}
        />
      </Modal.Body>
    </Modal>
  );
}

function ExtraDepreciationForm({ companyId, assetId, successCallback, handleClose, assetData }) {
  const { t } = useTranslation("asset");
  const { company } = useCompanyState();
  const [showPreview, setShowPreview] = useState(false);

  const generateVer = (values) => {
    return {
      booking_date: formatDate(values.booking_date),
      title: `Avskrivning tillgång ${assetData.number}, justering avskrivningar`,
      transactions: [
        {
          account_id: assetData.depreciation_account,
          amount: -values.amount,
        },
        {
          account_id: assetData.cost_account,
          amount: values.amount,
          cost_center_id: assetData.cost_center_for_depreciation,
          project_id: assetData.project_for_depreciation,
        },
      ],
    };
  };
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const formikProps = {
    initialValues: {
      booking_date: parseDate(new Date().toJSON().slice(0, 10)),
      amount: 0,
    },
    validationSchema: yup
      .object()
      .shape({
        booking_date: yup.date().nullable().required().max(today, t("common:errors:cantBeFutureDate")),
        amount: yup.number().nullable().required(),
      })
      .test("booking_date", function (values) {
        if (values.booking_date) {
          if (company.lock_accounting_date && values.booking_date <= new Date(company.lock_accounting_date)) {
            return this.createError({
              path: "booking_date",
              message: t("ver:errors.bookingInLockedDate", { lockAccountingDate: company.lock_accounting_date }),
            });
          }
          if (isBefore(values.booking_date, new Date(assetData.acquisition_date))) {
            return this.createError({
              path: "booking_date",
              message: t("errors.saleOrDecommissionBeforeAcquisition"),
            });
          }
        }
        return true;
      }),
    onSubmit: async (values) => {
      return assetAPI
        .createExtraDepreciation(companyId, assetId, {
          ...values,
          booking_date: formatDate(values.booking_date),
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (successCallback) {
            successCallback();
          }
          handleClose();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting }) => {
        return (
          <Form id="exta-depreciation-form" className="asset-form">
            <Row>
              <Col md={3}>
                <FormGroup.DatePicker
                  label={t("common:dates.bookingDate")}
                  name="booking_date"
                  required
                  popperClassName="popper-in-modal"
                />
              </Col>
              <Col md={3}>
                <FormGroup.MoneyInput label={t("common:money.amount")} name="amount" required />
              </Col>
              <Col>
                <FormGroup.MoneyInput
                  label={t("asset:amountAfterExtraDepr")}
                  name="fake_amount_extra"
                  disabled
                  value={assetData.depreciated_amount + values.amount}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p>{t("asset:extraDepreciationHelper")}</p>
              </Col>
            </Row>
            <>
              <hr className="mt-1 mb-1" />
              <Row>
                <Col>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        {!values.booking_date || !values.amount
                          ? t("ver:fillMandatoryForPreview")
                          : t("ver:previewVer")}
                      </Tooltip>
                    }
                  >
                    <Button
                      className="btn-depreciation-preview"
                      variant="toggle"
                      onClick={() => setShowPreview(!showPreview)}
                      disabled={!values.booking_date || !values.amount}
                    >
                      <i className="mdi mdi-file-search-outline" /> {t("ver:verShort")}
                      <span className="separator" />
                      <i className="mdi mdi-chevron-down" />
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col md={4} />
                <Col md={4} className="text-end">
                  <Button type="button" className="btn btn-white" onClick={() => handleClose()}>
                    {t("common:actions.cancel")}
                  </Button>
                  <SubmitButton isSubmitting={isSubmitting} />
                </Col>
                <Col md={4} />
              </Row>
            </>
            {showPreview && (
              <div className="pt-4">
                <BaseVerificationsPreviewContainer verifications={[generateVer(values)]} />
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

function SellPreview({ companyId, assetId, values }) {
  const [verifications, setVerifications] = useState(null);

  useEffect(() => {
    assetAPI
      .sellPreview(companyId, assetId, {
        ...values,
        sales_date: formatDate(values.sales_date),
        sales_account: values.sales_account?.value,
      })
      .then((response) => {
        setVerifications(response.data);
      })
      .catch((error) => {
        handleActionErrors(error);
        setVerifications([]);
      });
  }, [companyId, assetId, values]);
  return (
    <BaseVerificationsPreviewContainer verifications={verifications} showTotals isLoading={verifications === null} />
  );
}

function DecommissionPreview({ companyId, assetId, values }) {
  const [verifications, setVerifications] = useState(null);

  useEffect(() => {
    assetAPI
      .decommissionPreview(companyId, assetId, { decommission_date: formatDate(values.decommission_date) })
      .then((response) => {
        setVerifications(response.data);
      })
      .catch((error) => {
        handleActionErrors(error);
        setVerifications([]);
      });
  }, [companyId, assetId, values]);
  return (
    <BaseVerificationsPreviewContainer verifications={verifications} showTotals isLoading={verifications === null} />
  );
}

function SellForm({ companyId, assetId, successCallback, handleClose, assetData }) {
  const { t } = useTranslation("asset");
  const [showPreview, setShowPreview] = useState(false);
  const {
    company,
    accounts: { asOptions: accountOptions },
  } = useCompanyState();
  const formikProps = {
    initialValues: {
      sales_date: parseISO(new Date().toJSON().slice(0, 10)),
    },
    validationSchema: yup
      .object()
      .shape({
        sales_date: yup.date().nullable().required(),
        sales_amount: yup.number().nullable().required().min(1),
        sales_account: yup.object().nullable().required(),
      })
      .test("sales_date", function (values) {
        if (values.sales_date) {
          if (company.lock_accounting_date && values.sales_date <= new Date(company.lock_accounting_date)) {
            return this.createError({
              path: "sales_date",
              message: t("ver:errors.bookingInLockedDate", { lockAccountingDate: company.lock_accounting_date }),
            });
          }
          if (isBefore(values.sales_date, new Date(assetData.acquisition_date))) {
            return this.createError({
              path: "sales_date",
              message: t("errors.saleOrDecommissionBeforeAcquisition"),
            });
          }
        }
        return true;
      }),
    onSubmit: async (values) => {
      return assetAPI
        .sell(companyId, assetId, {
          ...values,
          sales_date: formatDate(values.sales_date),
          sales_account: values.sales_account?.value,
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (successCallback) {
            successCallback();
          }
          handleClose();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting }) => {
        return (
          <Form id="sell-form" className="inventory-register">
            <FormGroup.DatePicker label={t("salesDate")} name="sales_date" required popperClassName="popper-in-modal" />
            <FormGroup.MoneyInput label={t("salesAmount")} name="sales_amount" required />
            <FormGroup.SimpleSelect
              label={t("salesAccount")}
              options={accountOptions}
              name="sales_account"
              tdProps={{ style: { width: 160, maxWidth: 160 } }}
              required
            />
            <>
              <hr className="mt-1 mb-1" />
              <Row>
                <Col>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        {!values.sales_date || !values.sales_account || !values.sales_amount
                          ? t("ver:fillMandatoryForPreview")
                          : t("ver:previewVer")}
                      </Tooltip>
                    }
                  >
                    <Button
                      className="btn-depreciation-preview"
                      variant="toggle"
                      onClick={() => setShowPreview(!showPreview)}
                      disabled={!values.sales_date || !values.sales_account || !values.sales_amount}
                    >
                      <i className="mdi mdi-file-search-outline" /> {t("ver:verShort")}
                      <span className="separator" />
                      <i className="mdi mdi-chevron-down" />
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col md={4} />
                <Col md={4} className="text-end">
                  <Button type="button" className="btn btn-white" onClick={() => handleClose()}>
                    {t("common:actions.cancel")}
                  </Button>
                  <SubmitButton isSubmitting={isSubmitting} />
                </Col>
                <Col md={4} />
              </Row>
              {showPreview && (
                <div className="pt-4">
                  <SellPreview companyId={companyId} assetId={assetId} values={values} />
                </div>
              )}
            </>
          </Form>
        );
      }}
    </Formik>
  );
}

function DecommissionForm({ companyId, assetId, successCallback, handleClose, assetData }) {
  const { t } = useTranslation("asset");
  const { company } = useCompanyState();
  const [showPreview, setShowPreview] = useState(false);
  const formikProps = {
    initialValues: {
      decommission_date: parseISO(new Date().toJSON().slice(0, 10)),
      amount: 0,
    },
    validationSchema: yup
      .object()
      .shape({
        decommission_date: yup.date().nullable().required(),
      })
      .test("decommission_date", function (values) {
        if (values.decommission_date) {
          if (company.lock_accounting_date && values.decommission_date <= new Date(company.lock_accounting_date)) {
            return this.createError({
              path: "decommission_date",
              message: t("ver:errors.bookingInLockedDate", { lockAccountingDate: company.lock_accounting_date }),
            });
          }
          if (isBefore(values.decommission_date, new Date(assetData.acquisition_date))) {
            return this.createError({
              path: "decommission_date",
              message: t("errors.saleOrDecommissionBeforeAcquisition"),
            });
          }
        }
        return true;
      }),
    onSubmit: async (values) => {
      return assetAPI
        .decommission(companyId, assetId, {
          ...values,
          decommission_date: formatDate(values.decommission_date),
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          if (successCallback) {
            successCallback();
          }
          handleClose();
        })
        .catch((error) => {
          handleActionErrors(error);
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting }) => {
        return (
          <Form id="decommission-form" className="inventory-register">
            <FormGroup.DatePicker
              label={t("decommissionDate")}
              name="decommission_date"
              required
              popperClassName="popper-in-modal"
            />
            <FormGroup.MoneyInput label={t("common:money.amount")} name="amount" required={false} disabled />
            <>
              <hr className="mt-1 mb-1" />
              <Row>
                <Col>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip>
                        {!values.decommission_date ? t("ver:fillMandatoryForPreview") : t("ver:previewVer")}
                      </Tooltip>
                    }
                  >
                    <Button
                      className="btn-depreciation-preview"
                      variant="toggle"
                      onClick={() => setShowPreview(!showPreview)}
                      disabled={!values.decommission_date}
                    >
                      <i className="mdi mdi-file-search-outline" /> {t("ver:verShort")}
                      <span className="separator" />
                      <i className="mdi mdi-chevron-down" />
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col md={4} />
                <Col md={4} className="text-end">
                  <Button type="button" className="btn btn-white" onClick={() => handleClose()}>
                    {t("common:actions.cancel")}
                  </Button>
                  <SubmitButton isSubmitting={isSubmitting} />
                </Col>
                <Col md={4} />
              </Row>
              {showPreview && (
                <div className="pt-4">
                  <DecommissionPreview companyId={companyId} assetId={assetId} values={values} />
                </div>
              )}
            </>
          </Form>
        );
      }}
    </Formik>
  );
}
export default AssetForm;
