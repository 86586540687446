import React from "react";
import cx from "classnames";

import { formatMoney } from "utils/money";
import AddonTable from "./AddonTable";
import SalaryTable from "./SalaryTable";
import SITable from "./SITable";

function CashOut({
  cashOut,
  outlays,
  taxAccount,
  netSalary,
  socSec,
  personalTax,
  companyTax,
  vat,
  taxes,
  cashOutAddons,
  salaries,
  options,
  toggle,
  weeksByNo,
  paymentsToSuppliers,
  handleSimulationOutlayChange,
  handleSIChange,
  handleAddonChange,
  handleSalaryChange,
  t,
}) {
  return (
    <>
      <tr className={cx("cash-out", options.cashOut ? "ex-on" : "ex-off")} onClick={() => toggle("cashOut")}>
        <th>{t("cash.cashOut")}</th>
        {cashOut.map((amount, i) => (
          <td key={i}>{formatMoney(amount, 1, 1)}</td>
        ))}
      </tr>
      {options.cashOut && (
        <>
          <tr className={cx("nd2")}>
            <th className="ci-simulation">
              <OutlaySimulation title={t("navigation:outlays")} onChange={handleSimulationOutlayChange} />
            </th>
            {outlays.map((amount, i) => (
              <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
            ))}
          </tr>
          <tr className={cx("nd2 si", options.si ? "ex-on" : "ex-off")} onClick={() => toggle("si")}>
            <th>{t("cash.paymentsToSuppliers")}</th>
            {paymentsToSuppliers.weeks.map((amount, i) => (
              <td key={i}>{formatMoney(amount, 1, 1)}</td>
            ))}
          </tr>
          {options.si && <SITable data={paymentsToSuppliers} onUpdate={handleSIChange} weeksByNo={weeksByNo} t={t} />}
          <tr className={cx("nd2")}>
            <th>{t("cash.taxAccount")}</th>
            {taxAccount.map((amount, i) => (
              <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
            ))}
          </tr>
          <tr className={cx("nd2")}>
            <th>{t("cash.netSalaryPay")}</th>
            {netSalary.map((amount, i) => (
              <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
            ))}
          </tr>
          <tr className={cx("nd2", options.taxes ? "ex-on" : "ex-off")} onClick={() => toggle("taxes")}>
            <th>{t("cash.taxesToBePaid")}</th>
            {taxes.map((amount, i) => (
              <td key={i}>{formatMoney(amount, 1, 1)}</td>
            ))}
          </tr>
          {options.taxes && (
            <>
              <tr className="nd2">
                <th>{t("cash.socSec")}</th>
                {socSec.map((amount, i) => (
                  <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
                ))}
              </tr>
              <tr className="nd2">
                <th>{t("cash.personalTax")}</th>
                {personalTax.map((amount, i) => (
                  <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
                ))}
              </tr>
              <tr className="nd2">
                <th>{t("cash.companyTax")}</th>
                {companyTax.map((amount, i) => (
                  <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
                ))}
              </tr>
              <tr className="nd2">
                <th>{t("common:money.vat")}</th>
                {vat.map((amount, i) => (
                  <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
                ))}
              </tr>
            </>
          )}

          <tr className={cx("nd2 salaries", options.salaries ? "ex-on" : "ex-off")} onClick={() => toggle("salaries")}>
            <th>{t("cash.salariesSim")}</th>
            {salaries.weeks.map((amount, i) => (
              <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
            ))}
          </tr>
          {options.salaries && (
            <SalaryTable data={salaries} onUpdate={handleSalaryChange} weeksByNo={weeksByNo} t={t} />
          )}
          <tr
            className={cx("nd2 addon", options.addonsCashOut ? "ex-on" : "ex-off")}
            onClick={() => toggle("addonsCashOut")}
          >
            <th>{t("cash.myAddons")}</th>
            {cashOutAddons.weeks.map((amount, i) => (
              <td key={i}>{formatMoney(amount, 1, 1, "-")}</td>
            ))}
          </tr>
          {options.addonsCashOut && <AddonTable data={cashOutAddons} onUpdate={handleAddonChange} t={t} />}
        </>
      )}
    </>
  );
}

function OutlaySimulation({ title, onChange }) {
  function handleChange(event) {
    onChange(event.target.value);
  }

  return (
    <div>
      <div>{title}</div>
      <div onClick={(e) => e.stopPropagation()}>
        <select defaultValue="0" onChange={handleChange}>
          <option value="0">No repeat</option>
          <option value="1">Every week</option>
          <option value="4">Every month</option>
        </select>
      </div>
    </div>
  );
}

export default CashOut;
