import React from "react";
import _ from "lodash";

import useModal from "hooks/useModal";
import {
  AbsenceCommentsModal,
  DocumentCommentsModal,
  TimeLogCommentsModal,
  VerificationCommentsModal,
} from "components/modals";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { truncateText } from "utils/text";
import * as verificationAPI from "api2/verification";
import * as absenceAPI from "api2/absence";
import * as reportAPI from "api2/reports";
import * as documentsAPI from "api2/documents";
import { ActionButton, TooltipActionButton } from "./TooltipButton";

function _renderPopover({ comments, t, ...props }) {
  const latestComments = _.takeRight(comments, 2);
  return (
    <Popover id="popover-comments" className="popover-comments" {...props}>
      <Popover.Header as="h3">
        {t("comments")} {comments.length > 0 && `(${comments.length})`}
      </Popover.Header>
      <Popover.Body>
        <ul>
          {latestComments.map((comment) => (
            <li key={`com.${comment.id}`}>
              <strong>{comment.created_by_name}</strong>
              <br />
              {truncateText(comment.text, 70)}
            </li>
          ))}
        </ul>
        {!comments.length && <div>{t("common:noResultsFound")}</div>}
      </Popover.Body>
    </Popover>
  );
}

function CommentsOverlay({ getComments, variant, onClick }) {
  const { t } = useTranslation("common");
  const [show, setShow] = React.useState(false);
  const [comments, setComments] = React.useState([]);

  const onEnter = () => {
    getComments()
      .then((res) => setComments(res.data))
      .catch(() => setComments([]));
  };

  return (
    <OverlayTrigger
      show={show}
      placement="bottom"
      onEnter={onEnter}
      overlay={(props) => _renderPopover({ comments, t, ...props })}
    >
      <ActionButton
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        onClick={onClick}
        variant={variant}
        icon="fas fa-comment-dots"
      />
    </OverlayTrigger>
  );
}

function VerificationCommentsButton({ companyId, verification }) {
  const commentsModal = useModal();
  const { t } = useTranslation("common");
  const getComments = React.useCallback(() => {
    return verificationAPI.comment.list(companyId, verification.id);
  }, [companyId, verification.id]);
  if (!verification) {
    return null;
  }
  const variant = verification.comments.length ? "action active" : "action";

  return (
    <>
      {verification.comments.length ? (
        <CommentsOverlay variant={variant} onClick={commentsModal.open} getComments={getComments} />
      ) : (
        <TooltipActionButton
          text={t("comments")}
          onClick={commentsModal.open}
          icon="fas fa-comment-dots"
          variant={variant}
        />
      )}
      {commentsModal.show && (
        <VerificationCommentsModal
          companyId={companyId}
          verification={verification}
          handleClose={commentsModal.close}
        />
      )}
    </>
  );
}

function AbsenceCommentsButton({ companyId, report }) {
  const commentsModal = useModal();
  const { t } = useTranslation("common");
  const getComments = React.useCallback(() => {
    return absenceAPI.comment.list(companyId, report.id);
  }, [companyId, report.id]);
  if (!report) {
    return null;
  }
  const variant = report.comments.length ? "action active" : "action";

  return (
    <>
      {report.comments.length ? (
        <CommentsOverlay variant={variant} onClick={commentsModal.open} getComments={getComments} />
      ) : (
        <TooltipActionButton
          text={t("comments")}
          onClick={commentsModal.open}
          icon="fas fa-comment-dots"
          variant={variant}
        />
      )}
      {commentsModal.show && (
        <AbsenceCommentsModal companyId={companyId} report={report} handleClose={commentsModal.close} />
      )}
    </>
  );
}

function TimeLogCommentsButton({ companyId, log }) {
  const commentsModal = useModal();
  const { t } = useTranslation("common");
  const getComments = React.useCallback(() => {
    return reportAPI.timeReport.comment.list(companyId, log.id);
  }, [companyId, log.id]);
  if (!log) {
    return null;
  }
  const variant = log.comments.length ? "action active" : "action";

  return (
    <>
      {log.comments.length ? (
        <CommentsOverlay variant={variant} onClick={commentsModal.open} getComments={getComments} />
      ) : (
        <TooltipActionButton
          text={t("comments")}
          onClick={commentsModal.open}
          icon="fas fa-comment-dots"
          variant={variant}
        />
      )}
      {commentsModal.show && <TimeLogCommentsModal companyId={companyId} log={log} handleClose={commentsModal.close} />}
    </>
  );
}

function DocumentCommentsButton({ companyId, document }) {
  const commentsModal = useModal();
  const { t } = useTranslation("common");
  const getComments = React.useCallback(() => {
    return documentsAPI.comment.list(companyId, document.id);
  }, [companyId, document.id]);
  if (!document) {
    return null;
  }
  const variant = document.comments.length ? "action active" : "action";

  return (
    <>
      {document.comments.length ? (
        <CommentsOverlay variant={variant} onClick={commentsModal.open} getComments={getComments} />
      ) : (
        <TooltipActionButton
          text={t("comments")}
          onClick={commentsModal.open}
          icon="fas fa-comment-dots"
          variant={variant}
        />
      )}
      {commentsModal.show && (
        <DocumentCommentsModal companyId={companyId} document={document} handleClose={commentsModal.close} />
      )}
    </>
  );
}

export { VerificationCommentsButton, AbsenceCommentsButton, TimeLogCommentsButton, DocumentCommentsButton };
