import hash from "hash.js";
import clientOLD from "../api/client";
import client from "./client";

export function login({ email, password, code, save_device, resend_code }) {
  const emailHashed = hash.sha256().update(email).digest("hex");
  const deviceEmailTokenKey = `deviceToken_${emailHashed}`;
  const deviceEmailToken = localStorage.getItem(deviceEmailTokenKey);
  return client
    .post("/auth/standard/login-2f/", {
      email,
      password,
      code,
      save_device,
      resend_code,
      device_token: deviceEmailToken,
    })
    .then((response) => {
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        if (response.data.device_token) {
          localStorage.setItem(deviceEmailTokenKey, response.data.device_token);
        }
        client.defaults.headers.common.Authorization = `Token ${response.data.token}`;
        clientOLD.defaults.headers.common.Authorization = `Token ${response.data.token}`;
      }
      return response;
    });
}

export function login2fResendCode(email, password) {
  return client.post("/auth/standard/login-2f/resend-code/", {
    email,
    password,
  });
}

export function logout() {
  const request = client.delete("/auth/logout/");
  localStorage.removeItem("token");
  client.defaults.headers.common.Authorization = "";
  clientOLD.defaults.headers.common.Authorization = "";
  return request;
}

export function me() {
  const token = localStorage.getItem("token");
  if (token) {
    client.defaults.headers.common.Authorization = `Token ${token}`;
    clientOLD.defaults.headers.common.Authorization = `Token ${token}`;
  }
  return client.get("/auth/me/");
}

export function bankIDLoginRequest() {
  return client.post("/auth/bankid/v2/auth/start/").then((response) => response.data);
}

export function bankIDLoginCheck(orderRef) {
  return client.get("/auth/bankid/v2/auth/check/", { params: { order_ref: orderRef } }).then((response) => {
    if (response.status === 201) {
      localStorage.setItem("token", response.data.token);
      client.defaults.headers.common.Authorization = `Token ${response.data.token}`;
      clientOLD.defaults.headers.common.Authorization = `Token ${response.data.token}`;
    }
    return response;
  });
}

export function bankIDRefreshQr(orderRef) {
  return client.get("/auth/bankid/v2/refresh-qr/", { params: { order_ref: orderRef } });
}

export function resetPasswordChange(token, newPassword) {
  return client.post("/auth/password/reset/change/", { password: newPassword, token }).then((response) => {
    return response;
  });
}

export function resetPasswordStartByConsult(agencyId, userEmail) {
  return client.post(
    `/auth/password/reset/consult-start/`,
    {
      email: userEmail,
    },
    {
      headers: {
        "x-agency": agencyId,
      },
    }
  );
}

export function resetPasswordStartByAdmin(userEmail) {
  return client.post(`/auth/password/reset/admin-start/`, {
    email: userEmail,
  });
}

export function resetPasswordStartByUser(userEmail) {
  return client.post(`/auth/password/reset/start/`, {
    email: userEmail,
  });
}

export function freshchatRestoreIdUpdate(restoreId) {
  return client.patch("/users/wchat/", { restore_id: restoreId });
}

export function changeDefaultAfterLoginPage(startPage) {
  return client.patch("/users/landing-page/update/", { start_page: startPage });
}
