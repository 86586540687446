import React from "react";
import { useParams } from "react-router";
import { Card } from "react-bootstrap";
import "./ExportPage.scss";
import TableProvider from "components/tables/btable/provider";
import ExportExtendedTable from "components/tables/ExportExtendedTable";

import * as companyAPI from "api2/companies";

function ExportPage({ tableId = "exportPage" }) {
  const { companyId } = useParams();
  const initialFilters = { ordering: "-date_start" };
  return (
    <Card id="export-page">
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => companyAPI.financialYears.list(companyId, filters, config)}
          initialFilters={initialFilters}
        >
          <ExportExtendedTable companyId={companyId} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default ExportPage;
