import { Card, Col, Row } from "react-bootstrap";
import React from "react";
import { useParams } from "react-router";

import { SIEFileForm } from "components/forms/company-settings-forms";
import TableProvider from "components/tables/btable/provider";
import * as companyAPI from "api2/companies";
import { SieFileTable } from "components/tables";
import { useTranslation } from "react-i18next";

function SIEFileListPage() {
  const { companyId } = useParams();
  const initialFilters = { ordering: "-financial_year__date_start" };
  const { t } = useTranslation("company");

  return (
    <TableProvider
      tableId="sie_files_table"
      dataSource={(filters, config) => companyAPI.sieFiles.list(companyId, filters, config)}
      initialFilters={initialFilters}
    >
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{t("sieTableTitle")}</Card.Title>
              <SieFileTable companyId={companyId} selectable={false} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <SIEFileForm companyId={companyId} />
        </Col>
      </Row>
    </TableProvider>
  );
}

export default SIEFileListPage;
