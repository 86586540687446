import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

import * as companyAPI from "api2/companies";
import { BExpandedTable } from "components/tables/btable";
import HintButton from "components/ui/buttons/HintButton";
import { StaffPermRequired } from "components/perms";
import ExportWidget, { GlobalExportWidget } from "./ExportWidget";

function NotifyClientSection({ row, companyId }) {
  const { t } = useTranslation();
  const [sending, setSending] = useState(false);
  const notify = () => {
    setSending(true);
    companyAPI.exporter
      .notifyClient(companyId, row.id)
      .then(() => {
        toast.success(t("msg:sent"));
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__);
        }
        setSending(false);
      });
  };
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div>{row.date_end}</div>
      <StaffPermRequired>
        <div>
          <HintButton placement="left" hint={t("company:exporter.notifyClientInfo")} />
          <Button className="ms-1" variant="primary" size="sm" onClick={notify} disabled={sending}>
            {t("company:exporter.notifyClient")}
          </Button>
        </div>
      </StaffPermRequired>
    </div>
  );
}

function ExportExtendedTable({ companyId }) {
  const { t } = useTranslation(["company", "bread"]);
  const headers = useMemo(
    () => [
      {
        field: "date_start",
        label: t("common:starts"),
        className: "tw-50",
      },
      {
        field: "date_end",
        label: t("common:ends"),
        className: "tw-50",
        render: (row) => <NotifyClientSection row={row} companyId={companyId} />,
      },
    ],
    [t, companyId]
  );

  return (
    <>
      <BExpandedTable
        headers={headers}
        renderExpandedRow={(row) => <ExportWidget companyId={companyId} financialYearId={row.id} />}
      />
      <h4>{t("bread:Export")}</h4>
      <div className="expanded export-global">
        <GlobalExportWidget companyId={companyId} />
      </div>
    </>
  );
}

export default ExportExtendedTable;
