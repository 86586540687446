import React, { useCallback } from "react";
import { Form } from "react-bootstrap";
import { Checkbox } from "../ui/checkboxes/Checkbox";

function CheckboxFilter({ label, title, name, defaultChecked, onFilter, wrapperClass, ...props }) {
  const Component = useCallback(() => {
    return (
      <Checkbox
        label={label}
        checked={defaultChecked}
        handleChange={(isChecked) => onFilter({ [name]: isChecked })}
        {...props}
      />
    );
  }, [defaultChecked, label, name, onFilter, props]);

  if (title) {
    return (
      <Form.Group>
        <Form.Label htmlFor={`id_${name}`}>{title}</Form.Label>
        <Component />
      </Form.Group>
    );
  }
  return (
    <div className={wrapperClass}>
      <Component />
    </div>
  );
}

export default CheckboxFilter;
