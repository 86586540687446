import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";

import useInitialAsync from "hooks/useInitialAsync";
import * as offerAPI from "api2/offers";
import { RocketLoader } from "components/ui/loaders";
import CustomerInvoiceForm from "components/forms/CustomerInvoiceForm";

function OfferDuplicatePage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const { id: offerId } = useParams();
  const { loading, item } = useInitialAsync(
    ({ cancelToken }) => {
      return offerAPI.details(company.id, offerId, { cancelToken });
    },
    {},
    [company.id, offerId]
  );

  const onCreate = (invoice) => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }
  const connectedProducts = item.connected_products || {};
  delete item.connected_products;
  return (
    <CustomerInvoiceForm
      invoice={{
        ...item,
        id: null,
        booking_date: new Date(),
        delivery_method: "email",
      }}
      invoiceConnectedProducts={connectedProducts}
      company={company}
      onSave={onCreate}
      forOffer
    />
  );
}

export default OfferDuplicatePage;
