import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import logoBankIDBlack from "assets/images/bankid-black.svg";
import logoBankIDWhite from "assets/images/bankid-white.svg";
import { Link, Navigate, useLocation } from "react-router";
import { toast } from "react-toastify";
import useModal from "hooks/useModal";
import * as authAPI from "api2/auth";
import { useAuthState } from "hooks/useAuth";
import { startPages } from "api/options";
import BankIDLoginModal from "./BankIDLoginModal";
import AdminNotificationLoginAlert from "./AdminNotificationLoginAlert";

function BankIDLoginPage() {
  const { t } = useTranslation("others");
  const loginModal = useModal();
  const [loading, setLoading] = useState(false);
  const { authenticated, user } = useAuthState();
  const location = useLocation();

  if (authenticated) {
    const isConsult = user.is_consult || user.is_superuser;
    let startPageCode;
    if (isConsult) {
      startPageCode = Object.keys(startPages.paths_by_code).includes(user.start_page) // includes used to just to be safe
        ? user.start_page
        : startPages.code_for.officeSupport; // if smt will go wrong the redirect to office support
    } else {
      // regular users should always be redirected to Accounting
      startPageCode = startPages.code_for.accounting;
    }
    const { from } = location.state || { from: { pathname: startPages.paths_by_code[startPageCode] } };
    return <Navigate to={from} />;
  }

  const openModal = async () => {
    setLoading(true);
    const data = await authAPI
      .bankIDLoginRequest()
      .catch((error) => {
        toast.error(error.data.__all__);
      })
      .finally(() => {
        setLoading(false);
      });
    loginModal.open(data);
  };

  return (
    <>
      <div className="box">
        <h3>{t("others:bflowWelcome")}</h3>
        <AdminNotificationLoginAlert />
        <Button
          type="button"
          size="lg"
          variant="white"
          className="width-lg bankid"
          onClick={openModal}
          disabled={loading}
        >
          <img src={logoBankIDWhite} className="bankid-white" alt="BankID" />
          <img src={logoBankIDBlack} className="bankid-black" alt="BankID" />
          BankID <span className="text-lowercase">{t("others:auth.signIn")}</span>
        </Button>
        <Link to="/auth/standard-login" className="side-link">
          {t("others:login.standard")}
        </Link>
      </div>
      {loginModal.show && loginModal.data && (
        <BankIDLoginModal loginData={loginModal.data} handleClose={loginModal.close} />
      )}
    </>
  );
}

export default BankIDLoginPage;
