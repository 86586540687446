import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";

import useInitialAsync from "hooks/useInitialAsync";
import * as ciAPI from "api2/customer-invoices";
import { RocketLoader } from "components/ui/loaders";
import CustomerInvoiceForm from "components/forms/CustomerInvoiceForm";

function CustomerInvoiceDuplicatePage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const { id: verificationId } = useParams();
  const { loading, item } = useInitialAsync(
    ({ cancelToken }) => {
      return ciAPI.invoice.details(company.id, verificationId, { cancelToken });
    },
    {},
    [company.id, verificationId]
  );

  const onCreate = (invoice) => {
    navigate(-1);
  };

  if (loading) {
    return <RocketLoader />;
  }

  const connectedProducts = item.connected_products || {};
  delete item.connected_products;
  const invoice = {
    ...item,
    id: null,
    booking_date: new Date(),
    status: 0,
    sub_status: "",
    invoice_no: "",
    credit_for: null,
    delivery_method: item.delivery_method || item.customer?.default_delivery_method || "manual",
    periodisation_config: null,
    periodisation_status: null,
    records: item.records.map((record) => {
      return { ...record };
    }),
  };
  return (
    <CustomerInvoiceForm
      invoice={invoice}
      company={company}
      onSave={onCreate}
      invoiceConnectedProducts={connectedProducts}
    />
  );
}

export default CustomerInvoiceDuplicatePage;
