import React from "react";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

import { SubmitButton } from "components/ui/buttons";
import { AllError, FormGroup } from "components/formik";
import * as authAPI from "api2/auth";

function PasswordResetForm({ token }) {
  const { t } = useTranslation("others");
  const navigate = useNavigate();
  const formikProps = {
    initialValues: {
      password: "",
    },
    validationSchema: yup.object().shape({
      password: yup.string().min(10).required(),
    }),
    onSubmit: async (values, { setErrors }) => {
      return authAPI
        .resetPasswordChange(token, values.password || "")
        .then((response) => {
          navigate(`/auth/password-ok`, { replace: true });
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            if (["not_found", "expired"].includes(error.response.data.errors[0].code)) {
              toast.error(t("auth.invalidToken"));
            }
          } else if (error.data) {
            setErrors(error.data);
          }
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ isSubmitting, errors }) => {
        return (
          <Form noValidate>
            <FormGroup.Input name="password" type="password" placeholder={t("auth.newPassword")} />
            <AllError errors={errors} />
            <div className="form-group text-center mb-3">
              <SubmitButton
                size="lg"
                variant="white"
                className="width-lg btn-rounded"
                title="others:auth.reset"
                icon="fa-unlock"
                isSubmitting={isSubmitting}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default PasswordResetForm;
