import React, { useMemo, useState } from "react";
import * as options from "api/options";
import { paymentTermsOptionsWithoutDefault } from "api/options";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Alert, Button, ButtonGroup, Card, Col, Form as BForm, InputGroup, Row } from "react-bootstrap";
import _ from "lodash";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { toast } from "react-toastify";

import { CurrencyPicker, CustomerPicker, FormGroup } from "components/formik";
import * as ciAPI from "api2/customer-invoices";
import * as offerAPI from "api2/offers";
import { HintButton, SubmitButton } from "components/ui/buttons";
import { getCustomerCountryArea } from "utils/countries";

import { CCProjModal, CustomerInvoicePreviewModal, DiscountModal, RotRutModal } from "components/modals/invoices";
import useModal from "hooks/useModal";
import { formatData, validRotRut } from "utils/calc/ci";
import { useCompanyState } from "hooks/useCompany";
import { confirmExecute, confirmInfo } from "components/modals/ConfirmModal";
import { NewDocuments } from "components/ui/documents/verification-documents";
import * as documentAPI from "api/document";
import cx from "classnames";
import { confirmConfigurePeriodisation } from "components/modals/invoices/CIPeriodisationModal";
import { parseISO } from "date-fns";
import * as orderAPI from "api2/orders";
import { formatDate } from "utils/date";
import { transformDotNotationObject } from "utils/others";
import { SavedDocuments, SavedOfferDocuments, SavedOrderDocuments } from "components/ui/documents-refactored/documents";
import { allowedFileExtensionsFor } from "utils/file";

import {
  isReversedVATAllowed,
  onChangePriceGroup,
  onCustomerChange,
  onCustomerReset,
  onReversedVatChange,
  RecordsForm,
  RecordsTool,
} from "./helpers";

function CustomerInvoiceForm({
  invoice,
  company,
  onSave,
  invoiceConnectedProducts = {},
  forCreditInvoice = false,
  forOffer = false,
  forOrder = false,
}) {
  const [originalPrices, setOriginalPrices] = useState({});
  const { t, i18n } = useTranslation("ci");
  const priceGroups = options.priceGroups.asList();
  const [newFiles, setNewFiles] = useState([{ key: _.uniqueId("nd.") }]);
  const [connectedProducts, setConnectedProducts] = useState(invoiceConnectedProducts);
  const {
    currencies: { byId: currencies },
    costCenters: { byId: centerById, asOptions: centerOptions },
    projects: { byId: projectById, asOptions: projectOptions },
    accounts: { byId: accountById, asOptions: accountOptions },
  } = useCompanyState();
  const discountModal = useModal();
  const previewModal = useModal();
  const rotRutModal = useModal();
  const ccProjModal = useModal();
  const initialValues = useMemo(() => {
    const initial_amount_currency = {
      value: currencies[invoice.amount_currency].value,
      label:
        i18n.language === "sv"
          ? currencies[invoice.amount_currency].label_sv
          : currencies[invoice.amount_currency].label_en,
    };
    const offerInitials = forOffer
      ? {
          valid_period: options.paymentTerms.getOption(invoice.valid_period || company.default_invoice_payment_terms),
        }
      : {};
    const orderInitials = forOrder
      ? {
          delivery_date: invoice.delivery_date ? parseISO(invoice.delivery_date) : null,
          order_date: invoice.order_date ? parseISO(invoice.order_date) : new Date(),
        }
      : {};
    return {
      ...invoice,
      price_group: options.priceGroups.getOption(invoice.price_group) || priceGroups[0],
      your_reference: invoice.your_reference || "",
      payment_terms: options.paymentTerms.getOption(invoice.payment_terms || company.default_invoice_payment_terms),
      amount_currency: initial_amount_currency,
      periodisation_enabled: !(!invoice.id || invoice.periodisation_config === null),
      undo_periodisation_from_credited_invoice: false,
      ...offerInitials,
      ...orderInitials,
    };
  }, [company.default_invoice_payment_terms, priceGroups, invoice, currencies, i18n.language, forOffer, forOrder]);

  const formikProps = {
    initialValues,
    validationSchema: yup.object().shape({
      customer: yup.object().nullable().required(),
      booking_date: !forOrder ? yup.date().nullable().required() : yup.date().nullable().notRequired(),
      order_date: forOrder ? yup.date().nullable().required() : yup.date().nullable().notRequired(),
      reversed_vat_receiver_no: yup.string().when("reversed_vat", ([reversedVAT], schema, { parent }) => {
        const countryArea = getCustomerCountryArea(parent.customer && parent.customer.country);
        if (reversedVAT && countryArea === "outside_eu") {
          return schema;
        }
        return reversedVAT ? schema.required() : schema;
      }),
    }),
    onSubmit: async (values, { setErrors, setFieldError, setFieldValue }) => {
      if (!validRotRut(values.records)) {
        setFieldError("__all__", t("errors.rotRutAmountHigher"));
        return false;
      }
      if (!forOffer && !forOrder) {
        // to remove if all customers migrated and notified
        const shouldWarn = await ciAPI.customers.shouldWarnOldMailDelivery(company.id, values.customer.value);
        if (shouldWarn) {
          await confirmInfo(t("ci:confirm.oldMailDelivery"));
        }
      }
      if (!forOffer && !forOrder && !values.draft && _.includes(["email", "mail"], values.delivery_method)) {
        const answer = await confirmExecute(t("confirm.send"));
        if (!answer) {
          return false;
        }
      }
      if (
        !forOffer &&
        !forOrder &&
        !values.draft &&
        forCreditInvoice &&
        values.periodisation_status &&
        !["undone", "stopped_before_execution"].includes(values.periodisation_status)
      ) {
        const answer = await confirmExecute(
          t("confirm.undoPeriodisationOrJustSend", { status: t(`common:${values.periodisation_status}`) }),
          t("confirm.undoAndSendInvoice"),
          t("confirm.onlySendInvoice")
        );
        if (answer === null) {
          return false;
        }
        if (answer) {
          setFieldValue("undo_periodisation_from_credited_invoice", true);
          values.undo_periodisation_from_credited_invoice = true;
        }
      }
      const data = formatData(values, forOffer, forOrder);
      let promise;
      const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
      if (forOffer) {
        promise = offerAPI.offerSave(company.id, data, _newFiles);
      } else if (forOrder) {
        promise = orderAPI.orderSave(company.id, data, _newFiles);
      } else {
        promise = ciAPI.invoice.save(company.id, data, _newFiles);
      }
      return promise
        .then(async (response) => {
          if (values.draft) {
            toast.success(t("draftSaved"), { autoClose: 2000 });
          } else if (!forOffer && !forOrder && values.delivery_method === "email") {
            toast.success(t("invoiceSent"), { autoClose: 2000 });
          } else {
            toast.success(t("msg:saved"), { autoClose: 2000 });
          }
          if (!values.draft && values.periodisation_enabled && !forOffer && !forOrder) {
            await confirmConfigurePeriodisation(
              company.id,
              response.data.id || data.id,
              centerById,
              projectById,
              accountById,
              accountOptions
            );
          }
          if (onSave) {
            onSave({ ...data, ...response.data });
          }
        })
        .catch((error) => {
          if (error.status !== 403) {
            toast.error(t("msg:fixErrors"));

            if (error.data && error.data.attachments) {
              setErrors({ __all__: error.data.attachments });
            } else if (error.data && error.data.amount) {
              setErrors({ __all__: error.data.amount });
            } else {
              const errors = transformDotNotationObject(error.data);
              setErrors(errors);
            }
          }
        });
    },
  };

  async function openPreview(values, setTouched, setFieldError) {
    if (!validRotRut(values.records)) {
      setFieldError("__all__", t("errors.rotRutAmountHigher"));
      return false;
    }
    const errors = await setTouched({ customer: true });
    if (!Object.keys(errors).length) {
      const data = formatData(values, forOffer, forOrder);
      const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
      previewModal.open({
        ...data,
        newFiles: _newFiles,
        documents: values.documents,
      });
    }
    return true;
  }

  const onRotRutSelected = (updatedRecords, RotRutDetails, setFieldValue, values) => {
    if (RotRutDetails.updateCustomerPropertyDesignation || RotRutDetails.updateCustomerResidenceAssociationOrgNo) {
      const {
        updateCustomerPropertyDesignation,
        updateCustomerResidenceAssociationOrgNo,
        residence_org_no: residenceOrgNo,
        property_designation: propertyDesignation,
      } = RotRutDetails;
      const data = {
        residence_org_no: residenceOrgNo || values.customer.residence_org_no,
        property_designation: propertyDesignation || values.customer.property_designation,
      };
      if (updateCustomerResidenceAssociationOrgNo && residenceOrgNo !== values.customer.residence_org_no) {
        setFieldValue("customer.residence_org_no", residenceOrgNo);
      }
      if (updateCustomerPropertyDesignation && propertyDesignation !== values.customer.property_designation) {
        setFieldValue("customer.property_designation", propertyDesignation);
      }
      ciAPI.customers
        .updatePrivateInfo(company.id, values.customer.value, data.property_designation, data.residence_org_no)
        .catch(() => {});
      delete RotRutDetails.updateCustomerPropertyDesignation;
      delete RotRutDetails.updateCustomerResidenceAssociationOrgNo;
    }
    if (RotRutDetails?.work_start && RotRutDetails?.work_end) {
      const data = {};
      data.work_start = formatDate(RotRutDetails.work_start);
      data.work_end = formatDate(RotRutDetails.work_end);
      setFieldValue("rot_rut.work_start", data.work_start, false);
      setFieldValue("rot_rut.work_end", data.work_end, false);
    }
    setFieldValue("records", updatedRecords, false);
    setFieldValue("rot_rut", RotRutDetails, false);
    rotRutModal.close();
  };

  return (
    <Card>
      <Formik {...formikProps}>
        {({ values, errors, setFieldValue, setTouched, setValues, setFieldError, isSubmitting }) => {
          const isCustomerPrivate = values.customer && values.customer.customer_type === "private";
          const rotRutEnabled = !forOffer && !forOrder && isCustomerPrivate && company.invoice_tax_deduction_enabled;
          const reversedVATAllowed = isReversedVATAllowed(values.customer);
          return (
            <Form
              id="ciForm"
              onKeyDown={(e) => {
                if (e.which === 13) {
                  e.preventDefault();
                }
              }} // block enter key to submit form
            >
              <Card.Body>
                <Row xl={2} lg={1}>
                  <Col xl={6} lg={12}>
                    <CustomerPicker
                      name="customer"
                      label={t("common:customer")}
                      companyId={company.id}
                      menuPosition="portal"
                      customer={values.customer}
                      changeAllowed={forCreditInvoice === false}
                      onChange={(customer) =>
                        onCustomerChange(
                          company,
                          customer,
                          values,
                          setValues,
                          setFieldValue,
                          t,
                          connectedProducts,
                          originalPrices,
                          setOriginalPrices,
                          false
                        )
                      }
                      onReset={() =>
                        onCustomerReset(setFieldValue, values, setValues, connectedProducts, rotRutEnabled, company)
                      }
                      required
                    />
                  </Col>
                </Row>
                {values.customer && (
                  <>
                    <Row>
                      <Col lg={3}>
                        <FormGroup.Input name="your_reference" label={t("common:yourReference")} />
                      </Col>
                      <Col lg={3}>
                        <FormGroup.Input name="our_reference" label={t("common:ourReference")} />
                      </Col>
                      <Col lg={3}>
                        <FormGroup.SimpleSelect
                          name="payment_terms"
                          label={t("common:paymentTerms")}
                          options={paymentTermsOptionsWithoutDefault()}
                        />
                      </Col>
                      {forOrder ? (
                        <Col lg={3}>
                          <FormGroup.DatePicker name="order_date" label={t("ci:orderDate")} required />
                        </Col>
                      ) : (
                        <Col lg={3}>
                          <FormGroup.BookingDatePicker
                            name="booking_date"
                            label={forOffer ? t("offerDate") : t("common:dates.invoiceDate")}
                            required
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col lg={3}>
                        <CurrencyPicker
                          name="amount_currency"
                          disabled={forCreditInvoice === true}
                          currentCode={values.amount_currency.value}
                          currentDate={forOrder ? values.order_date : values.booking_date}
                          setFieldValue={setFieldValue}
                        />
                      </Col>
                      <Col lg={3}>
                        <div className={cx("form-group", { "is-invalid": !!errors?.reversed_vat_receiver_no })}>
                          <label className="form-label">{t("reversedVat")}</label>
                          <InputGroup className="mb-3">
                            <InputGroup.Text style={{ width: 40 }}>
                              <Field
                                type="checkbox"
                                name="reversed_vat"
                                disabled={!reversedVATAllowed || forCreditInvoice}
                                onChange={(e) =>
                                  onReversedVatChange(
                                    e.currentTarget.checked,
                                    values.customer,
                                    values,
                                    setValues,
                                    connectedProducts,
                                    company.vat_disabled
                                  )
                                }
                              />
                            </InputGroup.Text>
                            <Field
                              type="text"
                              name="reversed_vat_receiver_no"
                              placeholder={t("common:money.vatNo")}
                              disabled={!values.reversed_vat || values.customer.vat_number || !reversedVATAllowed}
                              required={values.reversed_vat}
                              className="form-control"
                            />
                            <BForm.Control.Feedback type="invalid">
                              <ErrorMessage name="reversed_vat_receiver_no" />
                            </BForm.Control.Feedback>
                          </InputGroup>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <FormGroup.SimpleSelect
                          name="price_group"
                          isDisabled={forCreditInvoice}
                          onChange={(selected) =>
                            onChangePriceGroup(selected, values, setFieldValue, connectedProducts)
                          }
                          label={t("priceGroup")}
                          options={priceGroups}
                        />
                      </Col>
                      {forOffer && (
                        <Col lg={3}>
                          <FormGroup.SimpleSelect
                            name="valid_period"
                            label={t("ci:validPeriod")}
                            options={paymentTermsOptionsWithoutDefault()}
                          />
                        </Col>
                      )}
                      {forOrder && (
                        <Col lg={3}>
                          <FormGroup.DatePicker
                            name="delivery_date"
                            label={t("ci:deliveryDate")}
                            options={paymentTermsOptionsWithoutDefault()}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                )}
                <FieldArray
                  name="records"
                  render={(arrayHelper) => (
                    <>
                      <RecordsForm
                        values={values}
                        arrayHelper={arrayHelper}
                        currency={values.amount_currency.value}
                        forCreditInvoice={forCreditInvoice}
                        t={t}
                      />
                      <RecordsTool
                        companyId={company.id}
                        arrayHelper={arrayHelper}
                        currency={values.amount_currency.value}
                        vatDisabled={company.vat_disabled || values.reversed_vat}
                        forCreditInvoice={forCreditInvoice}
                        rotRutEnabled={rotRutEnabled}
                        discountModal={discountModal}
                        rotRutModal={rotRutModal}
                        ccProjModal={ccProjModal}
                        centerOptions={centerOptions}
                        projectOptions={projectOptions}
                        priceGroup={values.price_group}
                        connectedProducts={connectedProducts}
                        setConnectedProducts={setConnectedProducts}
                        originalPrices={originalPrices}
                        setOriginalPrices={setOriginalPrices}
                        forOffer={forOffer}
                        forOrder={forOrder}
                        t={t}
                        bookingDate={values.booking_date}
                      />
                    </>
                  )}
                />
                <Row>
                  <Col>
                    {invoice.id && !forOrder && !forOffer && (
                      <SavedDocuments
                        companyId={company.id}
                        documents={invoice.documents}
                        partUrl={`/verifications/${invoice.id}/documents`}
                      />
                    )}
                    {invoice.id && !forOrder && forOffer && (
                      <SavedOfferDocuments
                        offerId={invoice.id}
                        documents={invoice.documents}
                        companyId={company.id}
                        removable
                      />
                    )}
                    {invoice.id && forOrder && (
                      <SavedOrderDocuments
                        orderId={invoice.id}
                        documents={invoice.documents}
                        companyId={company.id}
                        removable
                      />
                    )}
                    <NewDocuments
                      documents={newFiles}
                      fileTypes={allowedFileExtensionsFor.customer_invoice}
                      multiple
                      onChange={({ file, index }) =>
                        documentAPI.onNewFileChange({
                          file,
                          index,
                          allFiles: newFiles,
                          setFile: setNewFiles,
                          max: 3,
                        })
                      }
                    />
                  </Col>
                </Row>
                {values.delivery_method === "email" && !forOffer && !forOrder && (
                  <FormGroup.MultiEmail
                    name="extra_receivers"
                    label={t("extraReceivers")}
                    helpText={t("helpExtraReceivers")}
                  />
                )}
                {!forOffer && !forOrder && (
                  <Row>
                    <Col sm={9}>
                      <div className="form-group">
                        <label className="form-label">{t("sendInvoiceBy")}</label>
                        <DeliveryMethodSwitcher
                          t={t}
                          deliveryMethod={values.delivery_method}
                          changeDeliveryMethod={(newMethod) => setFieldValue("delivery_method", newMethod)}
                        />
                      </div>
                    </Col>
                    <Col sm={3} className="text-end">
                      <HintButton hint={t("msg.enablePeriodisationHint")} />
                      <Button
                        type="button"
                        variant="toggle"
                        className="ms-1"
                        onClick={() => {
                          setFieldValue("periodisation_enabled", !values.periodisation_enabled);
                        }}
                      >
                        {values.periodisation_enabled ? (
                          <i className="fas fa-check-square me-1" />
                        ) : (
                          <i className="fas fa-square me-1" style={{ fontWeight: 400 }} />
                        )}
                        {t("enablePeriodisation")}
                      </Button>
                    </Col>
                  </Row>
                )}
                {discountModal.show && (
                  <DiscountModal
                    show
                    companyId={company.id}
                    currency={values.amount_currency.value}
                    forCreditInvoice={forCreditInvoice}
                    records={values.records}
                    onSave={(records) => {
                      setFieldValue("records", records);
                      discountModal.close();
                    }}
                    onCancel={discountModal.close}
                  />
                )}
                {rotRutModal.show && !forOffer && !forOrder && (
                  <RotRutModal
                    currency={values.amount_currency.value}
                    companyId={company.id}
                    customer={values.customer}
                    records={values.records.map((record, i) => ({
                      ...record,
                      index: i,
                    }))}
                    rotRutDetails={values.rot_rut}
                    onCancel={rotRutModal.close}
                    onSave={({ records, RotRutDetails }) => {
                      onRotRutSelected(records, RotRutDetails, setFieldValue, values);
                    }}
                  />
                )}
                {ccProjModal.show && !forOffer && !forOrder && (
                  <CCProjModal
                    companyId={company.id}
                    records={values.records}
                    onCancel={ccProjModal.close}
                    onSave={(records) => {
                      setFieldValue("records", records, false);
                      ccProjModal.close();
                    }}
                    bookingDate={values.booking_date}
                  />
                )}
                {previewModal.show && !!previewModal.data && (
                  <CustomerInvoicePreviewModal
                    companyId={company.id}
                    invoiceData={previewModal.data}
                    forOffer={forOffer}
                    forOrder={forOrder}
                    onClose={previewModal.close}
                  />
                )}
                {errors && errors.__all__ && <Alert variant="danger">{errors.__all__}</Alert>}
              </Card.Body>
              <Card.Footer className="space">
                <div className="space-1">
                  <ButtonGroup>
                    <Button
                      type="button"
                      variant="toggle"
                      className="me-2"
                      onClick={() => openPreview(values, setTouched, setFieldError)}
                    >
                      <i className="fas fa-search me-1" />
                      {!forOffer && !forOrder
                        ? t("actions.previewInvoice")
                        : t(forOffer ? "actions.previewOffer" : t("actions.previewOrder"))}
                    </Button>
                    {forOffer || forOrder ? (
                      <>
                        <SubmitButton
                          title="actions.save"
                          isSubmitting={isSubmitting}
                          className="me-2"
                          onClick={() => {
                            setFieldValue("send_after_save", false);
                          }}
                        />
                        <SubmitButton
                          title={t("actions.saveAndSend")}
                          isSubmitting={isSubmitting}
                          onClick={() => {
                            setFieldValue("send_after_save", true);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <SubmitButton
                          variant="secondary"
                          icon="fa-sticky-note"
                          className="me-2"
                          isSubmitting={isSubmitting}
                          title="common:actions.saveAsDraft"
                          onClick={() => {
                            setFieldValue("draft", true);
                          }}
                        />
                        <SubmitButton
                          title={values.delivery_method !== "manual" ? "actions.sendAndBook" : "actions.saveAndBook"}
                          isSubmitting={isSubmitting}
                          onClick={() => {
                            setFieldValue("draft", false);
                          }}
                        />
                      </>
                    )}
                  </ButtonGroup>
                </div>
              </Card.Footer>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
}

function DeliveryMethodSwitcher({ deliveryMethod, changeDeliveryMethod, t }) {
  return (
    <div>
      <ButtonGroup aria-label="Status filter">
        <Button variant="switch" active={deliveryMethod === "email"} onClick={() => changeDeliveryMethod("email")}>
          <i className="fas fa-at" /> {t("actions.sendEmail")}
        </Button>
        <Button
          variant="switch"
          active={deliveryMethod === "einvoice"}
          onClick={() => changeDeliveryMethod("einvoice")}
        >
          <i className="fas fa-desktop" /> {t("actions.sendEInvoice")}
        </Button>
        <Button variant="switch" active={deliveryMethod === "manual"} onClick={() => changeDeliveryMethod("manual")}>
          <i className="fas fa-exclamation" /> {t("actions.sendManual")}
        </Button>
      </ButtonGroup>
      <small className="form-text">
        {
          {
            email: t("actions.sendEmailInfo"),
            // Draft: t("ci.sendDraftInfo"),
            mail: t("actions.sendMailInfo"),
            einvoice: t("actions.sendEInvoiceInfo"),
            manual: t("actions.sendDoNotInfo"),
          }[deliveryMethod]
        }
      </small>
    </div>
  );
}

export default CustomerInvoiceForm;
export { DeliveryMethodSwitcher };
