import React, { memo } from "react";
import { SubmitButton } from "components/ui/buttons";

function ConsultSettingsSaveButtons({ isSubmitting, savePerms }) {
  return (
    <SubmitButton
      isSubmitting={isSubmitting}
      type="button"
      onClick={() => savePerms(true)}
      icon=""
      className="ms-1 px-4"
      size="lg"
    />
  );
}

export default memo(ConsultSettingsSaveButtons);
