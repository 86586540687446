import React, { useEffect } from "react";

import useTable from "components/tables/btable/useTable";
import { formatDuration } from "utils/date";
import { formatMoney } from "utils/money";
import { useTranslation } from "react-i18next";

function countFilledInputs(data) {
  const output = {
    time_spend: 0,
    invoiced_amount: 0,
    invoiced_hour: 0,
  };
  data.forEach((company) => {
    output.time_spend += company.time_spend;
    output.invoiced_amount += company.invoiced_amount;
    // output.invoiced_hour += company.invoiced_hour;
  });
  if (output.time_spend !== 0) {
    output.invoiced_hour = Math.round(output.invoiced_amount / (output.time_spend / 3600));
  }
  return output;
}

function ProfitabilityExtraHeader() {
  const { data } = useTable();
  const [calc, setCalc] = React.useState({});
  const { t } = useTranslation("company");
  useEffect(() => {
    setCalc(countFilledInputs(data));
  }, [data]);

  return (
    <tr className="sticky2">
      <th colSpan={3} className="text-end">
        {t("manage.totalAbove")}
      </th>
      <th className="text-center">{formatDuration(calc.time_spend)}</th>
      <th className="text-center">{formatMoney(calc.invoiced_amount)}</th>
      <th className="text-center">{formatMoney(calc.invoiced_hour)}</th>
    </tr>
  );
}

export default ProfitabilityExtraHeader;
