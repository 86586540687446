import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import * as reconAPI from "api2/reconciliation";
import { formatDate } from "utils/date";
import { confirmExecute } from "components/modals/ConfirmModal";
import { toast } from "react-toastify";

function FetchAllSpecificationButton({ companyId, financialYear, reconciliationDate, onFetchSuccess }) {
  const { t } = useTranslation(["common", "reports"]);
  const [loading, setLoading] = useState(false);
  const fetchSpecificationForAccount = React.useCallback(async () => {
    const answer = await confirmExecute(
      t("reports:confirm.fetchAllSpecifications", {
        date: formatDate(reconciliationDate),
      })
    );
    if (!answer) {
      return;
    }
    setLoading(true);
    reconAPI.specifications
      .fetchAll(companyId, {
        financial_year: financialYear.id,
        date_from: formatDate(financialYear.date_start),
        date_to: reconciliationDate,
      })
      .then((response) => {
        if (onFetchSuccess) {
          onFetchSuccess(response.data);
        }
      })
      .catch((error) => {
        if (error.data.__all__) {
          toast.error(error.data.__all__);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [t, companyId, onFetchSuccess, financialYear.id, financialYear.date_start, reconciliationDate]);

  return (
    <Button variant="blue" onClick={fetchSpecificationForAccount} disabled={loading}>
      {loading ? (
        <span>
          <i className="fas fa-spinner fa-pulse me-1" /> {t("actions.processing")}...
        </span>
      ) : (
        <span>
          <i className="fas fa-plus me-1" /> {t("actions.fetch")}
        </span>
      )}
    </Button>
  );
}

export default FetchAllSpecificationButton;
