import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { Link, useResolvedPath } from "react-router";
import { useTranslation } from "react-i18next";

import { SearchFilter } from "components/filters";
import useTable from "components/tables/btable/useTable";
import useModal from "hooks/useModal";
import { CreateDraftPeriodModal, SendDraftModal } from "components/modals/contracts";

function ContractInvoiceFilters({ companyId }) {
  const { filters, setFilters } = useTable();
  const { t } = useTranslation("common");
  const path = useResolvedPath("").pathname;
  const sendAllModal = useModal();
  const draftModal = useModal();
  return (
    <>
      <section className="table-filters-left">
        <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} />
        <ButtonGroup aria-label="Status filter" className="status-filter">
          <Button
            variant="toggle"
            active={!filters.mode || filters.mode === "all"}
            onClick={() => setFilters({ mode: "" })}
          >
            {t("common:statuses.all")}
          </Button>
          <Button
            variant="toggle-green"
            active={filters.mode === "active"}
            onClick={() => setFilters({ mode: "active" })}
          >
            {t("common:statuses.active")}
          </Button>
          <Button
            variant="toggle-orange"
            active={filters.mode === "inactive"}
            onClick={() => setFilters({ mode: "inactive" })}
          >
            {t("common:statuses.inactive")}
          </Button>
        </ButtonGroup>
      </section>
      <section className="table-filters-right">
        <ButtonGroup>
          <Link to={`${path}/form`} className="btn btn-blue">
            <i className="fas fa-plus" /> {t("common:actions.create")}
          </Link>
          <Button variant="secondary" onClick={draftModal.open}>
            <i className="fas fa-asterisk" /> {t("ci:actions.contractCreateAllDrafts")}
          </Button>
          <Button variant="secondary" onClick={sendAllModal.open}>
            <i className="fas fa-paper-plane" /> {t("ci:actions.contractSendDrafts")}
          </Button>
        </ButtonGroup>
      </section>
      {draftModal.show && <CreateDraftPeriodModal companyId={companyId} onCancel={draftModal.close} />}
      {sendAllModal.show && <SendDraftModal companyId={companyId} onCancel={sendAllModal.close} />}
    </>
  );
}

export default ContractInvoiceFilters;
