import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { DocumentDocumentsButton, HistoryButton, TooltipActionButton, TooltipActionLink } from "components/ui/buttons";
import { confirmExecute, confirmRemove } from "components/modals/ConfirmModal";
import * as documentsAPI from "api2/documents";
import useTable from "components/tables/btable/useTable";
import { useResolvedPath } from "react-router";

function ConsultArchiveActions({ row, companyId, setCounter, reloadIndicators }) {
  const { t } = useTranslation("common");
  const url = useResolvedPath("").pathname;
  const {
    dataActions: { reload },
  } = useTable();

  const onDelete = async () => {
    const answer = await confirmRemove(t("others:confirm.removeFileArchive", { title: row.title }));
    if (answer) {
      documentsAPI.consultDocuments
        .remove(companyId, row.id)
        .then(() => {
          toast.success(t("msg:deleted"));
          setCounter((c) => c + 1);
          reload();
          reloadIndicators();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };

  const onDuplicate = async () => {
    const answer = await confirmExecute(t("others:confirm.duplicateFileArchive", { title: row.title }));
    if (answer) {
      documentsAPI.consultDocuments
        .duplicate(companyId, row.id)
        .then(() => {
          toast.success(t("msg:generated"));
          setCounter((c) => c + 1);
          reload();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };
  const onToggleArchive = async () => {
    const answer = await confirmExecute(
      t(row.is_archived ? "others:confirm.unarchive" : "others:confirm.archive"),
      t(row.is_archived ? "actions.yesUnarchive" : "actions.yesArchive"),
      "actions.cancel",
      true
    );
    if (answer) {
      documentsAPI.consultDocuments
        .toggleArchive(companyId, row.id)
        .then(() => {
          toast.success(t(row.is_archived ? "msg:unarchived" : "msg:archived"));
          reload();
        })
        .catch(() => toast.error(t("msg:canNotExecuteAction")));
    }
  };
  return (
    <>
      <HistoryButton apiResource={documentsAPI.consultDocuments.history} apiParams={[companyId, row.id]} />
      {row.has_file && (
        <DocumentDocumentsButton companyId={companyId} documentsIds={[row.id]} partUrl="documents/consultant" />
      )}
      <TooltipActionButton text={t("common:actions.duplicate")} onClick={onDuplicate} icon="fas fa-copy" />
      <TooltipActionLink text={t("common:actions.change")} link={`${url}/${row.id}/change`} icon="fas fa-pen" />
      {row.is_archived ? (
        <TooltipActionButton
          variant="success"
          text={t("common:actions.unarchive")}
          onClick={onToggleArchive}
          icon="fe-rotate-cw"
        />
      ) : (
        <TooltipActionButton text={t("common:actions.archive")} onClick={onToggleArchive} icon="fas fa-archive" />
      )}
      <TooltipActionButton
        variant="action-red"
        text={t("common:actions.remove")}
        onClick={onDelete}
        icon="fas fa-trash"
      />
    </>
  );
}

export default ConsultArchiveActions;
