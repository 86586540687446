import { useLocation } from "react-router";
import { formatISO, isDate } from "date-fns";
import _ from "lodash";
import { isSameDayOrAfter, isSameDayOrBefore, parseDate } from "./date";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function getDefaultRounding() {
  return window.localStorage.getItem("rounding") || "3";
}

export function scrollToSection(sectionID) {
  document.getElementById(sectionID).scrollIntoView();
}

const today = new Date();
today.setHours(0, 0, 0, 0);

export function filterActiveProjects(projectOptions, startDate = null, endDate = null) {
  if (!Array.isArray(projectOptions)) {
    return [];
  }

  const parseInputDate = (date) => {
    if (!date) return null;
    if (date instanceof Date) return date;
    if (typeof date === "string") return parseDate(date);
    throw new Error("Invalid date format");
  };

  const filterStartDate = parseInputDate(startDate) || new Date();
  const filterEndDate = endDate !== null ? parseInputDate(endDate) : filterStartDate;

  return projectOptions
    .map((project) => {
      const projectStartDate = parseInputDate(project.date_start);
      const projectEndDate = project.date_end ? parseInputDate(project.date_end) : null;

      const isActive =
        projectStartDate &&
        isSameDayOrBefore(projectStartDate, filterEndDate) &&
        (!projectEndDate || isSameDayOrAfter(projectEndDate, filterStartDate));

      return {
        ...project,
        is_active: !!isActive,
        isDisabled: !isActive,
      };
    })
    .filter((project) => project.is_active);
}

export function prepareProjectsForFilter(projectOptions) {
  // Prepares project options for use in filter components by setting 'isDisabled' to false for all projects.
  // This ensures that all projects, regardless of their original disabled status, can be selected in filter dropdowns.
  return projectOptions.map((project) => ({
    ...project,
    isDisabled: false,
  }));
}

export function filterActiveCC(option) {
  return option.is_active;
}

export function formatFilters(filters) {
  const newFilters = { ...filters };
  Object.keys(filters).forEach((key) => {
    if (isDate(filters[key])) {
      newFilters[key] = formatISO(filters[key], { representation: "date" });
    }
  });
  return newFilters;
}

export function enterKeyDownAsTab(event) {
  // prevent submitting form by Enter
  if (event.key === "Enter") {
    event.preventDefault();
    if (event.target.nodeName === "INPUT") {
      const { form } = event.target;
      const index = Array.prototype.indexOf.call(form, event.target);
      let elem;
      for (let i = index; i <= form.elements.length; i++) {
        elem = form.elements[i + 1];
        if (elem && elem.type === "text" && !elem.disabled) {
          break;
        }
        elem = null;
      }
      if (elem && event.target.className.includes("money-input")) {
        setTimeout(() => {
          elem.focus();
          elem.setSelectionRange(0, 1000);
        }, 100);
      } else if (elem) {
        elem.focus();
        elem.setSelectionRange(0, 1000);
      }
    }
  }
}

export function enterKeyDownAsTabForVerModal(event) {
  // difference then standard enterKeyDownAsTab: jump to submit button after last input
  if (event.key === "Enter" && event.target?.nodeName !== "BUTTON") {
    event.preventDefault();
    if (event.target.nodeName === "INPUT") {
      const { form } = event.target;
      const index = Array.prototype.indexOf.call(form, event.target);
      let elem;
      for (let i = index; i <= form.elements.length; i++) {
        elem = form.elements[i + 1];
        if (elem && ["text", "submit"].includes(elem.type) && !elem.disabled) {
          break;
        }
        elem = null;
      }
      if (
        elem &&
        (event.target.className.includes("money-input") || event.target.className.includes("react-datepicker"))
      ) {
        setTimeout(() => {
          elem.focus();
          if (elem.type === "text") {
            elem.setSelectionRange(0, 1000);
          }
        }, 100);
      } else if (elem) {
        elem.focus();
        if (elem.type === "text") {
          elem.setSelectionRange(0, 1000);
        }
      }
    }
  }
}

export function transformDotNotationObject(obj) {
  return _.transform(
    obj,
    (result, value, key) => {
      _.set(result, key, value);
    },
    {}
  );
}

export function filterOptionStartsWith(inputValue, option) {
  const lowercasedInput = inputValue.toLowerCase();
  return option.label.toLowerCase().startsWith(lowercasedInput);
}

export function filterOptionIncludes(inputValue, option) {
  const lowercasedInput = inputValue.toLowerCase();
  return option.label.toLowerCase().includes(lowercasedInput);
}
