import React from "react";
import { Outlet, useLocation, useOutletContext } from "react-router";
import { CustomerInvoiceNotEnabledErrorPage } from "pages/errors";
import InvoicingNavTabs from "./InvoicingNavTabs";

function InvoicingLayout() {
  const company = useOutletContext();
  const path = useLocation().pathname;
  const showTabs = ["/reports"].every((key) => !path.includes(key));
  if (!company.customer_invoice_enabled) {
    return <CustomerInvoiceNotEnabledErrorPage />;
  }
  return (
    <div className="tab-card">
      {showTabs && <InvoicingNavTabs />}
      <Outlet context={company} />
    </div>
  );
}

export default InvoicingLayout;
