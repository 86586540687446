import { NavLink, useResolvedPath } from "react-router";
import React from "react";
import { useTranslation } from "react-i18next";

import { PermCodeRequired } from "components/perms";
import { codesForSupplierInvoices } from "components/perms/PermCodes";

function SupplierInvoiceNavTabs() {
  const { t } = useTranslation("navigation");
  const path = useResolvedPath("").pathname;
  return (
    <ul className="nav nav-tabs nav-bordered nav-card pt-3" role="tablist">
      <li className="nav-item">
        <NavLink to={`${path}`} end className="nav-link">
          {t("supplierInvoices")}
        </NavLink>
      </li>
      <PermCodeRequired code={codesForSupplierInvoices.localSuppliers}>
        <li className="nav-item">
          <NavLink to={`${path}/suppliers`} className="nav-link" end>
            {t("suppliers")}
          </NavLink>
        </li>
      </PermCodeRequired>
    </ul>
  );
}

export default SupplierInvoiceNavTabs;
