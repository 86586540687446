import React from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { FieldArray, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import * as yup from "yup";

import { SubmitButton } from "components/ui/buttons";
import { formatMoney } from "utils/money";
import { FormGroup, TableGroup } from "components/formik";
import { autoFillRotRut, calculateRecordRotRutTotal } from "utils/calc/ci";
import cx from "classnames";
import PersonalNumber from "personnummer";
import rotRutTypes, { rotRutByValue } from "utils/calc/rotRutTypes";
import { DraggableModalDialog } from "../DraggableModalDialog";

function RotRutModal({
  currency,
  companyId,
  customer,
  records,
  rotRutDetails,
  onSave,
  onCancel,
  previewOnly = false,
  isFullyPaid = false,
}) {
  const { t } = useTranslation("ci");
  const initialPropertyDesignation = (() => {
    if (previewOnly) {
      return rotRutDetails ? rotRutDetails.property_designation : "";
    }
    return rotRutDetails
      ? rotRutDetails.property_designation || customer?.property_designation
      : customer?.property_designation || "";
  })();
  const initialResidenceAssociationOrgNo = (() => {
    if (previewOnly) {
      return rotRutDetails ? rotRutDetails.residence_org_no : "";
    }
    return rotRutDetails
      ? rotRutDetails.residence_org_no || customer?.residence_org_no
      : customer?.residence_org_no || "";
  })();
  const formikProps = {
    validateOnMount: true,
    initialValues: {
      RotRutDetails: rotRutDetails
        ? { ...rotRutDetails }
        : {
            customers: [],
            property_designation: initialPropertyDesignation,
            residence_org_no: initialResidenceAssociationOrgNo,
            work_start: null,
            work_end: null,
          },
      records: records.map((record) => ({
        ...record,
        rot_rut_activated: !!record.rot_rut,
        rot_rut: record.rot_rut
          ? {
              hours: record.rot_rut.hours,
              amount: record.rot_rut.amount,
              material_cost: record.rot_rut.material_cost,
              type: rotRutByValue(record.rot_rut.type),
            }
          : {
              hours: 0,
              amount: 0,
              material_cost: 0,
              type: rotRutTypes[0].options[0],
            },
      })),
    },
    validationSchema: yup
      .object()
      .shape({
        RotRutDetails: yup
          .object()
          .shape({
            property_designation: yup.string(),
            residence_org_no: yup.string(),
            customers: yup.array().of(
              yup.object().shape({
                ssn: yup
                  .string()
                  .required(t("common:errors.personalNumberRequired"))
                  .test("ssnValid", function (ssn2) {
                    if (!PersonalNumber.valid(ssn2)) {
                      return this.createError({
                        path: this.path,
                        message: t("common:errors.invalidPersonalNumber"),
                      });
                    }
                    return true;
                  }),
                amount: yup.number().required(t("errors.deductionRequired")),
              })
            ),
            work_start: yup.date().nullable(),
            work_end: yup.date().nullable(),
          })
          .test("workEnd-filled-requires-workStart", t("ci:errors.workDatesBothOrNone"), function (value) {
            const { work_start, work_end } = value;
            if (work_end && !work_start) {
              return this.createError({
                path: "RotRutDetails.work_start",
                message: t("ci:errors.workDatesBothOrNone"),
              });
            }
            return true;
          })
          .test("workStart-filled-requires-workEnd", t("ci:errors.workDatesBothOrNone"), function (value) {
            const { work_start, work_end } = value;
            if (work_start && !work_end) {
              return this.createError({
                path: "RotRutDetails.work_end",
                message: t("ci:errors.workDatesBothOrNone"),
              });
            }
            return true;
          })
          .test("workEndAfterWorkStart", "ci:errors.rotRutWorkEndBeforeWorkStart", function (value) {
            const { work_start, work_end } = value;
            if (work_start && work_end) {
              const startDate = new Date(work_start);
              const endDate = new Date(work_end);

              if (endDate < startDate) {
                return this.createError({
                  path: `RotRutDetails.work_end`,
                  message: t("ci:errors.rotRutWorkEndBeforeWorkStart"),
                });
              }
            }
            return true;
          }),
        records: yup.array().test("rotRutType", function (records2) {
          let type = "";
          for (let i = 0; i < records2.length; i++) {
            if (records2[i].rot_rut_activated) {
              if (type && records2[i].rot_rut.type.type !== type) {
                // eslint-disable-next-line react/no-this-in-sfc
                return this.createError({
                  path: "__all__",
                  message: t("errors.ROTorRUT"),
                });
              }
              type = records2[i].rot_rut.type.type;
            }
          }
          return true;
        }),
      })
      // eslint-disable-next-line func-names
      .test("rotExtra", function (values) {
        const activeRecords = values.records.filter((record) => record.rot_rut_activated === true);
        const isROT = activeRecords.length && activeRecords[0].rot_rut.type.type === "ROT";
        if (isROT && !values.RotRutDetails.property_designation) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({
            path: "RotRutDetails.property_designation",
            message: t("common:errors.required"),
          });
        }
        return true;
      }),
    onSubmit: async (values) => {
      let tmp;
      onSave({
        ...values,
        records: values.records.map((record) => {
          // record.RotRutAmount = calculateRecordRotRutTotal(record);
          tmp = {
            ...record,
            rot_rut: record.rot_rut_activated
              ? {
                  ...record.rot_rut,
                  type: record.rot_rut.type.value,
                }
              : null,
          };
          delete tmp.index;
          delete tmp.rot_rut_activated;
          return tmp;
        }),
      });
    },
  };

  function autoFill(values, setValues) {
    const newRotRut = autoFillRotRut(values);
    setValues(newRotRut);
  }

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={onCancel}
      dialogClassName="rot-rut-modal"
      size="xl"
      dialogAs={DraggableModalDialog}
    >
      <Formik {...formikProps} r>
        {({ values, errors, setValues, isValid, setFieldValue, dirty }) => {
          const serviceRecords = values.records
            .filter((item) => item.product && item.product.product_type === "service")
            .map((item) => ({
              ...item,
              availableDeduction: calculateRecordRotRutTotal(item),
            }));
          const toDeduct = serviceRecords.reduce((total, record) => total + record.rot_rut.amount, 0);
          const leftDeduct = toDeduct - values.RotRutDetails.customers.reduce((total, c) => total + c.amount || 0, 0);
          const activeRecords = serviceRecords.filter((record) => record.rot_rut_activated === true);
          const isROT = activeRecords.length && activeRecords[0].rot_rut.type.type === "ROT";
          return (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title className="m-0">{t("rotRutDeduction")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-0" style={{ maxHeight: "60vh", overflowX: "hidden" }}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>{t("rotRutEnabled")}</th>
                      <th style={{ width: 200 }}>{t("rotRut")}</th>
                      <th style={{ width: 150 }} className="text-end">
                        {t("hours")}
                      </th>
                      <th style={{ width: 150 }} className="text-end">
                        {t("materialCost")}
                      </th>
                      <th style={{ width: 180 }} className="text-end">
                        {t("availableDeduction")}
                      </th>
                      <th style={{ width: 150 }} className="text-end">
                        {t("usedDeduction")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray name="records">
                      <>
                        {serviceRecords.map((record) => (
                          <React.Fragment key={record.key}>
                            <tr>
                              <td>
                                <FormGroup.Checkbox
                                  name={`records[${record.index}].rot_rut_activated`}
                                  label={record.product.name || t("common:product")}
                                  disabled={previewOnly}
                                />
                              </td>
                              <TableGroup.SimpleSelect
                                name={`records[${record.index}].rot_rut.type`}
                                options={rotRutTypes}
                                getOptionLabel={(option) => t(`options:rotRut.${option.label}`)}
                                isDisabled={!record.rot_rut_activated || previewOnly}
                              />
                              <TableGroup.NumberInput
                                className="text-end"
                                name={`records[${record.index}].rot_rut.hours`}
                                disabled={!record.rot_rut_activated || isFullyPaid}
                              />
                              <TableGroup.MoneyInput
                                name={`records[${record.index}].rot_rut.material_cost`}
                                disabled={!record.rot_rut_activated || isFullyPaid}
                              />
                              <th
                                className="disabled text-end align-middle"
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                              >
                                {formatMoney(record.availableDeduction)}
                              </th>
                              <TableGroup.MoneyInput
                                name={`records[${record.index}].rot_rut.amount`}
                                disabled={previewOnly}
                              />
                            </tr>
                          </React.Fragment>
                        ))}
                        {!serviceRecords.length && (
                          <tr>
                            <td colSpan={6} className="p-2">
                              <span>{t("common:noResultsFound")}</span>
                            </td>
                          </tr>
                        )}
                      </>
                    </FieldArray>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={5} className={cx({ "has-errors": errors.__all__ })}>
                        {errors.__all__}
                      </td>
                      <td className="text-end">
                        <span>
                          {formatMoney(toDeduct)} {currency}
                        </span>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
                <div className="rr-row">
                  <span className="p-0 mt-2 ms-2">{t("ci:timePeriodLabel")}</span>
                  <span className="p-0 mt-2 ms-1">{t("common:from")}</span>
                  <Col lg={2} sm={12} className="ms-2 me-2">
                    <FormGroup.DatePicker
                      name="RotRutDetails.work_start"
                      popperClassName="popper-in-modal"
                      disabled={previewOnly}
                    />
                  </Col>
                  <span className="p-0 mt-2">{t("common:to")}</span>
                  <Col lg={2} sm={12} className="ms-2 me-2">
                    <FormGroup.DatePicker
                      name="RotRutDetails.work_end"
                      popperClassName="popper-in-modal"
                      disabled={previewOnly}
                    />
                  </Col>
                </div>
                <>
                  {isROT ? (
                    <>
                      <Row>
                        <Col lg={5} sm={12} className="ms-2">
                          <FormGroup.Input
                            name="RotRutDetails.property_designation"
                            required
                            label={t("propertyDesignation")}
                            disabled={previewOnly}
                          />
                        </Col>
                        {!previewOnly &&
                          values.RotRutDetails.property_designation !== customer.property_designation && (
                            <Col lg={5} sm={12}>
                              <Button
                                type="button"
                                variant="warning"
                                className="mt-3"
                                onClick={() => {
                                  setFieldValue(
                                    "RotRutDetails.updateCustomerPropertyDesignation",
                                    !values.RotRutDetails.updateCustomerPropertyDesignation
                                  );
                                }}
                              >
                                {values.RotRutDetails.updateCustomerPropertyDesignation ? (
                                  <i className="fas fa-check-square me-1" />
                                ) : (
                                  <i className="fas fa-square me-1" />
                                )}
                                {t("updateCustomerWithNewData")}
                              </Button>
                            </Col>
                          )}
                      </Row>
                      <Row>
                        <Col lg={5} sm={12} className="ms-2">
                          <FormGroup.Input
                            name="RotRutDetails.residence_org_no"
                            label={t("residenceNo")}
                            disabled={previewOnly}
                          />
                        </Col>
                        {!previewOnly && values.RotRutDetails.residence_org_no !== customer.residence_org_no && (
                          <Col lg={5} sm={12}>
                            <Button
                              type="button"
                              variant="warning"
                              className="mt-3"
                              onClick={() => {
                                setFieldValue(
                                  "RotRutDetails.updateCustomerResidenceAssociationOrgNo",
                                  !values.RotRutDetails.updateCustomerResidenceAssociationOrgNo
                                );
                              }}
                            >
                              {values.RotRutDetails.updateCustomerResidenceAssociationOrgNo ? (
                                <i className="fas fa-check-square me-1" />
                              ) : (
                                <i className="fas fa-square me-1" />
                              )}
                              {t("Update customer with new value")}
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </>
                  ) : null}
                  <p className="p-0 m-0 ms-2">{t("howShouldDeduct")}</p>
                  <FieldArray name="RotRutDetails.customers">
                    {({ remove, push }) => (
                      <Table bordered className="border-bottom-0">
                        <thead>
                          <tr>
                            <th>{t("common:name")}</th>
                            <th>{t("common:contact.personalNumber")}*</th>
                            <th style={{ width: 200 }} className="text-end">
                              {t("deduction")}*
                            </th>
                            <th style={{ width: 50 }} />
                          </tr>
                        </thead>
                        <tbody>
                          {values.RotRutDetails.customers.map((c, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <TableGroup.Input
                                  name={`RotRutDetails.customers[${index}].name`}
                                  disabled={previewOnly}
                                />
                                <TableGroup.Input
                                  name={`RotRutDetails.customers[${index}].ssn`}
                                  disabled={previewOnly}
                                />
                                <TableGroup.MoneyInput
                                  name={`RotRutDetails.customers[${index}].amount`}
                                  disabled={previewOnly}
                                />
                                <td className="text-center">
                                  {!previewOnly && (
                                    <Button variant="toggle-red" size="sm" onClick={() => remove(index)}>
                                      <i className="fas fa-trash" />
                                    </Button>
                                  )}
                                </td>
                              </tr>
                              <TableGroup.RowErrors
                                errors={
                                  errors.RotRutDetails &&
                                  errors.RotRutDetails.customers &&
                                  errors.RotRutDetails.customers[index]
                                }
                              />
                            </React.Fragment>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td className="border-0">
                              {!previewOnly && (
                                <Button
                                  variant="toggle-green"
                                  size="sm"
                                  onClick={() => {
                                    push({
                                      amount: leftDeduct || 0,
                                      ssn: !values.RotRutDetails.customers.length ? customer.identification || "" : "",
                                      name: !values.RotRutDetails.customers.length ? customer.name : "",
                                    });
                                  }}
                                >
                                  <i className="fas fa-plus" /> {t("actions.addMember")}
                                </Button>
                              )}
                            </td>
                            <td className="border-0" />
                            <td className="text-end text-danger">
                              {t("leftDeduct")} {formatMoney(leftDeduct)} {currency}
                            </td>
                            <td className="border-0" />
                          </tr>
                        </tfoot>
                      </Table>
                    )}
                  </FieldArray>
                </>
              </Modal.Body>
              <Modal.Footer className="space">
                <Button type="button" variant="secondary" onClick={onCancel}>
                  {t("common:actions.cancel")}
                </Button>
                {!previewOnly && (
                  <>
                    <Button type="button" variant="primary" onClick={() => autoFill(values, setValues)}>
                      {t("common:actions.autoFill")}
                    </Button>
                    <SubmitButton disabled={!isValid || leftDeduct !== 0} />
                  </>
                )}
                {previewOnly && <SubmitButton disabled={!dirty} />}
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default RotRutModal;
