import React from "react";
import { ButtonGroup } from "react-bootstrap";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";

function SilverbackFilters() {
  const { t } = useTranslation("common");
  return (
    <>
      <section className="table-filters-left" />
      <section className="table-filters-right">
        <ButtonGroup>
          <Link to="/consult/silverback/form" className="btn btn-blue">
            <i className="fas fa-plus" /> {t("common:actions.create")}
          </Link>
        </ButtonGroup>
      </section>
    </>
  );
}

export default SilverbackFilters;
