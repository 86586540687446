import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useResolvedPath } from "react-router";

import * as orderAPI from "api2/orders";
import { confirmExecute } from "components/modals/ConfirmModal";
import { HistoryButton, TooltipActionButton, TooltipActionLink } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import { FileUrlModal } from "components/modals";
import useModal from "hooks/useModal";

function OrderActions({ row, companyId }) {
  const { t } = useTranslation("ci");
  const path = useResolvedPath("").pathname;
  const fileModal = useModal();
  const {
    dataActions: { reload },
  } = useTable();

  const show = {
    invoiceBtn: row.status === "active",
    cancelBtn: row.status === "active",
    sentBtn: row.status === "active",
    editBtn: row.status === "active",
  };
  const openPreviewModal = () => {
    fileModal.open(`/orders/${row.id}/pdf/`);
  };

  const onSentToCustomer = async () => {
    const answer = await confirmExecute(
      t("confirm.sendOrder", {
        customer: row.customer_name,
        email: row.customer_emails.length
          ? row.customer_emails.join(", ")
          : `${t("common:contact.emailShort")} ${t("common:notSet")}`,
      })
    );

    if (answer) {
      orderAPI
        .sendToCustomer(companyId, row.id)
        .then(() => {
          toast.success(t("msg:sent"));
          reload();
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };

  const onInvoice = async () => {
    const answer = await confirmExecute(t("confirm.invoiceOrder", { customerName: row.customer_name }));
    if (answer) {
      orderAPI
        .invoiceOrderUpdate(companyId, row)
        .then(() => {
          toast.success(t("msg:generated"));
          reload();
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };
  const onCancel = async () => {
    const answer = await confirmExecute(t("confirm.cancelOrder", { customerName: row.customer_name }));
    if (answer) {
      orderAPI
        .cancelOrder(companyId, row)
        .then(() => {
          toast.success(t("ci:msg:orderCanceled"));
          reload();
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };
  return (
    <>
      <HistoryButton apiResource={orderAPI.history} apiParams={[companyId, row.id]} />
      <TooltipActionButton text={t("common:actions.preview")} onClick={openPreviewModal} icon="fas fa-images" />
      {show.sentBtn && (
        <TooltipActionButton
          text={t("actions.sendOrderToCustomer")}
          onClick={onSentToCustomer}
          icon="fas fa-paper-plane"
        />
      )}
      {show.editBtn && (
        <TooltipActionLink text={t("common:actions.change")} link={`${path}/${row.id}/change`} icon="fas fa-pen" />
      )}
      <TooltipActionLink text={t("common:actions.duplicate")} link={`${path}/${row.id}/duplicate`} icon="fas fa-copy" />
      {show.invoiceBtn && (
        <div className="invoice-order-btn">
          <span className="dot" />
          <TooltipActionButton text={t("actions.invoiceOrder")} onClick={onInvoice} icon="fe-file-plus" />
        </div>
      )}
      {show.cancelBtn && <TooltipActionButton text={t("actions.cancelOrder")} onClick={onCancel} icon="fas fa-trash" />}
      {fileModal.show && <FileUrlModal companyId={companyId} fileUrl={fileModal.data} handleClose={fileModal.close} />}
    </>
  );
}

export default OrderActions;
