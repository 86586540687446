import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import axios from "axios";
import qs from "qs";
import * as reportAPI from "api2/reports";
import TableLoader from "components/tables/btable/TableLoader";
import ProfitLossYearTable from "./ProfitLossYearTable";

const initialReport = {
  months: [],
  categories: [],
  budgets: {},
  budgets_ack: 0,
  sums: {},
  ack: 0,
};

function BudgetPLYearly() {
  const { companyId, financialYear, showAllAccounts, setEditMode, accountTerm } = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(initialReport);
  useEffect(() => {
    const signal = axios.CancelToken.source();

    function getReport() {
      setLoading(true);
      reportAPI
        .profitAndLoss12(
          companyId,
          {
            date_from: financialYear.date_start,
            date_to: financialYear.date_end,
            financial_year: financialYear.id,
            show_all_accounts: showAllAccounts,
            with_budget: true,
            term: accountTerm,
          },
          {
            cancelToken: signal.token,
            paramsSerializer: (params) => {
              return qs.stringify(params, { indices: false });
            },
          }
        )
        .then((response) => {
          setReport(response.data);
          setLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setReport(initialReport);
            setLoading(false);
          }
        });
    }

    getReport();

    return () => {
      signal.cancel("aborted");
    };
  }, [
    companyId,
    financialYear.id,
    financialYear.date_start,
    setEditMode,
    financialYear.date_end,
    showAllAccounts,
    accountTerm,
  ]);

  if (loading) {
    return <TableLoader />;
  }

  return <ProfitLossYearTable report={report} />;
}

export default BudgetPLYearly;
