import React from "react";
import { useNavigate, useOutletContext } from "react-router";

import MileageForm from "components/forms/MileageForm";

function MileageAddPage() {
  const company = useOutletContext();
  const navigate = useNavigate();

  const outlay = {
    booking_date: new Date(),
    trip_from: "",
    trip_to: "",
    trip_purpose: "",
    distance: "",
    ownership: "private",
    fuel_type: "petrol",
    amount: undefined,
    amount_currency: "SEK",
    sub_status: "",
  };

  const onCreate = (newOutlay) => {
    navigate(-1);
  };

  return <MileageForm companyId={company.id} outlay={outlay} onSave={onCreate} />;
}

export default MileageAddPage;
