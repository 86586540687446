import React from "react";
import { Alert } from "react-bootstrap";
import { adminNotification } from "api2/notifications";
import useInitialAsync from "hooks/useInitialAsync";
import cx from "classnames";
import { useTranslation } from "react-i18next";

function AdminNotificationLoginAlert() {
  const { item: notifications } = useInitialAsync(
    ({ cancelToken }) => adminNotification.listPublic({ cancelToken }),
    [],
    []
  );
  const { i18n } = useTranslation();
  return (
    <div className="public-notifications mb-3" style={{ width: "100%" }}>
      {notifications.map((notification) => (
        <Alert variant={notification.color} key={notification.id}>
          <i
            className={cx({
              "fe-alert-triangle": notification.icon === "warn",
              "fe-info": notification.icon === "info",
              "fe-bell": notification.icon === "bell",
            })}
          />
          <div className="ms-1">{i18n.language === "en" ? notification.message_en : notification.message_sv}</div>
        </Alert>
      ))}
    </div>
  );
}

export default AdminNotificationLoginAlert;
