import { NavLink, useResolvedPath } from "react-router";
import React from "react";
import { useTranslation } from "react-i18next";

function StatsNavTabs() {
  const { t } = useTranslation("navigation");
  const path = useResolvedPath("").pathname;

  return (
    <ul className="nav nav-tabs nav-bordered nav-card pt-3" role="tablist">
      <li className="nav-item">
        <NavLink to={`${path}/companies`} className="nav-link">
          {t("companies")}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={`${path}/verifications`} className="nav-link">
          {t("verifications")}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={`${path}/customer-invoices`} className="nav-link">
          {t("customerInvoices")}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={`${path}/supplier-invoices`} className="nav-link">
          {t("supplierInvoices")}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={`${path}/outlays`} className="nav-link">
          {t("outlays")}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={`${path}/salaries`} className="nav-link">
          {t("salaries")}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={`${path}/users`} className="nav-link">
          {t("users")}
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to={`${path}/other`} className="nav-link">
          {t("other")}
        </NavLink>
      </li>
    </ul>
  );
}

export default StatsNavTabs;
