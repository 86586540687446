import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router";

import { DayToDayTable } from "components/tables";
import TableProvider from "components/tables/btable/provider";
import * as tableAPI from "api/table";
import { useCompanyDispatch } from "hooks/useCompany";

function DayToDayOperationsListPage({ t }) {
  const { selectCompany: selectCompanyAction } = useCompanyDispatch();
  const navigate = useNavigate();

  const selectCompany = async (companyId, nextRoute = "") => {
    await selectCompanyAction(companyId);
    navigate(nextRoute, { replace: true });
  };

  return (
    <Card id="company-manage-table">
      <Card.Body>
        <TableProvider
          tableId="company-manage2"
          initialFilters={{ favourites: true }}
          dataSource={tableAPI.manageDayToDay}
        >
          <DayToDayTable selectCompany={selectCompany} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default DayToDayOperationsListPage;
