import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import cx from "classnames";
import { validFiles as validFilesApi } from "api2/documents";
import { BASE_ALLOWED_FILE_SIZE } from "utils/file";

function UploadButton({ fileSize = BASE_ALLOWED_FILE_SIZE, fileTypes, setFile, fileAttached }) {
  const fileRef = useRef(null);
  const { t } = useTranslation("common");

  function validate(selectedFile) {
    const backendValidation = () => {
      validFilesApi
        .send([selectedFile], fileTypes, fileSize)
        .then(() => {})
        .catch(() => {
          toast.error(t("msg:fileBackendValidationFailed", { fileName: selectedFile.name }));
        });
    };
    const extension = `.${selectedFile.name.split(".").pop().toLowerCase()}`;
    if (!fileTypes.includes(extension)) {
      // allowed extension...
      toast.error(t("common:file.notSupported", { formats: fileTypes }), {
        autoClose: 4000,
      });
      return false;
    }
    if (selectedFile.size > fileSize) {
      toast.error(`The file size is too big. Max ${(fileSize / (1024 * 1024)).toFixed(1)} MB allowed`, {
        autoClose: 4000,
      });
      return false;
    }
    backendValidation(); // add file even if backend validation failed - only popup msg
    return true;
  }

  function onFileSelected(event) {
    event.preventDefault();
    const selectedFile = (event.target.files || event.dataTransfer.files)[0];
    if (selectedFile) {
      const isValid = validate(selectedFile);
      if (isValid) {
        // handle
        setFile(selectedFile);
      }
    }
  }

  return (
    <div
      className={cx("upload-btn", {
        disabled: !!fileAttached,
      })}
    >
      <i className="fe-upload" onClick={() => fileRef.current.click()} />
      <input ref={fileRef} type="file" onChange={onFileSelected} />
    </div>
  );
}

export default UploadButton;
