import React, { useContext } from "react";
import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

import { SelectionContext } from "state/providers/SelectionProvider";
import useModal from "hooks/useModal";
import { ApproveResultsModal } from "components/modals/ResultsModal";
import { approve as siApprove } from "api2/supplier-invoices";
import { approve as outApprove } from "api2/outlays";
import { approve as salApprove } from "api2/salaries";
import { approve as absenceApprove } from "api2/absence";
import { useMenuNumbersDispatch } from "hooks/useMenuNumbers";
import { codesFor } from "components/perms/PermCodes";
import { HasPermCode } from "components/perms";

function ApproveBox() {
  const { t } = useTranslation("common");

  const navigate = useNavigate();
  const location = useLocation();
  const { selection, getFlatten, clearSelection } = useContext(SelectionContext);
  const { reload: reloadMenuNumbers } = useMenuNumbersDispatch();
  const approvalModal = useModal();
  const canApproveSI = HasPermCode(codesFor.supplierInvoices.approve);
  const canApproveOutlays = HasPermCode(codesFor.outlays.approve);
  const canApproveSalaries = HasPermCode(codesFor.salaries.approve);
  const canApproveAbsences = HasPermCode(codesFor.absences.approve);
  const siCount = canApproveSI ? Object.values(selection.prelimSupplierInvoices || {}).length : 0;
  const outCount = canApproveOutlays ? Object.values(selection.prelimOutlays || {}).length : 0;
  const salCount = canApproveSalaries ? Object.values(selection.prelimSalaries || {}).length : 0;
  const absCount = canApproveAbsences ? Object.values(selection.prelimAbsences || {}).length : 0;
  const count = siCount + outCount + salCount + absCount;

  const onApprovalModalOpen = () => {
    const flattenSelection = getFlatten();
    const items = [];
    flattenSelection.forEach((item) => {
      let apiRequest;
      switch (item.object_type) {
        case "si":
          apiRequest = siApprove;
          break;
        case "out":
          apiRequest = outApprove;
          break;
        case "sal":
          apiRequest = salApprove;
          break;
        case "abs_rep":
          apiRequest = absenceApprove;
          break;
        default:
          apiRequest = undefined;
      }
      if (apiRequest) {
        items.push({ id: item.id, title: item.title, apiRequest });
      }
    });
    approvalModal.open(items);
  };

  const onApprovalModalClose = (success) => {
    approvalModal.close();
    clearSelection();
    if (success === true) {
      reloadMenuNumbers();
    }
    navigate(location.pathname, { replace: true }); // to reload table, because outside of table
  };

  return (
    <>
      <Card id="payment-box">
        <Card.Body>
          <Button
            variant="toggle-orange"
            size="lg"
            disabled={count <= 0}
            active={count > 0}
            onClick={onApprovalModalOpen}
          >
            {t("actions.approveSelected")}: {count}
          </Button>
          <div className="totals">
            <div>
              <strong>{t("navigation:supplierInvoices")}:</strong>
              {siCount}
            </div>
            <div>
              <strong>{t("navigation:outlays")}:</strong>
              {outCount}
            </div>
            <div>
              <strong>{t("navigation:salaries")}:</strong>
              {salCount}
            </div>
            <div>
              <strong>{t("navigation:employeeAbsence")}:</strong>
              {absCount}
            </div>
          </div>
        </Card.Body>
      </Card>
      {approvalModal.show && (
        <ApproveResultsModal selectedItems={approvalModal.data} handleClose={onApprovalModalClose} />
      )}
    </>
  );
}

export default ApproveBox;
