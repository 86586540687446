import React from "react";
import { Card } from "react-bootstrap";
import { useOutletContext, useParams } from "react-router";

import TableProvider from "components/tables/btable/provider";
import { SalaryArchiveTable } from "components/tables";
import * as documentsAPI from "api2/documents";

function SalaryArchiveListPage({ initialCategory, selectable = false, tableId = "salaryArchive" }) {
  const company = useOutletContext();

  const { category } = useParams();
  const initialFilters = {
    category: initialCategory || category || "",
    projection: "",
  };
  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => documentsAPI.salaryDocuments.list(company.id, filters, config)}
          initialFilters={initialFilters}
        >
          <SalaryArchiveTable companyId={company.id} selectable={selectable} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default SalaryArchiveListPage;
