import React from "react";
import { useTranslation } from "react-i18next";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import Waves from "node-waves";
import * as yup from "yup";
import sv from "date-fns/locale/sv";
import en from "date-fns/locale/en-US";
import { createBrowserRouter, RouterProvider } from "react-router";
import UserProvider from "state/providers/UserProvider";
import CompanyProvider from "state/providers/CompanyProvider";
import { routes } from "./routes/routes";

Waves.init();
registerLocale("sv", sv);
registerLocale("en", en);

const router = createBrowserRouter(routes);

function Root({ initialState = {} }) {
  const { t, i18n } = useTranslation("common");

  setDefaultLocale(i18n.language);

  yup.addMethod(yup.string, "phone", function () {
    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{0,4}\)?)?$/;
    return this.test("phone", t("errors.invalid.phone"), (value) => phoneRegExp.test(value));
  });

  yup.setLocale({
    mixed: {
      required: t("errors.required"),
    },
    string: {
      email: t("errors.invalid.email"),
      min: ({ min }) => t("errors.invalid.minS", { min }),
      max: ({ max }) => t("errors.invalid.maxS", { max }),
    },
    number: {
      min: ({ min }) => t("errors.invalid.min", { min }),
      max: ({ max }) => t("errors.invalid.max", { max }),
      positive: t("errors.invalid.positive"),
    },
  });

  return (
    <UserProvider>
      <CompanyProvider>
        <RouterProvider router={router} />
      </CompanyProvider>
    </UserProvider>
  );
}

export default Root;
