import React from "react";
import { Navigate, useLocation } from "react-router";

import { useAuthState } from "hooks/useAuth";
import RocketLoader from "components/ui/loaders/RocketLoader";

function PrivateRoute({ children }) {
  const location = useLocation();
  const { authenticated, loading } = useAuthState();

  if (loading) {
    return <RocketLoader />;
  }
  if (!authenticated) {
    return (
      <Navigate
        to={{
          pathname: "/auth/login",
          state: { from: location },
        }}
      />
    );
  }

  return children;
}

export default PrivateRoute;
