import React from "react";
import { NavLink } from "react-router";
import { useTranslation } from "react-i18next";
import { NormalTimeTask, WorkTimeTask } from "components/ui/boxes/TTTask";
import { useAuthState } from "hooks/useAuth";

function TimeTrackerMenuItem({ agencyId }) {
  const { t } = useTranslation("navigation");
  const { user } = useAuthState();
  const isConsult = user.is_superuser || user.is_consult;
  if (isConsult) {
    return (
      <>
        <li>
          <NavLink end to="/tt/calendar" className="tt" target="_blank">
            <i className="fe-clock" />
            <span> {t("timeTracker")}</span>
            <span className="float-end">
              <i className="fe-external-link" />
            </span>
          </NavLink>
        </li>
        <li>
          {agencyId && <WorkTimeTask agencyId={agencyId} />}
          <NormalTimeTask />
        </li>
      </>
    );
  }
  return null;
}

export default TimeTrackerMenuItem;
