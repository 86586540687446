import React from "react";
import { Card } from "react-bootstrap";
import { useOutletContext } from "react-router";

import TableProvider from "components/tables/btable/provider";
import { TaxAuthorityTable } from "components/tables";
import * as taxAPI from "api2/taxes";

function TaxAuthorityListPage() {
  const company = useOutletContext();

  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId="taxAuthorities"
          dataSource={(filters, config) => taxAPI.authority.list(company.id, filters, config)}
        >
          <TaxAuthorityTable companyId={company.id} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default TaxAuthorityListPage;
