import React, { useCallback, useContext, useState } from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";
import { useOutletContext } from "react-router";
import { useTranslation } from "react-i18next";
import useInitialAsync from "hooks/useInitialAsync";
import * as documentsAPI from "api2/documents";
import { RocketLoader } from "components/ui/loaders";
import { CheckboxFilter, FinancialYearFilter } from "components/filters";
import { FiltersContext } from "state/providers/FiltersProviderRelatedToFY";

import CompanyArchiveCard from "./CompanyArchiveCard";
import { useAuthState } from "../../../../hooks/useAuth";

function CompanyArchiveAuditorPage({ companyId, data, filters, updateFilter, reloadData }) {
  const { t } = useTranslation("common");
  const { user } = useAuthState();
  const isConsult = user.is_superuser || user.is_consult;
  const haveItems =
    data.reduce((sum, nextItem) => {
      return sum + nextItem.items.length;
    }, 0) !== 0;
  return (
    <Card id="company-archive">
      <Card.Body>
        <Row id="filters" className="d-flex flex-row align-items-end">
          <Col lg={3} sm={8}>
            <FinancialYearFilter
              label={t("common:financialYear")}
              name="financial_year"
              isClearable={false}
              defaultValue={filters.financial_year}
              onFilter={(selected) => {
                updateFilter({ financial_year: selected.financial_year });
              }}
            />
          </Col>
          <Col>
            <CheckboxFilter
              label={t("common:archived")}
              name="is_archived"
              wrapperClass="pt-3"
              defaultChecked={!!filters.is_archived}
              defaultValue={!!filters.is_archived}
              onFilter={(selected) => {
                updateFilter({ is_archived: selected.is_archived });
              }}
            />
          </Col>
        </Row>
        <p className="note">{t("company:auditorArchiveCategoryNote")}</p>
        {!haveItems && (
          <Alert variant="info" className="mt-1">
            {t("common:NoDocumentsUploaded")}
          </Alert>
        )}
        <div className="grid-2 mt-2">
          {data
            .filter((child) => (isConsult && !filters.projection ? true : child.items.length))
            .map((child) => (
              <CompanyArchiveCard
                key={child.category}
                companyId={companyId}
                category={child.category}
                items={child.items}
                projection={filters.projection}
                isConsult={isConsult}
                financialYearId={filters.financial_year}
                reloadData={reloadData}
              />
            ))}
        </div>
      </Card.Body>
    </Card>
  );
}

function CompanyArchiveAuditorWrapper() {
  const company = useOutletContext();
  const [counter, setCounter] = useState(1); // to refresh
  const { filters, updateFilter } = useContext(FiltersContext);
  const { loading, item } = useInitialAsync(
    () => {
      return documentsAPI.companyDocuments.sectionAuditor(company.id, filters);
    },
    [],
    [counter, filters.financial_year, filters.projection, filters.is_archived]
  );
  const reloadData = useCallback(() => {
    setCounter((c) => c + 1);
  }, []);

  if (loading) {
    return <RocketLoader />;
  }

  return (
    <CompanyArchiveAuditorPage
      companyId={company.id}
      data={item}
      reloadData={reloadData}
      filters={filters}
      updateFilter={updateFilter}
    />
  );
}

export default CompanyArchiveAuditorWrapper;
