import React from "react";
import { Navigate, useOutletContext } from "react-router";
import _ from "lodash";

import useInitialAsync from "hooks/useInitialAsync";
import * as statsAPI from "api/stats";
import { RocketLoader } from "components/ui/loaders";
import CashFlowTable from "./CashFlowTable";

function CashFlowPage() {
  const company = useOutletContext();
  const { item: data, loading } = useInitialAsync(
    ({ cancelToken }) => statsAPI.cashflowReport(company.id, {}, { cancelToken }),
    {},
    [company.id]
  );
  if (!company.cashflow_enabled) {
    return <Navigate to="/" />;
  }

  if (loading) {
    return <RocketLoader />;
  }

  if (_.isEmpty(data)) {
    return null;
  }

  const updateSimulation = (simulationData) => {
    return statsAPI.cashflowSimulation(company.id, {
      ...simulationData,
      salary: simulationData.salary.filter((sal) => !sal.perm),
    });
  };

  const initial = {};
  const numberOfWeeks = 15;
  initial.incomingBalance = _.fill(Array(numberOfWeeks), 0);
  initial.incomingBalance[0] = data.bank_balance / 1000; // to Ksek
  initial.taxAccount = _.fill(Array(numberOfWeeks), 0);
  initial.taxAccount[0] = data.tax_balance / 1000; // to Ksek
  initial.netSalary = data.net_salary_to_be_paid / 1000;
  initial.ci = data.customer_invoices;
  initial.outlays = _.fill(Array(numberOfWeeks), 0);
  initial.outlays[0] = -data.outlays / 1000; // to Ksek
  initial.taxes = data.taxes_to_be_paid;
  initial.si = data.supplier_invoices.map((invoice) => ({
    id: invoice.id,
    due_date: invoice.due_date,
    amount: -invoice.amount_sek / 1000,
    supplier: invoice.supplier_snapshot__name,
  }));

  initial.simulation = data.cashflow_simulation;
  initial.simulation.salary = [
    ...data.salaries.map((salary) => ({
      employee: `${salary.receiver__first_name} ${salary.receiver__last_name}`,
      amount1: salary.amount_sek / 1000,
      amount2: salary.amount_sek / 1000,
      amount3: salary.amount_sek / 1000,
      perm: true,
    })),
    ...initial.simulation.salary,
  ];
  return (
    <CashFlowTable
      initial={initial}
      company={company}
      numberOfWeeks={numberOfWeeks}
      updateSimulation={updateSimulation}
    />
  );
}

export default CashFlowPage;
