import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import BTable from "components/tables/btable";
import { formatMoney } from "utils/money";
import Filters from "./OrderFilters";
import Actions from "./OrderActions";
import { formatDate } from "../../../utils/date";

function OrderTable({ companyId }) {
  const { t } = useTranslation("common");
  const getRowProps = (row) => {
    let className;
    switch (row.status) {
      case "active":
        if (!row.delivery_date || formatDate(new Date(row.delivery_date)) >= formatDate(new Date())) {
          className = "empty";
        } else {
          className = "unpaid";
        }
        break;
      case "canceled":
        className = "denied";
        break;
      case "invoiced":
        className = "paid";
        break;
      default:
        className = "";
    }
    return {
      className,
    };
  };
  const headers = useMemo(
    () => [
      {
        field: "number",
        label: t("ci:orderNumber"),
      },
      {
        field: "customer_name",
        label: t("common:customer"),
      },
      {
        field: "amount",
        label: t("common:money.amount"),
        className: "text-end",
        render: (value) => `${formatMoney(value.amount)} ${value.amount_currency}`,
      },
      {
        field: "order_date",
        label: t("ci:orderDate"),
      },
      {
        field: "delivery_date",
        label: t("ci:deliveryDate"),
      },
      {
        field: "last_email_sent_date",
        label: t("common:dates.sentDate"),
      },
    ],
    [t]
  );

  return (
    <BTable
      headers={headers}
      selectable={false}
      FiltersComponent={<Filters companyId={companyId} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      getRowProps={getRowProps}
    />
  );
}

export default OrderTable;
