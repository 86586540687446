import React from "react";
import { useNavigate, useOutletContext } from "react-router";

import SalaryArchive from "components/forms/SalaryArchiveForm";
import { formatDate } from "utils/date";

function SalaryArchiveAddPage() {
  const company = useOutletContext();
  const navigate = useNavigate();
  const archive = {
    category: 17,
    title: "",
    description: "",
    month: formatDate(new Date()),
  };

  const onCreate = (newArchive) => {
    navigate(-1);
  };

  return <SalaryArchive companyId={company.id} archive={archive} onSave={onCreate} />;
}

export default SalaryArchiveAddPage;
