import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import BTable from "components/tables/btable";
import { NavLink } from "react-router";
import Filters from "./OnboardingFilters";
import OnboardingProgress from "./OnboardingProgress";

function OnboardingProgressTable() {
  const { t } = useTranslation("common");

  const headers = useMemo(
    () => [
      {
        field: "company_name",
        sortField: "company__name",
        label: t("common:companyName"),
        render: (row) => (
          <NavLink to={`/onboarding/${row.id}`}>
            <span> {row.company_name}</span>
          </NavLink>
        ),
      },
      {
        field: "consult_name",
        sortField: "company__consult__last_name",
        label: t("common:consult"),
      },
      {
        field: "previous_system",
        label: t("company:previousSystem"),
        canSort: false,
      },
      {
        field: "status",
        label: t("common:statuses.status"),
      },
      {
        field: "progress",
        label: t("common:progress"),
        canSort: false,
        render: (row) => <OnboardingProgress companyId={row.id} progress={row.progress} />,
      },
    ],
    [t]
  );

  return (
    <BTable
      headers={headers}
      tableClassName="sticky-header"
      tableResponsive={false}
      FiltersComponent={<Filters forSteps={false} />}
    />
  );
}

export default OnboardingProgressTable;
