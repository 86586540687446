import React from "react";
import { Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { formatMoney } from "utils/money";

function UpcomingEventsTable({ data }) {
  const { t } = useTranslation("reports");
  return (
    <Card>
      <Card.Body className="p-0">
        <Table responsive>
          <thead>
            <tr>
              <th>{t("dashboard.upcomingEvents")}</th>
              <th className="text-end">{t("common:dates.date")}</th>
              <th className="text-end">SEK</th>
            </tr>
          </thead>
          <tbody>
            {data.events.map((event) => (
              <tr key={event.id}>
                <td>{event.description}</td>
                <td className="text-end">{event.due_date}</td>
                <td className={cx("text-end", event.amount > 0 ? "text-danger" : "text-success")}>
                  {formatMoney(event.amount, 0, 0)}
                </td>
              </tr>
            ))}
            {!data.events.length && (
              <tr>
                <td colSpan={3}>{t("common:noResultsFound")}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default UpcomingEventsTable;
