import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router";

function HotKeysComponent() {
  const navigate = useNavigate();

  function goTo(event, location) {
    event.preventDefault();
    navigate(location);
    return false;
  }

  useHotkeys("alt+1", (event) => goTo(event, "/consult/bank-matching"));
  useHotkeys("alt+2", (event) => goTo(event, "/consult/balance"));
  useHotkeys("alt+3", (event) => goTo(event, "/consult/profit-and-loss"));
  useHotkeys("alt+4", (event) => goTo(event, "/consult/profit-and-loss/year"));
  useHotkeys("alt+5", (event) => goTo(event, "/consult/invoicing"));
  useHotkeys("alt+6", (event) => goTo(event, "/consult/supplier-invoices"));
  useHotkeys("alt+7", (event) => goTo(event, "/consult/archive/file-archive"));
  useHotkeys("alt+8", (event) => goTo(event, "/consult/archive/company-archive"));
  useHotkeys("alt+9", (event) => goTo(event, "/consult/ledger"));
  useHotkeys("alt+0", (event) => goTo(event, "/consult/verifications"));
  useHotkeys("alt+f", (event) => goTo(event, "/companies"));

  return null;
}

export default HotKeysComponent;
