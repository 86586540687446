import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import React from "react";

import "pages/dashboard/reports/ViewSwitcher.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

function BudgetViewSwitcher({ view, disabled }) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  return (
    <div className="view-switcher form-group">
      <label className="form-label">{t("common:view")}</label>
      <ToggleButtonGroup className="d-block" name="view">
        <ToggleButton
          id="monthly"
          disabled={disabled}
          variant="switch"
          value="monthly"
          active={view === "monthly"}
          onClick={() => !disabled && navigate("profit-and-loss", { replace: true })}
        >
          {t("standard")}
        </ToggleButton>
        <ToggleButton
          id="yearly"
          type="checkbox"
          value="yearly"
          disabled={disabled}
          variant="switch"
          active={view === "yearly"}
          onChange={() => !disabled && navigate("profit-and-loss/year", { replace: true })}
        >
          12 {t("months")}
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

export default BudgetViewSwitcher;
