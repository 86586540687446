import React from "react";
import { Card } from "react-bootstrap";
import { useOutletContext, useSearchParams } from "react-router";

import TableProvider from "components/tables/btable/provider";
import { FileArchiveTable } from "components/tables";
import * as documentsAPI from "api2/documents";

function FileArchiveListPage({ initialMode, selectable = false, tableId = "fileArchive" }) {
  const company = useOutletContext();
  const [search] = useSearchParams();
  const mode = search.get("mode");
  const initialFilters = {
    status: initialMode || mode || "new",
  };

  return (
    <Card>
      <Card.Body>
        <TableProvider
          tableId={tableId}
          dataSource={(filters, config) => documentsAPI.archiveDocuments.list(company.id, filters, config)}
          initialFilters={initialFilters}
        >
          <FileArchiveTable companyId={company.id} selectable={selectable} />
        </TableProvider>
      </Card.Body>
    </Card>
  );
}

export default FileArchiveListPage;
