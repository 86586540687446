import { useBlocker, useBeforeUnload } from "react-router";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

function UnsavedWarn({ isUnsaved }) {
  const { t } = useTranslation();
  useBlocker(() => {
    if (isUnsaved) {
      return !window.confirm(t("common:confirm.unsavedData"));
    }
    return false;
  });
  useBeforeUnload(
    useCallback(
      (event) => {
        if (isUnsaved) {
          event.preventDefault();
          event.returnValue = "";
        }
      },
      [isUnsaved]
    ),
    { capture: true }
  );
  return null;
}

export default UnsavedWarn;
