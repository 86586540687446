import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router";
import { useTranslation } from "react-i18next";
import NavBar from "components/ui/navbar";
import { RocketLoader } from "components/ui/loaders";
import { SetDocTitle } from "components/ui/titles/PageTitle";
import { useCompanyDispatch, useCompanyState } from "hooks/useCompany";
import OfficeSupportMenu from "./OfficeSupportMenu";

function OfficeSupportLayout() {
  const { t } = useTranslation(["company"]);
  const { company, loading } = useCompanyState();
  const { loadCompany } = useCompanyDispatch();
  const companyId = window.localStorage.getItem("lastCompanyId");
  useEffect(() => {
    loadCompany(companyId, true);
  }, [loadCompany, companyId]);

  if (loading && companyId) {
    return <RocketLoader />;
  }
  if (!company) {
    return <Navigate to="/office-support-companies/" />;
  }
  return (
    <>
      <SetDocTitle title={`${t("officeSupport")} - ${company.name}`} />
      <NavBar company={company} forDashboard={false} />
      <OfficeSupportMenu />
      <div className="content-page office-support-layout">
        <div className="content">
          <div className="container-fluid">
            <Outlet context={company} />
          </div>
        </div>
      </div>
    </>
  );
}

export default OfficeSupportLayout;
