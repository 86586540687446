import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Form, Formik, useFormikContext } from "formik";
import { Button, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import * as options from "api/options";
import * as supportAPI from "api2/office-support";
import { toast } from "react-toastify";
import * as yup from "yup";
import cx from "classnames";
import { allowedFileExtensionsFor } from "utils/file";
import Highlighter from "react-highlight-words";
import useInitialAsync from "../../../hooks/useInitialAsync";
import { RocketLoader } from "../../../components/ui/loaders";
import UploadButton from "./UploadButton";
import {
  ArchiveNoteButton,
  DeleteNoteButton,
  EditButton,
  ExistsAttachmentBadge,
  NewAttachmentBadge,
  UnarchiveNoteButton,
} from "./Buttons";
import { useAuthState } from "../../../hooks/useAuth";
import { formatDatetime } from "../../../utils/date";

function OfficeSupportUpdateNoteContainer({
  companyId,
  note,
  noteIdInEdit,
  setNoteIdInEdit,
  noteIdInChildCreation,
  setNoteIdInChildCreation,
  onUpdate,
  onDelete,
  filters,
}) {
  const { t } = useTranslation("common");
  const { user } = useAuthState();
  const userCanManage = user.id === note.created_by || user.is_superuser;
  const initialShowChildren = filters.term
    ? note.children.filter((child) => child.text.toLowerCase().includes(filters.term.toLowerCase())).length > 0
    : false;
  const [showChildren, setShowChildren] = useState(initialShowChildren);
  const [childrenCount, setChildrenCount] = useState(note.children.length);
  const [hasSavedFile, setHasSavedFile] = useState(note.documents.length > 0);
  useEffect(() => {
    setHasSavedFile(note.documents.length > 0);
  }, [note.documents]);
  const onShowCreateChildNote = () => {
    setNoteIdInEdit(null);
    setShowChildren(true);
    setNoteIdInChildCreation(note.id);
  };
  const [newFile, setNewFile] = useState(null);

  function FormUpdateChecker() {
    const { dirty } = useFormikContext();
    React.useEffect(() => {
      // changeEditedForm({ id: "client-details", dirty });
    }, [dirty]);
    return null;
  }

  const isEditMode = () => noteIdInEdit === note.id;
  const isSomeChildEditMode = () => {
    const childIds = note.children.map(function (el) {
      return el.id;
    });
    return childIds.includes(noteIdInEdit);
  };
  const categoriesOptions = options.notesCategory.asList();
  const onCancel = (resetForm) => {
    resetForm();
    setNoteIdInEdit(null);
    setNewFile(null);
    // onUpdate();
  };
  const onShowHideChildrenClick = () => {
    if (!(isSomeChildEditMode() || noteIdInChildCreation === note.id) || !showChildren) {
      setShowChildren(!showChildren);
    }
  };
  const formikProps = {
    initialValues: {
      text: note.text,
      category: options.notesCategory.getOption(note.category),
    },
    validationSchema: yup.object().shape({
      text: yup.string().required(),
      category: yup.object().nullable().notRequired(),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await supportAPI.notes
        .save(
          companyId,
          {
            id: note.id,
            text: values.text,
            category: values.category ? values.category.value : "",
          },
          newFile
        )
        .then((response) => {
          setNoteIdInEdit(null);
          onUpdate(response.data);
          toast.success(t("msg:saved"), { autoClose: 2000 });
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            setErrors({ ...error.data });
          }
        });
    },
  };
  const editPossible = userCanManage && noteIdInEdit === null && !isSomeChildEditMode() && !noteIdInChildCreation;
  const allowCoreManageActions = editPossible && !note.is_archived;
  const unarchivePossible = editPossible && note.is_archived;
  const currentNoteInAnyEdition =
    noteIdInEdit === note.id || isSomeChildEditMode() || note.id === noteIdInChildCreation;
  const disableNote = (!!noteIdInEdit || !!noteIdInChildCreation) && !currentNoteInAnyEdition;

  function showAddChildrenRow() {
    return (
      <Row className="showAndAddChildrenRow">
        <Col>
          {childrenCount > 0 && (
            <span className="showHideToggleChildren" onClick={() => onShowHideChildrenClick()}>
              <i className={showChildren ? "fe-chevron-up" : "fe-chevron-down"} /> {childrenCount}{" "}
              {childrenCount > 1 ? t("subnotes") : t("subnote")}
              {"  "}
            </span>
          )}
          {!unarchivePossible && (
            <OverlayTrigger
              trigger={["hover", "click"]}
              placement="bottom"
              overlay={<Tooltip id={String(_.uniqueId("tooltip_"))}>{t("common:actions.addNote")}</Tooltip>}
            >
              <Button
                className={cx("add-child-button", {
                  disabled: !!noteIdInEdit || !!noteIdInChildCreation,
                })}
                variant="toggle"
                onClick={onShowCreateChildNote}
              >
                <i className="fe-plus" />
              </Button>
            </OverlayTrigger>
          )}
        </Col>
      </Row>
    );
  }

  return (
    <Card
      className={cx("note-card", {
        "hover-allowed": editPossible,
        archived: note.is_archived,
        shadowed: !note.is_archived && !disableNote,
        disabled: disableNote,
      })}
    >
      <Formik {...formikProps}>
        {({ values, touched, isSubmitting, setFieldValue, errors, resetForm, dirty }) => {
          return (
            <Form key={`note-form-${note.id}`}>
              <FormUpdateChecker />
              <Row className="note-row onHoverIcon flex-nowrap">
                <Col className="number-col">
                  <Highlighter
                    highlightClassName="highlightClass"
                    searchWords={[filters?.term || ""]}
                    autoEscape
                    textToHighlight={`${note.number}`} // `${}` needed - as workaround,
                  />
                </Col>
                <Col className="initialsCol">
                  <div className="initialsCircle">{`${note.creator_first_name.charAt(0)}${note.creator_last_name.charAt(
                    0
                  )}`}</div>
                </Col>
                {isEditMode() ? (
                  <Col>
                    <Row>
                      <Col>
                        <div>
                          <div className="text-and-upload-field-container">
                            <Row>
                              <Col>
                                <FormGroup.AutoHeightEditableArea name="text" />
                              </Col>
                              <Col className="file_upload_col">
                                <UploadButton
                                  fileTypes={allowedFileExtensionsFor.notes}
                                  setFile={setNewFile}
                                  fileAttached={newFile || hasSavedFile}
                                />
                              </Col>
                            </Row>
                          </div>
                          {showAddChildrenRow()}
                        </div>
                      </Col>
                      <Col className="category">
                        <FormGroup.SimpleSelect name="category" options={categoriesOptions} isClearable />
                      </Col>
                      <Col className="right-side-column">
                        <Row>
                          <Col>
                            {newFile && (
                              <NewAttachmentBadge
                                fileName={newFile.name}
                                onDelete={() => {
                                  setNewFile(null);
                                  setHasSavedFile(false);
                                }}
                              />
                            )}
                            {hasSavedFile && (
                              <ExistsAttachmentBadge
                                key={note.documents[0].id}
                                companyId={companyId}
                                note={note}
                                onDeleteSuccess={() => {
                                  setNewFile(null);
                                  setHasSavedFile(false);
                                }}
                                forceShowDeleteButton
                              />
                            )}
                            <Row>
                              <Col className="text-end pe-3 pt-1">
                                <>
                                  <Button
                                    variant="link"
                                    size="lg"
                                    onClick={() => onCancel(resetForm)}
                                    className="cancel-btn"
                                  >
                                    {t("common:actions.cancel")}
                                  </Button>
                                  <SubmitButton
                                    variant="secondary"
                                    isSubmitting={isSubmitting}
                                    // disabled={!dirty && !newFile}
                                    icon=""
                                  />
                                </>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <>
                    <Col>
                      <p>
                        <Highlighter
                          highlightClassName="highlightClass"
                          searchWords={[filters?.term || ""]}
                          autoEscape
                          textToHighlight={note.text}
                        />
                        <span className="created">{formatDatetime(note.created)}</span>
                      </p>
                      {showAddChildrenRow()}
                    </Col>
                    <Col className="right-side-column">
                      <Row>
                        <Col>
                          {values.category && <span className="badge badge-category">{values.category.label}</span>}
                        </Col>
                        <Col>
                          {allowCoreManageActions && (
                            <div className="actions-buttons showOnHover">
                              <EditButton onClick={() => setNoteIdInEdit(note.id)} />
                              <span>
                                <ArchiveNoteButton companyId={companyId} note={note} onSuccessCallback={onDelete} />
                              </span>
                              <span>
                                <DeleteNoteButton companyId={companyId} note={note} onSuccessCallback={onDelete} />
                              </span>
                            </div>
                          )}
                          {unarchivePossible && (
                            <div className="actions-buttons">
                              <UnarchiveNoteButton companyId={companyId} note={note} onSuccessCallback={onDelete} />
                              <span>
                                <DeleteNoteButton companyId={companyId} note={note} onSuccessCallback={onDelete} />
                              </span>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row className="parent-note-exists-attachment-row">
                        <Col>
                          {hasSavedFile && (
                            <ExistsAttachmentBadge
                              companyId={companyId}
                              note={note}
                              onDeleteSuccess={() => {
                                setNewFile(null);
                                setHasSavedFile(false);
                                onUpdate();
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </Form>
          );
        }}
      </Formik>
      {showChildren && (
        <OfficeSupportChildrenComponent
          key={`children_for_${note.id}`}
          companyId={companyId}
          noteId={note.id}
          noteIdInEdit={noteIdInEdit}
          setNoteIdInEdit={setNoteIdInEdit}
          noteIdInChildCreation={noteIdInChildCreation}
          setNoteIdInChildCreation={setNoteIdInChildCreation}
          childrenCount={childrenCount}
          setChildrenCount={setChildrenCount}
          filters={filters}
          updateParent={onUpdate}
        />
      )}
    </Card>
  );
}

function OfficeSupportChildrenComponent({
  companyId,
  noteId,
  noteIdInEdit,
  setNoteIdInEdit,
  noteIdInChildCreation,
  setNoteIdInChildCreation,
  childrenCount,
  setChildrenCount,
  filters,
  updateParent,
}) {
  const { user } = useAuthState();
  const [counterRefresher, setCounterRefresher] = useState(1); // to refresh
  const elementToScroll = useRef(null);
  const { loading, item: note } = useInitialAsync(
    ({ cancelToken }) => {
      return supportAPI.notes.details(companyId, noteId, { cancelToken });
    },
    {},
    [counterRefresher]
  );
  useEffect(() => {
    if (noteIdInChildCreation === note.id) {
      elementToScroll.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
  }, [noteIdInChildCreation, note.id]);
  if (loading) {
    return <RocketLoader />;
  }
  const refreshChildren = () => {
    setCounterRefresher(counterRefresher + 1);
  };
  const onCreateChildNote = () => {
    updateParent({ prevent_refresh: true });
    refreshChildren();
    setChildrenCount(childrenCount + 1);
    setNoteIdInChildCreation(null);
  };
  const onDeleteChildNote = () => {
    refreshChildren();
    setChildrenCount(childrenCount - 1);
  };
  const onUpdateChildNote = () => {
    refreshChildren();
    setNoteIdInEdit(null);
  };
  const turnOffCreation = () => {
    setNoteIdInChildCreation(null);
  };
  const turnOffEdit = () => {
    setNoteIdInEdit(null);
  };
  const allowActions = noteIdInEdit === null && !note.is_archived && !noteIdInChildCreation;
  return (
    <>
      {note.children.map((child, index) => {
        return (
          <OfficeSupportChildContainer
            key={`child_${child.id}`}
            companyId={companyId}
            parentNote={note}
            childNumber={index + 1}
            inEditMode={noteIdInEdit === child.id}
            setNoteIdInEdit={setNoteIdInEdit}
            filters={filters}
            child={child}
            allowActions={allowActions}
            onSaveCallback={onUpdateChildNote}
            onCancelCallback={turnOffEdit}
            onDeleteCallback={onDeleteChildNote}
          />
        );
      })}
      {noteIdInChildCreation === note.id && (
        <div ref={elementToScroll}>
          <OfficeSupportChildContainer
            companyId={companyId}
            parentNote={note}
            childNumber={note.children.length + 1}
            inEditMode
            setNoteIdInEdit={setNoteIdInEdit}
            filters={filters}
            child={{
              id: null,
              creator_first_name: user.first_name,
              creator_last_name: user.last_name,
              text: "",
            }}
            onCancelCallback={turnOffCreation}
            onSaveCallback={onCreateChildNote}
          />
        </div>
      )}
    </>
  );
}

function OfficeSupportChildContainer({
  companyId,
  parentNote,
  childNumber,
  inEditMode,
  setNoteIdInEdit,
  filters,
  child,
  allowActions,
  onSaveCallback,
  onCancelCallback,
  onDeleteCallback,
}) {
  const { t } = useTranslation("company");
  const [hasSavedFile, setHasSavedFile] = useState(child.id && child.documents.length > 0);
  const [newFile, setNewFile] = useState(null);
  const { user } = useAuthState();
  const userCanManage = user.id === child.created_by || user.is_superuser;
  const editPossible = userCanManage && allowActions;
  const onCancel = (resetForm) => {
    resetForm();
    setNewFile(null);
    onCancelCallback();
  };
  const formikProps = {
    initialValues: {
      ...child,
    },
    validationSchema: yup.object().shape({
      // name: yup.string().required(),
    }),
    onSubmit: async (values, { setErrors, resetForm }) => {
      await supportAPI.notes.child
        .save(
          companyId,
          parentNote.id,
          {
            id: child.id,
            text: values.text,
          },
          newFile
        )
        .then((response) => {
          resetForm({ values });
          if (child.id) {
            toast.success(t("msg:saved"), { autoClose: 2000 });
          } else {
            toast.success(t("msg:created"), { autoClose: 2000 });
          }
          onSaveCallback();
        })
        .catch((error) => {
          toast.error(t("msg:fixErrors"));
          if (error.data.__all__) {
            setErrors({ __all__: error.data.__all__ });
          } else {
            setErrors({ ...error.data });
          }
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ values, touched, isSubmitting, setFieldValue, errors, resetForm, dirty }) => {
        return (
          <Form key={`child-note-form-${child.id || "creation"}`}>
            <div className="child-container">
              <Row
                className={cx("child-row onHoverIcon", {
                  "hover-allowed": editPossible,
                })}
              >
                <Col className="number-col">{`${parentNote.number}.${childNumber}`}</Col>
                <Col className="initialsCol">
                  <div className="initialsCircle">{`${child.creator_first_name.charAt(
                    0
                  )}${child.creator_last_name.charAt(0)}`}</div>
                </Col>
                <Col>
                  {inEditMode ? (
                    <Row>
                      <Col className="text-and-upload-field-container">
                        <Row>
                          <Col>
                            <FormGroup.AutoHeightEditableArea name="text" />
                          </Col>
                          <Col className="file_upload_col">
                            <UploadButton
                              fileTypes={allowedFileExtensionsFor.notes}
                              setFile={setNewFile}
                              fileAttached={newFile || hasSavedFile}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col className="submit-col">
                        <Button type="submit" variant="toggle">
                          <i className="fe-check" />
                        </Button>
                        <Button variant="toggle" onClick={() => onCancel(resetForm)}>
                          <i className="fe-x" />
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <p>
                      <Highlighter
                        highlightClassName="highlightClass"
                        searchWords={[filters?.term || ""]}
                        autoEscape
                        textToHighlight={child.text}
                      />
                      <span className="created">{formatDatetime(child.created)}</span>
                    </p>
                  )}
                </Col>
                {!inEditMode && editPossible && (
                  <Col className="extra-icons-row">
                    <div className="showOnHover">
                      <EditButton onClick={() => setNoteIdInEdit(child.id)} />
                      <DeleteNoteButton
                        companyId={companyId}
                        note={{ ...child, parentId: parentNote.id }}
                        onSuccessCallback={onDeleteCallback}
                      />
                    </div>
                  </Col>
                )}
                <Col className="right-side-column">
                  {newFile && (
                    <NewAttachmentBadge
                      fileName={newFile.name}
                      onDelete={() => {
                        setNewFile(null);
                        setHasSavedFile(false);
                      }}
                    />
                  )}
                  {hasSavedFile && (
                    <ExistsAttachmentBadge
                      key={child.documents[0].id}
                      companyId={companyId}
                      note={child}
                      onDeleteSuccess={() => setHasSavedFile(false)}
                      forceShowDeleteButton={inEditMode}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default OfficeSupportUpdateNoteContainer;
