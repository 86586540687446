import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useResolvedPath } from "react-router";
import * as offerAPI from "api2/offers";
import { confirmExecute } from "components/modals/ConfirmModal";
import { HistoryButton, TooltipActionButton, TooltipActionLink } from "components/ui/buttons";
import useTable from "components/tables/btable/useTable";
import { FileUrlModal } from "components/modals";
import useModal from "hooks/useModal";
import OfferModal from "./OfferModal";

function OfferActions({ row, companyId }) {
  const { t } = useTranslation("ci");
  const path = useResolvedPath("").pathname;
  const fileModal = useModal();
  const offerAcceptedModal = useModal();
  const {
    dataActions: { reload },
  } = useTable();

  const show = {
    acceptBtn: row.status === "active",
    deniedBtn: row.status === "active",
    sentBtn: row.status === "active",
    editBtn: row.status === "active",
  };
  const openPreviewModal = () => {
    fileModal.open(`/offers/${row.id}/pdf/`);
  };

  const onSentToCustomer = async () => {
    const answer = await confirmExecute(
      t("confirm.sendOffer", {
        customer: row.customer_name,
        email: row.customer_emails || `${t("common:contact.emailShort")} ${t("common:notSet")}`,
      })
    );

    if (answer) {
      offerAPI
        .sendToCustomer(companyId, row.id)
        .then(() => {
          toast.success(t("msg:sent"));
          reload();
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };

  const onAccept = () => {
    offerAcceptedModal.open();
  };

  const onDeny = async () => {
    const answer = await confirmExecute(t("confirm.denyOffer", { customerName: row.customer_name }));
    if (answer) {
      offerAPI
        .denyOffer(companyId, row.id)
        .then(() => {
          toast.success(t("common:msg:denied"));
          reload();
        })
        .catch((error) => {
          if (error.data.__all__) {
            toast.error(error.data.__all__);
          } else {
            toast.error(t("msg:canNotExecuteAction"));
          }
        });
    }
  };
  return (
    <>
      <HistoryButton apiResource={offerAPI.history} apiParams={[companyId, row.id]} />
      <TooltipActionButton text={t("common:actions.preview")} onClick={openPreviewModal} icon="fas fa-images" />
      {show.sentBtn && (
        <TooltipActionButton
          text={t("actions.sendOfferToCustomer")}
          onClick={onSentToCustomer}
          icon="fas fa-paper-plane"
        />
      )}
      {show.editBtn && (
        <TooltipActionLink text={t("common:actions.change")} link={`${path}/${row.id}/change`} icon="fas fa-pen" />
      )}
      <TooltipActionLink text={t("common:actions.duplicate")} link={`${path}/${row.id}/duplicate`} icon="fas fa-copy" />
      {show.acceptBtn && (
        <TooltipActionButton text={t("actions.acceptOffer")} onClick={onAccept} icon="fas fa-thumbs-up" />
      )}
      {show.deniedBtn && (
        <TooltipActionButton text={t("actions.denyOffer")} onClick={onDeny} icon="fas fa-thumbs-down" />
      )}
      {fileModal.show && <FileUrlModal companyId={companyId} fileUrl={fileModal.data} handleClose={fileModal.close} />}
      {offerAcceptedModal.show && (
        <OfferModal companyId={companyId} row={row} handleClose={offerAcceptedModal.close} reload={reload} />
      )}
    </>
  );
}

export default OfferActions;
