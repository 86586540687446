import React from "react";
import { Outlet, useLocation, useOutletContext } from "react-router";

import SupplierInvoiceNavTabs from "./SupplierInvoiceNavTabs";

function SupplierInvoiceLayout() {
  const company = useOutletContext();
  const path = useLocation().pathname;
  const showTabs = ["/reports"].every((key) => !path.includes(key));

  return (
    <div className="tab-card">
      {showTabs && <SupplierInvoiceNavTabs />}
      <Outlet context={company} />
    </div>
  );
}

export default SupplierInvoiceLayout;
