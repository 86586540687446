import React, { useContext } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { truncateText } from "utils/text";
import { formatMoney } from "utils/money";
import { formatDate } from "utils/date";
import { IsoBasketContext } from "../IsoBasketProvider";

function ISOSentStage({ handleClose }) {
  const { t } = useTranslation("common");
  const { bankFileName, bankUrl, payments } = useContext(IsoBasketContext);

  const openBankPage = () => {
    window.open(bankUrl, "_blank");
  };
  return (
    <>
      <div className="title">
        <h2>{t("others:iso.fileSummary", { bankFileName })}</h2>
        <h4 className="text-warning">
          <i className="fe-send" /> {t("others:iso.fileSentOk")}
        </h4>
      </div>
      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th style={{ width: 320 }}>{t("receiver")}</th>
              <th className="text-end">{t("basket.refOrOcr")}</th>
              <th className="text-end" style={{ minWidth: 120 }}>
                {t("dates.paymentDate")}
              </th>
              <th className="text-end">{t("money.amount")}</th>
              <th className="text-end">{t("statuses.status")}</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((item) => (
              <tr key={item.id} className="state state--partially_signed">
                <td>
                  <span>{item.title}</span>
                </td>
                <td className="text-end" title={item.reference}>
                  {truncateText(item.reference, 30)}
                </td>
                <td className="text-end">{formatDate(item.payment_date)}</td>
                <td className="text-end">
                  {formatMoney(item.payment_amount, 2, 2)} {item.payment_currency}
                </td>
                <td className="text-end">
                  <i className="fe-check-circle" />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={handleClose}>
          {t("common:actions.cancel")}
        </Button>
        <Button variant="primary" onClick={openBankPage} disabled={!bankUrl}>
          {t("others:iso.signFileInBank")}
        </Button>
      </Modal.Footer>
    </>
  );
}

export default ISOSentStage;
