import React, { useContext, useState } from "react";
import {
  AgencyFilter,
  CompanyConsultFilter,
  MonthDateFilter,
  QuarterDateFilter,
  SearchFilter,
} from "components/filters";
import { Button, ButtonGroup } from "react-bootstrap";
import { formatDate } from "utils/date";
import useTable from "components/tables/btable/useTable";
import { useTranslation } from "react-i18next";
import { addDays, lastDayOfQuarter, subQuarters } from "date-fns";
import * as vatAPI from "api2/vat";
import { ContactContext } from "./ContactContext";

function firstDayOfQuarter(date) {
  const lastDay = lastDayOfQuarter(date);
  return addDays(subQuarters(lastDay, 1), 1);
}

function ContactForm() {
  const { t } = useTranslation("common");
  const { state: contactState, setState } = useContext(ContactContext);

  const updateField = (event) => {
    setState((s) => ({ ...s, [event.target.name]: event.target.value }));
  };
  const saveContactPhone = (event) => {
    localStorage.setItem("contactPhone", event.target.value);
  };
  return (
    <>
      <div className="form-group">
        <label>
          {t("contact.contactPerson")}
          <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          name="contactName"
          value={contactState.contactName}
          onChange={updateField}
          className="form-control form-control-sm"
        />
      </div>
      <div className="form-group">
        <label>
          {t("contact.contactPhone")}
          <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          name="contactPhone"
          value={contactState.contactPhone}
          onChange={updateField}
          onBlur={saveContactPhone}
          className="form-control form-control-sm"
        />
      </div>
      <div className="form-group">
        <label>{t("contact.contactEmail")}</label>
        <input
          type="email"
          name="contactEmail"
          value={contactState.contactEmail}
          onChange={updateField}
          className="form-control form-control-sm"
        />
      </div>
    </>
  );
}

function MonthlyQuarterlySwitch({ value, setFilters, lastMonthSelected, lastQuarterSelected }) {
  const { t } = useTranslation("common");
  return (
    <ButtonGroup className="mb-2">
      <Button
        variant="switch"
        active={value === "monthly"}
        value="monthly"
        onClick={(e) => {
          setFilters({ period: e.currentTarget.value, period_month: lastMonthSelected });
        }}
      >
        {t("common:monthly")}
      </Button>
      <Button
        variant="switch"
        active={value === "quarterly"}
        value="quarterly"
        onClick={(e) => {
          setFilters({
            period: e.currentTarget.value,
            period_month: firstDayOfQuarter(lastQuarterSelected),
          });
        }}
      >
        {t("common:quarterly")}
      </Button>
    </ButtonGroup>
  );
}

function ScanAllButton() {
  const { t } = useTranslation("common");
  const { filters, setFilters } = useTable();
  const [loading, setLoading] = useState(false);
  const onScan = () => {
    setLoading(true);
    return vatAPI.vatEU
      .scanAll({ ...filters, period_month: formatDate(filters.period_month) })
      .then(() => {
        if (filters.period === "monthly") {
          setFilters({
            show_all: null,
            ordering: "euvat_status",
          });
        } else {
          setFilters({ show_all: null, ordering: "euvat_status" });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Button variant="primary" onClick={onScan} disabled={loading} style={{ minWidth: 120 }}>
      {t("actions.scanAll")}
    </Button>
  );
}

function EuVatFilters() {
  const { filters, setFilters } = useTable();
  const [lastMonthSelected, setLastMonth] = useState(filters.period_month);
  const [lastQuarterSelected, setLastQuarter] = useState(filters.period_month);
  const { t } = useTranslation("company");
  return (
    <div className="vat-filters mb-2">
      <div className="filters-left">
        <div className="filters-row">
          <SearchFilter onFilter={setFilters} defaultValue={filters.term} width={300} autoFocus />
          <CompanyConsultFilter
            isClearable
            onFilter={setFilters}
            defaultValue={filters.consult}
            placeholder={t("common:consult")}
          />
          <AgencyFilter
            name="agency"
            onFilter={setFilters}
            placeholder={t("common:agency")}
            defaultValue={filters.agency}
          />
          <Button
            variant="toggle"
            onClick={() => setFilters({ favourites: filters.favourites ? null : true })}
            active={filters.favourites}
          >
            {t("favouritesOnly")}
          </Button>
        </div>
        <div className="filters-row">
          <MonthlyQuarterlySwitch
            value={filters.period}
            setFilters={setFilters}
            lastMonthSelected={lastMonthSelected}
            lastQuarterSelected={lastQuarterSelected}
          />
          {filters.period === "monthly" ? (
            <MonthDateFilter
              isClearable={false}
              defaultValue={filters.period_month}
              onChange={(date) => {
                setLastMonth(date || new Date());
                setFilters({ period_month: date || new Date() });
              }}
            />
          ) : (
            <QuarterDateFilter
              isClearable={false}
              defaultValue={filters.period_month}
              onChange={(date) => {
                setLastQuarter(date || firstDayOfQuarter(new Date()));
                setFilters({ period_month: date || firstDayOfQuarter(new Date()) });
              }}
            />
          )}
          <ScanAllButton />
          <Button
            style={{ minWidth: 180 }}
            className="ms-1"
            variant="toggle"
            onClick={() => setFilters({ show_all: filters.show_all ? null : true })}
            active={filters.show_all}
          >
            {t("showAllCompanies")}
          </Button>
        </div>
      </div>
      <div className="filters-right">
        <ContactForm />
      </div>
    </div>
  );
}

export default EuVatFilters;
