import React, { useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { createRoot } from "react-dom/client";
import { toast } from "react-toastify";
import { DraggableModalDialog } from "../DraggableModalDialog";

function InternalTaskFinishModal({ giveAnswer, task, close }) {
  const { t } = useTranslation(["tt", "common"]);
  const ref = useRef();
  const yesCharge = () => {
    const comment = ref.current.value.trim();
    if (!comment) {
      toast.error(t("chargeComment"));
    } else {
      giveAnswer(true, comment);
    }
  };

  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={() => giveAnswer(undefined, "")}
      dialogClassName="confirmation-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title className="m-0">{t("common:comment")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Control as="textarea" defaultValue={task.comment || ""} rows={3} ref={ref} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="primary" className="float-end" onClick={yesCharge}>
          <span> {t("common:actions.save")} </span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const confirmInternalFinish = (task) =>
  new Promise((res) => {
    const root = createRoot(document.createElement("div"));
    const giveAnswer = (answer, comment) => {
      root.unmount();
      res({ answer, comment });
    };

    root.render(<InternalTaskFinishModal task={task} giveAnswer={giveAnswer} />);
  });

export default confirmInternalFinish;
